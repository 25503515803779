import { sumWithNull } from "common/utils/numberFormatHelpers";
import { ColumnParams } from "././pnlTypes";
import { CellClassParams, ValueGetterParams } from "@ag-grid-community/core";

export const getColumnDefs = (params: ColumnParams): any[] => {
    const { columnState, desks, findDeskByName, defaultColumnWidth, valueFormatter, includeYearToDatePnl } = params;

    return [
        {
            colId: "deskNumber",
            field: "deskNumber",
            headerName: "Desk Number",
            width: columnState ? columnState["deskNumber"] : defaultColumnWidth,
            aggFunc: ({ rowNode: { key } }: any) => {
                if (!key || desks.length === 0) return;
                const desk = findDeskByName(key, desks);
                return desk?.deskNumber;
            },
        },
        {
            colId: "dayPnl",
            headerName: "Day PnL",
            type: "numericColumn",
            field: "dayPnl",
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => (data ? data.dayPnl || "" : ""),
            enableValue: true,
            aggFunc: sumWithNull,
            width: columnState ? columnState["dayPnl"] : defaultColumnWidth,
        },
        {
            colId: "overnightPnl",
            headerName: "Overnight PnL",
            type: "numericColumn",
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => (data ? data.overnightPnl || "" : ""),
            enableValue: true,
            aggFunc: sumWithNull,
            width: columnState ? columnState["overnightPnl"] : defaultColumnWidth,
        },
        {
            colId: "brokerage",
            headerName: "Brokerage",
            type: "numericColumn",
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => (data ? data.brokerage || "" : ""),
            enableValue: true,
            aggFunc: sumWithNull,
            width: columnState ? columnState["brokerage"] : defaultColumnWidth,
        },
        {
            colId: "abnTradeFees",
            headerName: "ABN Cost",
            type: "numericColumn",
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => (data ? data.abnTradeFees || "" : ""),
            enableValue: true,
            aggFunc: sumWithNull,
            width: columnState ? columnState["abnTradeFees"] : defaultColumnWidth,
        },
        {
            colId: "exchangeTradeFees",
            headerName: "Exchange Cost",
            type: "numericColumn",
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => (data ? data.exchangeTradeFees || "" : ""),
            enableValue: true,
            aggFunc: sumWithNull,
            width: columnState ? columnState["exchangeTradeFees"] : defaultColumnWidth,
        },
        {
            colId: "netPnl",
            headerName: "Net PnL",
            type: "numericColumn",
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => (data ? data.netPnl || "" : ""),
            enableValue: true,
            aggFunc: sumWithNull,
            width: columnState ? columnState["netPnl"] : defaultColumnWidth,
        },
        ...(includeYearToDatePnl
            ? [
                  {
                      colId: "yearToDatePnl",
                      headerName: "YTD PnL T-1",
                      type: "numericColumn",
                      cellClassRules: {
                          "text-red-600": (params: CellClassParams) => params.value < 0,
                      },
                      valueFormatter: valueFormatter,
                      valueGetter: ({ data }: ValueGetterParams) => (data ? data.yearToDatePnl || "" : ""),
                      enableValue: true,
                      aggFunc: "sum",
                      width: columnState ? columnState["yearToDatePnl"] : defaultColumnWidth,
                      hide: !includeYearToDatePnl,
                  },
              ]
            : []),
    ];
};
