import { tenorPeriods } from "common/constants";
import { getTenor2Year } from "common/utils/getTenor2Year";
import { getYearFromPeriod } from "common/utils/getYearFromPeriod";
import { isCurrentPeriod } from "common/utils/isCurrentPeriod";
import { format } from "date-fns";
import { blotterEntryTenorsSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import * as React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Tenor } from "./tenor";

const today = new Date();

export const Tenors: React.FC = () => {
    const dispatch = useDispatch();
    const {
        tenor1Period,
        tenor1Year,
        tenor1StartDate,
        tenor1EndDate,
        tenor2Period,
        tenor2Year,
        tenor2StartDate,
        tenor2EndDate,
        pricingDay,
    } = useSelector(blotterEntryTenorsSelector, shallowEqual);

    const handleOnStartDateSelected = (date: Date | null, tenor: Common.Tenor) => {
        let newPricingDay = pricingDay;

        if (date && tenor === "tenor1") {
            if (date.getMonth() !== today.getMonth()) {
                newPricingDay = null;
            }
        }

        dispatch(
            updateBlotterEntry({
                [`${tenor}Period`]: date ? null : undefined,
                [`${tenor}Year`]: date ? null : undefined,
                [`${tenor}StartDate`]: date ? format(date, "yyyy-MM-dd") : null,
                pricingDay: newPricingDay,
            }),
        );
    };

    const handleOnEndDateSelected = (date: Date | null, tenor: Common.Tenor) => {
        let newPricingDay = pricingDay;

        if (date && tenor === "tenor1") {
            if (date.getMonth() !== today.getMonth()) {
                newPricingDay = null;
            }
        }

        dispatch(
            updateBlotterEntry({
                [`${tenor}Period`]: date ? null : undefined,
                [`${tenor}Year`]: date ? null : undefined,
                [`${tenor}EndDate`]: date ? format(date, "yyyy-MM-dd") : null,
                pricingDay: newPricingDay,
            }),
        );
    };

    const handleOnTenorPeriodSelected = (period: Common.Period, tenor: Common.Tenor) => {
        let year = getYearFromPeriod(period);
        let newPricingDay = pricingDay;

        if (tenor === "tenor1") {
            if (!isCurrentPeriod(period, year, tenor1StartDate, tenor1EndDate)) {
                newPricingDay = null;
            }
        }

        if (tenor === "tenor2") {
            const t1Period = tenorPeriods.find((t) => t.name === tenor1Period);
            year = getTenor2Year(t1Period, tenor1Year, period);
        }

        const isDeselected = period.name === { tenor1Period, tenor2Period }[`${tenor}Period`];

        dispatch(
            updateBlotterEntry({
                [`${tenor}Period`]: isDeselected ? null : period.name,
                [`${tenor}Year`]: isDeselected ? null : year,
                [`${tenor}StartDate`]: null,
                [`${tenor}EndDate`]: null,
                pricingDay: newPricingDay,
            }),
        );
    };

    const handleOnTenorYearSelected = (year: string, tenor: Common.Tenor) => {
        const today = new Date();
        let newPricingDay = pricingDay;

        if (tenor === "tenor1") {
            if (today.getFullYear().toString() !== year) {
                newPricingDay = null;
            }
        }

        const isDeselected = year === { tenor1Year, tenor2Year }[`${tenor}Year`];

        dispatch(
            updateBlotterEntry({
                [`${tenor}Year`]: isDeselected ? null : year,
                [`${tenor}StartDate`]: null,
                [`${tenor}EndDate`]: null,
                pricingDay: newPricingDay,
            }),
        );
    };

    return (
        <>
            <Tenor
                id="tenor1"
                title="Tenor 1"
                period={tenor1Period}
                year={tenor1Year}
                startDate={tenor1StartDate}
                endDate={tenor1EndDate}
                onStartDateSelected={(date) => handleOnStartDateSelected(date, "tenor1")}
                onEndDateSelected={(date) => handleOnEndDateSelected(date, "tenor1")}
                onTenorPeriodSelected={(period) => handleOnTenorPeriodSelected(period, "tenor1")}
                onTenorYearSelected={(year) => handleOnTenorYearSelected(year, "tenor1")}
            />
            <Tenor
                id="tenor2"
                title="Tenor 2"
                period={tenor2Period}
                year={tenor2Year}
                startDate={tenor2StartDate}
                endDate={tenor2EndDate}
                onStartDateSelected={(date) => handleOnStartDateSelected(date, "tenor2")}
                onEndDateSelected={(date) => handleOnEndDateSelected(date, "tenor2")}
                onTenorPeriodSelected={(period) => handleOnTenorPeriodSelected(period, "tenor2")}
                onTenorYearSelected={(year) => handleOnTenorYearSelected(year, "tenor2")}
            />
        </>
    );
};
