import { Dropdown } from "common/components/dropdown";
import { FormLabel } from "common/components/form";
import { blotterEntryCounterPartyTypeIdSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import { NOT_SELECTED_COUNTERPARTY_ID } from "features/blotter/hooks/constants";
import { counterPartyTypesSelector } from "features/counterPartyTypes/counterPartyTypesSlice";
import { fetchCounterPartyTypes } from "features/counterPartyTypes/counterPartyTypesThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const CounterPartyType: React.FC = () => {
    const dispatch = useDispatch();
    const counterPartyTypes = useSelector(counterPartyTypesSelector);
    const counterPartyTypeId = useSelector(blotterEntryCounterPartyTypeIdSelector);

    React.useEffect(() => {
        dispatch(fetchCounterPartyTypes());
    }, []);

    React.useEffect(() => {
        if (!counterPartyTypeId && counterPartyTypes && counterPartyTypes.length > 0) {
            dispatch(updateBlotterEntry({ counterPartyTypeId: counterPartyTypes[0].counterPartyTypeId }));
        }
    }, [counterPartyTypeId]);

    const handleSelection = (selectedCounterPartyType?: Common.DropdownItem) => {
        dispatch(
            updateBlotterEntry({ counterPartyTypeId: selectedCounterPartyType ? selectedCounterPartyType.id : null }),
        );
        return true;
    };

    return (
        <div className="flex flex-col w-32 blotterEntryForm__spacing" data-testid="counterPartyType">
            <div className="flex justify-center">
                <FormLabel>CP Type</FormLabel>
            </div>
            {counterPartyTypes && (
                <Dropdown
                    allowEmptySelection={false}
                    selectedId={counterPartyTypeId || NOT_SELECTED_COUNTERPARTY_ID}
                    data={counterPartyTypes.map((e) => ({ id: e.counterPartyTypeId, value: e.name }))}
                    onChange={handleSelection}
                />
            )}
        </div>
    );
};
