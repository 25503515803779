import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { TradedUnitsAPIResponse, State } from "./types";

const initialState: State = {
    tradedUnits: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getTradedUnitsSlice = createSlice({
    name: "tradedUnits",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<{ status: Common.Status }>) => {
            state.meta.status = action.payload.status;
            return state;
        },
        getTradedUnitsSuccess: (state, action: PayloadAction<TradedUnitsAPIResponse>) => {
            state.tradedUnits = action.payload.tradedUnits;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getTradedUnitsFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
    },
});

export const { setStatus, getTradedUnitsSuccess, getTradedUnitsFailed } = getTradedUnitsSlice.actions;

const selectTradedUnits = (state: RootState) => state.features.tradedUnits;
export const tradedUnitsSelector = createSelector(selectTradedUnits, (tradedUnits) => tradedUnits);
