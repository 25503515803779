import { format } from "date-fns";

export const getTenor1Period = (
    tenor1StartDate: Common.Nullable<string>,
    tenor1EndDate: Common.Nullable<string>,
    tenor1Period: Common.Nullable<string>,
    date: Date,
) => {
    return tenor1StartDate || tenor1EndDate ? null : tenor1Period || format(date, "MMM");
};
