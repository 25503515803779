import { BlotterTrade } from "features/blotter/blotterTypes";
import { NOT_SELECTED_COUNTERPARTY_ID } from "features/blotter/hooks/constants";
import { counterPartyTypesSelector, statusSelector } from "features/counterPartyTypes/counterPartyTypesSlice";
import { fetchCounterPartyTypes } from "features/counterPartyTypes/counterPartyTypesThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const CounterPartyTypeDropdownEditor = React.forwardRef((props: { value: string; data: BlotterTrade }, ref) => {
    const counterPartyTypes = useSelector(counterPartyTypesSelector);
    const status = useSelector(statusSelector);
    const [value, setValue] = React.useState(props.value || "");

    const dispatch = useDispatch();
    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useEffect(() => {
        if (status === "init") {
            dispatch(fetchCounterPartyTypes());
        }
    }, [status]);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value || null;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(event.currentTarget.value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    return (
        <select
            data-testid="counterPartyType-dropdown-editor"
            onChange={handleChange}
            className="w-full"
            ref={refInput}
            value={value}
        >
            {counterPartyTypes
                ?.filter((a) => a.counterPartyTypeId !== NOT_SELECTED_COUNTERPARTY_ID)
                .map((t) => (
                    <option key={t.counterPartyTypeId} value={t.name}>
                        {t.name}
                    </option>
                ))}
        </select>
    );
});
