import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { format, parseISO } from "date-fns";
import { selectedDeskSelector } from "features/desks/desksSlice";
import {
    BlotterTrade,
    BlotterTradeDto,
    BlotterTradePnlRealTimeMessage,
    BlotterTradeRealTimeMessage,
    BlotterTradesApiResponse,
    CreateBlotterTrade,
    isBlotterTradePnlMessage,
    State,
} from "./blotterTypes";

const blotterDate = format(new Date(), "yyyy-MM-dd");

const emptyBlotterEntry = {
    id: undefined,
    deskId: null,
    date: null,
    price: null,
    side: null,
    quantity: null,
    tradedUnitId: null,
    blotterProductGroupId: null,
    tenor1Period: null,
    tenor1Year: null,
    tenor1StartDate: null,
    tenor1EndDate: null,
    tenor2Period: null,
    tenor2Year: null,
    tenor2StartDate: null,
    tenor2EndDate: null,
    exchangeId: null,
    brokerageAdjustment: null,
    pricingDay: null,
    brokerId: null,
    counterPartyTypeId: null,
    notes: null,
    isStrip: false,
    transferDeskId: null,
};

const initialState: State = {
    blotterDate,
    blotterEntry: emptyBlotterEntry,
    blotterTrades: undefined,
    blotterTradeToDelete: undefined,
    blotterTradeToDuplicate: undefined,
    showBlotterEntryForm: true,
    productGroupText: "",
    brokerText: "",
    validationMessage: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
    lastRealTimeMessage: undefined,
    signalRGroupName: undefined,
    signalRConnectionId: undefined,
    autoUpdate: true,
    affectedBlotterTradeIds: [],
    isFilterEnabled: false,
    isBlotterUpdateRequired: false,
    editingRow: null,
};

export const getBlotterSlice = createSlice({
    name: "blotter",
    initialState,
    reducers: {
        initialiseBlotterEntry: (state, action: PayloadAction<{ date: string; deskId: number }>) => {
            state.blotterEntry = {
                ...(state.blotterEntry as CreateBlotterTrade),
                date: action.payload.date,
                deskId: action.payload.deskId,
            };
            return state;
        },
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        setProductGroupText: (state, action: PayloadAction<string>) => {
            state.productGroupText = action.payload;
            return state;
        },
        setBrokerText: (state, action: PayloadAction<string>) => {
            state.brokerText = action.payload;
            return state;
        },
        setBrokerId: (state, action: PayloadAction<number | null>) => {
            state.blotterEntry.brokerId = action.payload;
            return state;
        },
        getBlotterTradesSuccess: (state, action: PayloadAction<BlotterTradesApiResponse>) => {
            state.blotterTrades = action.payload.blotterTrades;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getBlotterTradesFailed: (state, action: PayloadAction<string>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload;
            return state;
        },
        updateBlotterTrade: (state, action: PayloadAction<BlotterTradeDto>) => {
            state.blotterTrades = state.blotterTrades
                ? state.blotterTrades.map((b) => {
                      if (b.id === action.payload.id) {
                          return {
                              ...b,
                              ...action.payload,
                          };
                      }

                      return b;
                  })
                : state.blotterTrades;

            return state;
        },
        setBlotterTradeToDelete: (state, action: PayloadAction<number | undefined>) => {
            state.blotterTradeToDelete = state.blotterTrades?.find((b) => b.id === action.payload);
            return state;
        },
        setBlotterTradeToDuplicate: (state, action: PayloadAction<number | undefined>) => {
            state.blotterTradeToDuplicate = state.blotterTrades?.find((b) => b.id === action.payload);
            return state;
        },
        setValidationMessage: (state, action: PayloadAction<string[] | undefined>) => {
            state.validationMessage = action.payload;
            state.meta.status = "loaded";
            return state;
        },
        toggleBlotterEntryForm: (state, action: PayloadAction<boolean>) => {
            state.showBlotterEntryForm = action.payload;
            return state;
        },
        updateBlotterEntry: (state, action: PayloadAction<Partial<CreateBlotterTrade>>) => {
            state.blotterEntry = { ...state.blotterEntry, ...(action.payload as CreateBlotterTrade) };
            return state;
        },
        clearBlotterEntry: (state) => {
            state.blotterEntry = {
                ...emptyBlotterEntry,
                date: state.blotterEntry!.date,
                deskId: state.blotterEntry!.deskId,
            };
            state.productGroupText = "";
            state.brokerText = "";
            state.validationMessage = undefined;
            state.editingRow = null;
            return state;
        },
        setBlotterDate: (state, action: PayloadAction<string>) => {
            state.blotterDate = action.payload;
            state.blotterEntry = { ...(state.blotterEntry as CreateBlotterTrade), date: action.payload };
            return state;
        },
        setLastRealTimeMessage: (
            state,
            action: PayloadAction<BlotterTradeRealTimeMessage | BlotterTradePnlRealTimeMessage>,
        ) => {
            if (state.meta.status === "loaded") {
                state.lastRealTimeMessage = action.payload;

                if (!isBlotterTradePnlMessage(action.payload))
                    state.affectedBlotterTradeIds = [
                        ...state.affectedBlotterTradeIds,
                        ...action.payload.affectedBlotterTradeIds,
                    ];
            }
            return state;
        },
        setSignalRGroupName: (state, action: PayloadAction<string | undefined>) => {
            state.signalRGroupName = action.payload;
            return state;
        },
        setSignalRConnectionId: (state, action: PayloadAction<string | undefined>) => {
            state.signalRConnectionId = action.payload;
            return state;
        },
        setAutoUpdate: (state, action: PayloadAction<boolean>) => {
            state.autoUpdate = action.payload;
            return state;
        },
        clearUpdatedTrades: (state) => {
            state.affectedBlotterTradeIds = [];
            return state;
        },
        setFilterEnabled: (state, action: PayloadAction<boolean>) => {
            state.isFilterEnabled = action.payload;
            state.autoUpdate = !action.payload;
            return state;
        },
        setIsBlotterUpdateRequired: (state, action: PayloadAction<boolean>) => {
            state.isBlotterUpdateRequired = action.payload;
            return state;
        },
        setBlotterEditingRow: (state, action: PayloadAction<number | null>) => {
            state.editingRow = action.payload;
            return state;
        },
    },
});

export const blotterTradesMapper = (blotterTrade: BlotterTradeDto): BlotterTrade => ({
    ...blotterTrade,
    date: blotterTrade.date ? parseISO(blotterTrade.date) : undefined,
    tenor1StartDate: blotterTrade.tenor1StartDate ? parseISO(blotterTrade.tenor1StartDate) : undefined,
    tenor1EndDate: blotterTrade.tenor1EndDate ? parseISO(blotterTrade.tenor1EndDate) : undefined,
    tenor2StartDate: blotterTrade.tenor2StartDate ? parseISO(blotterTrade.tenor2StartDate) : undefined,
    tenor2EndDate: blotterTrade.tenor2EndDate ? parseISO(blotterTrade.tenor2EndDate) : undefined,
    createdDateTime: parseISO(blotterTrade.createdDateTime),
});

export const {
    setStatus,
    setProductGroupText,
    getBlotterTradesSuccess,
    getBlotterTradesFailed,
    toggleBlotterEntryForm,
    updateBlotterEntry,
    clearBlotterEntry,
    setBlotterDate,
    initialiseBlotterEntry,
    setValidationMessage,
    setBrokerText,
    setBrokerId,
    updateBlotterTrade,
    setBlotterTradeToDelete,
    setBlotterTradeToDuplicate,
    setLastRealTimeMessage,
    setSignalRGroupName,
    setSignalRConnectionId,
    setAutoUpdate,
    clearUpdatedTrades,
    setFilterEnabled,
    setIsBlotterUpdateRequired,
    setBlotterEditingRow,
} = getBlotterSlice.actions;

export const blotterSelector = (state: RootState) => ({
    ...state.features.blotter,
    blotterTrades: state.features.blotter.blotterTrades?.map(blotterTradesMapper),
});

const selectBlotterCanEdit = (state: RootState) =>
    state.features.blotter.blotterDate === format(new Date(), "yyyy-MM-dd");

export const blotterCanEditSelector = createSelector(selectBlotterCanEdit, (canEdit) => canEdit);

const selectBlotterId = (state: RootState) => state.features.blotter.blotterEntry.id;
const selectBlotterEntryBrokerId = (state: RootState) => state.features.blotter.blotterEntry.brokerId;
const selectBlotterEntryBrokerText = (state: RootState) => state.features.blotter.brokerText;

const selectBlotterProductGroupId = (state: RootState) => state.features.blotter.blotterEntry.blotterProductGroupId;
const selectBlotterProductGroupText = (state: RootState) => state.features.blotter.productGroupText;

const selectBlotterDate = (state: RootState) => state.features.blotter.blotterDate;
const selectMeta = (state: RootState) => state.features.blotter.meta;

const selectValidationMessage = (state: RootState) => state.features.blotter.validationMessage;
const selectSignalRGroupName = (state: RootState) => state.features.blotter.signalRGroupName;

const selectBlotterTrades = (state: RootState) => state.features.blotter.blotterTrades;
const selectAffectedTrades = (state: RootState) => state.features.blotter.affectedBlotterTradeIds;

const selectIsBlotterUpdateRequired = (state: RootState) => state.features.blotter.isBlotterUpdateRequired;

const selectBlotterTradeToDelete = (state: RootState) => state.features.blotter.blotterTradeToDelete;
const selectBlotterTradeToDuplicate = (state: RootState) => state.features.blotter.blotterTradeToDuplicate;

const selectBlotterEntrySide = (state: RootState) => state.features.blotter.blotterEntry.side;
const selectBlotterEntryTradedUnitId = (state: RootState) => state.features.blotter.blotterEntry.tradedUnitId;

const selectBlotterEntryQuantity = (state: RootState) => state.features.blotter.blotterEntry.quantity;

const selectBlotterEntryPrice = (state: RootState) => state.features.blotter.blotterEntry.price;

const selectBlotterEntryExchangeId = (state: RootState) => state.features.blotter.blotterEntry.exchangeId;
const selectBlotterEntryCounterPartyTypeId = (state: RootState) =>
    state.features.blotter.blotterEntry.counterPartyTypeId;
const selectBlotterEntryBrokerageAdjustment = (state: RootState) =>
    state.features.blotter.blotterEntry.brokerageAdjustment;
const selectBlotterEntryIsStrip = (state: RootState) => state.features.blotter.blotterEntry.isStrip;
const selectBlotterEntryTransferDesk = (state: RootState) => ({
    transferDeskId: state.features.blotter.blotterEntry.transferDeskId,
    deskId: state.features.blotter.blotterEntry.deskId,
});

const selectTenors = (state: RootState) => ({
    tenor1Period: state.features.blotter.blotterEntry.tenor1Period,
    tenor1Year: state.features.blotter.blotterEntry.tenor1Year,
    tenor1StartDate: state.features.blotter.blotterEntry.tenor1StartDate,
    tenor1EndDate: state.features.blotter.blotterEntry.tenor1EndDate,
    tenor2Period: state.features.blotter.blotterEntry.tenor2Period,
    tenor2Year: state.features.blotter.blotterEntry.tenor2Year,
    tenor2StartDate: state.features.blotter.blotterEntry.tenor2StartDate,
    tenor2EndDate: state.features.blotter.blotterEntry.tenor2EndDate,
    pricingDay: state.features.blotter.blotterEntry.pricingDay,
});

const selectBlotterGridHeaderState = (state: RootState) => ({
    autoUpdate: state.features.blotter.autoUpdate,
    blotterDate: state.features.blotter.blotterDate,
    blotterTrades: state.features.blotter.blotterTrades,
    lastRealTimeMessage: state.features.blotter.lastRealTimeMessage,
    status: state.features.blotter.meta.status,
});

const selectBlotterEditingRow = (state: RootState) => state.features.blotter.editingRow;

export const blotterEditingRowSelector = createSelector(selectBlotterEditingRow, (editingRow) => editingRow);

export const blotterGridHeaderStateSelector = createSelector(
    selectBlotterGridHeaderState,
    (headerState) => headerState,
);

export const blotterEntryTenorsSelector = createSelector(selectTenors, (tenors) => tenors);

export const blotterEntrySideSelector = createSelector(selectBlotterEntrySide, (side) => side);
export const blotterEntryTradedUnitSelector = createSelector(
    selectBlotterEntryTradedUnitId,
    (tradedUnitId) => tradedUnitId,
);
export const blotterEntryQuantitySelector = createSelector(selectBlotterEntryQuantity, (quantity) => quantity);
export const blotterEntryExchangeIdSelector = createSelector(selectBlotterEntryExchangeId, (exchangeId) => exchangeId);
export const blotterEntryCounterPartyTypeIdSelector = createSelector(
    selectBlotterEntryCounterPartyTypeId,
    (counterPartyTypeId) => counterPartyTypeId,
);
export const blotterEntryBrokerageAdjustmentSelector = createSelector(
    selectBlotterEntryBrokerageAdjustment,
    (adjustment) => adjustment,
);
export const blotterEntryIsStripSelector = createSelector(selectBlotterEntryIsStrip, (isStrip) => isStrip);

export const blotterEntryTransferDeskSelector = createSelector(
    selectBlotterEntryTransferDesk,
    (transferDesk) => transferDesk,
);

export const blotterTradeToDeleteSelector = createSelector(
    selectBlotterTradeToDelete,
    (blotterTradeToDelete) => blotterTradeToDelete,
);

export const blotterTradeToDuplicateSelector = createSelector(
    selectBlotterTradeToDuplicate,
    (blotterTradeToDuplicate) => blotterTradeToDuplicate,
);

export const blotterGridSelector = createSelector(
    [selectBlotterTrades, selectMeta, selectAffectedTrades],
    (blotterTrades, meta, affectedBlotterTradeIds) => ({
        blotterTrades: blotterTrades?.map(blotterTradesMapper),
        status: meta.status,
        message: meta.message,
        affectedBlotterTradeIds,
    }),
);

export const blotterEntryProductGroupSelector = createSelector(
    [selectBlotterProductGroupId, selectBlotterProductGroupText],
    (blotterProductGroupId, productGroupText) => ({
        blotterProductGroupId,
        productGroupText,
    }),
);

export const brokerBlotterEntrySelector = createSelector(
    [selectBlotterEntryBrokerId, selectBlotterEntryBrokerText],
    (brokerId, brokerText) => ({ brokerId, brokerText }),
);

export const blotterMetaSelector = createSelector(selectMeta, (meta): Common.MetaState => meta);

export const blotterIsEditingSelector = createSelector(selectBlotterId, (id) => {
    return !!id;
});

export const canViewBlotterEntryFormSelector = createSelector(
    [selectedDeskSelector, selectBlotterDate, selectBlotterId],
    (selectedDesk, currentBlotterDate, id) => {
        const canEdit = blotterDate === currentBlotterDate;
        const isEditing = !!id;
        const isParentDesk = !selectedDesk || selectedDesk.deskId === selectedDesk.topLevelDeskId;

        return !((isParentDesk && !isEditing) || !canEdit);
    },
);

export const blotterPageSelector = createSelector(
    [selectValidationMessage, selectSignalRGroupName],
    (validationMessage, signalRGroupName) => ({ validationMessage, signalRGroupName }),
);

const selectShowBlotterEntryForm = (state: RootState) => state.features.blotter.showBlotterEntryForm;

export const showBlotterEntryFormSelector = createSelector(selectShowBlotterEntryForm, (showForm) => showForm);

export const blotterEntryFormContainerSelector = createSelector(
    [selectShowBlotterEntryForm, selectBlotterDate],
    (showBlotterEntryForm, blotterDate) => ({ showBlotterEntryForm, blotterDate }),
);

export const blotterDateSelector = createSelector(selectBlotterDate, (blotterDate) => blotterDate);

export const blotterEntryPriceSelector = createSelector(selectBlotterEntryPrice, (price) => price);

export const isBlotterUpdateRequiredSelector = createSelector(
    selectIsBlotterUpdateRequired,
    (isBlotterUpdateRequired) => isBlotterUpdateRequired,
);
