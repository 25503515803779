import { DateComponent } from "common/components/dateComponent";
import { format, parseISO } from "date-fns";
import * as React from "react";

export const DateEditor = React.forwardRef((props: { value: Common.Nullable<Date> }, ref) => {
    const [value, setValue] = React.useState<Common.Nullable<Date>>(props.value);
    const dateRef = React.useRef(value ? format(value, "yyyy-MM-dd") : "");

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                let date = value;
                if (dateRef.current) {
                    date = parseISO(dateRef.current);
                } else {
                    date = null;
                }
                return date || undefined;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const handleDateSelected = (date: Date | null) => {
        setValue(date);
        if (date) {
            dateRef.current = format(date, "yyyy-MM-dd");
        } else {
            dateRef.current = "";
        }
    };

    return (
        <div className="w-32">
            <DateComponent date={value} onSelectedDate={handleDateSelected} />
        </div>
    );
});
