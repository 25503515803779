import { Checkbox, PassiveCheckbox } from "common/components/checkbox";
import { DateComponent } from "common/components/dateComponent";
import { HeaderNavigationTabs } from "common/components/headerNavigationTabs";
import { OpenNewWindow } from "common/components/openNewWindow";
import { CROSS_EXCHANGE_RISK, LIVE_RISK } from "common/constants";
import { useCustomLocationProperties } from "common/hooks";
import { ChevronDoubleLeft, ChevronDoubleRight, SaveIcon } from "common/icons";
import { formatISO, parseISO } from "date-fns";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    decreaseDecimalPosition,
    increaseDecimalPosition,
    liveRiskRealTimeDataSelector,
    metaSelector,
    setIsCrossExchangeView,
    setIncludeTas,
    setRollForwardDateTime,
} from "./liveRiskSlice";

type Props = {
    sumRef: any;
    avgRef: any;
    colsFunction(): number;
    onExportToCSV(): void;
};

export const LiveRiskHeader: React.FC<Props> = ({ onExportToCSV, sumRef, avgRef, colsFunction }) => {
    const [dateValue, setDateValue] = React.useState<Date | null>(null);
    const dispatch = useDispatch();
    const { isPopupWindow, shortPath } = useCustomLocationProperties();
    const { includeTas, rollForwardDateTime, isCrossExchangeView } = useSelector(liveRiskRealTimeDataSelector);
    const meta = useSelector(metaSelector);
    const cols = colsFunction();
    const multiLine = !cols || cols < 5;
    const maxHeight = multiLine ? 80 : undefined;

    const viewTypeUrlSegment = isCrossExchangeView ? `${LIVE_RISK}/${CROSS_EXCHANGE_RISK}` : LIVE_RISK;

    const liveRiskNavigationTabs = [
        {
            name: "Blotter Based",
            to: `/${isPopupWindow ? "window" : "risk"}/${viewTypeUrlSegment}/blotter`,
        },
        {
            name: "Exchange Based",
            to: `/${isPopupWindow ? "window" : "risk"}/${viewTypeUrlSegment}/exchange`,
        },
    ];

    const sumAndAverageLabels = (
        <>
            <div className="ml-auto self-center ml-4" style={{ minWidth: "40px" }}>
                <label ref={sumRef}></label>
            </div>
            <div className="self-center ml-4" style={{ minWidth: "40px" }}>
                <label ref={avgRef}></label>
            </div>
        </>
    );

    const handleDateSelected = React.useCallback(
        (date: Date | null) => {
            if (date) {
                dispatch(setRollForwardDateTime(formatISO(date)));
            } else if (rollForwardDateTime) {
                dispatch(setRollForwardDateTime(""));
            }
        },
        [rollForwardDateTime],
    );

    const includeTasCheckbox = (
        <div className="ml-2 mr-2 mt-2 cursor-pointer" data-testid="is-tas">
            <Checkbox
                checked={includeTas}
                onChange={() => dispatch(setIncludeTas(!includeTas))}
                disabled={meta.status === "loading" || meta.status === "init"}
            >
                TAS
            </Checkbox>
        </div>
    );

    const crossExchangeCheckBox = (
        <div className="ml-2 mr-2 mt-2 cursor-pointer" data-testid="cross-exchange-view">
            <PassiveCheckbox
                checked={isCrossExchangeView}
                onChange={() => dispatch(setIsCrossExchangeView(!isCrossExchangeView))}
                disabled={meta.status === "loading" || meta.status === "init"}
            >
                Cross&nbsp;Exchange
            </PassiveCheckbox>
        </div>
    );

    const headerNav = (
        <div>
            <HeaderNavigationTabs tabs={liveRiskNavigationTabs} />
        </div>
    );

    const rollForwardDateSelect = (
        <div className="ml-1 cursor-pointer" data-testid="roll-forward-date-time">
            <DateComponent date={dateValue} onSelectedDate={handleDateSelected} disabled={false} showTimeInput />
        </div>
    );

    const rightButtons = (
        <div className="flex ml-2 group-buttons">
            {multiLine && sumAndAverageLabels}
            <ChevronDoubleLeft
                data-testid="live-risk-decrease"
                onClick={() => dispatch(decreaseDecimalPosition())}
                className="cursor-pointer select-none"
            />
            <ChevronDoubleRight
                data-testid="live-risk-increase"
                onClick={() => dispatch(increaseDecimalPosition())}
                className="cursor-pointer select-none"
            />
            <SaveIcon data-testid="live-risk-csv" className="cursor-pointer select-none mx-1" onClick={onExportToCSV} />
            <OpenNewWindow name="live" relativeUrl={`/window/${viewTypeUrlSegment}/${shortPath}`} />
        </div>
    );

    React.useEffect(() => {
        if (rollForwardDateTime) {
            setDateValue(parseISO(rollForwardDateTime));
        }
    }, [rollForwardDateTime]);

    return (
        <div
            className={`p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs1 ${
                !multiLine && "flex"
            }`}
            style={{ maxHeight: maxHeight }}
        >
            {multiLine ? (
                <>
                    <div className="flex">
                        <div className="uppercase mt-1 mb-1 ml-2 mr-2">Live Risk</div>
                        {headerNav}
                        {rollForwardDateSelect}
                        {rightButtons}
                    </div>
                    <div className="flex">
                        {crossExchangeCheckBox} {includeTasCheckbox}
                    </div>
                </>
            ) : (
                <>
                    <div className="flex">
                        <div className="uppercase mt-1 mb-1 ml-2 mr-2">Live Risk</div>
                        {headerNav}
                        {rollForwardDateSelect}
                        {includeTasCheckbox}
                        {crossExchangeCheckBox}
                    </div>
                    {sumAndAverageLabels}
                    {rightButtons}
                </>
            )}
        </div>
    );
};
