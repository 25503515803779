import { ColumnResizedEvent } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { desksSelector } from "features/desks/desksSlice";
import _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { productGroupsSelector, riskItemsByGroupSelector } from "./rollOffRiskSlice";

export const useGridState = () => {
    const [gridWidth, setGridWidth] = React.useState<number>(0);

    const riskItemsByGroup = useSelector(riskItemsByGroupSelector);
    const productGroups = useSelector(productGroupsSelector);

    const { selectedDeskId } = useSelector(desksSelector);

    const initialColumnWidth = 140;
    const productGroupColumnWidth = 100;
    const grandTotalColumnWidth = 120;
    const productColumnWidth = 200;
    const diffProductColumnWidth = 200;

    const [columnState, setColumnState] = React.useState<any>();
    const [columnDefs, setColumnDefs] = React.useState<any>();

    const handleColumnResize = (event: ColumnResizedEvent) => {
        if (event.column && event.finished) {
            const { level, productGroup, product, diffProduct } = event.column!.getColDef().refData as any;
            const width = event.column!.getActualWidth();

            // Set column state here
            setColumnState((prevState: any) => {
                switch (level) {
                    case "0":
                        prevState[productGroup] = { ...prevState[productGroup], width };
                        break;
                    case "1":
                        prevState[productGroup].children[product] = {
                            ...prevState[productGroup].children[product],
                            width,
                        };
                        break;
                    case "2":
                        prevState[productGroup].children[product].children[diffProduct] = {
                            ...prevState[productGroup].children[product].children[diffProduct],
                            width,
                        };
                        break;
                    case "3":
                        prevState["grand-total"] = prevState["grand-total"] || {};
                        prevState["grand-total"].width = width;
                        break;
                    default:
                        return prevState;
                }
                return prevState;
            });

            setGridWidth((prevState) => calculateWidth(prevState));
        }
    };

    React.useEffect(() => {
        // TODO: Check if we need to add the page number here
        const newColumnState = recalculateColumnState(columnState);

        if (columnState == undefined || !_.isEqual(columnState, newColumnState)) {
            setColumnState(newColumnState);
        }
    }, [selectedDeskId, riskItemsByGroup, productGroups]);

    const gridRef = React.useRef<AgGridReact>(null);

    const calculateWidthFromProducts = (state: any) => {
        const products = Object.keys(state);
        let width = 0;
        products.forEach((product) => {
            if (
                state[product] &&
                state[product].expanded &&
                state[product].children &&
                Object.keys(state[product].children).length > 0
            ) {
                width -= state[product].width ?? 0;
                width += calculateWidthFromProducts(state[product].children);
            }

            width += state[product]?.width ?? 0;
        });
        return width;
    };

    const recalculateColumnState = (colState?: any) => {
        const result = Object.keys(riskItemsByGroup).reduce((prev: any, productGroup: string) => {
            const current = { ...prev } || {};
            current[productGroup] = current[productGroup] || {
                width: colState?.[productGroup]?.width ?? productGroupColumnWidth,
                expanded: colState?.[productGroup]?.expanded ?? false,
                children: {},
            };

            Object.keys(riskItemsByGroup[productGroup]).forEach((product) => {
                let productSymbol =
                    Object.values(Object.values(riskItemsByGroup[productGroup][product])?.[0])?.[0]?.[0]?.symbol ||
                    null;

                productSymbol = productSymbol ? ` (${productSymbol})` : "";

                current[productGroup].children[`${product}${productSymbol}`] = current[productGroup].children[
                    `${product}${productSymbol}`
                ] || {
                    width:
                        colState?.[productGroup]?.children?.[`${product}${productSymbol}`]?.width ?? productColumnWidth,
                    expanded: colState?.[productGroup]?.children?.[`${product}${productSymbol}`]?.expanded ?? false,
                    children: {},
                };

                Object.keys(riskItemsByGroup[productGroup][product]).forEach((diffProduct) => {
                    const diffProductObject = riskItemsByGroup[productGroup][product][diffProduct];
                    let diffSymbol = diffProductObject[Object.keys(diffProductObject)?.[0]]?.[0]?.diffSymbol || null;

                    diffSymbol = diffSymbol ? ` (${diffSymbol})` : "";

                    current[productGroup].children[`${product}${productSymbol}`].children[
                        `${diffProduct}${diffSymbol}`
                    ] = current[productGroup].children[`${product}${productSymbol}`].children[
                        `${diffProduct}${diffSymbol}`
                    ] || {
                        width:
                            colState?.[productGroup]?.children?.[`${product}${productSymbol}`]?.children?.[
                                `${diffProduct}${diffSymbol}`
                            ]?.width ?? diffProductColumnWidth,
                    };
                });
            });

            return current;
        }, {});

        result.deskId = selectedDeskId;
        result["grand-total"] = result["grand-total"] || { width: grandTotalColumnWidth };

        return result;
    };

    const calculateWidth = (originalValue: number, colState?: any) => {
        const myState = colState ?? columnState;
        const productsWidth = !myState ? 0 : calculateWidthFromProducts(myState) + initialColumnWidth;
        return productsWidth || originalValue;
    };

    const getProductGroupColumnSettings = (productGroup: string) => {
        const defaultSettings = { minWidth: productGroupColumnWidth, width: productGroupColumnWidth };
        return { ...defaultSettings, ...(columnState ?? {})[productGroup] };
    };

    const getProductColumnSettings = (productGroup: string, product: string) => {
        const defaultSettings = { minWidth: productColumnWidth, width: productColumnWidth };
        return { ...defaultSettings, ...(columnState ?? {})[productGroup]?.children?.[product] };
    };

    const getDiffProductColumnSettings = (productGroup: string, product: string, diffProduct: string) => {
        const defaultSettings = { minWidth: diffProductColumnWidth, width: diffProductColumnWidth };
        return {
            ...defaultSettings,
            ...(columnState ?? {})[productGroup]?.children?.[product]?.children?.[diffProduct],
        };
    };

    return {
        gridWidth,
        setGridWidth,
        grandTotalColumnWidth,
        columnState,
        setColumnState,
        getProductGroupColumnSettings,
        getProductColumnSettings,
        getDiffProductColumnSettings,
        columnDefs,
        setColumnDefs,
        gridRef,
        calculateWidth,
        handleColumnResize,
    };
};
