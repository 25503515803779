import { DeskSelection } from "features/desks/deskSelection";
import * as React from "react";
import { ApplicationVersion, HeaderLogo, NavigationLinks, RealTime } from "./components";
import "./navigation.styles.scss";

export const Navigation: React.FC = () => {
    return (
        <header className="p-6 flex items-center bg-white-900">
            <HeaderLogo />
            <NavigationLinks />
            <div className="flex ml-auto items-center">
                <ApplicationVersion />
                <RealTime />
                <DeskSelection />
            </div>
        </header>
    );
};
