import { Dropdown } from "common/components/dropdown";
import { FormLabel } from "common/components/form";
import { blotterEntryTransferDeskSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import { desksSelector } from "features/desks/desksSlice";
import { DeskDTO } from "features/desks/desksTypes";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { findDeskById } from "features/desks/desksSlice";
import { transferDesksSelector } from "features/transferDesks/transferDesksSlice";

export const TransferDesk: React.FC = () => {
    const dispatch = useDispatch();
    const { selectedDeskId } = useSelector(desksSelector, shallowEqual);
    const { desks } = useSelector(transferDesksSelector, shallowEqual);
    const { transferDeskId, deskId } = useSelector(blotterEntryTransferDeskSelector, shallowEqual);

    const handleSelection = (selectedItem?: Common.DropdownItem) => {
        if (selectedItem && desks.length > 0) {
            const desk = findDeskById(selectedItem.id, desks);
            if (desk && (desk.deskId === desk.topLevelDeskId || desk.deskId === selectedDeskId)) {
                return false;
            }
        }
        dispatch(updateBlotterEntry({ transferDeskId: selectedItem ? selectedItem.id : null }));
        return true;
    };

    const mapDeskToDropdownItem = (d: DeskDTO): Common.DropdownItem => ({
        id: d.deskId,
        value: d.name,
        disabled: d.deskId === deskId || d.deskId === d.topLevelDeskId,
        children: d.childDesks ? d.childDesks.map(mapDeskToDropdownItem) : undefined,
    });

    const data = desks.map(mapDeskToDropdownItem);

    return (
        <div className="flex flex-col w-32" data-testid="transfer-desk">
            <div className="flex justify-center">
                <FormLabel>Transfer</FormLabel>
            </div>
            <Dropdown selectedId={transferDeskId} data={data} onChange={handleSelection} className="w-64" />
        </div>
    );
};
