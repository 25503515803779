import classNames from "classnames";
import * as React from "react";

type Props = {
    onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
    size?: "sm" | "md";
    mode?: Common.UIMode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<Props> = ({ size = "md", mode = "primary", children, className, onClick, ...props }) => {
    return (
        <button
            onClick={onClick}
            className={classNames(
                "font-bold rounded-lg",
                {
                    "py-2 px-4 text-sm": size === "md",
                    "py-1 px-4 text-xs": size === "sm",
                },
                mode === "primary" && "bg-green-900 text-white-900",
                mode === "secondary" && "bg-white-900 text-black-800",
                mode === "danger" && "bg-red-900 text-white-600",
                className,
            )}
            {...props}
        >
            {children}
        </button>
    );
};
