import { useCustomLocationProperties } from "common/hooks";
import { deskHasBeenSetSelector, desksSelector } from "features/desks/desksSlice";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { rollOffRiskSelector } from "./rollOffRiskSlice";
import { fetchMoreRollOffRisk } from "./rollOffRiskThunks";
import "./rollOffRisk.styles.scss";

type Props = {
    gridWidth: number;
};

export const RollOffRiskLoadMore: React.FC<Props> = ({ gridWidth }) => {
    const dispatch = useDispatch();
    const { includeFutures, timespanDays, defaultTimespanDays } = useSelector(rollOffRiskSelector);
    const { selectedDeskId } = useSelector(desksSelector);
    const { shortPath } = useCustomLocationProperties();
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    return (
        <div
            style={{
                width: gridWidth === 0 ? innerWidth : gridWidth,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50px",
            }}
        >
            <button
                data-testid="roll-off-risk-load-more"
                className="shadow-lg cursor-pointer rounded-lg py-1 px-4 text-xs font-bold bg-black-900 text-white-900 load-more"
                onClick={() => {
                    if (deskHasBeenSet) {
                        dispatch(
                            fetchMoreRollOffRisk(
                                shortPath,
                                selectedDeskId!,
                                includeFutures,
                                timespanDays,
                                defaultTimespanDays,
                            ),
                        );
                    }
                }}
            >{`Load more`}</button>
        </div>
    );
};
