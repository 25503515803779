import * as React from "react";

type Props = {
    test: string;
};

export const SwitchStatement: React.FC<Props> = ({ test, children }) => {
    if (!children) return <></>;

    let child;

    if (!Array.isArray(children)) {
        child = children;
    } else {
        child = (children as any).find((child: any) => {
            if (Array.isArray((child as any).props.value)) {
                return ((child as any).props.value as string[]).indexOf(test) >= 0;
            }

            return (child as any).props.value === test;
        });
    }

    return child ?? <></>;
};
