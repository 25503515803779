import {
    deleteDummyEntries,
    deleteDummyEntry,
    getSpreaderEntries,
    getSpreaderRisk,
    updateDummyEntry,
} from "api/spreaderApi";
import { LiveRiskRealTimeMessage, SpreaderEntryRealTimeMessage } from "common/types";
import { AppDispatch } from "core/store";
import { DeskDTO } from "features/desks/desksTypes";
import { resetFetchOnReconnect } from "features/realTime/realTimeSlice";
import {
    clearEntries,
    getSpreaderEntriesSuccess,
    getSpreaderFailed,
    getSpreaderRiskSuccess,
    reset,
    setIsRiskUpdateRequired,
    setIsEntriesUpdateRequired,
    setStatus,
    updateEntrySuccess,
} from "./spreaderSlice";
import { DeleteDummyEntryDto, UpdateDummyEntryDto } from "../spreaderTypes";

export const fetchSpreaderRisk = (deskId?: Common.Nullable<number>) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("refetching"));
            dispatch(setIsRiskUpdateRequired(false));

            if (deskId) {
                const risk = await getSpreaderRisk(deskId);
                dispatch(getSpreaderRiskSuccess(risk));
            }

            dispatch(setStatus("loaded"));
        } catch {
            dispatch(getSpreaderFailed("Failed to load spreader risk"));
        }
    };
};

export const fetchSpreaderEntries = (deskId?: Common.Nullable<number>) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("refetching"));
            dispatch(setIsEntriesUpdateRequired(false));

            if (deskId) {
                const entries = await getSpreaderEntries(deskId);

                dispatch(getSpreaderEntriesSuccess(entries));
            }

            dispatch(setStatus("loaded"));
        } catch {
            dispatch(getSpreaderFailed("Failed to load spreader entries"));
        }
    };
};

export const fetchSpreaderEntriesAndRisk = (deskId?: Common.Nullable<number>) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("loading"));

            dispatch(setIsRiskUpdateRequired(false));
            dispatch(setIsEntriesUpdateRequired(false));

            if (deskId) {
                const entries = await getSpreaderEntries(deskId);
                const risk = await getSpreaderRisk(deskId);

                dispatch(getSpreaderEntriesSuccess(entries));
                dispatch(getSpreaderRiskSuccess(risk));
            } else {
                dispatch(reset());
            }

            dispatch(setStatus("loaded"));
        } catch {
            dispatch(getSpreaderFailed("Failed to load spreader entries and risk"));
        }
    };
};

export const updateSpreaderDummyEntry = (productGroup: string, entry: UpdateDummyEntryDto) => {
    return async (dispatch: AppDispatch) => {
        try {
            await updateDummyEntry([entry]);
            dispatch(updateEntrySuccess({ productGroup, entry }));
        } catch {}
    };
};

export const deleteSpreaderDummyEntry = (productGroup: string, entry: DeleteDummyEntryDto) => {
    return async (dispatch: AppDispatch) => {
        try {
            await deleteDummyEntry(entry);
            dispatch(updateEntrySuccess({ productGroup, entry }));
        } catch {}
    };
};

export const clearAll = (deskId: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            await deleteDummyEntries(deskId);
            dispatch(clearEntries());
        } catch {}
    };
};

export const autoFlattenEntries = (deskId: number, entries: UpdateDummyEntryDto[]) => {
    return async (dispatch: AppDispatch) => {
        try {
            await updateDummyEntry(entries);
            const riskEntries = await getSpreaderEntries(deskId);
            dispatch(getSpreaderEntriesSuccess(riskEntries));
        } catch {}
    };
};

export const refetchSpreaderRisk = (message: LiveRiskRealTimeMessage, desk: Common.Nullable<DeskDTO>) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (!desk) return;

            dispatch(setStatus("refetching"));
            dispatch(setIsRiskUpdateRequired(false));

            if (desk.deskId !== message.deskId && desk.deskId !== message.topLevelDeskId) {
                dispatch(setStatus("loaded"));
                return;
            }

            const result = await getSpreaderRisk(desk.deskId);
            dispatch(getSpreaderRiskSuccess(result));
            dispatch(setStatus("loaded"));
        } catch (err) {
            dispatch(setStatus("loaded"));
        }
    };
};

export const refetchSpreaderEntries = (message: SpreaderEntryRealTimeMessage, desk: Common.Nullable<DeskDTO>) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (!desk) return;

            dispatch(setStatus("refetching"));
            dispatch(setIsEntriesUpdateRequired(false));

            if (desk.deskId !== message.deskId) {
                dispatch(setStatus("loaded"));
                return;
            }

            const result = await getSpreaderEntries(desk.deskId);
            dispatch(getSpreaderEntriesSuccess(result));
            dispatch(setStatus("loaded"));
        } catch (err) {
            dispatch(setStatus("loaded"));
        }
    };
};

export const refetchOnReconnect = (deskId: Common.Nullable<number>) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (!deskId) return;

            dispatch(setStatus("loading"));

            dispatch(setIsRiskUpdateRequired(false));
            dispatch(setIsEntriesUpdateRequired(false));

            const entries = await getSpreaderEntries(deskId);
            const risk = await getSpreaderRisk(deskId);

            dispatch(getSpreaderEntriesSuccess(entries));
            dispatch(getSpreaderRiskSuccess(risk));
        } finally {
            dispatch(setStatus("loaded"));
            dispatch(resetFetchOnReconnect());
        }
    };
};
