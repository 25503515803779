import { applicationSelector } from "features/application/applicationSlice";
import * as React from "react";
import { useSelector } from "react-redux";

export const ApplicationVersion: React.FC = () => {
    const { version, isNewVersionAvailable } = useSelector(applicationSelector);

    if (!version) return null;

    return (
        <>
            {isNewVersionAvailable ? (
                <div className="text-sm bg-yellow-100 mr-2 px-4 py-2 rounded-lg" data-testid="new-application-version">
                    <span>New version available.</span>
                    <a
                        className="font-normal ml-2 underline"
                        href={location.href}
                        data-testid="application-version-reload"
                    >
                        Reload
                    </a>
                </div>
            ) : (
                <div className="text-sm text-white-100 mr-4" data-testid="application-version">
                    v{version}
                </div>
            )}
        </>
    );
};
