import { Checkbox } from "common/components/checkbox";
import { HeaderNavigationTabs } from "common/components/headerNavigationTabs";
import { OpenNewWindow } from "common/components/openNewWindow";
import { useCustomLocationProperties } from "common/hooks";
import { ChevronDoubleLeft, ChevronDoubleRight, SaveIcon } from "common/icons";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    decreaseDecimalPosition,
    increaseDecimalPosition,
    rollOffRiskSelector,
    setIncludeFutures,
} from "./rollOffRiskSlice";

type Props = {
    onExportToCSV(): void;
};

export const RollOffRiskHeader: React.FC<Props> = ({ onExportToCSV }) => {
    const dispatch = useDispatch();
    const { isPopupWindow, shortPath } = useCustomLocationProperties();

    const { meta, includeFutures } = useSelector(rollOffRiskSelector);

    const rollOffRiskNavigationTabs = [
        {
            name: "Blotter Based",
            to: `/${isPopupWindow ? "window" : "risk"}/roll-off/blotter`,
        },
        {
            name: "Exchange Based",
            to: `/${isPopupWindow ? "window" : "risk"}/roll-off/exchange`,
        },
    ];

    return (
        <div className="flex items-center p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs">
            <div className="uppercase">Roll Off Risk</div>
            <div className="ml-4">
                <HeaderNavigationTabs tabs={rollOffRiskNavigationTabs} />
            </div>
            <div className="ml-2 cursor-pointer"></div>
            <div className="ml-2">
                <Checkbox
                    checked={includeFutures}
                    onChange={() => dispatch(setIncludeFutures(!includeFutures))}
                    disabled={meta.status === "loading" || meta.status === "init"}
                >
                    Include Futures
                </Checkbox>
            </div>
            <div className="flex ml-auto">
                <ChevronDoubleLeft
                    data-testid="roll-off-risk-decrease"
                    onClick={() => dispatch(decreaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <ChevronDoubleRight
                    data-testid="roll-off-risk-increase"
                    onClick={() => dispatch(increaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <SaveIcon
                    data-testid="roll-off-risk-csv"
                    className="cursor-pointer select-none mx-1"
                    onClick={onExportToCSV}
                />
                <OpenNewWindow name="roll-off-risk" relativeUrl={`/window/roll-off/${shortPath}`} />
            </div>
        </div>
    );
};
