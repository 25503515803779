import classNames from "classnames";
import { FormButton } from "common/components/form";
import { blotterIsEditingSelector, clearBlotterEntry } from "features/blotter/blotterSlice";
import { submitBlotterTrade } from "features/blotter/blotterThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./blotterEntryForm.styles.scss";
import {
    Adjustment,
    Broker,
    Exchange,
    Price,
    PricingDay,
    ProductGroup,
    Quantity,
    Side,
    Strip,
    Tenors,
    TransferDesk,
    Unit,
} from "./components";
import { CounterPartyType } from "./components/counterPartyType";

type Props = {
    show: boolean;
};

export const BlotterEntryForm: React.FC<Props> = ({ show }) => {
    const dispatch = useDispatch();
    const isEditing = useSelector(blotterIsEditingSelector);

    const handleSubmit = () => {
        dispatch(submitBlotterTrade());
    };

    const handleClear = () => {
        dispatch(clearBlotterEntry());
    };

    return (
        <div
            data-testid="blotter-entry-form"
            className={classNames(
                "blotterEntryForm justify-center flex flex-1 pb-4 flex-wrap bg-white-600 overflow-y-auto",
                {
                    hidden: !show,
                },
            )}
        >
            <Side />
            <Quantity />
            <Unit />
            <ProductGroup />
            <Tenors />

            <div className="flex flex-col mt-4">
                <div className="flex">
                    <PricingDay />
                    <div className="flex flex-2 flex-col self-start">
                        <div className="flex">
                            <Price />
                            <Broker />
                        </div>
                        <div className="flex">
                            <Exchange />
                            <Adjustment />
                        </div>
                    </div>
                </div>
                <div className="flex mt-4">
                    <div className="flex flex-col flex-1 blotterEntryForm__spacing">
                        <Strip />
                    </div>
                    <div className="flex-1 blotterEntryForm__spacing">
                        <TransferDesk />
                    </div>
                    <div className="flex flex-col flex-1">
                        <CounterPartyType />
                    </div>
                </div>
                <div className="flex mt-4">
                    <div className="flex flex-col flex-1 blotterEntryForm__spacing"></div>
                    <div className="flex-1 blotterEntryForm__spacing"></div>
                    <div className="flex flex-col flex-1">
                        <FormButton data-testid="submit-button" onClick={handleSubmit}>
                            {isEditing ? "Save" : "Submit"}
                        </FormButton>
                        <FormButton data-testid="clear-button" onClick={handleClear}>
                            {isEditing ? "Cancel" : "Clear"}
                        </FormButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
