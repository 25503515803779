import { AppDispatch } from "core/store";
import * as brokersApi from "api/brokersApi";
import { getBrokersSuccess, getBrokersFailed } from "./brokersSlice";

export const fetchBrokers = (search?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await brokersApi.getBrokers(search);
            dispatch(getBrokersSuccess({ search, response }));
        } catch (err: any) {
            dispatch(getBrokersFailed(err.message));
        }
    };
};
