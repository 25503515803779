import { SpreaderRiskResponseDto, SpreaderRiskRowItem, UpdateDummyEntryDto } from "../spreaderTypes";
import { CUSTOM_AUTOFLATTEN_PRODUCTS, CUSTOM_POSITION, DEFAULT_POSITION } from "../utils/constants";
import { isSpreaderCustomColumn } from "../utils/customColumns";

export const monthlyAutoFlatten = (
    spreaderView: string,
    deskId: number,
    riskTable: SpreaderRiskRowItem[],
    risk: SpreaderRiskResponseDto,
): UpdateDummyEntryDto[] => {
    const productGroupList = Object.keys(risk.productGroups);
    const productGroupIds = productGroupList.map((productGroup) => risk.productGroups[productGroup].productGroupId);

    const dummyEntries = productGroupIds
        .filter((p) => !isSpreaderCustomColumn(p))
        .map((productGroupId) => {
            // Find the product group name based on the product group id
            const productGroupName = productGroupList.reduce((name, currentProductGroup) => {
                if (risk.productGroups[currentProductGroup].productGroupId === productGroupId) {
                    name = currentProductGroup;
                }
                return name;
            }, "");

            // Get the tenor period based on the position
            const tenorPeriod = riskTable[getPosition(productGroupName)].tenorPeriod;
            const value = riskTable.reduce((value: number, riskRow: any) => value + (riskRow[productGroupId] || 0), 0);

            // Return a dummy entry for the tenor period and product
            return { deskId, productGroupId, tenorPeriod, value: value * -1, spreaderView: spreaderView };
        });

    return dummyEntries;
};

export const getPosition = (productGroupName: string) => {
    return CUSTOM_AUTOFLATTEN_PRODUCTS.some((p) => p === productGroupName) ? CUSTOM_POSITION : DEFAULT_POSITION;
};
