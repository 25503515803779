import { getRollOffRisk } from "api/rollOffRiskApi";
import { AppDispatch } from "core/store";
import { getRollOffRiskSuccess, getRollOffRiskFailed, setStatus, setIsRiskUpdateRequired } from "./rollOffRiskSlice";
import { DeskDTO } from "features/desks/desksTypes";
import { resetFetchOnReconnect } from "features/realTime/realTimeSlice";
import { LiveRiskRealTimeMessage } from "common/types";
import { RollOffRiskResponseDto } from "features/rollOffRisk/rollOffRiskTypes";

export const fetchRollOffRisk = (
    positionType: string,
    deskId: Common.Nullable<number>,
    includeFutures: boolean,
    timespanDays: number,
    defaultTimespanDays: number,
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setStatus("loading"));
        try {
            let rollOffRisk: RollOffRiskResponseDto;
            let updatedTimespanDays = timespanDays;
            let updatedDefaultTimespanDays = defaultTimespanDays;
            const daysInYear = 365;

            rollOffRisk = await getRollOffRisk(positionType, deskId, updatedTimespanDays, includeFutures);
            const expiries = new Set(rollOffRisk.riskItems.map((a) => a.riskExpiryDateTime));

            if (expiries.size <= 20 && updatedTimespanDays < daysInYear) {
                updatedTimespanDays = daysInYear;
                updatedDefaultTimespanDays = daysInYear;

                rollOffRisk = await getRollOffRisk(positionType, deskId, updatedTimespanDays, includeFutures);
            }

            dispatch(
                getRollOffRiskSuccess({
                    response: rollOffRisk as RollOffRiskResponseDto,
                    timespanDays: updatedTimespanDays,
                    defaultTimespanDays: updatedDefaultTimespanDays,
                }),
            );
        } catch (error: any) {
            dispatch(getRollOffRiskFailed(error.message));
        }
    };
};

export const fetchMoreRollOffRisk = (
    positionType: string,
    deskId: Common.Nullable<number>,
    includeFutures: boolean,
    timespanDays: number,
    defaultTimespanDays: number,
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setStatus("loading"));
        dispatch(setIsRiskUpdateRequired(false));

        try {
            const updatedTimespanDays = timespanDays + defaultTimespanDays;
            const rollOffRisk = await getRollOffRisk(positionType, deskId, updatedTimespanDays, includeFutures);
            dispatch(getRollOffRiskSuccess({ response: rollOffRisk, timespanDays: updatedTimespanDays }));
        } catch (error: any) {
            dispatch(getRollOffRiskFailed(error.message));
        }
    };
};

export const refetchRollOffRisk = (
    message: LiveRiskRealTimeMessage,
    positionType: string,
    desk: Common.Nullable<DeskDTO>,
    includeFutures: boolean,
    timespanDays: number,
) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (desk && desk.deskId !== message.deskId && desk.deskId !== message.topLevelDeskId) {
                dispatch(setStatus("loaded"));
                return;
            }

            dispatch(setStatus("refetching"));
            dispatch(setIsRiskUpdateRequired(false));

            const rollOffRisk = await getRollOffRisk(
                positionType,
                desk ? desk.deskId : null,
                timespanDays,
                includeFutures,
            );
            dispatch(getRollOffRiskSuccess({ response: rollOffRisk }));
        } catch (err) {
            dispatch(setStatus("loaded"));
        }
    };
};

export const refetchOnReconnect = (
    positionType: string,
    desk: Common.Nullable<DeskDTO>,
    includeFutures: boolean,
    timespanDays: number,
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("loading"));
            dispatch(setIsRiskUpdateRequired(false));

            const rollOffRisk = await getRollOffRisk(
                positionType,
                desk ? desk.deskId : null,
                timespanDays,
                includeFutures,
            );
            dispatch(getRollOffRiskSuccess({ response: rollOffRisk }));
        } catch {
            dispatch(setStatus("loaded"));
        } finally {
            dispatch(resetFetchOnReconnect());
        }
    };
};
