import classNames from "classnames";
import { CalendarIcon, CancelIcon } from "common/icons";
import { format, isValid, parse } from "date-fns";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./dateComponent.styles.scss";
import noop from "lodash/noop";

type Props = {
    date?: Common.Nullable<Date>;
    disabled?: boolean;
    label?: string;
    error: boolean;
    showPlaceholder: boolean;
    showCalendarIcon: boolean;
    inputClassName: string;
    showTime?: boolean;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    onDateChanged: (date: Date | null) => void;
    closeDatePicker: () => void;
};

export const DateInputField = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            date,
            showCalendarIcon,
            showPlaceholder,
            disabled = false,
            showTime = false,
            error,
            label,
            inputClassName,
            onClick,
            onDateChanged,
            closeDatePicker,
        },
        ref,
    ) => {
        const dateFormatString = showTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy";
        const [dateInputValue, setDateInputValue] = React.useState(date ? format(date, dateFormatString) : "");
        const [dateValue, setDateValue] = React.useState(date);

        const handleBlur = (close?: boolean) => {
            try {
                if (dateInputValue === "") {
                    setDateInputValue("");
                    onDateChanged(null);
                } else {
                    const parsedDate = parse(dateInputValue, dateFormatString, new Date());

                    if (!isValid(parsedDate)) {
                        throw new Error("Invalid date");
                    }

                    setDateValue(parsedDate);
                    onDateChanged(parsedDate);
                    close && closeDatePicker();
                }
            } catch {
                setDateInputValue(dateValue ? format(dateValue, dateFormatString) : "");
            }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            e.key === "Enter" && !disabled && handleBlur(true);
        };

        const handleClear = (e: React.MouseEvent<SVGElement>) => {
            e.stopPropagation();
            setDateInputValue("");
            onDateChanged(null);
        };

        return (
            <div
                className={classNames(
                    "dateInputField flex bg-white-900 items-center rounded-lg border border-white-50 cursor-pointer ",
                    { "opacity-50": disabled },
                    error && "border border-red-500",
                )}
                style={{ padding: "2px 8px" }}
                onClick={disabled ? () => {} : onClick}
                data-testid="date-input-field"
            >
                {label && <span className="text-xs mr-2 w-12">{label}</span>}
                <input
                    ref={ref}
                    type="text"
                    data-testid="date-input-field-textbox"
                    onChange={(e) => {
                        setDateInputValue(e.currentTarget.value);
                    }}
                    onBlur={() => handleBlur()}
                    onKeyDown={handleKeyDown}
                    value={dateInputValue}
                    className={classNames(
                        "dateInputField__input outline-none focus:outline-none text-xs py-0.5",
                        inputClassName,
                    )}
                    disabled={disabled}
                    placeholder={showPlaceholder ? "DD/MM/YYYY" : ""}
                />
                <CancelIcon
                    className={classNames("dateInputField__clearIcon", !!dateInputValue ? "opacity-70" : "opacity-0")}
                    onClick={!!dateInputValue ? handleClear : noop}
                />
                {showCalendarIcon && <CalendarIcon className="ml-1" data-testid="date-input-field-icon" />}
            </div>
        );
    },
);
