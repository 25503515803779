import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { parseISO } from "date-fns";
import _ from "lodash";
import { State, Trade, TradeDTO, TradeRealTimeMessage, TradesAPIResponse, TradesRealTimeUpdate } from "./tradesTypes";

const initialState: State = {
    trades: undefined,
    includeOnScreenTrades: false,
    totalCount: undefined,
    tradeDate: null,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
    newTradeIds: [],
    autoUpdate: true,
    isFilterEnabled: false,
    lastRealTimeMessage: undefined,
    signalRGroupName: undefined,
    isTradesUpdateRequired: false,
};

export const getTradesSlice = createSlice({
    name: "trades",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        setTradeDate: (state, action: PayloadAction<Common.Nullable<string>>) => {
            state.tradeDate = action.payload;
            return state;
        },
        setIncludeOnScreenTrades: (state, action: PayloadAction<boolean>) => {
            state.includeOnScreenTrades = action.payload;
            return state;
        },
        getTradesSuccess: (state, action: PayloadAction<TradesAPIResponse>) => {
            state.trades = action.payload.trades;
            state.totalCount = action.payload.trades.length;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getTradesUpdateSuccess: (state, action: PayloadAction<TradesRealTimeUpdate>) => {
            const currentTradeIds = (state.trades ?? []).map((t) => t.tradeId);
            state.newTradeIds = action.payload.response.trades
                .filter((newTrade) => !currentTradeIds.some((t) => t === newTrade.tradeId))
                .map((t) => t.tradeId);

            if (action.payload.autoUpdate) {
                state.trades = action.payload.response.trades;
                state.totalCount = action.payload.response.trades.length;
            }

            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();

            return state;
        },
        getTradesFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
        clearUpdatedTrades: (state) => {
            state.newTradeIds = [];
            state.autoUpdate = true;
            state.isFilterEnabled = false;
            return state;
        },
        setAutoUpdate: (state, action: PayloadAction<boolean>) => {
            state.autoUpdate = action.payload;
            return state;
        },
        setLastRealTimeMessage: (state, action: PayloadAction<TradeRealTimeMessage>) => {
            state.lastRealTimeMessage = action.payload;
            return state;
        },
        setSignalRGroupName: (state, action: PayloadAction<string | undefined>) => {
            state.signalRGroupName = action.payload;
            return state;
        },
        setFilterEnabled: (state, action: PayloadAction<boolean>) => {
            state.isFilterEnabled = action.payload;
            state.autoUpdate = action.payload ? false : true;
            return state;
        },
        setIsTradesUpdateRequired: (state, action: PayloadAction<boolean>) => {
            state.isTradesUpdateRequired = action.payload;
            return state;
        },
    },
});

const tradesMapper = (trade: TradeDTO): Trade => ({
    ...trade,
    startDate: trade.startDate ? parseISO(trade.startDate) : undefined,
    endDate: trade.endDate ? parseISO(trade.endDate) : undefined,
    transactTime: parseISO(trade.transactTime),
    abnTradeFees: trade.abnTradeFees,
    exchangeTradeFees: trade.exchangeTradeFees,
});

export const {
    setStatus,
    getTradesSuccess,
    getTradesFailed,
    getTradesUpdateSuccess,
    clearUpdatedTrades,
    setAutoUpdate,
    setLastRealTimeMessage,
    setSignalRGroupName,
    setTradeDate,
    setIncludeOnScreenTrades,
    setFilterEnabled,
    setIsTradesUpdateRequired,
} = getTradesSlice.actions;

export const tradesSelector = (state: RootState) => ({
    ...state.features.trades,
    trades: state.features.trades.trades?.map(tradesMapper),
});
