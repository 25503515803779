import * as React from "react";

export const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.79662 0.166626C4.16481 0.166626 4.46329 0.465103 4.46329 0.833293V1.35181H7.8707V0.833293C7.8707 0.465103 8.16917 0.166626 8.53736 0.166626C8.90555 0.166626 9.20403 0.465103 9.20403 0.833293V1.35181H10.3151C10.8063 1.35181 11.2773 1.54692 11.6246 1.89421C11.9719 2.2415 12.167 2.71252 12.167 3.20366V10.3148C12.167 10.8059 11.9719 11.2769 11.6246 11.6242C11.2773 11.9715 10.8063 12.1666 10.3151 12.1666H2.01884C1.5277 12.1666 1.05668 11.9715 0.709387 11.6242C0.362097 11.2769 0.166992 10.8059 0.166992 10.3148V3.20366C0.166992 2.71252 0.362097 2.2415 0.709387 1.89421C1.05668 1.54692 1.5277 1.35181 2.01884 1.35181H3.12996V0.833293C3.12996 0.465103 3.42843 0.166626 3.79662 0.166626ZM3.12996 2.68514H2.01884C1.88132 2.68514 1.74944 2.73977 1.6522 2.83702C1.55496 2.93426 1.50033 3.06614 1.50033 3.20366V10.3148C1.50033 10.4523 1.55495 10.5842 1.6522 10.6814C1.74944 10.7787 1.88132 10.8333 2.01884 10.8333H10.3151C10.4527 10.8333 10.5845 10.7787 10.6818 10.6814C10.779 10.5842 10.8337 10.4523 10.8337 10.3148V3.20366C10.8337 3.06614 10.779 2.93426 10.6818 2.83702C10.5845 2.73977 10.4527 2.68514 10.3151 2.68514H9.20403V3.20366C9.20403 3.57185 8.90555 3.87033 8.53736 3.87033C8.16917 3.87033 7.8707 3.57185 7.8707 3.20366V2.68514H4.46329V3.20366C4.46329 3.57185 4.16481 3.87033 3.79662 3.87033C3.42843 3.87033 3.12996 3.57185 3.12996 3.20366V2.68514ZM2.53736 5.57403C2.53736 5.20584 2.83584 4.90737 3.20403 4.90737H9.12996C9.49815 4.90737 9.79662 5.20584 9.79662 5.57403C9.79662 5.94222 9.49815 6.2407 9.12996 6.2407H3.20403C2.83584 6.2407 2.53736 5.94222 2.53736 5.57403Z"
            className="fill-current text-black-900"
            data-testid="calendar-icon"
        />
    </svg>
);
