import { getYearFromPeriod } from "./getYearFromPeriod";

export const getTenor2Year = (
    tenor1Period: Common.Period | undefined,
    tenor1YearString: Common.Nullable<string>,
    tenor2Period: Common.Period,
    referenceDate: Date = new Date(),
) => {
    const tenor1Year = +(tenor1YearString || referenceDate.getFullYear().toString());
    let tenor2Year = +(getYearFromPeriod(tenor2Period, referenceDate) || referenceDate.getFullYear().toString());

    if (tenor1Period && tenor2Period.months[0] < tenor1Period.months[0] && tenor1Year === tenor2Year) {
        tenor2Year++;
    } else if (tenor2Year < tenor1Year) {
        tenor2Year++;
    } else if (tenor2Period.name === "Cal") {
        tenor2Year++;
    }

    return tenor2Year.toString();
};
