import { useCustomLocationProperties } from "common/hooks";
import { getPageTitle } from "common/utils/getPageTitle";
import { selectedDeskSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SpreaderGrid } from "./spreaderGrid";

import { SpreaderUpdateNotification } from "./spreaderUpdateNotification";
import { setViewSpreadsOnly, spreaderViewSpreadsOnlySelector } from "./spreaderSlice";

type Props = {
    match?: any;
};

export const Spreader: React.FC<Props> = ({ match }) => {
    const selectedDesk = useSelector(selectedDeskSelector);
    const viewSpreadsOnly = useSelector(spreaderViewSpreadsOnlySelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const { isPopupWindow } = useCustomLocationProperties();
    const { params } = match || {};
    const { spreads } = params || {};

    React.useEffect(() => {
        document.title = getPageTitle("Spreader", selectedDesk);
    }, [selectedDesk]);

    React.useEffect(() => {
        if (spreads === "spreads") {
            dispatch(setViewSpreadsOnly(true));
        }
    }, []);

    React.useEffect(() => {
        history.push(
            `${isPopupWindow ? "/window" : ""}${
                !viewSpreadsOnly ? "/spreader/monthly" : "/spreader/monthly/spreads-only"
            }`,
        );
    }, [viewSpreadsOnly, isPopupWindow]);

    return (
        <div className="flex flex-1 flex-col p-6 overflow-auto">
            <SpreaderGrid />
            <SpreaderUpdateNotification />
        </div>
    );
};
