import { AppDispatch } from "core/store";
import * as blotterProductGroupsApi from "api/blotterProductGroupsApi";
import { getBlotterProductGroupsFailed, getBlotterProductGroupsSuccess } from "./blotterProductGroupsSlice";

export const fetchBlotterProductGroups = (deskId?: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await blotterProductGroupsApi.getBlotterProductGroups(deskId);
            dispatch(getBlotterProductGroupsSuccess({ deskId, data: response }));
        } catch (err: any) {
            dispatch(getBlotterProductGroupsFailed(err.message));
        }
    };
};
