import { format } from "date-fns";

export const getTenor1Year = (
    tenor1StartDate: Common.Nullable<string>,
    tenor1EndDate: Common.Nullable<string>,
    tenor1Year: Common.Nullable<string>,
    date: Date,
) => {
    return tenor1StartDate || tenor1EndDate ? null : tenor1Year || format(date, "yyyy");
};
