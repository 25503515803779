import { FormInput, FormLabel } from "common/components/form";
import { defaultQuantities } from "common/constants";
import {
    blotterEntryQuantitySelector,
    blotterEntrySideSelector,
    updateBlotterEntry,
} from "features/blotter/blotterSlice";
import debounce from "lodash/debounce";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlotterEntryFormButton } from "./blotterEntryFormButton";

export const Quantity: React.FC = () => {
    const [inputValue, setInputValue] = React.useState("");
    const dispatch = useDispatch();
    const quantity = useSelector(blotterEntryQuantitySelector);
    const side = useSelector(blotterEntrySideSelector);

    const handleQuantitySelection = (selectedQuantity: number) => {
        setInputValue("");
        dispatch(updateBlotterEntry({ quantity: selectedQuantity === quantity ? null : selectedQuantity }));
    };

    const debouncedInputChange = React.useCallback(
        debounce((quantity: number) => {
            dispatch(updateBlotterEntry({ quantity }));
        }, 400),
        [],
    );

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const quantity = e.currentTarget.value;
        setInputValue(quantity);
        debouncedInputChange(+quantity);
    };

    React.useEffect(() => {
        if (quantity === null) {
            setInputValue("");
        } else if (!defaultQuantities.some((q) => q === quantity)) {
            setInputValue(quantity.toString());
        }
    }, [quantity]);

    return (
        <div
            data-testid="quantity-panel"
            className="blotterEntryForm__quantity flex flex-col blotterEntryForm__spacing mt-4"
        >
            <div className="flex justify-center">
                <FormLabel>Quantity</FormLabel>
            </div>
            <div className="flex flex-wrap">
                {defaultQuantities.map((i) => (
                    <BlotterEntryFormButton
                        side={side}
                        selected={quantity === i}
                        key={i}
                        className="w-9"
                        onClick={() => handleQuantitySelection(i)}
                    >
                        {i}
                    </BlotterEntryFormButton>
                ))}
            </div>
            <div className="mt-2">
                <FormInput data-testid="quantity-input" type="number" value={inputValue} onChange={handleInputChange} />
            </div>
        </div>
    );
};
