import { Blotter } from "features/blotter";
import { BlotterGridContainer } from "features/blotter/components/blotterGrid";
import { EODRisk } from "features/eodRisk/eodRisk";
import { Home } from "features/home";
import { LiveRisk } from "features/liveRisk/liveRisk";
import { LiveRiskGridWindowContainer } from "features/liveRisk/liveRiskGrid";
import { AccessDeniedPage, ErrorPage, LoadingPage } from "features/pages";
import { PnL } from "features/pnl";
import { RiskSummaryItemsGridWindowContainer } from "features/riskSummaryItems/riskSummaryItemsGrid";
import { RollOffRisk } from "features/rollOffRisk/rollOffRisk";
import { Spreader } from "features/spreader/monthly/spreader";
import { WeeklySpreader } from "features/spreader/weekly/weeklySpreader";
import { Trades } from "features/trades";

export const fullPageRoutes: Common.Route[] = [
    {
        name: "access-denied",
        displayName: "Access Denied",
        to: "/access-denied",
        component: AccessDeniedPage,
    },
    {
        name: "loading",
        displayName: "Loading",
        to: "/loading",
        component: LoadingPage,
    },
    {
        name: "error",
        displayName: "Error",
        to: "/error",
        component: ErrorPage,
    },
];

export const windowRoutes: Common.Route[] = [
    {
        name: "trades",
        displayName: "Trades",
        to: "/window/trades",
        component: Trades,
    },
    {
        name: "blotter",
        displayName: "Blotter",
        to: "/window/blotter",
        component: BlotterGridContainer,
    },
    {
        name: "eod-onyx",
        displayName: "EOD Risk",
        to: ["/window/eod/onyx", "/window/eod/abn"],
        component: EODRisk,
    },
    {
        name: "live-risk-summary",
        displayName: "Risk Summary Items",
        to: "/window/live-risk-summary",
        component: RiskSummaryItemsGridWindowContainer,
    },
    {
        name: "roll-off-blotter-exchange",
        displayName: "Live Risk",
        to: ["/window/roll-off/blotter", "/window/roll-off/exchange"],
        component: RollOffRisk,
    },
    {
        to: "/window/spreader/monthly/:spreads-only",
        name: "monthly-spreader-spreads-only",
        displayName: "Monthly",
        exact: true,
        component: Spreader,
    },
    {
        to: "/window/spreader/monthly",
        name: "monthly-spreader",
        displayName: "Monthly",
        exact: true,
        component: Spreader,
    },
    {
        to: "/window/spreader/weekly",
        name: "weekly-spreader",
        displayName: "Weekly",
        exact: true,
        component: WeeklySpreader,
    },
    {
        name: "live-blotter-exchange",
        displayName: "Live Risk",
        to: [
            "/window/live/:cross-exchange/:blotter",
            "/window/live/:cross-exchange/:exchange",
            "/window/live/:blotter",
            "/window/live/:exchange",
        ],
        component: LiveRiskGridWindowContainer,
    },
    {
        name: "pnl",
        displayName: "PnL",
        to: "/window/pnl",
        component: PnL,
    },
];

export const routes: Common.Route[] = [
    {
        to: "/",
        name: "home",
        displayName: "Home",
        component: Home,
        exact: true,
        hideFromNav: true,
    },
    {
        to: "/risk",
        name: "risk",
        displayName: "Risk",
        exact: false,
        testId: "risk-navigation-menu-chevron",
        childRoutes: [
            {
                to: "/risk/live",
                name: "live-risk",
                displayName: "Live Risk",
                exact: false,
                redirectTo: "/risk/live/blotter",
                childRoutes: [
                    {
                        to: [
                            "/risk/live/:cross-exchange/:blotter",
                            "/risk/live/:cross-exchange/:exchange",
                            "/risk/live/:blotter",
                            "/risk/live/:exchange",
                        ],
                        name: "live-risk-blotter-exchange",
                        exact: true,
                        component: LiveRisk,
                        hideFromNav: true,
                    },
                ],
            },
            {
                to: "/risk/roll-off",
                name: "roll-off-risk",
                displayName: "Roll Off Risk",
                exact: false,
                redirectTo: "/risk/roll-off/blotter",
                childRoutes: [
                    {
                        to: ["/risk/roll-off/blotter", "/risk/roll-off/exchange"],
                        name: "roll-off-risk-blotter-exchange",
                        exact: true,
                        component: RollOffRisk,
                        hideFromNav: true,
                    },
                ],
            },
            {
                to: "/risk/eod",
                name: "eod-statement-risk",
                displayName: "EoD Statement Risk",
                exact: false,
                redirectTo: "/risk/eod/onyx",
                childRoutes: [
                    {
                        to: ["/risk/eod/onyx", "/risk/eod/abn"],
                        name: "eod-statement-risk-onyx-abn",
                        exact: true,
                        component: EODRisk,
                        hideFromNav: true,
                    },
                    {
                        to: "/risk/eod/compare",
                        name: "eod-statement-risk-compare",
                        exact: true,
                        component: EODRisk,
                        hideFromNav: true,
                    },
                ],
            },
        ],
    },
    {
        to: "/spreader",
        name: "spreader",
        redirectTo: "/spreader/monthly",
        displayName: "Spreader",
        exact: false,
        childRoutes: [
            {
                to: "/spreader/monthly",
                name: "monthly-spreader",
                displayName: "Monthly",
                exact: false,
                childRoutes: [
                    {
                        to: ["/spreader/monthly", "/spreader/monthly/:spreads-only"],
                        name: "monthly-spreader-view",
                        exact: true,
                        component: Spreader,
                        hideFromNav: true,
                    },
                ],
            },
            {
                to: "/spreader/weekly",
                name: "weekly-spreader",
                displayName: "Weekly",
                exact: true,
                component: WeeklySpreader,
            },
        ],
    },
    {
        to: "/blotter",
        name: "blotter",
        displayName: "Blotter",
        component: Blotter,
        exact: true,
    },
    {
        to: "/trades",
        name: "trades",
        displayName: "Trades",
        component: Trades,
        exact: true,
    },
    {
        to: "/pnl",
        name: "pnl",
        displayName: "PnL",
        exact: true,
        component: PnL,
    },
    {
        to: "/workspace",
        name: "workspace",
        displayName: "Workspace",
        exact: true,
        hideFromNav: true,
        childRoutes: [
            {
                to: "/workspace/workspace-1",
                name: "workspace-1",
                displayName: "Workspace 1",
                exact: true,
            },
            {
                to: "/workspace/add",
                name: "add-workspace",
                displayName: "+ Add Workspace",
                exact: true,
            },
        ],
    },
];
