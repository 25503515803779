import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { CounterPartyTypesAPIResponse, State } from "./types";
import orderBy from "lodash/orderBy";

const initialState: State = {
    counterPartyTypes: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getCounterPartyTypesSlice = createSlice({
    name: "counterPartyTypes",
    initialState,
    reducers: {
        getCounterPartyTypesSuccess: (state, action: PayloadAction<CounterPartyTypesAPIResponse>) => {
            state.counterPartyTypes = orderBy(action.payload.counterPartyTypes, ["sortOrder"], ["asc"]);
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getCounterPartyTypesFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
    },
});

export const { getCounterPartyTypesSuccess, getCounterPartyTypesFailed } = getCounterPartyTypesSlice.actions;

const selectCounterPartyTypes = (state: RootState) => state.features.counterPartyTypes.counterPartyTypes;
const selectStatus = (state: RootState) => state.features.counterPartyTypes.meta.status;

export const counterPartyTypesSelector = createSelector(
    selectCounterPartyTypes,
    (counterPartyTypes) => counterPartyTypes,
);
export const statusSelector = createSelector(selectStatus, (status) => status);
