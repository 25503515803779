import classNames from "classnames";
import * as React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { HeaderTab } from "common/types";

type Props = {
    tabs: HeaderTab[];
};

export const HeaderNavigationTabs: React.FC<Props> = ({ tabs }) => {
    const { pathname } = useLocation();
    return (
        <div className="flex" data-testid="header-navigation-tabs">
            {tabs.map(({ name, to, disabled = false }, index) => (
                <Link key={name} to={to} className={disabled ? "pointer-events-none" : ""}>
                    <div
                        data-testid={`header-navigation-tab-${index + 1}`}
                        className={classNames(
                            "px-4 py-1",
                            pathname === to ? "bg-green-900 text-white-900" : "bg-white-500",
                            disabled ? "text-white-100" : "cursor-pointer",
                            {
                                "rounded-l-lg": index === 0,
                                "rounded-r-lg": index === tabs.length - 1,
                            },
                        )}
                    >
                        {name}
                    </div>
                </Link>
            ))}
        </div>
    );
};
