import { getAccountSlice } from "features/auth/authSlice";
import { getTradesSlice } from "features/trades/tradesSlice";
import { getDesksSlice } from "features/desks/desksSlice";
import { getBlotterSlice } from "features/blotter/blotterSlice";
import { getTradedUnitsSlice } from "features/tradedUnits/tradedUnitsSlice";
import { getBlotterProductGroupsSlice } from "features/blotterProductGroups/blotterProductGroupsSlice";
import { getExchangesSlice } from "features/exchanges/exchangesSlice";
import { getBrokersSlice } from "features/brokers/brokersSlice";
import { getEodRiskSlice } from "features/eodRisk/eodRiskSlice";
import { combineReducers } from "redux";
import { getLiveRiskSlice } from "features/liveRisk/liveRiskSlice";
import { getRiskSummaryItemSlice } from "features/riskSummaryItems/riskSummaryItemsSlice";
import { getRealTimeSlice } from "features/realTime/realTimeSlice";
import { getRollOffRisksSlice } from "features/rollOffRisk/rollOffRiskSlice";
import { getSpreaderSlice } from "features/spreader/monthly/spreaderSlice";
import { getWeeklySpreaderSlice } from "features/spreader/weekly/weeklySpreaderSlice";
import { getApplicationSlice } from "features/application/applicationSlice";
import { getPnLSlice } from "features/pnl/pnlSlice";
import { getTransferDesksSlice } from "features/transferDesks/transferDesksSlice";
import { getCounterPartyTypesSlice } from "features/counterPartyTypes/counterPartyTypesSlice";

const featuresReducer = combineReducers({
    account: getAccountSlice.reducer,
    trades: getTradesSlice.reducer,
    desks: getDesksSlice.reducer,
    blotter: getBlotterSlice.reducer,
    tradedUnits: getTradedUnitsSlice.reducer,
    blotterProductGroups: getBlotterProductGroupsSlice.reducer,
    exchanges: getExchangesSlice.reducer,
    counterPartyTypes: getCounterPartyTypesSlice.reducer,
    brokers: getBrokersSlice.reducer,
    eodRisk: getEodRiskSlice.reducer,
    liveRisk: getLiveRiskSlice.reducer,
    riskSummaryItems: getRiskSummaryItemSlice.reducer,
    rollOffRisk: getRollOffRisksSlice.reducer,
    spreader: getSpreaderSlice.reducer,
    weeklySpreader: getWeeklySpreaderSlice.reducer,
    pnl: getPnLSlice.reducer,
    transferDesks: getTransferDesksSlice.reducer,
});

const globalReducer = combineReducers({
    application: getApplicationSlice.reducer,
    realTime: getRealTimeSlice.reducer,
});

export const rootReducer = combineReducers({
    features: featuresReducer,
    global: globalReducer,
});
