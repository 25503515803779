import { AppDispatch } from "core/store";
import * as exchangesApi from "api/exchangesApi";
import { getExchangesSuccess, getExchangesFailed } from "./exchangesSlice";

export const fetchExchanges = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await exchangesApi.getExchanges();
            dispatch(getExchangesSuccess(response));
        } catch (err: any) {
            dispatch(getExchangesFailed(err.message));
        }
    };
};
