import {
    CellRange,
    ColumnGroupOpenedEvent,
    GridApi,
    ProcessHeaderForExportParams,
    RangeSelectionChangedEvent,
    ValueFormatterParams,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import Big from "big.js";
import classNames from "classnames";
import { GridLoading, GroupHeader, TenorYearToggle } from "common/components/grid";
import {
    CROSS_EXCHANGE_RISK,
    defaultGridHeaderHeight,
    LIVE_RISK,
    LIVE_RISK_TYPE_BLOTTER,
    LIVE_RISK_TYPE_EXCHANGE,
} from "common/constants";
import { useCustomLocationProperties } from "common/hooks";
import { Collapse, Expand } from "common/icons";
import { populateAverageAndSumValues } from "common/utils/cellCalculations";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import {
    diffProductValueGetter,
    getProductGroupTotal,
    getTenorGrandTotal,
    productValueGetter,
} from "common/utils/risk";
import { getTenorRiskSummaryCellValue, riskSummaryTenorItemColumns } from "common/utils/risk/getTenorRiskSummaryItems";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import { riskSummaryTenorItemsSelector, setIsCrossExchangeView } from "./liveRiskSlice";
import _ from "lodash";
import capitalize from "lodash/capitalize";
import * as React from "react";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { useLiveRiskHubConnection } from "./hooks/useLiveRiskHubConnection";
import { LiveRiskHeader } from "./liveRiskHeader";
import {
    liveRiskGridDataSelector,
    liveRiskRealTimeDataSelector,
    metaSelector,
    productDiffsByProductGroupSelector,
    productGroupsSelector,
    productsByProductGroupSelector,
    riskItemsByGroupSelector,
    riskItemsByTenorGrandTotalSelector,
    riskItemsByTenorSelector,
} from "./liveRiskSlice";
import { fetchLiveRisk } from "./liveRiskThunks";
import { useGridState } from "./useGridState";
import { preserveSelectionOnRender } from "common/components/grid/gridHelpers";
import { getColumnDefs } from "./columnDefs";
import { riskSummaryItemsSelector } from "features/riskSummaryItems/riskSummaryItemsSlice";
import { useHistory } from "react-router";

type Props = {
    match?: any;
};

export const LiveRiskGridWindowContainer: React.FC<Props> = ({ match }) => {
    const { signalRGroupName, isCrossExchangeView } = useSelector(liveRiskRealTimeDataSelector);
    const selectedDesk = useSelector(selectedDeskSelector);

    const { params } = match || {};
    const { cross, blotter, exchange } = params || {};
    const dispatch = useDispatch();
    const history = useHistory();

    useLiveRiskHubConnection(selectedDesk?.topLevelDeskId, signalRGroupName);

    React.useEffect(() => {
        const viewTypeUrlSegment = isCrossExchangeView ? `${LIVE_RISK}/${CROSS_EXCHANGE_RISK}` : LIVE_RISK;
        history.push(
            !isCrossExchangeView
                ? `/window/${viewTypeUrlSegment}/${blotter || exchange}`
                : `/window/${viewTypeUrlSegment}/${blotter || exchange}`,
        );
    }, [isCrossExchangeView]);

    React.useEffect(() => {
        if (cross === "cross") {
            dispatch(setIsCrossExchangeView(true));
        }
    }, []);

    return <LiveRiskGrid />;
};

/* istanbul ignore next */
export const LiveRiskGrid: React.FC = () => {
    const {
        gridWidth,
        grandTotalColumnWidth,
        columnState,
        setColumnState,
        getProductGroupColumnSettings,
        getProductColumnSettings,
        getDiffProductColumnSettings,
        setColumnDefs,
        columnDefs,
        gridRef,
        setGridWidth,
        calculateWidth,
        handleColumnResize,
        recalculateColumnState,
    } = useGridState();

    const dispatch = useDispatch();
    const minWidth = 612;
    const { shortPath } = useCustomLocationProperties();

    const { rollForwardDateTime, includeTas, isCrossExchangeView } = useSelector(liveRiskRealTimeDataSelector);

    const { riskItems, decimalPosition, groupedYears } = useSelector(liveRiskGridDataSelector);

    const meta = useSelector(metaSelector);

    const riskItemsByGroup = useSelector(riskItemsByGroupSelector);
    const riskItemsByTenor = useSelector(riskItemsByTenorSelector);
    const riskItemsByTenorGrandTotal = useSelector(riskItemsByTenorGrandTotalSelector);
    const productsByProductGroup = useSelector(productsByProductGroupSelector);
    const productDiffsByProductGroup = useSelector(productDiffsByProductGroupSelector);

    const { selectedDeskId } = useSelector(desksSelector);
    const selectedDesk = useSelector(selectedDeskSelector);
    const productGroups = useSelector(productGroupsSelector);

    const riskSummaryTenorItems = useSelector(riskSummaryTenorItemsSelector);
    const { riskSummaryItems } = useSelector(riskSummaryItemsSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    React.useEffect(() => {
        if (deskHasBeenSet && [LIVE_RISK_TYPE_BLOTTER, LIVE_RISK_TYPE_EXCHANGE].some((p) => p === shortPath)) {
            dispatch(fetchLiveRisk(shortPath, selectedDeskId!, includeTas, rollForwardDateTime));
        }
        return setColumnState(recalculateColumnState(columnState));
    }, [selectedDesk, selectedDeskId, includeTas, rollForwardDateTime, shortPath, isCrossExchangeView]);

    const valueFormatter = React.useCallback(
        ({ value }: ValueFormatterParams) =>
            value !== null ? formatNumberWithCommas(new Big(value).toFixed(decimalPosition, Big.roundHalfEven)) : "",
        [decimalPosition],
    );

    const productGroupsLoaded = productGroups !== null;
    const noDataAvailable =
        _.isEmpty(riskItems) || _.isEmpty(riskItemsByGroup) || _.isEmpty(riskItemsByTenor) || !productGroupsLoaded;

    const handleProcessHeaderForClipboard = (params: ProcessHeaderForExportParams) => {
        const colDef = params.column.getColDef();
        const { headerName, refData } = colDef;

        if (refData === undefined) return headerName;

        let { productGroup, product, diffProduct } = refData;
        productGroup = productGroup || "";
        product = product ? `-${product}` : "";
        diffProduct = diffProduct ? `-${diffProduct}` : "";

        return `${productGroup}${product}${diffProduct}` || headerName;
    };

    const sumLabel = React.useRef<HTMLLabelElement>(null);
    const avgLabel = React.useRef<HTMLLabelElement>(null);
    const selectedCellRanges = React.useRef<CellRange[]>([]);

    const onRangeSelectionChanged = (event: RangeSelectionChangedEvent) => {
        if (!event.api) {
            return [];
        }

        updateSumAndAverage(event.api);
    };

    const updateSumAndAverage = (gridApi: GridApi) => {
        const updatedRanges = gridApi?.getCellRanges() || [];

        populateAverageAndSumValues(gridApi, updatedRanges, sumLabel, avgLabel, decimalPosition);

        selectedCellRanges.current = updatedRanges.map((range) => ({ ...range }));
    };

    React.useEffect(() => {
        const { api, columnApi } = gridRef.current || {};

        if (!api || !columnApi) {
            return;
        }

        preserveSelectionOnRender(selectedCellRanges, api, columnApi);
        updateSumAndAverage(api);
    }, [
        selectedDeskId,
        includeTas,
        rollForwardDateTime,
        isCrossExchangeView,
        columnState,
        riskItems,
        riskSummaryTenorItems,
        groupedYears,
        riskSummaryItems,
        decimalPosition,
        columnDefs,
    ]);

    React.useEffect(() => {
        if (productGroups) {
            const columnDefs = getColumnDefs({
                productGroups,
                riskItemsByTenor,
                riskItems,
                riskSummaryTenorItemColumns,
                groupedYears,
                dispatch,
                valueFormatter,
                riskItemsByGroup,
                getProductGroupColumnSettings,
                isCrossExchangeView,
                getProductGroupTotal,
                getProductColumnSettings,
                productValueGetter,
                productsByProductGroup,
                diffProductValueGetter,
                productDiffsByProductGroup,
                columnState,
                grandTotalColumnWidth,
                getTenorGrandTotal,
                getDiffProductColumnSettings,
                selectedDesk,
                getTenorRiskSummaryCellValue,
                riskSummaryTenorItems,
            });

            setColumnDefs(columnDefs);
            setGridWidth((prevState) => calculateWidth(prevState));
        }
    }, [
        columnState,
        decimalPosition,
        riskItemsByTenor,
        riskSummaryTenorItems,
        groupedYears,
        productGroups,
        selectedDeskId,
        selectedDesk,
    ]);

    return (
        <div data-testid={`liveRisk-grid`} className="liveRisk-grid flex-1 bg-white-300">
            <div
                className={classNames("ag-theme-alpine h-full", {
                    "opacity-50": meta.status === "loading" && !noDataAvailable,
                })}
                style={{
                    minWidth: `${minWidth}px`,
                    maxWidth: "100%",
                    width: gridWidth ? `${gridWidth}px` : "100%",
                }}
            >
                <LiveRiskHeader
                    colsFunction={() => {
                        let visibleColumns = productGroups?.length || 0;
                        const { columnApi } = gridRef.current || {};

                        if (columnApi) {
                            // -3 is for the tenor year, tenor month and grand total columns
                            visibleColumns = columnApi.getAllDisplayedColumns().length - 3;
                        }

                        return Math.max(
                            visibleColumns,
                            productGroups?.length || (gridWidth ? 0 : Number.MAX_SAFE_INTEGER),
                        );
                    }}
                    sumRef={sumLabel}
                    avgRef={avgLabel}
                    onExportToCSV={() =>
                        gridRef.current &&
                        gridRef.current.api.exportDataAsCsv({
                            fileName: `Live_Risk_${capitalize(shortPath)}_Based_${selectedDesk?.name ?? "Onyx"}.csv`,
                        })
                    }
                />
                {noDataAvailable && productGroupsLoaded && meta.status === "loaded" && (
                    <div data-testid={`no-data`} className="py-4 px-2 text-sm">
                        No data available
                    </div>
                )}

                {noDataAvailable && (meta.status === "loading" || !productGroupsLoaded) && <GridLoading />}

                {meta.status === "error" && <div className="py-4 px-2 text-sm">An error has occurred</div>}

                {!noDataAvailable && meta.status !== "error" && (
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        onColumnResized={handleColumnResize}
                        suppressRowHoverHighlight={true}
                        suppressScrollOnNewData={true}
                        modules={[RangeSelectionModule, ClipboardModule]}
                        enableRangeSelection={true}
                        onRangeSelectionChanged={onRangeSelectionChanged}
                        enableCellTextSelection={false}
                        copyHeadersToClipboard={true}
                        defaultCsvExportParams={{
                            processCellCallback: ({ value, column }: any) => {
                                if (!value || !isFinite(value)) return value;

                                const colId = column ? column.getColId() : "";
                                if (colId === "year") return value;

                                return formatNumberWithCommas(
                                    new Big(value).toFixed(decimalPosition, Big.roundHalfEven),
                                );
                            },
                        }}
                        processHeaderForClipboard={handleProcessHeaderForClipboard}
                        suppressExcelExport
                        suppressRowTransform={true}
                        popupParent={document.body}
                        components={{
                            TenorYearToggle,
                            Expand,
                            Collapse,
                            GroupHeader,
                        }}
                        defaultColDef={{
                            resizable: true,
                            sortable: false,
                            filter: false,
                            floatingFilter: false,
                            suppressMovable: true,
                            flex: gridWidth < minWidth ? 1 : 0,
                        }}
                        headerHeight={defaultGridHeaderHeight}
                        rowStyle={{ borderTopStyle: "none" }}
                        domLayout="autoHeight"
                        ensureDomOrder
                        rowData={riskItemsByTenor}
                        pinnedBottomRowData={riskItemsByTenorGrandTotal}
                        icons={{
                            sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                            sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                            columnGroupOpened: renderToString(<Collapse />),
                            columnGroupClosed: renderToString(<Expand />),
                            menu: "",
                            resize: "",
                        }}
                        getRowHeight={(params: any) => (params.node.isRowPinned() ? 50 : undefined)}
                        onColumnGroupOpened={(event: ColumnGroupOpenedEvent) => {
                            const { api, columnApi } = gridRef?.current || {};

                            if (!api) return;

                            // Handle when a column is expanded or collapsed
                            const level = event.columnGroup.getLevel();
                            const isExpanded = event.columnGroup.isExpanded();
                            const groupId = event.columnGroup.getColGroupDef()?.groupId;
                            const parent = event.columnGroup.getOriginalParent();
                            const parentGroupId = parent?.getColGroupDef()?.groupId;
                            const headerName = event.columnGroup.getColGroupDef()?.headerName ?? "";

                            const noOfGroupsExpanded = columnState
                                ? Object.keys(columnState)
                                      .map((groupId) => columnState[groupId] && columnState[groupId].expanded)
                                      .filter(Boolean).length
                                : 0;

                            // Handle when a top level group is expanded or collapsed
                            if (level === 0 && noOfGroupsExpanded === 0 && isExpanded) {
                                // If expand and we don't already have any expanded groups then expand the header height and column width
                                api && api.setHeaderHeight(75);
                            } else if (level === 0 && noOfGroupsExpanded === 1 && !isExpanded) {
                                // If collapse and this is the last group expanded, then collapse the header height
                                api && api.setHeaderHeight(defaultGridHeaderHeight);
                            }

                            // Update the local grouping state
                            setColumnState((prevState: any) => {
                                const newState = { ...prevState };

                                // If there is no group id set then just return prevState
                                if (!groupId) return prevState;

                                switch (level) {
                                    case 0:
                                        // Initialise the state for the given groupId key
                                        newState[groupId] = newState[groupId] || {
                                            children: {},
                                        };
                                        // Set the expanded state
                                        newState[groupId].expanded = isExpanded;

                                        // If collapse, iterate through the children and collapse all children
                                        if (!isExpanded) {
                                            Object.keys(newState[groupId].children).forEach((c) => {
                                                // Initialise the child key if not already created and set the expanded state
                                                newState[groupId].children[c] = newState[groupId].children[c] || {};
                                                newState[groupId].children[c].expanded = false;

                                                // Iterate through all products and set the corresponding column expanded state
                                                Object.keys(riskItemsByGroup[groupId]).map((product) => {
                                                    columnApi?.setColumnGroupOpened(`${groupId}-${product}`, false);
                                                });
                                            });
                                        }
                                        break;
                                    case 1:
                                        // If there is not parent group id then return
                                        if (!parentGroupId) return prevState;

                                        // Initialise the parent group and children
                                        newState[parentGroupId].children[headerName] =
                                            newState[parentGroupId].children[headerName] || {};

                                        newState[parentGroupId].children[headerName].expanded = isExpanded;
                                        break;
                                    default:
                                        return prevState;
                                }

                                return newState;
                            });
                        }}
                    />
                )}
            </div>
        </div>
    );
};
