import { resolveDate } from "./resolveDate";

export const isCurrentPeriod = (
    period: Common.Period | undefined,
    year: Common.Nullable<string>,
    startDate: Common.Nullable<string>,
    endDate: Common.Nullable<string>,
    referenceDate: Date = new Date(),
) => {
    const currentMonth = referenceDate.getMonth() + 1;
    const currentYear = referenceDate.getFullYear().toString();

    if (!year) year = currentYear;

    if (period) {
        return (
            period.name !== "Cal" &&
            period.months.some((m) => m === currentMonth) &&
            year.toString() === currentYear.toString()
        );
    } else if (startDate && endDate) {
        const start = resolveDate(startDate) || referenceDate;
        const end = resolveDate(endDate) || referenceDate;

        return start.getMonth() + 1 === currentMonth && end.getMonth() + 1 === currentMonth;
    }

    return true;
};
