import { DateComponent } from "common/components/dateComponent";
import { FormLabel } from "common/components/form";
import { resolveDate } from "common/utils/resolveDate";
import * as React from "react";
import { BlotterEntryFormButton } from "./blotterEntryFormButton";
import { tenorPeriods, tenorYears } from "common/constants";
import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { blotterEntrySideSelector } from "features/blotter/blotterSlice";
import { useSelector } from "react-redux";

type Props = {
    id: string;
    title: string;
    period: Common.Nullable<string>;
    year: Common.Nullable<string>;
    startDate: Common.Nullable<Date | string>;
    endDate: Common.Nullable<Date | string>;
    onStartDateSelected(date: Date | null): void;
    onEndDateSelected(date: Date | null): void;
    onTenorPeriodSelected(period: Common.Period): void;
    onTenorYearSelected(year: string): void;
};

export const Tenor: React.FC<Props> = ({
    id,
    title,
    period,
    year,
    startDate,
    endDate,
    onStartDateSelected,
    onEndDateSelected,
    onTenorPeriodSelected,
    onTenorYearSelected,
}) => {
    const start = resolveDate(startDate);
    const end = resolveDate(endDate);

    const side = useSelector(blotterEntrySideSelector);

    const isBlotterPanelDisabled = !!startDate || !!endDate;

    const hasError = !!start && !!end && differenceInDays(start, end) > 0;

    return (
        <div className="flex flex-col blotterEntryForm__spacing mt-4" data-testid={id}>
            <div className="flex justify-center">
                <FormLabel>{title}</FormLabel>
            </div>
            <div className={classNames("blotterEntryForm__tenor flex", isBlotterPanelDisabled && "opacity-50")}>
                <div>
                    {tenorPeriods.map((p) => (
                        <BlotterEntryFormButton
                            side={side}
                            key={p.name}
                            className="w-16"
                            selected={period === p.name}
                            onClick={() => onTenorPeriodSelected(p)}
                            disabled={isBlotterPanelDisabled}
                        >
                            {p.name}
                        </BlotterEntryFormButton>
                    ))}
                </div>
                <div>
                    {tenorYears.map((y) => (
                        <BlotterEntryFormButton
                            key={y}
                            side={side}
                            className="w-16"
                            selected={year === y}
                            onClick={() => onTenorYearSelected(y)}
                            disabled={isBlotterPanelDisabled}
                        >
                            {y}
                        </BlotterEntryFormButton>
                    ))}
                </div>
            </div>
            <div className="ml-2 my-2" data-testid={`${id}-start-date`}>
                <DateComponent
                    label="START:"
                    date={start}
                    onSelectedDate={onStartDateSelected}
                    disabled={false}
                    error={hasError}
                />
            </div>
            <div className="ml-2 my-2" data-testid={`${id}-end-date`}>
                <DateComponent
                    label="END:"
                    date={end}
                    onSelectedDate={onEndDateSelected}
                    disabled={false}
                    error={hasError}
                />
            </div>
        </div>
    );
};
