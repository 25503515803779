import { AccountInfo } from "@azure/msal-common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { State } from "./authTypes";

const initialState: State = {
    account: undefined,
    hasAdminRole: false,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getAccountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.meta.status = "loading";
            return state;
        },
        getAccountSuccess: (state, action: PayloadAction<AccountInfo>) => {
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            state.account = {
                id: action.payload.localAccountId,
                email: action.payload.username,
                name: action.payload.name ?? "",
                roles: (action.payload.idTokenClaims as any)?.roles ?? [],
            };
            state.hasAdminRole =
                (action.payload.idTokenClaims as any)?.roles?.some(
                    (r: string) => (r as Common.AppRole) === "UI.Admin",
                ) || false;

            return state;
        },
        getAccountFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.message = action.payload.message;
            return state;
        },
        setAccountExpired: (state) => {
            state.meta.status = "expired";
            return state;
        },
    },
});

export const { setLoading, getAccountSuccess, getAccountFailed, setAccountExpired } = getAccountSlice.actions;

export const getAccountSelector = (state: RootState) => state.features.account;
