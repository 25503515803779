export const years = new Array(11).fill(0).map((_, index) => new Date().getFullYear() - 5 + index);

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
