import * as desksApi from "api/desksApi";
import { AppDispatch } from "core/store";
import { clearBlotterEntry } from "features/blotter/blotterSlice";
import {
    setStatus,
    getDesksSuccess,
    getDesksFailed,
    setSelectedDeskId,
    getProductGroupsSuccess,
    setIsOnyxDeskSelected,
} from "./desksSlice";
import { DeskDTO } from "./desksTypes";

export const fetchDesks = (hasAdminRole: boolean) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setStatus({ status: "loading" }));

        try {
            const desksResponse = await desksApi.getDesks();
            dispatch(getDesksSuccess({ apiResponse: desksResponse, hasAdminRole }));
        } catch (err: any) {
            dispatch(getDesksFailed(err.message));
        }
    };
};

export const setDesk = (desk?: DeskDTO) => {
    return (dispatch: AppDispatch) => {
        if (desk) {
            sessionStorage.deskId = desk.deskId;
            dispatch(setIsOnyxDeskSelected(false));
        } else {
            sessionStorage.removeItem("deskId");
            dispatch(setIsOnyxDeskSelected(true));
        }

        dispatch(clearBlotterEntry());
        dispatch(setSelectedDeskId(desk ? desk.deskId : null));
    };
};

export const fetchDeskProductGroups = (deskId: number) => {
    return async (dispatch: AppDispatch) => {
        const deskProductGroups = await desksApi.getDeskProductGroups(deskId);
        dispatch(getProductGroupsSuccess(deskProductGroups));
    };
};
