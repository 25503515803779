import classNames from "classnames";
import { DateComponent } from "common/components/dateComponent";
import { FormLabel } from "common/components/form";
import { tenorPeriods } from "common/constants";
import { getTenor1Period } from "common/utils/getTenor1Period";
import { getTenor1Year } from "common/utils/getTenor1Year";
import { isCurrentPeriod } from "common/utils/isCurrentPeriod";
import { addBusinessDays, differenceInCalendarMonths, lastDayOfMonth, startOfMonth } from "date-fns";
import { blotterEntrySideSelector, blotterEntryTenorsSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BlotterEntryFormButton } from "./blotterEntryFormButton";

const today = new Date();
const tomorrow = addBusinessDays(today, 1);

export const PricingDay: React.FC = () => {
    const [pricingDate, setPricingDate] = React.useState<Date | null>(null);
    const dispatch = useDispatch();

    const side = useSelector(blotterEntrySideSelector);
    const { tenor1Period, tenor1Year, pricingDay, tenor1StartDate, tenor1EndDate }= useSelector(blotterEntryTenorsSelector, shallowEqual)

    React.useEffect(() => {
        if (!pricingDay) {
            setPricingDate(null);
        } else {
            const date = new Date();
            date.setDate(pricingDay);
            setPricingDate(date);
        }
    }, [pricingDay]);

    const handlePricingDateSelected = React.useCallback(
        (date: Date | null) => {
            setPricingDate(date);
            dispatch(
                updateBlotterEntry({
                    pricingDay: date ? date.getDate() : null,
                    tenor1Period: date
                        ? getTenor1Period(tenor1StartDate, tenor1EndDate, tenor1Period, date)
                        : tenor1Period,
                    tenor1Year: date ? getTenor1Year(tenor1StartDate, tenor1EndDate, tenor1Year, date) : tenor1Year,
                }),
            );
        },
        [tenor1StartDate, tenor1Year, tenor1Period, tenor1Year],
    );

    const handleTodaySelected = React.useCallback(() => {
        const isDeselected = today.getDate() === pricingDay;

        setPricingDate(isDeselected ? null : today);

        dispatch(
            updateBlotterEntry({
                pricingDay: isDeselected ? null : today.getDate(),
                tenor1Period: isDeselected
                    ? tenor1Period
                    : getTenor1Period(tenor1StartDate, tenor1EndDate, tenor1Period, today),
                tenor1Year: isDeselected
                    ? tenor1Year
                    : getTenor1Year(tenor1StartDate, tenor1EndDate, tenor1Year, today),
            }),
        );
    }, [tenor1StartDate, tenor1EndDate, tenor1Period, tenor1Year, pricingDay]);

    const handleTomorrowSelected = React.useCallback(() => {
        const isDeselected = tomorrow.getDate() === pricingDay;
        setPricingDate(isDeselected ? null : tomorrow);
        dispatch(
            updateBlotterEntry({
                pricingDay: isDeselected ? null : tomorrow.getDate(),
                tenor1Period: isDeselected
                    ? tenor1Period
                    : getTenor1Period(tenor1StartDate, tenor1EndDate, tenor1Period, tomorrow),
                tenor1Year: isDeselected
                    ? tenor1Year
                    : getTenor1Year(tenor1StartDate, tenor1EndDate, tenor1Year, tomorrow),
            }),
        );
    }, [tenor1StartDate, tenor1EndDate, tenor1Period, tenor1Year, pricingDay]);

    const handleFullMonthSelected = React.useCallback(() => {
        const isDeselected = pricingDay === 0;
        setPricingDate(null);
        dispatch(
            updateBlotterEntry({
                pricingDay: isDeselected ? null : 0,
                tenor1Period: isDeselected
                    ? tenor1Period
                    : getTenor1Period(tenor1StartDate, tenor1EndDate, tenor1Period, today),
                tenor1Year: isDeselected
                    ? tenor1Year
                    : getTenor1Year(tenor1StartDate, tenor1EndDate, tenor1Year, today),
            }),
        );
    }, [tenor1StartDate, tenor1EndDate, tenor1Period, tenor1Year, pricingDay]);

    const t1Period = tenorPeriods.find((p) => p.name === tenor1Period);
    const isDisabled = !isCurrentPeriod(t1Period, tenor1Year, tenor1StartDate, tenor1EndDate);

    return (
        <div
            className={classNames("flex flex-1 flex-col w-32 blotterEntryForm__spacing", isDisabled && "opacity-50")}
            data-testid="pricing-day"
        >
            <div className="flex justify-center">
                <FormLabel>Pricing Day</FormLabel>
            </div>
            <BlotterEntryFormButton
                side={side}
                disabled={isDisabled}
                onClick={handleTodaySelected}
                selected={pricingDay === today.getDate()}
                data-testid="today-button"
            >
                Today
            </BlotterEntryFormButton>
            <BlotterEntryFormButton
                side={side}
                disabled={isDisabled || differenceInCalendarMonths(tomorrow, today) > 0}
                onClick={handleTomorrowSelected}
                selected={pricingDay === tomorrow.getDate()}
                data-testid="tomorrow-button"
            >
                Tomorrow
            </BlotterEntryFormButton>
            <BlotterEntryFormButton
                side={side}
                disabled={isDisabled}
                selected={pricingDay === 0}
                onClick={handleFullMonthSelected}
                data-testid="full-month-button"
            >
                Full Month
            </BlotterEntryFormButton>
            <div className="ml-1 mt-1">
                <DateComponent
                    date={pricingDate}
                    onSelectedDate={handlePricingDateSelected}
                    disabled={isDisabled}
                    minDate={startOfMonth(today)}
                    maxDate={lastDayOfMonth(today)}
                />
            </div>
        </div>
    );
};
