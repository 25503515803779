import axios from "axios";
import { ProductGroupDto } from "common/types";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { DesksAPIResponse } from "../features/desks/desksTypes";

export const getDesks = async () => {
    const token = await getToken();

    const response = await axios.get<DesksAPIResponse>(`${apiBaseUrl}/api/desks`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};

export const getTransferDesks = async () => {
    const token = await getToken(); 

    const response = await axios.get<DesksAPIResponse>(`${apiBaseUrl}/api/desks/transfer`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`
        }
    })

    return response.data;
}

export const getDeskProductGroups = async (deskId: number) => {
    const token = await getToken();

    const response = await axios.get<ProductGroupDto[]>(`${apiBaseUrl}/api/desks/${deskId}/product-groups`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};
