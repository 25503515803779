import * as React from "react";

export const Collapse: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
    <svg
        data-testid="collapse-icon"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="1.75" fill="#A3A3A3" stroke="white" strokeWidth="0.5" />
        <path d="M3 7.5H11V6.5H3V7.5Z" fill="white" />
    </svg>
);
