import {
    AccountInfo,
    AuthenticationResult,
    Configuration,
    EventMessage,
    EventType,
    PublicClientApplication,
} from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "core/appInsights";
import { audience, authority, clientId } from "core/env";
import { store } from "core/store";
import { setAccountExpired } from "features/auth/authSlice";

export enum Scopes {
    OPEN_ID = "openid",
    PROFILE = "profile",
    USER_READ = "User.Read",
}

const msalConfig: Configuration = {
    auth: {
        clientId,
        authority,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const clientApplication = new PublicClientApplication(msalConfig);

export const getAccountAsync = async (): Promise<AccountInfo | null> => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active acccount on page load
    const accounts = clientApplication.getAllAccounts();

    if (accounts.length > 0) {
        clientApplication.setActiveAccount(accounts[0]);
    }

    /* istanbul ignore next */
    clientApplication.addEventCallback((event: EventMessage) => {
        // set active account after redirect
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
            event.payload
        ) {
            const account = (event.payload as any).account;
            account &&
                appInsights &&
                appInsights.setAuthenticatedUserContext(account.username, account.homeAccountId, true);
            clientApplication.setActiveAccount(account);
        } else if (
            (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
                event.eventType === EventType.SSO_SILENT_FAILURE ||
                event.eventType === EventType.LOGIN_FAILURE) &&
            event.error
        ) {
            const error = new Error(event.error.message);
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Warning },
                {
                    customDescription: `Error (${event.eventType} | ${event.error.message}) trying to log in`,
                },
            );
        }
    });

    // handle auth redired/do all initial setup for msal
    try {
        await clientApplication.handleRedirectPromise();
        // Check if user signed in
        const account = clientApplication.getActiveAccount();
        if (!account || !account.idTokenClaims) {
            // redirect anonymous user to login page
            clientApplication.loginRedirect();
        }
        return account;
    } catch (error: any) {
        appInsights.trackException(
            { error, severityLevel: SeverityLevel.Warning },
            { customDescription: "Error signing in user" },
        );
        return null;
    }
};

export const getToken = async (): Promise<AuthenticationResult> => {
    try {
        const account = clientApplication.getActiveAccount();
        if (!account) {
            store.dispatch(setAccountExpired());
            return defaultAuthenticationResult;
        } else {
            try {
                const authenticationResult = await clientApplication.acquireTokenSilent({
                    scopes: [audience],
                    account,
                });
                return authenticationResult;
            } catch (error: any) {
                await clientApplication.acquireTokenRedirect({
                    scopes: [audience],
                    account,
                });
                return defaultAuthenticationResult;
            }
        }
    } catch (error: any) {
        appInsights.trackException(
            { error, severityLevel: SeverityLevel.Warning },
            { customDescription: "Error acquiring the token." },
        );
        return defaultAuthenticationResult;
    }
};

export const defaultAuthenticationResult: AuthenticationResult = {
    authority: "",
    uniqueId: "",
    tenantId: "",
    scopes: [],
    account: null,
    idToken: "",
    idTokenClaims: {},
    accessToken: "",
    fromCache: false,
    expiresOn: null,
    tokenType: "",
    correlationId: "",
};
