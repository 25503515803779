import { getRiskSummaryItems } from "api/liveRiskApi";
import { AppDispatch } from "core/store";
import { getRiskSummaryItemsFailed, getRiskSummaryItemsSuccess, setStatus } from "./riskSummaryItemsSlice";

export const fetchRiskSummaryItems = (
    positionType: string,
    deskId: Common.Nullable<number>,
    includeTas: boolean,
    rollForwardDateTime?: string,
) => {
    return async (dispatch: AppDispatch) => {
        if (deskId === null) {
            dispatch(getRiskSummaryItemsSuccess([]));
            return;
        }
        dispatch(setStatus("loading"));
        try {
            const riskSummaryItems = await getRiskSummaryItems(positionType, deskId, includeTas, rollForwardDateTime);
            dispatch(getRiskSummaryItemsSuccess(riskSummaryItems));
        } catch (error: any) {
            dispatch(getRiskSummaryItemsFailed(error.message));
        }
    };
};
