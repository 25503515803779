import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { appInsightsConnectionString } from "core/env";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: appInsightsConnectionString,
        autoTrackPageVisitTime: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        maxAjaxCallsPerView: -1,
        enableAjaxPerfTracking: true,
    },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
