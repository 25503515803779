import * as React from "react";

export const FullScreen: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 4C3.44772 4 3 4.44772 3 5V15C3 15.5523 3.44772 16 4 16H6V15H4.5C4.22386 15 4 14.7761 4 14.5V6H16V14.5C16 14.7761 15.7761 15 15.5 15H14V16H16C16.5523 16 17 15.5523 17 15V5C17 4.44772 16.5523 4 16 4H4Z"
            className="fill-current text-white-100"
        />
        <path d="M10 8L6 13H9V16H11V13H14L10 8Z" className="fill-current text-white-100" />
    </svg>
);
