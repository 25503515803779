import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { CounterPartyTypesAPIResponse } from "features/counterPartyTypes/types";

export const getCounterPartyTypes = async () => {
    const token = await getToken();

    const response = await axios.get<CounterPartyTypesAPIResponse>(`${apiBaseUrl}/api/counter-party-types`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};
