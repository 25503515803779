import { FormLabel } from "common/components/form";
import { blotterEntrySideSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlotterEntryFormButton } from "./blotterEntryFormButton";

export const Side: React.FC = () => {
    const dispatch = useDispatch();
    const side = useSelector(blotterEntrySideSelector);

    const handleSelection = (selectedSide: Common.Side) => {
        dispatch(updateBlotterEntry({ side: selectedSide === side ? null : selectedSide }));
    };

    return (
        <div className="flex flex-col mt-4 blotterEntryForm__spacing" data-testid="side-panel">
            <div className="flex justify-center">
                <FormLabel>Side</FormLabel>
            </div>
            <BlotterEntryFormButton
                side={side}
                data-testid="buy-button"
                selected={side === "BUY"}
                onClick={() => handleSelection("BUY")}
            >
                BUY
            </BlotterEntryFormButton>
            <BlotterEntryFormButton
                side={side}
                data-testid="sell-button"
                selected={side === "SELL"}
                onClick={() => handleSelection("SELL")}
            >
                SELL
            </BlotterEntryFormButton>
        </div>
    );
};
