import { CellRange, CellRangeParams, ColumnApi, GridApi } from "@ag-grid-community/core";

export const mapCellRangeToCellParams = (range: CellRange): CellRangeParams => {
    const endIsZero = range.endRow?.rowIndex === 0;
    const startIsZero = range.startRow?.rowIndex === 0;
    const columnStartId = range.columns.slice(-1).pop()?.getId();
    const columnEndId = range.columns.slice(0, 1).pop()?.getId();
    const columnIds = range.columns.map((column) => column.getId());
    const rangeParams: CellRangeParams = {
        rowStartIndex: startIsZero ? 0 : range.startRow?.rowIndex || null,
        rowStartPinned: range.startRow?.rowPinned || null,
        rowEndIndex: endIsZero ? 0 : range.endRow?.rowIndex || null,
        rowEndPinned: range.endRow?.rowPinned || null,
        columnStart: columnStartId,
        columnEnd: columnEndId,
        columns: columnIds,
    };

    return rangeParams;
};

export const getUpdatedColumnIds = (selectedColumns: string[], columnApi: ColumnApi): Object => {
    const columnNameDictionary: any = {};

    selectedColumns.forEach((column) => {
        const productName = column.split("_")[0];
        const allColumnIds = columnApi?.getAllDisplayedColumns()?.map((column) => column.getId()) || [];
        const newColumnName = allColumnIds.find((column) => column.split("_")[0] === productName) || null;
        columnNameDictionary[column] = newColumnName;
    });

    return columnNameDictionary;
};

export const preserveSelectionOnRender = (
    selectedCellRanges: React.MutableRefObject<CellRange[]>,
    gridApi: GridApi,
    gridColumnApi: ColumnApi,
) => {
    const ranges = selectedCellRanges.current.map((range) => ({ ...range }));
    const paramsArray = ranges.map((range: CellRange) => mapCellRangeToCellParams(range));
    const selectedColumns = paramsArray.flatMap((range) => (range.columns as string[]) || []);
    const columnNameDictionary = getUpdatedColumnIds(selectedColumns, gridColumnApi);

    gridApi.clearRangeSelection();

    if (columnNameDictionary) {
        paramsArray.forEach((range) => {
            const updatedColumns = range.columns
                ?.map((column) => (columnNameDictionary as any)[column as string] as string)
                .filter((column) => column !== null);

            gridApi.addCellRange({
                ...range,
                columns: updatedColumns || [],
            });
        });
    }
};
