import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { BrokersAPIResponse } from "features/brokers/types";

export const getBrokers = async (search?: string) => {
    const token = await getToken();
    const searchParam = search ? `?search=${search}` : "";

    const response = await axios.get<BrokersAPIResponse>(`${apiBaseUrl}/api/brokers${searchParam}`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};
