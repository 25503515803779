import isFinite from "lodash/isFinite";
import { cellShouldBeBlank } from "./riskHelperFunctions";

export const productValueGetter = (productsByProductGroup: any, productGroup: string, product: string, data: any) => {
    if (cellShouldBeBlank(data)) return null;

    const { isGrandTotal, isGroupedByYear } = data;

    if (isGrandTotal) {
        return Object.keys((productsByProductGroup as any)[productGroup][product]).reduce((prev, tenorDate) => {
            prev += (productsByProductGroup as any)[productGroup][product][tenorDate];
            return prev;
        }, 0);
    }

    if (isGroupedByYear) {
        return Object.keys((productsByProductGroup as any)[productGroup][product]).reduce((prev, tenorDate) => {
            if (data.year === tenorDate.substr(0, 4)) {
                prev += (productsByProductGroup as any)[productGroup][product][tenorDate];
            }
            return prev;
        }, 0);
    }

    const value = (productsByProductGroup as any)[productGroup][product][data.tenorDate];
    return isFinite(value) ? value : null;
};
