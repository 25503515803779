import {
    BodyScrollEvent,
    FilterChangedEvent,
    GridApi,
    GridReadyEvent,
    ValueFormatterParams,
} from "@ag-grid-community/core";
import { AgGridColumn, AgGridReact } from "@ag-grid-community/react";
import classNames from "classnames";
import { GridContainer, GridDateFilter, GridLoading } from "common/components/grid";
import { dateFormatter, dateTimeFormatter } from "common/formatters";
import { format } from "date-fns";
import { selectedDeskSelector } from "features/desks/desksSlice";
import debounce from "lodash/debounce";
import delay from "lodash/delay";
import includes from "lodash/includes";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdatedTrades, setAutoUpdate, setFilterEnabled, tradesSelector } from "../tradesSlice";
import { TradesGridHeader } from "./tradesGridHeader";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import Big from "big.js";

/* istanbul ignore next */
export const TradesGrid: React.FC = () => {
    const { meta, trades, newTradeIds, autoUpdate } = useSelector(tradesSelector);
    const [gridApi, setGridApi] = React.useState<GridApi>();
    const dispatch = useDispatch();

    const selectedDesk = useSelector(selectedDeskSelector);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const onFlashRows = (newTradeIds: number[]) => {
        if (gridApi) {
            const displayedRowCount = gridApi.getDisplayedRowCount();
            let rowIndex = gridApi.getFirstDisplayedRow();

            // Loop through all the displayed rows and if we have a matching trade id then flash it
            const rowNodes = [];
            for (let i = 0; i < displayedRowCount; i++) {
                const row = gridApi.getDisplayedRowAtIndex(rowIndex);
                if (row && newTradeIds.some((tradeId) => tradeId === row.data.tradeId)) {
                    rowNodes.push(row);
                }
                rowIndex++;
            }
            if (rowNodes && rowNodes.length > 0)
                gridApi.flashCells({
                    rowNodes,
                });
        }
    };

    const debouncedScrollHandler = React.useCallback(
        debounce((pos: number) => {
            // Automatically update the grid if the scroll position is below 32
            dispatch(setAutoUpdate(pos < 32));
        }, 300),
        [],
    );

    // Handle filter change
    const handleFilterChange = (e: FilterChangedEvent) => {
        if (e.afterFloatingFilter) {
            dispatch(setFilterEnabled(e.api.isAnyFilterPresent()));
        }
    };

    // Handle scroll
    const handleScroll = React.useCallback((e: BodyScrollEvent) => {
        debouncedScrollHandler(e.top);
    }, []);

    const valueFormatter = React.useCallback(
        ({ value }: ValueFormatterParams, decimalPlaces: number) =>
            value !== undefined && value !== null
                ? formatNumberWithCommas(new Big(value).toFixed(decimalPlaces, Big.roundHalfEven))
                : "",
        [],
    );

    const multiValueFormatter = React.useCallback(({ value }: ValueFormatterParams, decimalPlaces: number) => {
        if (value === undefined || value === null) {
            return "";
        }
        return value
            .split(",")
            .map((v: string) => formatNumberWithCommas(new Big(v.trim()).toFixed(decimalPlaces, Big.roundHalfEven)))
            .join(", ");
    }, []);

    // Animate the grid rows which have changed
    React.useLayoutEffect(() => {
        if (autoUpdate && newTradeIds && newTradeIds.length > 0) {
            delay(onFlashRows, 0, newTradeIds);
            dispatch(clearUpdatedTrades());
        }
    }, [autoUpdate, newTradeIds]);

    const noDataAvailable = !Array.isArray(trades) || trades.length === 0;

    return (
        <>
            <TradesGridHeader
                onExportToCSV={() =>
                    gridApi && gridApi.exportDataAsCsv({ fileName: `Trades_${selectedDesk?.name ?? "Onyx"}.csv` })
                }
            />

            {meta.status === "error" && <div className="py-4 px-2 text-sm">An error has occurred</div>}

            {noDataAvailable && meta.status === "loaded" && (
                <div data-testid="no-data" className="py-4 px-2">
                    No data available
                </div>
            )}

            {noDataAvailable && meta.status === "loading" && <GridLoading />}

            {!noDataAvailable && meta.status !== "error" && (
                <div
                    data-testid={`trades-grid${meta.status === "loaded" ? "" : "-loading"}`}
                    className={classNames("flex-1 bg-white-300", {
                        "opacity-50": meta.status === "loading",
                    })}
                >
                    <GridContainer>
                        <AgGridReact
                            modules={[RangeSelectionModule, ClipboardModule]}
                            suppressRowHoverHighlight={true}
                            enableRangeSelection={true}
                            enableCellTextSelection={false}
                            copyHeadersToClipboard={true}
                            onFilterChanged={handleFilterChange}
                            onBodyScroll={handleScroll}
                            onGridReady={onGridReady}
                            enableCellChangeFlash
                            suppressExcelExport
                            components={{ agDateInput: GridDateFilter }}
                            defaultCsvExportParams={{
                                processCellCallback: ({ value, column }: any) => {
                                    if (!value || !column) return value;

                                    const colId = column.getColId();

                                    if (includes(["startDate", "endDate"], colId)) {
                                        return format(value, "dd/MM/yyyy");
                                    } else if (colId === "transactTime") {
                                        return format(value, "dd/MM/yyyy HH:mm:ss");
                                    }

                                    return value;
                                },
                            }}
                            popupParent={document.body}
                            defaultColDef={{
                                flex: 1,
                                minWidth: 65,
                                resizable: true,
                                sortable: true,
                                filter: true,
                                floatingFilter: true,
                            }}
                            rowData={trades}
                            rowStyle={{ borderTopStyle: "none" }}
                            ensureDomOrder
                            icons={{
                                sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                                sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                                menu: "",
                                resize: "",
                            }}
                            rowClassRules={{
                                "trades-grid-row--on-screen": function (params: any) {
                                    return params.data.isOnScreenTrade === true;
                                },
                                "trades-grid-row--matched": function (params: any) {
                                    return !!params.data.isMatched;
                                },
                            }}
                        >
                            <AgGridColumn
                                field="tradeId"
                                minWidth={90}
                                type="numericColumn"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="deskName"
                                minWidth={100}
                                headerName="Desk"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="transactTime"
                                valueFormatter={dateTimeFormatter}
                                minWidth={155}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn field="side" floatingFilterComponentParams={{ suppressFilterButton: true }} />
                            <AgGridColumn
                                field="lastQty"
                                minWidth={90}
                                type="numericColumn"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="tradedUnit"
                                headerName="Unit"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="lastPx"
                                minWidth={90}
                                type="numericColumn"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="livePrice"
                                minWidth={90}
                                type="numericColumn"
                                valueFormatter={(params: ValueFormatterParams) => multiValueFormatter(params, 6)}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="pnl"
                                minWidth={90}
                                type="numericColumn"
                                valueFormatter={(params: ValueFormatterParams) => valueFormatter(params, 2)}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="startDate"
                                minWidth={125}
                                valueFormatter={dateFormatter}
                                filter="agDateColumnFilter"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="endDate"
                                minWidth={125}
                                valueFormatter={dateFormatter}
                                filter="agDateColumnFilter"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="securityId"
                                minWidth={200}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="onyxProductId"
                                headerName="Product Id"
                                minWidth={100}
                                type="numericColumn"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="symbol"
                                minWidth={80}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="productType"
                                minWidth={115}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="productSubType"
                                headerName="Sub Type"
                                minWidth={92}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="productGroup"
                                headerName="Product Group"
                                minWidth={130}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="broker"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                                minWidth={240}
                            />
                            <AgGridColumn
                                field="securityExchange"
                                minWidth={95}
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                                headerName="Exchange"
                            />
                            <AgGridColumn
                                field="abnTradeFees"
                                headerName="ABN Cost"
                                minWidth={80}
                                type="numericColumn"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                                valueFormatter={(params: ValueFormatterParams) => valueFormatter(params, 2)}
                            />
                            <AgGridColumn
                                field="exchangeTradeFees"
                                headerName="Exchange Cost"
                                minWidth={110}
                                type="numericColumn"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                                valueFormatter={(params: ValueFormatterParams) => valueFormatter(params, 2)}
                            />
                            <AgGridColumn
                                field="isTradeAtSettlement"
                                minWidth={75}
                                headerName="TAS"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="execID"
                                minWidth={90}
                                type="numericColumn"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                            <AgGridColumn
                                field="isMatched"
                                minWidth={90}
                                headerName="Matched"
                                floatingFilterComponentParams={{ suppressFilterButton: true }}
                            />
                        </AgGridReact>
                    </GridContainer>
                </div>
            )}
        </>
    );
};
