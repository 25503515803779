export const SEND_TRADES_DATA_UPDATE = "SendTradesDataUpdate";
export const SEND_PNL_DATA_UPDATE = "SendPnlTradesDataUpdate";
export const JOIN_GROUP = "JoinGroup";
export const LEAVE_GROUP = "LeaveGroup";
export const SUBSCRIBED_TO_GROUP = "SubscribedToGroup";
export const UNSUBSCRIBED_FROM_GROUP = "UnsubscribedFromGroup";
export const NOT_SELECTED_COUNTERPARTY_ID = 10;
export const EDIT = "edit";
export const DELETE = "delete";
export const CANCEL = "cancel";
export const DUPLICATE = "duplicate";
