import sortBy from "lodash/sortBy";
import { format, compareAsc, addWeeks, addDays } from "date-fns";
import {
    SpreaderEntryResponseDto,
    SpreaderRiskItemDto,
    SpreaderRiskResponseDto,
    SpreaderRiskRowItem,
} from "../spreaderTypes";

export const createRiskRowItem = (risk: SpreaderRiskResponseDto) =>
    Object.keys(risk.productGroups).reduce((entry, productGroup) => {
        const productGroupId = risk.productGroups[productGroup].productGroupId;
        return {
            ...entry,
            [productGroupId]: undefined,
            [`${productGroupId}-spread`]: 0,
            [`${productGroupId}-dummy`]: undefined,
        };
    }, {});

export const populateTenorData = (initialEntry: Partial<SpreaderRiskRowItem>, risk: SpreaderRiskResponseDto) => {
    const riskTable = Object.keys(risk.productGroups).reduce(
        (tenorPeriods: SpreaderRiskRowItem[], productGroup: string) => {
            risk.productGroups[productGroup].riskItems.forEach(({ tenorPeriod, tenorNumber, tenorCode }) => {
                if (!tenorPeriods.some((t) => t.tenorNumber === tenorNumber)) {
                    tenorPeriods.push({
                        ...initialEntry,
                        tenorPeriod,
                        tenorNumber,
                        tenorCode,
                    });
                }
            });

            return tenorPeriods;
        },
        [],
    );

    // Add in missing tenors where there are no risk items
    const allUniqueTenorNumbers = new Set(riskTable.map((a) => a.tenorNumber));

    const sortedTenorsDates = riskTable.map((t) => new Date(t.tenorCode)).sort(compareAsc);
    const minTenorDate = sortedTenorsDates[0];
    const maxTenorDate = sortedTenorsDates.slice(-1)[0];

    for (let tenorDate = minTenorDate; tenorDate < maxTenorDate; tenorDate = addWeeks(tenorDate, 1)) {
        const tenorNumber = tenorDate.getFullYear() * 10000 + (tenorDate.getMonth() + 1) * 100 + tenorDate.getDate();
        if (!allUniqueTenorNumbers.has(tenorNumber)) {
            riskTable.push({
                ...initialEntry,
                tenorPeriod: format(tenorDate, "ddMMM") + "-" + format(addDays(tenorDate, 5), "ddMMM"),
                tenorNumber: tenorNumber,
                tenorCode: format(tenorDate, "yyyy-MM-dd"),
            });
        }
    }

    return riskTable;
};

export const getDummyEntryTotalValues = (
    riskRow: SpreaderRiskRowItem,
    productGroup: string,
    entries: SpreaderEntryResponseDto,
) => {
    if (!entries[productGroup]) {
        return undefined;
    }

    const dummyEntryForTenorPeriod =
        entries[productGroup].entries.find((e) => e.tenorPeriod === riskRow.tenorPeriod)?.value || undefined;

    if ([dummyEntryForTenorPeriod].every((d) => d === undefined)) return undefined;

    return {
        tenor: dummyEntryForTenorPeriod,
        calendar: 0,
        quarter: 0,
    };
};

export const populateValues = (
    riskTable: SpreaderRiskRowItem[],
    risk: SpreaderRiskResponseDto,
    entries: SpreaderEntryResponseDto,
) => {
    const productGroupList = Object.keys(risk.productGroups);
    const newRiskTable: SpreaderRiskRowItem[] = [];

    riskTable.forEach((riskRow: SpreaderRiskRowItem, index: number) => {
        const newRiskRow: any = { ...riskRow };
        const previousRiskRow: any = index > 0 ? newRiskTable[index - 1] : undefined;

        productGroupList.forEach((productGroup) => {
            const { productGroupId, conversionFactor } = risk.productGroups[productGroup];

            const riskItem: SpreaderRiskItemDto | undefined = risk.productGroups[productGroup].riskItems.find(
                (r) => r.tenorNumber === riskRow.tenorNumber,
            );

            const dummyValues = getDummyEntryTotalValues(riskRow, productGroup, entries);

            const riskValue = riskItem ? riskItem.quantityBBL / 1000 : 0;

            newRiskRow[`${productGroupId}-dummy`] = dummyValues?.tenor;

            if (!riskRow.tenorNumber) return;

            newRiskRow[productGroupId] =
                riskValue + (dummyValues ? (dummyValues.tenor || 0) + dummyValues.quarter + dummyValues.calendar : 0);

            // Column with suffix required to allow total calculation to find it
            newRiskRow[`${productGroupId}-qty`] = newRiskRow[productGroupId];

            if (previousRiskRow) {
                newRiskRow[`${productGroupId}-spread`] =
                    newRiskRow[productGroupId] + previousRiskRow[`${productGroupId}-spread`];
            } else {
                newRiskRow[`${productGroupId}-spread`] = newRiskRow[productGroupId];
            }

            if (conversionFactor) {
                newRiskRow[`${productGroupId}-spread-kt`] = newRiskRow[`${productGroupId}-spread`] / conversionFactor;
            }
        });

        newRiskTable.push(newRiskRow);
    });

    return newRiskTable;
};

export const appendAdditionalTenorPeriods = (riskTable: SpreaderRiskRowItem[], risk: SpreaderRiskResponseDto) => [
    ...riskTable,
    ...risk.additionalTenorPeriods.map((p) => ({ tenorNumber: 999999, tenorPeriod: p, tenorCode: "" })),
];

export const createWeeklySpreaderRiskTable = (risk: SpreaderRiskResponseDto, entries: SpreaderEntryResponseDto) => {
    const initialEntry = createRiskRowItem(risk);
    let riskTable = populateTenorData(initialEntry, risk);
    riskTable = sortBy(riskTable, "tenorCode");
    riskTable = appendAdditionalTenorPeriods(riskTable, risk);
    riskTable = populateValues(riskTable, risk, entries);

    return riskTable;
};
