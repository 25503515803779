import * as React from "react";

export const ChevronDoubleDown: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29289 4.27644C3.90237 4.64502 3.90237 5.24262 4.29289 5.6112L9.29289 10.3303C9.68342 10.6989 10.3166 10.6989 10.7071 10.3303L15.7071 5.6112C16.0976 5.24262 16.0976 4.64502 15.7071 4.27644C15.3166 3.90785 14.6834 3.90785 14.2929 4.27644L10 8.32816L5.70711 4.27644C5.31658 3.90785 4.68342 3.90785 4.29289 4.27644ZM4.29289 9.6697C3.90237 10.0383 3.90237 10.6359 4.29289 11.0045L9.29289 15.7236C9.68342 16.0921 10.3166 16.0921 10.7071 15.7236L15.7071 11.0045C16.0976 10.6359 16.0976 10.0383 15.7071 9.6697C15.3166 9.30111 14.6834 9.30111 14.2929 9.6697L10 13.7214L5.70711 9.6697C5.31658 9.30111 4.68342 9.30111 4.29289 9.6697Z"
            className="fill-current text-white-100"
        />
    </svg>
);
