import { DesksAPIResponse, State } from "./transferDesksTypes";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";

const initialState: State = {
    desks: [],
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getTransferDesksSlice = createSlice({
    name: "transferDesks",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<{ status: Common.Status }>) => {
            state.meta.status = action.payload.status;
            return state;
        },
        getTransferDesksSuccess: (state, action: PayloadAction<DesksAPIResponse>) => {
            state.desks = action.payload.desks;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getTransferDesksFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
    },
});

export const { setStatus, getTransferDesksSuccess, getTransferDesksFailed } = getTransferDesksSlice.actions;

const selectDesks = (state: RootState) => state.features.transferDesks;

export const transferDesksSelector = createSelector(selectDesks, (desks) => desks);
