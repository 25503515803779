import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { format, parseISO, addBusinessDays } from "date-fns";
import _ from "lodash";
import { EODRiskResponseDto, State } from "./eodRiskTypes";
import { TenorDateRiskItem } from "common/types";

const processingDate = format(addBusinessDays(new Date(), -1), "yyyy-MM-dd");

const initialState: State = {
    decimalPosition: 0,
    groupedYears: [],
    processingDate,
    riskItems: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getEodRiskSlice = createSlice({
    name: "eodRisk",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        setProcessingDate: (state, action: PayloadAction<string>) => {
            state.processingDate = action.payload;
            return state;
        },
        getEodRiskItemsSuccess: (state, action: PayloadAction<EODRiskResponseDto>) => {
            state.riskItems = action.payload.riskItems;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            state.groupedYears = [];
            return state;
        },
        getEodRiskItemsFailed: (state, action: PayloadAction<string>) => {
            state.meta.status = "error";
            state.meta.message = action.payload;
            state.meta.lastUpdated = Date.now();
            state.groupedYears = [];
            return state;
        },
        increaseDecimalPosition: (state) => {
            if (state.decimalPosition === 4) return state;
            state.decimalPosition += 1;
            return state;
        },
        decreaseDecimalPosition: (state) => {
            if (state.decimalPosition === 0) return state;
            state.decimalPosition -= 1;
            return state;
        },
        toggleGroupedYear: (state, action: PayloadAction<string>) => {
            if (state.groupedYears.some((y) => y === action.payload)) {
                state.groupedYears = [...state.groupedYears.filter((y) => y !== action.payload)];
            } else {
                state.groupedYears = [...state.groupedYears, action.payload];
            }
            return state;
        },
    },
});

export const {
    setProcessingDate,
    getEodRiskItemsSuccess,
    getEodRiskItemsFailed,
    setStatus,
    increaseDecimalPosition,
    decreaseDecimalPosition,
    toggleGroupedYear,
} = getEodRiskSlice.actions;

export const eodRiskSelector = (state: RootState) => state.features.eodRisk;

export const productGroupsSelector = (state: RootState) => {
    const deskProductGroupNames = state.features.desks.productGroups?.map((dpg) => dpg.productGroup);
    const riskItemProductGroups = riskItemsByGroupSelector(state);
    const riskItemProductGroupNames = Object.keys(riskItemProductGroups).sort();

    if (!deskProductGroupNames) {
        return null;
    }

    return [
        ...deskProductGroupNames.filter((deskProductGroup) =>
            riskItemProductGroupNames.some((riskProductGroup) => riskProductGroup === deskProductGroup),
        ),
        ...riskItemProductGroupNames.filter(
            (riskProductGroup) =>
                !deskProductGroupNames.some((deskProductGroup) => deskProductGroup === riskProductGroup),
        ),
    ];
};

export const riskItemsByGroupSelector = (state: RootState) => {
    const riskItems = state.features.eodRisk.riskItems;

    return _.mapValues(
        _.groupBy(riskItems, (i) => i.productGroup),
        (app) =>
            _.mapValues(
                _.groupBy(app, (i) => i.productName),
                (app) => _.groupBy(app, (i) => i.diffProductName),
            ),
    );
};

export const riskItemsByTenorSelector = (state: RootState): TenorDateRiskItem[] => {
    const riskItems = state.features.eodRisk.riskItems;
    const groupedYears = state.features.eodRisk.groupedYears;

    if (!riskItems) return [];

    // Get a unique list of diff product names
    const uniqueDiffProductNames = _.uniq(riskItems.map((r) => r.diffProductName));

    // Create an object with all the product names and amounts as key/value pairs for rendering on the grid
    const diffProductObject = uniqueDiffProductNames.reduce((prev, current) => {
        return { ...prev, [current]: 0 };
    }, {});

    // Create an object per tenor date / or year for groupings
    const groupedByYears: string[] = [];
    const newRiskItemsByTenor = _.uniq(riskItems.map((r) => r.tenorDate))
        .map((tenorDate) => {
            const year = format(parseISO(tenorDate), "yyyy");
            const isGroupedByYear = _.includes(groupedYears, year);

            if (isGroupedByYear) {
                if (_.includes(groupedByYears, year)) {
                    return null;
                }
                groupedByYears.push(year);
                return {
                    tenorDate: year,
                    year,
                    isGroupedByYear,
                    isGrandTotal: false,
                    ...diffProductObject,
                };
            }

            return {
                tenorDate,
                year: "",
                isGroupedByYear: false,
                isGrandTotal: false,
                ...diffProductObject,
            };
        })
        .filter(Boolean) as TenorDateRiskItem[];

    // Populate only the first row with the year for row spanning
    const rowSpanYears: string[] = [];
    return newRiskItemsByTenor.map((item) => {
        const year = format(parseISO(item.tenorDate), "yyyy");
        if (rowSpanYears.some((y) => y === year)) {
            return { ...item };
        }
        rowSpanYears.push(year);
        return { ...item, year };
    });
};

export const riskItemsByTenorGrandTotalSelector = (state: RootState) => {
    const riskItems = state.features.eodRisk.riskItems;
    if (!riskItems) return [];

    return riskItems.reduce(
        (prev, current, _index) => {
            const riskItem: TenorDateRiskItem = { ...prev, isOverallGrandTotal: false, exchange: null };
            riskItem[current.diffProductName] = (riskItem[current.diffProductName] || 0) + current.quantityBBL;
            return { ...prev, ...riskItem };
        },
        { year: "", tenorDate: format(new Date(), "yyyy-MM-dd"), isGrandTotal: true, isGroupedByYear: false },
    );
};

export const productsByProductGroupSelector = (state: RootState) => {
    const riskItems = state.features.eodRisk.riskItems;
    if (!riskItems) return {};

    return riskItems.reduce((prev, current) => {
        const newValue: any = { ...prev };
        newValue[current.productGroup] = newValue[current.productGroup] || {};

        newValue[current.productGroup][current.productName] = newValue[current.productGroup][current.productName] || {};

        newValue[current.productGroup][current.productName][current.tenorDate] =
            newValue[current.productGroup][current.productName][current.tenorDate] || 0;

        newValue[current.productGroup][current.productName][current.tenorDate] += current.quantityBBL / 1000;

        return newValue;
    }, {});
};

export const productDiffsByProductGroupSelector = (state: RootState) => {
    const riskItems = state.features.eodRisk.riskItems;
    if (!riskItems) return {};

    return riskItems.reduce((prev, current) => {
        const newValue: any = { ...prev };
        newValue[current.productGroup] = newValue[current.productGroup] || {};

        newValue[current.productGroup][current.productName] = newValue[current.productGroup][current.productName] || {};

        newValue[current.productGroup][current.productName][current.diffProductName] =
            newValue[current.productGroup][current.productName][current.diffProductName] || {};

        newValue[current.productGroup][current.productName][current.diffProductName][current.tenorDate] =
            newValue[current.productGroup][current.productName][current.diffProductName][current.tenorDate] || 0;

        newValue[current.productGroup][current.productName][current.diffProductName][current.tenorDate] +=
            current.quantityBBL / 1000;

        return newValue;
    }, {});
};

const selectLastUpdated = (state: RootState) => state.features.eodRisk.meta.lastUpdated;
export const lastUpdatedSelector = createSelector(selectLastUpdated, (lastUpdated) => lastUpdated);
