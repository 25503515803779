import { RiskItemDto, TenorDateRiskItem } from "common/types";
import { getProductGroupTotal } from "./getProductGroupTotal";

export const riskSummaryTenorItemColumns: Record<string, { name: string; key: string; width: number }[]> = {
    NGL: [
        {
            name: "NGL",
            key: "NGL",
            width: 70,
        },
        {
            name: "NGL Spr",
            key: "NGL-Spr",
            width: 70,
        },
        {
            name: "Nap",
            key: "Nap",
            width: 70,
        },
        {
            name: "Nap Spr",
            key: "Nap-Spr",
            width: 70,
        },
        {
            name: "BRT Spr",
            key: "Brent-Futures-Spread",
            width: 70,
        },
        {
            name: "BRT Spr +1",
            key: "Brent-Futures-Spread-Non-Prompt",
            width: 70,
        },
    ],
};

export const calculateRiskSummaryTenorItems = (riskItems: RiskItemDto[]): Record<string, Record<string, number>> => {
    const riskSummaryTenorItems: Record<string, Record<string, number>> = {};

    calculateRiskSummaryTenorItemsSumColumn(
        "NGL",
        riskItems,
        ["C3 LST", "C3 ENT", "C3 Conway", "C3 NWE", "C3 FEI", "C4 FEI", "C4 CP", "C3 CP", "C4 ENT"],
        riskSummaryTenorItems,
    );
    calculateRiskSummaryTenorItemsSumColumn("Nap", riskItems, ["Naphtha", "MOPJ", "C5 ENT"], riskSummaryTenorItems);

    const tenorDates = getOrderedUniqueTenorDates(riskItems);

    calculateRiskSummaryTenorItemsSpreadColumnFromSumColumn("NGL-Spr", "NGL", tenorDates, riskSummaryTenorItems);

    calculateRiskSummaryTenorItemsSpreadColumnFromSumColumn("Nap-Spr", "Nap", tenorDates, riskSummaryTenorItems);

    calculateRiskSummaryTenorItemsSpreadColumnFromProductGroupColumn(
        "Brent-Futures-Spread",
        "BRT",
        tenorDates,
        riskSummaryTenorItems,
        riskItems,
        2,
    );

    calculateRiskSummaryTenorItemsSpreadColumnFromProductGroupColumn(
        "Brent-Futures-Spread-Non-Prompt",
        "BRT",
        tenorDates,
        riskSummaryTenorItems,
        riskItems,
        3,
    );

    return riskSummaryTenorItems;
};

const calculateRiskSummaryTenorItemsSumColumn = (
    columnName: string,
    riskItems: RiskItemDto[],
    includedProductGroups: string[],
    result: Record<string, Record<string, number>>,
) => {
    if (!(columnName in result)) {
        result[columnName] = {};
    }

    for (const riskItem of riskItems) {
        if (includesIgnoringCase(includedProductGroups, riskItem.productGroup)) {
            if (!riskItem.tenorDate.substring(0, 7)) {
                return {};
            }

            if (!(riskItem.tenorDate.substring(0, 7) in result[columnName])) {
                result[columnName][riskItem.tenorDate.substring(0, 7)] = riskItem.quantityBBL / 1000;
            } else {
                result[columnName][riskItem.tenorDate.substring(0, 7)] += riskItem.quantityBBL / 1000;
            }
        }
    }
};

const getOrderedUniqueTenorDates = (riskItems: RiskItemDto[]): string[] => {
    const tenorDates = riskItems.map((riskItem) => riskItem.tenorDate.substring(0, 7));
    const uniqueTenorDates = [...Array.from(new Set(tenorDates))];
    return uniqueTenorDates.sort((a, b) => a.localeCompare(b));
};

const calculateRiskSummaryTenorItemsSpreadColumnFromSumColumn = (
    spreadColumnName: string,
    sourceColumnName: string,
    tenorDates: string[],
    riskSummaryTenorColumns: Record<string, Record<string, number>>,
) => {
    if (!(spreadColumnName in riskSummaryTenorColumns)) {
        riskSummaryTenorColumns[spreadColumnName] = {};
    }

    try {
        for (let index = 0; index < tenorDates.length; index++) {
            const tenorDate = tenorDates[index];
            if (index === 0) {
                riskSummaryTenorColumns[spreadColumnName][tenorDate] =
                    riskSummaryTenorColumns[sourceColumnName][tenorDate] ?? 0;
            } else if (index == 1) {
                riskSummaryTenorColumns[spreadColumnName][tenorDate] =
                    (riskSummaryTenorColumns[sourceColumnName][tenorDate] ?? 0) +
                    (riskSummaryTenorColumns[spreadColumnName][tenorDates[0]] ?? 0) -
                    (getColumnTotal(sourceColumnName, riskSummaryTenorColumns) ?? 0);
            } else {
                riskSummaryTenorColumns[spreadColumnName][tenorDate] =
                    (riskSummaryTenorColumns[sourceColumnName][tenorDate] ?? 0) +
                    (riskSummaryTenorColumns[spreadColumnName][tenorDates[index - 1]] ?? 0);
            }
        }
    } catch (error) {
        console.error(error);
    }
};

const calculateRiskSummaryTenorItemsSpreadColumnFromProductGroupColumn = (
    spreadColumnName: string,
    sourceColumnName: string,
    tenorDates: string[],
    riskSummaryTenorColumns: Record<string, Record<string, number>>,
    riskItems: RiskItemDto[],
    indexOffset: number,
) => {
    if (indexOffset < 1) return;

    if (!(spreadColumnName in riskSummaryTenorColumns)) {
        riskSummaryTenorColumns[spreadColumnName] = {};
    }

    try {
        for (let index = 0; index < tenorDates.length; index++) {
            const tenorDate = tenorDates[index];

            if (index < indexOffset) {
                const value = getRiskCellValue(sourceColumnName, tenorDate, riskItems);
                if (value !== null) riskSummaryTenorColumns[spreadColumnName][tenorDate] = value;
            } else if (index === indexOffset) {
                riskSummaryTenorColumns[spreadColumnName][tenorDate] =
                    (getRiskCellValue(sourceColumnName, tenorDate, riskItems) ?? 0) +
                    (riskSummaryTenorColumns[spreadColumnName][tenorDates[index - 1]] ?? 0) -
                    getRiskProductGroupTotalValue(sourceColumnName, riskItems);
            } else if (index > indexOffset) {
                riskSummaryTenorColumns[spreadColumnName][tenorDate] =
                    (getRiskCellValue(sourceColumnName, tenorDate, riskItems) ?? 0) +
                    (riskSummaryTenorColumns[spreadColumnName][tenorDates[index - 1]] ?? 0);
            }
        }
    } catch (error) {
        console.error(error);
    }
};

const getRiskCellValue = (
    productGroupName: string,
    tenorDate: string,
    riskItems: RiskItemDto[],
): Common.Nullable<number> => {
    const tenorDateRiskItem: TenorDateRiskItem = {
        tenorDate: getTenorDateTime(tenorDate),
        isGrandTotal: false,
        isGroupedByYear: false,
        year: "",
        exchange: null,
        isOverallGrandTotal: false,
    };
    return getProductGroupTotal(riskItems, productGroupName, tenorDateRiskItem);
};

const getRiskProductGroupTotalValue = (productGroupName: string, riskItems: RiskItemDto[]): number => {
    const tenorDateRiskItem: TenorDateRiskItem = {
        tenorDate: "",
        isGrandTotal: true,
        isGroupedByYear: false,
        year: "",
        exchange: null,
        isOverallGrandTotal: false,
    };
    return getProductGroupTotal(riskItems, productGroupName, tenorDateRiskItem) ?? 0;
};

const getTenorDateTime = (tenorDate: string): string => {
    return `${tenorDate}-01T00:00:00`;
};

export const getTenorRiskSummaryCellValue = (
    columnName: string,
    tenorDateRiskItem: TenorDateRiskItem,
    riskSummaryTenorItems: Record<string, Record<string, number>> | undefined,
): Common.Nullable<Number> => {
    if (!riskSummaryTenorItems || !riskSummaryTenorItems[columnName]) return null;
    if (tenorDateRiskItem.exchange && tenorDateRiskItem.isGrandTotal && !tenorDateRiskItem.isOverallGrandTotal)
        return null;

    if (tenorDateRiskItem.isGrandTotal) {
        return getColumnTotal(columnName, riskSummaryTenorItems);
    }
    return riskSummaryTenorItems[columnName][tenorDateRiskItem.tenorDate.substring(0, 7)] ?? null;
};
const getColumnTotal = (columnName: string, dictionary: Record<string, Record<string, number>>): number => {
    return getSumOfValues(dictionary[columnName]);
};

const getSumOfValues = (dictionary: Record<string, number>): number => {
    const dictionaryValues = Object.values(dictionary);
    if (dictionaryValues.length == 0) return 0;

    return dictionaryValues.reduce((a, b) => a + b);
};

const includesIgnoringCase = (stringCollection: string[], stringValue: string): boolean => {
    return stringCollection.map((s) => s.toLowerCase()).includes(stringValue.toLowerCase());
};
