import { GridApi } from "@ag-grid-community/core";
import { blotterTradeToDuplicateSelector, setBlotterTradeToDuplicate } from "features/blotter/blotterSlice";
import { duplicateBlotterTrade } from "features/blotter/blotterThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const useBlotterTradeDuplicateModal = (gridApi?: GridApi) => {
    const dispatch = useDispatch();
    const blotterTradeToDuplicate = useSelector(blotterTradeToDuplicateSelector);
    const [modalProps, setModalProps] = React.useState<{ show: boolean; message?: string }>({ show: false });

    React.useEffect(() => {
        if (blotterTradeToDuplicate) {
            setModalProps({
                show: true,
                message: `Confirm duplication of blotter trade ID - ${blotterTradeToDuplicate.id} ${
                    blotterTradeToDuplicate.side
                } ${blotterTradeToDuplicate.quantity ?? ""} ${blotterTradeToDuplicate.tradedUnitName ?? ""} ${
                    blotterTradeToDuplicate.blotterProductGroupName ?? ""
                }`,
            });
        } else {
            setModalProps({ show: false });
        }
    }, [blotterTradeToDuplicate]);

    const handleDuplicateModalClose = () => {
        dispatch(setBlotterTradeToDuplicate());
        if (gridApi) {
            gridApi.deselectAll();
        }
    };

    const handleDuplicateModalTransactionDate = () => {
        if (blotterTradeToDuplicate) {
            dispatch(duplicateBlotterTrade(blotterTradeToDuplicate.id, true));
        }
        if (gridApi) {
            gridApi.deselectAll();
        }
    };

    const handleDuplicateModalCurrentDate = () => {
        if (blotterTradeToDuplicate) {
            dispatch(duplicateBlotterTrade(blotterTradeToDuplicate.id, false));
        }
        if (gridApi) {
            gridApi.deselectAll();
        }
    };

    return {
        handleDuplicateModalClose,
        handleDuplicateModalTransactionDate,
        handleDuplicateModalCurrentDate,
        duplicateModalProps: modalProps,
    };
};
