import { HeaderLogo } from "features/navigation/components";
import * as React from "react";
import BarLoader from "react-spinners/BarLoader";

export const LoadingPage = () => {
    return (
        <section className="flex w-full flex-1 justify-center items-center flex-col" data-testid="loading-page">
            <HeaderLogo />
            <BarLoader css="margin-top: 0.5rem;" color="#4B4B4B" />
        </section>
    );
};
