import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import {
    DeleteDummyEntryDto,
    SpreaderEntryResponseDto,
    SpreaderRiskResponseDto,
    UpdateDummyEntryDto,
} from "features/spreader/spreaderTypes";
import { store } from "core/store";
import { getWithRetries } from "./getWithRetries";
import { MONTHLY_SPREADER_VIEW, WEEKLY_SPREADER_VIEW } from "features/spreader/utils/constants";

const getSignalRConnectionId = () => {
    const state = store.getState();
    return state.features.spreader.spreaderSignalRConnectionId || "";
};

export const getSpreaderEntries = async (deskId: number) => {
    const token = await getToken();

    const response = await axios.get<SpreaderEntryResponseDto>(
        `${apiBaseUrl}/api/spreader/entries?deskId=${deskId}&spreaderView=${MONTHLY_SPREADER_VIEW}`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );
    return response.data;
};

export const getWeeklySpreaderEntries = async (deskId: number) => {
    const token = await getToken();
    const response = await axios.get<SpreaderEntryResponseDto>(
        `${apiBaseUrl}/api/spreader/entries?deskId=${deskId}&spreaderView=${WEEKLY_SPREADER_VIEW}`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );

    return response.data;
};

export const getSpreaderRisk = async (deskId: number) => {
    const token = await getToken();

    const response = await getWithRetries<SpreaderRiskResponseDto>(
        `${apiBaseUrl}/api/spreader/risk?deskId=${deskId}&spreaderView=${MONTHLY_SPREADER_VIEW}`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );

    return response.data;
};

export const getWeeklySpreaderRisk = async (deskId: number) => {
    const token = await getToken();
    const response = await axios.get<SpreaderRiskResponseDto>(
        `${apiBaseUrl}/api/spreader/weekly-risk?deskId=${deskId}&spreaderView=${WEEKLY_SPREADER_VIEW}`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );

    return response.data;
};

export const updateDummyEntry = async (entries: UpdateDummyEntryDto[]) => {
    const token = await getToken();

    await axios.put(
        `${apiBaseUrl}/api/spreader/entries`,
        { entries },
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
                SignalRConnectionId: getSignalRConnectionId(),
            },
        },
    );
};

export const deleteDummyEntry = async (entry: DeleteDummyEntryDto) => {
    const token = await getToken();

    await axios.delete(`${apiBaseUrl}/api/spreader/entries`, {
        data: {
            entry,
        },
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            SignalRConnectionId: getSignalRConnectionId(),
        },
    });
};

export const deleteDummyEntries = async (deskId: number) => {
    const token = await getToken();

    await axios.delete(`${apiBaseUrl}/api/spreader/entries/clear?deskId=${deskId}`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            SignalRConnectionId: getSignalRConnectionId(),
        },
    });
};

export const deleteWeeklyDummyEntries = async (deskId: number) => {
    const token = await getToken();

    await axios.delete(`${apiBaseUrl}/api/spreader/weekly-entries/clear?deskId=${deskId}`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            SignalRConnectionId: getSignalRConnectionId(),
        },
    });
};
