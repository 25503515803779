import { FormLabel } from "common/components/form";
import {
    blotterEntryIsStripSelector,
    blotterEntrySideSelector,
    updateBlotterEntry,
} from "features/blotter/blotterSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlotterEntryFormButton } from "./blotterEntryFormButton";

export const Strip: React.FC = () => {
    const dispatch = useDispatch();
    const side = useSelector(blotterEntrySideSelector);
    const isStrip = useSelector(blotterEntryIsStripSelector);

    return (
        <div data-testid="strip-panel" className="flex flex-col w-32">
            <div className="flex justify-center">
                <FormLabel>Strip</FormLabel>
            </div>
            <BlotterEntryFormButton
                side={side}
                selected={!!isStrip}
                onClick={() => dispatch(updateBlotterEntry({ isStrip: !isStrip }))}
            >
                Is Strip
            </BlotterEntryFormButton>
        </div>
    );
};
