import { netBrentProductGroupId } from "./customColumns";

export const CUSTOM_AUTOFLATTEN_PRODUCTS = ["BRT", "RBOB"];
export const DEFAULT_POSITION = 1;
export const CUSTOM_POSITION = 3;

export const MONTHLY_SPREADER_VIEW = "MONTHLY";
export const WEEKLY_SPREADER_VIEW = "WEEKLY";

export const spreaderColumnWidth = {
    weeklyTenor: 120,
    monthlyTenor: 57,
    highlightedColumn: 57,
    defaultSpreadsOnly: 62,
    monthlyDefault: 42,
    weeklyDefault: 68,
};

export const columnsWithHighlightedTotals: string[] = [netBrentProductGroupId];
