import classNames from "classnames";
import * as React from "react";
import "./formButton.styles.scss";

export type FormButtonProps = {
    selected?: boolean;
    selectedColor?: string;
    disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export const FormButton: React.FC<FormButtonProps> = ({
    children,
    className,
    selected,
    disabled,
    selectedColor = "bg-green-900",
    ...props
}) => {
    const selectedStyle = `${selectedColor} text-white-900`;

    return (
        <button
            disabled={disabled}
            className={classNames(
                "formButton focus:outline-none tracking-widest leading-5 py-1 px-1 m-1 uppercase text-xs rounded-lg shadow-lg bg-white-600 text-black-800",
                "hover:bg-white-100 hover:text-green-900",
                { [selectedStyle]: selected },
                disabled && "cursor-not-allowed",
                className,
            )}
            {...props}
        >
            {children}
        </button>
    );
};
