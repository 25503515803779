import * as React from "react";
import { FullScreen } from "common/icons";

type Props = {
    name: string;
    relativeUrl: string;
    width?: number;
    height?: number;
};

export const OpenNewWindow: React.FC<Props> = ({ name, relativeUrl, width = 1200, height = 800 }) => {
    return (
        <div
            data-testid="open-new-window"
            className="cursor-pointer"
            onClick={() =>
                window.open(
                    relativeUrl,
                    name,
                    `width=${width},height=${height},menubar=no,status=no,titlebar=no,toolbar=no`,
                )
            }
        >
            <FullScreen />
        </div>
    );
};
