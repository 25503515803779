import { EOD_RISK_TYPE_ABN, EOD_RISK_TYPE_ONYX } from "common/constants";
import { useCustomLocationProperties } from "common/hooks";
import { getPageTitle } from "common/utils/getPageTitle";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./eodRisk.styles.scss";
import { EODRiskGrid } from "./eodRiskGrid";
import { eodRiskSelector } from "./eodRiskSlice";
import { fetchEodRisk } from "./eodRiskThunks";

export const EODRisk: React.FC = () => {
    const { processingDate } = useSelector(eodRiskSelector);
    const { selectedDeskId } = useSelector(desksSelector);
    const selectedDesk = useSelector(selectedDeskSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const { shortPath } = useCustomLocationProperties();
    const dispatch = useDispatch();

    React.useEffect(() => {
        document.title = getPageTitle("EoD Risk", selectedDesk);
    }, [selectedDesk]);

    React.useEffect(() => {
        if (deskHasBeenSet && [EOD_RISK_TYPE_ONYX, EOD_RISK_TYPE_ABN].some((p) => p === shortPath)) {
            dispatch(fetchEodRisk(shortPath, processingDate, selectedDeskId!));
        }
    }, [processingDate, selectedDeskId, shortPath]);

    return (
        <div className="flex flex-col flex-1 p-6 overflow-auto" data-testid="eod-risk-container">
            <div className="flex flex-1 flex-col">
                <EODRiskGrid />
            </div>
        </div>
    );
};
