import * as React from "react";

export const ChevronDoubleLeft: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7236 4.29289C15.355 3.90237 14.7574 3.90237 14.3888 4.29289L9.6697 9.29289C9.30111 9.68342 9.30111 10.3166 9.6697 10.7071L14.3888 15.7071C14.7574 16.0976 15.355 16.0976 15.7236 15.7071C16.0921 15.3166 16.0921 14.6834 15.7236 14.2929L11.6718 10L15.7236 5.70711C16.0921 5.31658 16.0921 4.68342 15.7236 4.29289ZM10.3303 4.29289C9.96172 3.90237 9.36412 3.90237 8.99554 4.29289L4.27644 9.29289C3.90785 9.68342 3.90785 10.3166 4.27644 10.7071L8.99554 15.7071C9.36412 16.0976 9.96172 16.0976 10.3303 15.7071C10.6989 15.3166 10.6989 14.6834 10.3303 14.2929L6.27858 10L10.3303 5.70711C10.6989 5.31658 10.6989 4.68342 10.3303 4.29289Z"
            fill="#A3A3A3"
        />
    </svg>
);
