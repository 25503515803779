import { ProcessCellForExportParams, ValueFormatterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import Big from "big.js";
import classNames from "classnames";
import { GridLoading } from "common/components/grid";
import { LIVE_RISK_TYPE_BLOTTER, LIVE_RISK_TYPE_EXCHANGE } from "common/constants";
import { useCustomLocationProperties } from "common/hooks";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import { useLiveRiskHubConnection } from "features/liveRisk/hooks/useLiveRiskHubConnection";
import { liveRiskRealTimeDataSelector, setStatus } from "features/liveRisk/liveRiskSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiskSummaryItemsHeader } from "./riskSummaryItemsHeader";
import { riskSummaryItemsSelector } from "./riskSummaryItemsSlice";
import { fetchRiskSummaryItems } from "./riskSummaryItemsThunks";
import { getColumnDefs } from "./columnDefs";

export const RiskSummaryItemsGridWindowContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { signalRGroupName } = useSelector(liveRiskRealTimeDataSelector);
    const selectedDesk = useSelector(selectedDeskSelector);

    React.useEffect(() => {
        dispatch(setStatus("loaded"));
    }, []);

    useLiveRiskHubConnection(selectedDesk?.topLevelDeskId, signalRGroupName);

    return <RiskSummaryItemsGrid />;
};

/* istanbul ignore next */
export const RiskSummaryItemsGrid: React.FC = () => {
    const [columnDefs, setColumnDefs] = React.useState<any>();

    const { meta, riskSummaryItems, decimalPosition } = useSelector(riskSummaryItemsSelector);
    const dispatch = useDispatch();
    const { selectedDeskId } = useSelector(desksSelector);
    const selectedDesk = useSelector(selectedDeskSelector);

    const { rollForwardDateTime, includeTas } = useSelector(liveRiskRealTimeDataSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const { shortPath } = useCustomLocationProperties();

    React.useEffect(() => {
        if (
            deskHasBeenSet &&
            meta.status === "init" &&
            [LIVE_RISK_TYPE_BLOTTER, LIVE_RISK_TYPE_EXCHANGE].some((p) => p === shortPath)
        ) {
            dispatch(fetchRiskSummaryItems(shortPath, selectedDeskId!, includeTas, rollForwardDateTime));
        }
    }, [selectedDeskId, meta, shortPath, includeTas, rollForwardDateTime]);

    const valueFormatter = React.useCallback(
        ({ value }: ValueFormatterParams) => {
            return formatNumberWithCommas(new Big(value).toFixed(decimalPosition, Big.roundHalfEven));
        },
        [decimalPosition],
    );

    const noDataAvailable = riskSummaryItems === undefined || riskSummaryItems.length === 0;

    const hideQuantityKt =
        riskSummaryItems === undefined ||
        riskSummaryItems?.every((riskSummaryItem) => riskSummaryItem.quantityInKT === null);

    React.useEffect(() => {
        setColumnDefs(
            getColumnDefs({
                hideQuantityKt,
                valueFormatter,
            }),
        );
    }, [hideQuantityKt, decimalPosition]);

    const gridRef = React.useRef<AgGridReact>(null);

    return (
        <div data-testid={`live-risk-summary-items-grid`} className="flex-1 bg-white-300">
            <div
                className={classNames("ag-theme-alpine w-full", {
                    "opacity-50": !noDataAvailable && meta.status === "loading",
                })}
                style={{ width: hideQuantityKt ? "250px" : "350px" }}
            >
                <RiskSummaryItemsHeader
                    onExportToCSV={() =>
                        gridRef.current &&
                        gridRef.current.api?.exportDataAsCsv({
                            fileName: `Risk_Summary_Items_${selectedDesk?.name ?? "Onyx"}.csv`,
                        })
                    }
                />

                {meta.status === "error" && (
                    <div className="py-4 px-2 text-sm">An error occurred loading risk summary items</div>
                )}

                {meta.status === "loading" && noDataAvailable && (
                    <div className="mt-8">
                        <GridLoading />
                    </div>
                )}

                {meta.status === "loaded" && noDataAvailable && (
                    <div className="py-4 px-2 text-sm">No data available</div>
                )}

                {!noDataAvailable && meta.status !== "error" && (
                    <AgGridReact
                        ref={gridRef}
                        suppressRowHoverHighlight={true}
                        modules={[RangeSelectionModule, ClipboardModule]}
                        enableRangeSelection={true}
                        enableCellTextSelection={false}
                        copyHeadersToClipboard={true}
                        defaultCsvExportParams={{
                            processCellCallback: ({ value }: ProcessCellForExportParams) => {
                                if (!value || !isFinite(value)) return value;
                                return formatNumberWithCommas(
                                    new Big(value).toFixed(decimalPosition, Big.roundHalfEven),
                                );
                            },
                        }}
                        suppressExcelExport
                        suppressRowTransform={true}
                        popupParent={document.body}
                        defaultColDef={{
                            resizable: true,
                            sortable: false,
                            filter: false,
                            floatingFilter: false,
                            suppressMovable: true,
                        }}
                        rowStyle={{ borderTopStyle: "none" }}
                        domLayout="autoHeight"
                        ensureDomOrder
                        rowData={riskSummaryItems}
                        icons={{
                            sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                            sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                            menu: "",
                            resize: "",
                        }}
                        columnDefs={columnDefs}
                    />
                )}
            </div>
        </div>
    );
};
