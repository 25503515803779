import { useLocation } from "react-router-dom";
import * as React from "react";

export const useCustomLocationProperties = () => {
    const { pathname } = useLocation();

    const shortPath = React.useMemo(() => {
        return pathname
            .split("/")
            .reduce((_: any, b: any) => b, "")
            .toLowerCase();
    }, [pathname]);

    const isPopupWindow = pathname.indexOf(`/window/`) >= 0;

    return {
        shortPath,
        isPopupWindow,
    };
};
