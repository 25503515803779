import React from "react";
import { useSelector } from "react-redux";
import { getAccountSelector } from "features/auth/authSlice";
import { NavLink, NavLinkProps } from "react-router-dom";

type Props = {
    role?: Common.AppRole;
};

export const CustomNavLink: React.FC<Props & NavLinkProps> = ({ children, role = "", ...rest }) => {
    const { account } = useSelector(getAccountSelector);
    if (!account || (role && !account.roles.some((r) => r === role))) return null;
    return <NavLink {...rest}>{children}</NavLink>;
};
