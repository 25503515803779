import { RiskItemDto, TenorDateRiskItem } from "common/types";

export const getTenorGrandTotal = (riskItems: RiskItemDto[] | undefined, data: TenorDateRiskItem) => {
    if (!riskItems) return 0;
    const { year, tenorDate, isGrandTotal, isGroupedByYear } = data;
    return riskItems.reduce((prev, current) => {
        const tenorYear = current.tenorDate.substr(0, 4);
        if (
            (!isGroupedByYear && current.tenorDate !== tenorDate && !isGrandTotal) ||
            (isGroupedByYear && year !== tenorYear && !isGrandTotal)
        ) {
            return prev;
        }
        return prev + current.quantityBBL / 1000;
    }, 0);
};
