import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { LicenseManager } from "@ag-grid-enterprise/core";
import ErrorBoundary from "common/components/errorBoundary";
import { store } from "core/store";
import en from "date-fns/locale/en-GB";
import * as React from "react";
import { registerLocale } from "react-datepicker";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { registerAxiosInterceptors } from "./api";
import { App } from "./app";
import "./styles.scss";

registerLocale("en", en);

LicenseManager.setLicenseKey(
    "CompanyName=Onyx Commodities,LicensedApplication=Eagle Risk System,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-032736,SupportServicesEnd=14_October_2023_[v2]_MTY5NzIzODAwMDAwMA==9f49a3aa3da00943bf52da8e3ecdb783",
);

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

registerAxiosInterceptors();

const isIframe = window !== window.parent && !window.opener;

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ErrorBoundary>{isIframe ? <p>Processing AAD response...</p> : <App />}</ErrorBoundary>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
);
if ((window as any).Cypress) {
    (window as any).store = store;
}
