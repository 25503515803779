import * as React from "react";

export const ChevronDoubleUp: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29289 15.7236C3.90237 15.355 3.90237 14.7574 4.29289 14.3888L9.29289 9.6697C9.68342 9.30111 10.3166 9.30111 10.7071 9.6697L15.7071 14.3888C16.0976 14.7574 16.0976 15.355 15.7071 15.7236C15.3166 16.0921 14.6834 16.0921 14.2929 15.7236L10 11.6718L5.70711 15.7236C5.31658 16.0921 4.68342 16.0921 4.29289 15.7236ZM4.29289 10.3303C3.90237 9.96172 3.90237 9.36412 4.29289 8.99554L9.29289 4.27644C9.68342 3.90785 10.3166 3.90785 10.7071 4.27644L15.7071 8.99554C16.0976 9.36412 16.0976 9.96172 15.7071 10.3303C15.3166 10.6989 14.6834 10.6989 14.2929 10.3303L10 6.27858L5.70711 10.3303C5.31658 10.6989 4.68342 10.6989 4.29289 10.3303Z"
            className="fill-current text-white-100"
        />
    </svg>
);
