import { BlotterTrade } from "features/blotter/blotterTypes";
import { brokersSelector } from "features/brokers/brokersSlice";
import { fetchBrokers } from "features/brokers/brokersThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const BrokersDropdownEditor = React.forwardRef((props: { value: string; data: BlotterTrade }, ref) => {
    const [value, setValue] = React.useState<string>(props.value || "");
    const { allBrokers } = useSelector(brokersSelector);
    const dispatch = useDispatch();

    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value || null;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                const selectedId = allBrokers?.find((g) => g.name === value)?.brokerId;
                props.data.brokerId = selectedId || null;
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    React.useEffect(() => {
        if (!allBrokers) {
            dispatch(fetchBrokers());
        }
    }, [allBrokers]);

    return (
        <span>
            <select
                value={value}
                onChange={handleChange}
                className="w-full"
                ref={refInput}
                data-testid="broker-dropdown-editor"
            >
                <option value=""></option>
                {allBrokers?.map((p) => (
                    <option key={p.brokerId} value={p.name}>
                        {p.name}
                    </option>
                ))}
            </select>
        </span>
    );
});
