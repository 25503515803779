import { GridApi } from "@ag-grid-community/core";
import { blotterTradeToDeleteSelector, setBlotterTradeToDelete } from "features/blotter/blotterSlice";
import { deleteBlotterTrade } from "features/blotter/blotterThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const useBlotterTradeDeleteModal = (gridApi?: GridApi) => {
    const dispatch = useDispatch();
    const blotterTradeToDelete = useSelector(blotterTradeToDeleteSelector);
    const [modalProps, setModalProps] = React.useState<{ show: boolean; message?: string }>({ show: false });

    React.useEffect(() => {
        if (blotterTradeToDelete) {
            setModalProps({
                show: true,
                message: `Confirm deletion of blotter trade ID - ${blotterTradeToDelete.id} ${
                    blotterTradeToDelete.side
                } ${blotterTradeToDelete.quantity ?? ""} ${blotterTradeToDelete.tradedUnitName ?? ""} ${
                    blotterTradeToDelete.blotterProductGroupName ?? ""
                }`,
            });
        } else {
            setModalProps({ show: false });
        }
    }, [blotterTradeToDelete]);

    const handleDeleteModalClose = () => {
        dispatch(setBlotterTradeToDelete());
        if (gridApi) {
            gridApi.deselectAll();
        }
    };

    const handleDeleteModalConfirm = () => {
        if (blotterTradeToDelete) {
            dispatch(deleteBlotterTrade(blotterTradeToDelete.id));
        }
        if (gridApi) {
            gridApi.deselectAll();
        }
    };

    return { handleDeleteModalClose, handleDeleteModalConfirm, deleteModalProps: modalProps };
};
