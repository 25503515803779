import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { RiskSummaryItemDto } from "features/riskSummaryItems/riskSummaryItemTypes";
import { LiveRiskResponseDto } from "features/liveRisk/liveRiskTypes";
import { getWithRetries } from "./getWithRetries";

export const getLiveRisk = async (
    positionType: string,
    deskId: Common.Nullable<number>,
    includeTas: boolean,
    rollForwardDateTime?: string,
) => {
    const token = await getToken();

    const params = new URLSearchParams();
    params.append("positionType", positionType);
    params.append("isTAS", includeTas.toString());
    deskId && params.append("deskId", deskId.toString());
    rollForwardDateTime && params.append("rollForwardDateTime", rollForwardDateTime);

    const response = await getWithRetries<LiveRiskResponseDto>(`${apiBaseUrl}/api/live-risk?${params.toString()}`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};

export const getRiskSummaryItems = async (
    positionType: string,
    deskId: Common.Nullable<number>,
    includeTas: boolean,
    rollForwardDateTime?: string,
) => {
    const token = await getToken();
    const params = new URLSearchParams();
    params.append("positionType", positionType);
    deskId !== null && deskId !== undefined && params.append("deskId", deskId.toString());
    params.append("isTAS", includeTas.toString());
    rollForwardDateTime && params.append("rollForwardDateTime", rollForwardDateTime);

    const response = await getWithRetries<RiskSummaryItemDto[]>(
        `${apiBaseUrl}/api/live-risk-summary?${params.toString()}`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );

    return response.data;
};
