import { FormInput, FormLabel } from "common/components/form";
import { blotterEntryBrokerageAdjustmentSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import debounce from "lodash/debounce";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const Adjustment: React.FC = () => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = React.useState("");

    const brokerageAdjustment = useSelector(blotterEntryBrokerageAdjustmentSelector);

    const debouncedInputChange = React.useCallback(
        debounce((brokerageAdjustment: string) => {
            dispatch(updateBlotterEntry({ brokerageAdjustment }));
        }, 400),
        [],
    );

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value);
        debouncedInputChange(e.currentTarget.value);
    };

    React.useEffect(() => {
        if (brokerageAdjustment === null || brokerageAdjustment === undefined) {
            setInputValue("");
        } else {
            setInputValue(brokerageAdjustment);
        }
    }, [brokerageAdjustment]);

    return (
        <div data-testid="adjustment" className="flex flex-col w-32">
            <div className="flex justify-center">
                <FormLabel>Adjustment</FormLabel>
            </div>
            <FormInput value={inputValue} onChange={handleInputChange} data-testid="adjustmentInput" />
        </div>
    );
};
