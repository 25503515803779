import { Account } from "features/auth/authTypes";

export const userHasRoleOrIsAdmin = (
    account: Account | undefined,
    role: Common.AppRole | Common.AppRole[] = [],
    hasAdminRole?: boolean,
): boolean => {
    if (!account) {
        return false;
    }

    if (hasAdminRole) {
        return true;
    }

    if (!Array.isArray(role)) {
        role = [role];
    }

    role.push("UI.Admin");

    return userHasRole(account, role);
};

export const userHasRole = (account: Account | undefined, role: Common.AppRole | Common.AppRole[] = []): boolean => {
    if (!account) {
        return false;
    }

    if (!Array.isArray(role)) {
        role = [role];
    }

    const roleArray = role as Common.AppRole[];

    return account.roles.some((accountRole) => roleArray.some((authRole) => authRole === accountRole));
};
