import { Modal } from "common/components/modal";
import { getPageTitle } from "common/utils/getPageTitle";
import { selectedDeskSelector } from "features/desks/desksSlice";
import { fetchTransferDesks } from "features/transferDesks/transferDesksThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./blotter.styles.scss";
import { blotterPageSelector, setValidationMessage } from "./blotterSlice";
import { submitBlotterTrade } from "./blotterThunks";
import { BlotterEntryFormContainer } from "./components/blotterEntryForm";
import { BlotterGridContainer } from "./components/blotterGrid";

export const Blotter: React.FC = () => {
    const [modalProps, setModalProps] = React.useState<{ show: boolean; message?: string[] }>({ show: false });
    const { validationMessage } = useSelector(blotterPageSelector);
    const selectedDesk = useSelector(selectedDeskSelector);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const handleControlOrShiftEnter = (e: KeyboardEvent) => {
            if (e.key === "Enter" && (e.shiftKey || e.ctrlKey)) {
                dispatch(submitBlotterTrade());
            }
        };

        document.addEventListener("keydown", handleControlOrShiftEnter);
        dispatch(fetchTransferDesks());

        return () => document.removeEventListener("keydown", handleControlOrShiftEnter);
    }, []);

    React.useEffect(() => {
        document.title = getPageTitle("Blotter", selectedDesk);
    }, [selectedDesk]);

    React.useEffect(() => {
        setModalProps({ show: !!validationMessage, message: validationMessage });
    }, [validationMessage]);

    const handleModalClose = () => {
        dispatch(setValidationMessage(undefined));
        setModalProps({ show: false });
    };

    return (
        <>
            <BlotterGridContainer />
            <BlotterEntryFormContainer />
            {modalProps.show && (
                <Modal
                    title="Blotter"
                    onClose={handleModalClose}
                    message={modalProps.message}
                    actions={[
                        {
                            id: "close",
                            text: "Close",
                            action: handleModalClose,
                            mode: "primary",
                        },
                    ]}
                />
            )}
        </>
    );
};
