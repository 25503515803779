import { FormButton, FormButtonProps } from "common/components/form";
import * as React from "react";

type Props = FormButtonProps & {side: Common.Nullable<Common.Side>}

export const BlotterEntryFormButton: React.FC<Props> = React.memo(({ side, children, ...props }) => {
    const selectedColor = side === "SELL" ? { selectedColor: "bg-red-900" } : undefined;

    return (
        <FormButton {...selectedColor} {...props}>
            {children}
        </FormButton>
    );
});
