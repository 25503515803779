import * as React from "react";
import { BarLoader } from "react-spinners";
import "./gridLoading.styles.scss";

export const GridLoading: React.FC = () => {
    return (
        <div
            data-testid="grid-loading"
            className="gridLoading flex w-full justify-center flex-col content-center"
            style={{ height: "90%" }}
        >
            <BarLoader css="margin-top: 0.5rem;align-self: center;" color="#4B4B4B" />
            <div className="self-center uppercase mt-2 font-bold text-xs">Loading</div>
        </div>
    );
};
