import { OpenNewWindow } from "common/components/openNewWindow";
import { useCustomLocationProperties } from "common/hooks";
import { ChevronDoubleLeft, ChevronDoubleRight, SaveIcon } from "common/icons";
import * as React from "react";
import { useDispatch } from "react-redux";
import { decreaseDecimalPosition, increaseDecimalPosition } from "./riskSummaryItemsSlice";

type Props = {
    onExportToCSV(): void;
};

export const RiskSummaryItemsHeader: React.FC<Props> = ({ onExportToCSV }) => {
    const dispatch = useDispatch();
    const { shortPath } = useCustomLocationProperties();

    return (
        <div
            className="flex items-center p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs"
            style={{ minHeight: "44px" }}
        >
            <div className="uppercase">Risk Summary</div>
            <div className="ml-8 cursor-pointer"></div>
            <div className="flex ml-auto">
                <ChevronDoubleLeft
                    data-testid="live-risk-summary-groups-decrease"
                    onClick={() => dispatch(decreaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <ChevronDoubleRight
                    data-testid="live-risk-summary-groups-increase"
                    onClick={() => dispatch(increaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <SaveIcon
                    data-testid="live-risk-summary-csv"
                    className="cursor-pointer select-none mx-1"
                    onClick={onExportToCSV}
                />
                <OpenNewWindow name="live-risk-summary" relativeUrl={`/window/live-risk-summary/${shortPath}`} />
            </div>
        </div>
    );
};
