import { deskHasBeenSetSelector, desksSelector } from "features/desks/desksSlice";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setAutoUpdate, tradesSelector } from "../tradesSlice";
import { fetchTrades } from "../tradesThunks";

export const TradeUpdateNotification: React.FC = () => {
    const dispatch = useDispatch();
    const { newTradeIds, autoUpdate, tradeDate, includeOnScreenTrades } = useSelector(tradesSelector);
    const { selectedDeskId } = useSelector(desksSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    if (autoUpdate || newTradeIds.length === 0) return null;

    return (
        <div
            data-testid="trade-update-notification"
            className="absolute shadow-lg cursor-pointer left-1/2 rounded-lg py-1 px-4 text-xs font-bold bg-black-900 text-white-900"
            style={{ marginTop: "124px" }}
            onClick={() => {
                if (deskHasBeenSet) {
                    dispatch(setAutoUpdate(true));
                    dispatch(fetchTrades(tradeDate, includeOnScreenTrades, selectedDeskId!));
                }
            }}
        >{`${newTradeIds.length} New Trades`}</div>
    );
};
