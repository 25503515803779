import { Button } from "common/components/button";
import { PassiveCheckbox } from "common/components/checkbox";
import { OpenNewWindow } from "common/components/openNewWindow";
import { ChevronDoubleLeft, ChevronDoubleRight, SaveIcon } from "common/icons";
import { desksSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    decreaseDecimalPosition,
    increaseDecimalPosition,
    metaSelector,
    setViewSpreadsOnly,
    spreaderViewSpreadsOnlySelector,
} from "./spreaderSlice";
import { MONTHLY_SPREADER_VIEW } from "../utils/constants";

type Props = {
    sumRef: any;
    avgRef: any;
    onAutoFlatten(spreaderView: string): void;
    onExportToCSV(): void;
    onClearAll(): void;
};

export const SpreaderGridHeader: React.FC<Props> = ({ onExportToCSV, onAutoFlatten, onClearAll, sumRef, avgRef }) => {
    const dispatch = useDispatch();
    const { selectedDeskId } = useSelector(desksSelector);
    const meta = useSelector(metaSelector);
    const headerStyle = `flex flex-wrap items-center p-2 border border-white-700 font-bold text-left text-xs`;
    const viewSpreadsOnly = useSelector(spreaderViewSpreadsOnlySelector);

    return (
        <div className="border-white-700 text-black-800 bg-white-700">
            <div data-testid="spreader-grid-header" className={headerStyle}>
                <div className="uppercase">Spreader</div>
                {selectedDeskId && (
                    <>
                        <div className="ml-4">
                            <Button
                                data-testid="spreader-auto-flatten"
                                size="sm"
                                mode="none"
                                className="bg-white-500"
                                onClick={() => onAutoFlatten(MONTHLY_SPREADER_VIEW)}
                            >
                                Auto Flatten
                            </Button>
                        </div>
                        <div className="ml-2">
                            <Button
                                data-testid="spreader-clear-all"
                                size="sm"
                                mode="none"
                                className="bg-white-500"
                                onClick={onClearAll}
                            >
                                Clear All
                            </Button>
                        </div>
                        <div className="ml-2 mr-2 cursor-pointer" data-testid="spreads-only">
                            <PassiveCheckbox
                                checked={viewSpreadsOnly}
                                onChange={() => dispatch(setViewSpreadsOnly(!viewSpreadsOnly))}
                                disabled={meta.status === "loading" || meta.status === "init"}
                            >
                                View spreads only
                            </PassiveCheckbox>
                        </div>
                    </>
                )}
                <div className="ml-auto flex">
                    <div className="self-center mr-4">
                        <label ref={sumRef}></label>
                    </div>
                    <div className="self-center mr-4">
                        <label ref={avgRef}></label>
                    </div>
                    <ChevronDoubleLeft
                        data-testid="spreader-decrease"
                        onClick={() => dispatch(decreaseDecimalPosition())}
                        className="cursor-pointer select-none"
                    />
                    <ChevronDoubleRight
                        data-testid="spreader-increase"
                        onClick={() => dispatch(increaseDecimalPosition())}
                        className="cursor-pointer select-none"
                    />
                    <SaveIcon
                        data-testid="spreader-csv"
                        className="cursor-pointer select-none mx-1"
                        onClick={onExportToCSV}
                    />
                    <OpenNewWindow
                        name="spreader"
                        relativeUrl={`/window/spreader/monthly${viewSpreadsOnly ? "/spreads-only" : ""}`}
                    />
                </div>
                {viewSpreadsOnly && (
                    <>
                        <div style={{ flexBasis: "100%", height: 0, paddingTop: "4px" }}></div>
                        <div>
                            <span className="text-red-600">Warning: Hidden columns may contain entered values</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
