import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { State } from "./realTimeTypes";

const initialState: State = {
    connectionState: undefined,
    error: undefined,
    lastUpdatedTime: undefined,
    route: undefined,
    fetchOnReconnect: false,
};

export const getRealTimeSlice = createSlice({
    name: "realTime",
    initialState,
    reducers: {
        setConnectionState: (
            state,
            action: PayloadAction<{ state?: HubConnectionState; route?: string; time?: string }>,
        ) => {
            state.fetchOnReconnect = false;

            if (
                (state.connectionState === HubConnectionState.Disconnected ||
                    state.connectionState === HubConnectionState.Reconnecting) &&
                action.payload.state === HubConnectionState.Connected
            ) {
                state.fetchOnReconnect = true;
            }

            state.connectionState = action.payload.state;
            state.lastUpdatedTime = action.payload.time;
            state.route = action.payload.route;
            return state;
        },
        resetFetchOnReconnect: (state) => {
            state.fetchOnReconnect = false;
            return state;
        },
    },
});

export const { setConnectionState, resetFetchOnReconnect } = getRealTimeSlice.actions;

const selectRealTime = (state: RootState) => state.global.realTime;

export const fetchOnReconnectSelector = createSelector(selectRealTime, (realTime) => realTime.fetchOnReconnect);
export const realTimeSelector = createSelector(selectRealTime, (realTime) => realTime);
