import * as React from "react";

export const Check: React.FC = () => (
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4923 1.41421L9.07805 0L3.7152 5.36285L5.12941 6.77706L10.4923 1.41421Z"
            className="fill-current text-white-900"
        />
        <path
            d="M5.12941 6.77706L1.41421 3.06193L0 4.47614L3.7152 8.19134L5.12941 6.77706Z"
            className="fill-current text-white-900"
        />
    </svg>
);
