import { ProcessCellForExportParams, ValueFormatterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import Big from "big.js";
import classNames from "classnames";
import { GridLoading } from "common/components/grid";
import { formatNumberWithCommas, isNumeric } from "common/utils/numberFormatHelpers";
import { getPageTitle } from "common/utils/getPageTitle";
import {
    deskHasBeenSetSelector,
    desksSelector,
    findDeskByName,
    flatDesksSelector,
    selectedDeskSelector,
} from "features/desks/desksSlice";
import { fetchOnReconnectSelector } from "features/realTime/realTimeSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColumnDefs } from "./columnDefs";
import { usePnlHubConnection } from "./hooks/usePnlHubConnection";
import { PnLHeader } from "./pnlHeader";
import {
    decimalPositionSelector,
    lastRealTimeMessageSelector,
    metaSelector,
    pnlItemRowsSelector,
    setIsPnlUpdateRequired,
    isPnlUpdateRequiredSelector,
    pnlIncludeYearToDateSelector,
} from "./pnlSlice";
import "./pnlStyles.scss";
import { fetchPnL, refetchPnL } from "./pnlThunks";
import { PnlItemRow } from "./pnlTypes";
import { useGridState } from "./useGridState";
import { DeskDTO } from "features/desks/desksTypes";
import { Onyx } from "common/constants";
import { getAccountSelector } from "features/auth/authSlice";
import { userHasRoleOrIsAdmin } from "common/utils/authorization";

export const PnL: React.FC = () => {
    const {
        minWidth,
        gridWidth,
        handleColumnResize,
        columnState,
        defaultColumnWidth,
        defaultMinColumnWidth,
        gridRef,
        columnDefs,
        setColumnDefs,
        setColumnState,
        pnlHierachy,
        setPnlHierachy,
    } = useGridState();

    const dispatch = useDispatch();
    const { desks, isOnyxDeskSelected } = useSelector(desksSelector);
    const flatDesks = useSelector(flatDesksSelector);
    const selectedDesk = useSelector(selectedDeskSelector);
    const decimalPosition = useSelector(decimalPositionSelector);
    const lastRealTimeMessage = useSelector(lastRealTimeMessageSelector);

    const meta = useSelector(metaSelector);
    const pnlItemRows = useSelector(pnlItemRowsSelector);

    const fetchOnReconnect = useSelector(fetchOnReconnectSelector);
    const isPnlUpdateRequired = useSelector(isPnlUpdateRequiredSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);
    const includeYearToDatePnl = useSelector(pnlIncludeYearToDateSelector);
    const { account } = useSelector(getAccountSelector);
    usePnlHubConnection();

    React.useEffect(() => {
        document.title = getPageTitle("PnL", selectedDesk);
    }, [selectedDesk]);

    React.useEffect(() => {
        if (deskHasBeenSet) {
            dispatch(fetchPnL(selectedDesk?.topLevelDeskId));
        }
        return setColumnState(null);
    }, [selectedDesk, deskHasBeenSet]);

    React.useEffect(() => {
        if (!lastRealTimeMessage || !deskHasBeenSet) return;

        if (meta.status !== "loading" && meta.status !== "refetching") {
            dispatch(refetchPnL(selectedDesk?.topLevelDeskId));
        } else {
            dispatch(setIsPnlUpdateRequired(true));
        }
    }, [lastRealTimeMessage]);

    React.useEffect(() => {
        if (!lastRealTimeMessage || !meta.status || !deskHasBeenSet) return;

        if (meta.status !== "loading" && meta.status !== "refetching" && isPnlUpdateRequired) {
            dispatch(refetchPnL(selectedDesk?.topLevelDeskId));
        }
    }, [meta.status]);

    React.useEffect(() => {
        if (fetchOnReconnect && deskHasBeenSet) {
            dispatch(fetchPnL(selectedDesk?.topLevelDeskId));
        }
    }, [fetchOnReconnect]);

    const valueFormatter = React.useCallback(
        ({ value }: ValueFormatterParams) =>
            isNumeric(value) ? formatNumberWithCommas(new Big(value).toFixed(decimalPosition, Big.roundHalfEven)) : "",
        [decimalPosition],
    );

    React.useEffect(() => {
        const displayYearToDatePnl = userHasRoleOrIsAdmin(account, "UI.YearToDatePnl.Read") && includeYearToDatePnl;

        setColumnDefs(
            getColumnDefs({
                columnState,
                desks,
                findDeskByName,
                defaultColumnWidth,
                valueFormatter,
                includeYearToDatePnl: displayYearToDatePnl,
            }),
        );
    }, [columnState, decimalPosition, desks, includeYearToDatePnl]);

    const addHierarchy = (pnlItemRow: PnlItemRow): any => {
        const hierarchy = [];
        let deskId: number | undefined = pnlItemRow.deskId;

        // Build the tree hierachy for display in the grid e.g. ["TopLevelDesk", "IntermediateDesk", "ChildDesk"]
        while (deskId && deskId !== pnlItemRow.topLevelDeskId) {
            const desk = flatDesks.find((a: DeskDTO) => a.deskId === deskId);

            if (desk) {
                hierarchy.unshift(desk.name);
            }

            deskId = desk?.parentDeskId;
        }

        if (pnlItemRow.topLevelDeskName) {
            hierarchy.unshift(pnlItemRow.topLevelDeskName);
        }

        // Add Onyx to the top of the hierarchy if Onyx desk is selected
        if (isOnyxDeskSelected) {
            hierarchy.unshift(Onyx);
        }

        return {
            ...pnlItemRow,
            hierarchy,
        };
    };
    React.useEffect(() => {
        setPnlHierachy(pnlItemRows?.map((a) => addHierarchy(a)));
    }, [pnlItemRows]);

    const autoGroupColumnDef = React.useMemo(
        () => ({
            headerName: "Desk Name",
            field: "deskName",
            minWidth: 250,
            cellRendererParams: {
                suppressCount: true,
            },
            sort: "asc" as any,
            comparator: (valueA: string, valueB: string, _nodeA: any, _nodeB: any, _isInverted: boolean) =>
                (flatDesks.find((d) => d.name === valueA)?.sortOrder || 0) -
                (flatDesks.find((d) => d.name === valueB)?.sortOrder || 0),
        }),
        [flatDesks],
    );

    const noDataAvailable = pnlItemRows === undefined || pnlItemRows.length === 0;

    return (
        <div className="flex flex-col flex-1 p-6 overflow-auto" data-testid="pnl-container">
            <div className="flex flex-1 flex-col">
                <div className="pnl-grid flex-1 bg-white-300">
                    <div
                        className={classNames("ag-theme-alpine h-full", {
                            "opacity-50": !noDataAvailable && meta.status === "loading",
                        })}
                        style={{
                            minWidth: `${minWidth}px`,
                            maxWidth: "100%",
                            width: gridWidth ? `${gridWidth}px` : "100%",
                        }}
                    >
                        <PnLHeader
                            onExportToCSV={() => {
                                gridRef.current &&
                                    gridRef.current.api.exportDataAsCsv({
                                        fileName: `PnL_${selectedDesk?.name ?? "Onyx"}.csv`,
                                    });
                            }}
                        />
                        {meta.status === "error" && <div className="py-4 px-2 text-base">An error occurred</div>}
                        {meta.status === "loading" && noDataAvailable && <GridLoading />}
                        {meta.status === "loaded" && noDataAvailable && (
                            <div className="py-4 px-2 text-base">No data available</div>
                        )}
                        {!noDataAvailable && meta.status !== "error" && (
                            <div data-testid="pnl-grid">
                                <AgGridReact
                                    ref={gridRef}
                                    modules={[RangeSelectionModule, ClipboardModule, RowGroupingModule]}
                                    gridOptions={{
                                        getRowId: (data: any) => data.data.deskId,
                                        suppressRowHoverHighlight: true,
                                    }}
                                    treeData
                                    enableCellChangeFlash={false}
                                    enableRangeSelection={true}
                                    enableCellTextSelection={false}
                                    copyHeadersToClipboard={true}
                                    defaultCsvExportParams={{
                                        processCellCallback: ({ value }: ProcessCellForExportParams) => {
                                            if (!value || !isFinite(value)) return value;
                                            return formatNumberWithCommas(
                                                new Big(value).toFixed(decimalPosition, Big.roundHalfEven),
                                            );
                                        },
                                    }}
                                    suppressExcelExport
                                    suppressRowTransform={true}
                                    popupParent={document.body}
                                    defaultColDef={{
                                        resizable: true,
                                        sortable: false,
                                        filter: false,
                                        floatingFilter: false,
                                        minWidth: defaultMinColumnWidth,
                                        suppressMovable: true,
                                    }}
                                    autoGroupColumnDef={autoGroupColumnDef}
                                    suppressAggFuncInHeader
                                    rowStyle={{ borderTopStyle: "none" }}
                                    groupDisplayType="singleColumn"
                                    domLayout="autoHeight"
                                    ensureDomOrder
                                    rowData={pnlHierachy}
                                    getDataPath={(data: any) => data.hierarchy}
                                    icons={{
                                        sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                                        sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                                        menu: "",
                                        resize: "",
                                    }}
                                    onColumnResized={handleColumnResize}
                                    columnDefs={columnDefs}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
