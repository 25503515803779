import { userHasRole, userHasRoleOrIsAdmin } from "common/utils/authorization";
import { getAccountSelector } from "features/auth/authSlice";
import * as React from "react";
import { useSelector } from "react-redux";

type Props = {
    role?: Common.AppRole | Common.AppRole[];
    allowAdmin?: boolean;
};

export const Authorize: React.FC<Props> = ({ role = [], allowAdmin = true, children }) => {
    const { account, hasAdminRole } = useSelector(getAccountSelector);

    const userInRole = allowAdmin ? userHasRoleOrIsAdmin(account, role, hasAdminRole) : userHasRole(account, role);

    if (!userInRole) {
        return null;
    }

    return <>{children}</>;
};
