import { DateComponent } from "common/components/dateComponent";
import { OpenNewWindow } from "common/components/openNewWindow";
import { SaveIcon } from "common/icons";
import { format, parseISO } from "date-fns";
import { blotterGridHeaderStateSelector, setBlotterDate } from "features/blotter/blotterSlice";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

type Props = {
    onExportToCSV(): void;
};

export const BlotterGridHeader: React.FC<Props> = ({ onExportToCSV }) => {
    const [dateValue, setDateValue] = React.useState<Date>(new Date());

    const dispatch = useDispatch();

    const { blotterDate, blotterTrades } = useSelector(blotterGridHeaderStateSelector, shallowEqual);

    React.useEffect(() => {
        setDateValue(parseISO(blotterDate));
    }, []);

    const handleBlotterDateSelected = React.useCallback((date: Date | null) => {
        date && dispatch(setBlotterDate(format(date, "yyyy-MM-dd")));
    }, []);

    return (
        <div
            data-testid="blotter-grid-header"
            className="flex items-center p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs"
        >
            <div data-testid="blotter-count" className="uppercase">
                Blotter Trades ({blotterTrades?.length || 0})
            </div>
            <div className="ml-2 cursor-pointer" data-testid="blotter-date">
                <DateComponent
                    date={dateValue}
                    onSelectedDate={handleBlotterDateSelected}
                    disabled={false}
                    maxDate={new Date()}
                />
            </div>
            <div className="ml-auto flex">
                <SaveIcon
                    data-testid="blotter-csv"
                    className="cursor-pointer select-none mx-1"
                    onClick={onExportToCSV}
                />
                <OpenNewWindow name="blotter" relativeUrl="/window/blotter" />
            </div>
        </div>
    );
};
