import { format } from "date-fns";
import { BlotterTrade, CreateBlotterTrade } from "features/blotter/blotterTypes";

export const getBlotterEntryFromRowData = (row: Partial<BlotterTrade>): CreateBlotterTrade => {
    const {
        id,
        side,
        quantity,
        tradedUnitId,
        blotterProductGroupId,
        tenor1Period,
        tenor1Year,
        tenor1StartDate,
        tenor1EndDate,
        tenor2Period,
        tenor2Year,
        tenor2StartDate,
        tenor2EndDate,
        pricingDay,
        price,
        brokerId,
        counterPartyTypeId,
        brokerageAdjustment,
        exchangeId,
        isStrip,
        deskId,
        date,
        notes,
        transferDeskId,
    } = row;

    return {
        id,
        deskId: deskId || null,
        side: side || null,
        quantity: quantity ? Math.abs(quantity) : null,
        tradedUnitId: tradedUnitId || null,
        blotterProductGroupId: blotterProductGroupId || null,
        tenor1Period: tenor1Period || null,
        tenor1Year: tenor1Year ? tenor1Year.toString() : null,
        tenor1StartDate:
            tenor1StartDate && (!tenor1Period || !tenor1Year) ? format(tenor1StartDate, "yyyy-MM-dd") : null,
        tenor1EndDate: tenor1EndDate && (!tenor1Period || !tenor1Year) ? format(tenor1EndDate, "yyyy-MM-dd") : null,
        tenor2Period: tenor2Period || null,
        tenor2Year: tenor2Year ? tenor2Year.toString() : null,
        tenor2StartDate:
            tenor2StartDate && (!tenor2Period || !tenor2Year) ? format(tenor2StartDate, "yyyy-MM-dd") : null,
        tenor2EndDate: tenor2EndDate && (!tenor2Period || !tenor2Year) ? format(tenor2EndDate, "yyyy-MM-dd") : null,
        pricingDay: pricingDay || null,
        price: price?.toString() || null,
        brokerId: brokerId || null,
        counterPartyTypeId: counterPartyTypeId || null,
        brokerageAdjustment: brokerageAdjustment?.toString() || null,
        exchangeId: exchangeId || null,
        isStrip: !!isStrip,
        date: date ? format(date, "yyyy-MM-dd") : null,
        notes: notes || null,
        transferDeskId: transferDeskId || null,
    };
};
