import { getPageTitle } from "common/utils/getPageTitle";
import { getAccountSelector } from "features/auth/authSlice";
import { selectedDeskSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useSelector } from "react-redux";

export const Home: React.FC = () => {
    const { account } = useSelector(getAccountSelector);
    const selectedDesk = useSelector(selectedDeskSelector);

    React.useEffect(() => {
        document.title = getPageTitle("Home", selectedDesk);
    }, [selectedDesk]);

    if (!account) return null;

    return (
        <div className="flex-1 bg-white-300 p-8">
            <p className="mb-4">
                Welcome {account.name} &lt;{account.email}&gt;
            </p>
            <p>Your roles:</p>
            <ul className="list-disc pl-6">
                {account.roles.map((role: string) => (
                    <li key={role}>{role}</li>
                ))}
            </ul>
        </div>
    );
};
