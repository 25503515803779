import { ValueFormatterParams } from "@ag-grid-community/core";
import { format } from "date-fns";

export const dateFormatter = (data: ValueFormatterParams) =>
    data.value ? format(data.value, "dd/MM/yyyy") : data.value;

export const dateTimeFormatter = (data: ValueFormatterParams) =>
    data.value ? format(data.value, "dd/MM/yyyy HH:mm:ss") : data.value;

export const monthNameFormatter = (data: ValueFormatterParams) => (data.value ? format(data.value, "MMM") : data.value);

export const yearFormatter = (data: ValueFormatterParams) => (data.value ? format(data.value, "yyyy") : data.value);

export const timeFormatter = (data: ValueFormatterParams) => (data.value ? format(data.value, "HH:mm:ss") : data.value);
