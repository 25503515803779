import { RollOffRiskItemDto, TenorDateRiskItem } from "common/types";

export const getTenorGrandTotal = (riskItems: RollOffRiskItemDto[] | undefined, data: TenorDateRiskItem) => {
    if (!riskItems) return null;
    const { year, riskExpiryDateTime, isGrandTotal, isGroupedByYear } = data;
    return riskItems.reduce((prev: number | null, current) => {
        const tenorYear = current.riskExpiryDateTime.substr(0, 4);
        if (
            (!isGroupedByYear && current.riskExpiryDateTime !== riskExpiryDateTime && !isGrandTotal) ||
            (isGroupedByYear && year !== tenorYear && !isGrandTotal)
        ) {
            return prev;
        }
        return current.quantityBBL ? (prev ?? 0) + current.quantityBBL / 1000 : prev;
    }, null);
};
