export const tenorProductValueGetter = (
    diffProductItem: any,
    productDiffsByProductGroup: any,
    productGroup: string,
    product: string,
    diffProduct: string,
    tenorPeriod: string,
    data: any,
) => {
    // Return the total across all tenors
    if (data.isGrandTotal) {
        return Object.keys((productDiffsByProductGroup as any)[productGroup][product][diffProduct]).reduce(
            (prev, riskExpiryDateTime) => {
                prev =
                    (prev ?? 0) +
                    (productDiffsByProductGroup as any)[productGroup][product][diffProduct][riskExpiryDateTime];
                return prev;
            },
            null,
        );
    }

    // Return the total grouped by year
    if (data.isGroupedByYear) {
        return Object.keys((productDiffsByProductGroup as any)[productGroup][product][diffProduct]).reduce(
            (prev, riskExpiryDateTime) => {
                if (data.year === riskExpiryDateTime.substr(0, 4)) {
                    prev =
                        (prev ?? 0) +
                        (productDiffsByProductGroup as any)[productGroup][product][diffProduct][riskExpiryDateTime];
                }
                return prev;
            },
            null,
        );
    }

    // Return the amount for the diff product by tenor date
    const tenorPeriodRiskExpiryItem = diffProductItem[tenorPeriod]?.find(
        (d: any) => d.riskExpiryDateTime === data.riskExpiryDateTime,
    );

    return tenorPeriodRiskExpiryItem ? tenorPeriodRiskExpiryItem.quantityBBL / 1000 : null;
};
