import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { AjaxTimeouts, AxiosCancelledError, AxiosTimeoutError } from "common/constants";
import { appInsights } from "core/appInsights";

export const getWithRetries = async <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
): Promise<R> => {
    let attemptNumber = 0;

    while (attemptNumber < AjaxTimeouts.length) {
        const timeout = AjaxTimeouts[attemptNumber];

        try {
            return await getWithTimeout(timeout, url, config);
        } catch (error: any) {
            if ([AxiosTimeoutError, AxiosCancelledError].indexOf(error.code) >= 0) {
                appInsights.trackException(
                    { error, severityLevel: SeverityLevel.Error },
                    { customDescription: `Timeout limit of ${timeout}ms exceeded for request ${url}` },
                );

                attemptNumber += 1;
            } else {
                throw error;
            }
        }
    }

    throw "Maximum retry attempts exceeded for request";
};

const getWithTimeout = async <T = any, R = AxiosResponse<T>, D = any>(
    timeout: number,
    url: string,
    config: AxiosRequestConfig<D> = {},
): Promise<R> => {
    config.signal = newAbortSignal(timeout);
    config.timeout = timeout;
    return await axios.get(url, config);
};

const newAbortSignal = (timeout: number) => {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeout);
    return abortController.signal;
};
