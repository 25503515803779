import * as React from "react";

export const SideDropdownEditor = React.forwardRef((props: { value: string }, ref) => {
    const [value, setValue] = React.useState(props.value);
    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    return (
        <select
            data-testid="side-dropdown-editor"
            onChange={handleChange}
            ref={refInput}
            value={value}
            className="w-full"
        >
            <option value="BUY">BUY</option>
            <option value="SELL">SELL</option>
        </select>
    );
});
