import { Menu, MenuButton, MenuItem, MenuItems, MenuPopover } from "@reach/menu-button";
import classNames from "classnames";
import { Authorize } from "common/components/auth";
import { Case, SwitchStatement } from "common/components/switchStatement";
import { ChevronIcon } from "common/icons";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./deskSelection.styles.scss";
import { clearProductGroups, desksSelector, selectedDeskSelector } from "./desksSlice";
import { setDesk } from "./desksThunks";
import { DeskDTO } from "./desksTypes";
import { getAccountSelector } from "features/auth/authSlice";

export const DeskSelection = () => {
    const {
        desks,
        isOnyxDeskSelected,
        meta: { status },
    } = useSelector(desksSelector);

    const { hasAdminRole } = useSelector(getAccountSelector);

    const selectedDesk = useSelector(selectedDeskSelector);

    const dispatch = useDispatch();

    const renderDesks = (desks: DeskDTO[], level: number) => {
        // Create padding based on level, if greater than 1
        // i.e. if level = 2 then the left padding is 8
        const indentStyle: { [key: string]: boolean } = {};
        indentStyle[`pl-${level * 4}`] = level > 1;

        return desks.map((childDesk) => (
            <React.Fragment key={childDesk.deskId}>
                <MenuItem
                    data-testid={`desk-selection-item-${childDesk.deskId}`}
                    className={classNames("px-4 py-2 text-gray-900 text-sm cursor-pointer", {
                        ...indentStyle,
                        "desk__item--selected": selectedDesk && childDesk.deskId === selectedDesk.deskId,
                    })}
                    onSelect={() => {
                        if (childDesk?.deskId !== selectedDesk?.deskId) {
                            dispatch(clearProductGroups());
                            dispatch(setDesk(childDesk));
                        }
                    }}
                >
                    {childDesk.name}
                </MenuItem>
                {renderDesks(childDesk.childDesks, level + 1)}
            </React.Fragment>
        ));
    };

    if (!desks || (desks.length === 0 && hasAdminRole === false)) {
        return (
            <div data-testid="desk-selection">
                <span className="text-sm">No Desks Available</span>
            </div>
        );
    }

    return (
        <div data-testid="desk-selection">
            <SwitchStatement test={status}>
                <Case value="loading">
                    <span className="text-sm">Loading...</span>
                </Case>
                <Case value="loaded">
                    <Menu data-testid="desk-selection-menu">
                        <MenuButton
                            className="focus:outline-none px-4 py-2 rounded-lg mr-2 font-bold text-sm bg-white-500"
                            data-testid="desk-selection-button"
                        >
                            {selectedDesk ? selectedDesk.name : "Onyx"}
                            <span aria-hidden="true">
                                <ChevronIcon className="inline ml-2 mb-0.5 align-middle" />
                            </span>
                        </MenuButton>
                        <MenuPopover
                            className="overflow-auto bg-white-900 z-10 absolute rounded-lg shadow-lg"
                            portal={false}
                            style={{
                                right: "32px",
                                top: "64px",
                                maxHeight: "calc(100% - 90px)",
                                width: "244px",
                            }}
                        >
                            <MenuItems className="desks__dropdown flex flex-col bg-white-900 rounded-lg border-0 focus:outline-none shadow-lg">
                                <Authorize>
                                    <MenuItem
                                        data-testid={`desk-selection-item-onyx`}
                                        className={classNames("px-4 py-2 text-gray-900 text-sm cursor-pointer", {
                                            "desk__item--selected": isOnyxDeskSelected,
                                        })}
                                        onSelect={() => void dispatch(setDesk())}
                                    >
                                        Onyx
                                    </MenuItem>
                                </Authorize>
                                {desks && renderDesks(desks, 1)}
                            </MenuItems>
                        </MenuPopover>
                    </Menu>
                </Case>
            </SwitchStatement>
        </div>
    );
};
