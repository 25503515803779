import * as React from "react";

export const ChevronDoubleRight: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.27644 4.29289C4.64502 3.90237 5.24262 3.90237 5.6112 4.29289L10.3303 9.29289C10.6989 9.68342 10.6989 10.3166 10.3303 10.7071L5.6112 15.7071C5.24262 16.0976 4.64502 16.0976 4.27644 15.7071C3.90785 15.3166 3.90785 14.6834 4.27644 14.2929L8.32816 10L4.27644 5.70711C3.90785 5.31658 3.90785 4.68342 4.27644 4.29289ZM9.6697 4.29289C10.0383 3.90237 10.6359 3.90237 11.0045 4.29289L15.7236 9.29289C16.0921 9.68342 16.0921 10.3166 15.7236 10.7071L11.0045 15.7071C10.6359 16.0976 10.0383 16.0976 9.6697 15.7071C9.30111 15.3166 9.30111 14.6834 9.6697 14.2929L13.7214 10L9.6697 5.70711C9.30111 5.31658 9.30111 4.68342 9.6697 4.29289Z"
            fill="#A3A3A3"
        />
    </svg>
);
