import * as React from "react";
import { useSelector } from "react-redux";
import { getAccountSelector } from "features/auth/authSlice";
import { Redirect, Route } from "react-router-dom";

type Props = {
    role?: Common.AppRole;
};

type RouteProps = JSX.LibraryManagedAttributes<typeof Route, Route["props"]>;

export const CustomRoute: React.FC<Props & RouteProps> = ({ children, role = "", ...rest }) => {
    const { account } = useSelector(getAccountSelector);
    if (!account || (role && !account.roles.some((r) => r === role))) return <Redirect to="/access-denied" />;
    return <Route {...rest}>{children}</Route>;
};
