import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { BlotterTradeDto, BlotterTradesApiResponse, CreateBlotterTrade } from "features/blotter/blotterTypes";
import { store } from "core/store";

const getSignalRConnectionId = () => {
    const state = store.getState();
    return state.features.blotter.signalRConnectionId || "";
};

export const getBlotterTrades = async (
    blotterDate: string,
    deskId: Common.Nullable<number>,
): Promise<BlotterTradesApiResponse> => {
    const token = await getToken();
    const url = buildUrl(blotterDate, deskId);

    const response = await axios.get<BlotterTradesApiResponse>(url, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};

export const createBlotterTrade = async (blotterTradeDto: CreateBlotterTrade): Promise<void> => {
    const token = await getToken();

    await axios.post<CreateBlotterTrade>(`${apiBaseUrl}/api/blotter`, blotterTradeDto, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            SignalRConnectionId: getSignalRConnectionId(),
        },
    });
};

export const updateBlotterTrade = async (blotterTradeDto: CreateBlotterTrade): Promise<void> => {
    const token = await getToken();

    await axios.put<CreateBlotterTrade>(`${apiBaseUrl}/api/blotter/${blotterTradeDto.id}`, blotterTradeDto, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            SignalRConnectionId: getSignalRConnectionId(),
        },
    });
};

export const patchBlotterTrade = async (
    id: number,
    blotterTradeDto: Partial<CreateBlotterTrade>,
): Promise<BlotterTradeDto> => {
    const token = await getToken();

    const body = Object.keys(blotterTradeDto).map((k) => ({
        value: blotterTradeDto[k as keyof CreateBlotterTrade],
        path: `/${k}`,
        op: "replace",
    }));

    const response = await axios.patch<CreateBlotterTrade, { data: BlotterTradeDto }>(
        `${apiBaseUrl}/api/blotter/${id}`,
        body,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
                "Content-Type": "application/json-patch+json",
                SignalRConnectionId: getSignalRConnectionId(),
            },
        },
    );

    return response.data;
};

export const deleteBlotterTrade = async (id: number): Promise<void> => {
    const token = await getToken();

    await axios.delete(`${apiBaseUrl}/api/blotter/${id}`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            SignalRConnectionId: getSignalRConnectionId(),
        },
    });
};

export const duplicateBlotterTrade = async (id: number, useTransactionDate: boolean): Promise<void> => {
    const token = await getToken();

    await axios.post(
        `${apiBaseUrl}/api/blotter/duplicate`,
        { id, useTransactionDate },
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
                SignalRConnectionId: getSignalRConnectionId(),
            },
        },
    );
};

const buildUrl = (blotterDate: string, deskId: Common.Nullable<number>) => {
    const datePart = `date=${blotterDate}`;
    const deskIdPart = deskId ? `&deskId=${deskId}` : ``;

    return `${apiBaseUrl}/api/blotter?${datePart}${deskIdPart}`;
};
