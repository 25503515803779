import { Checkbox } from "common/components/checkbox";
import { DateComponent } from "common/components/dateComponent";
import { OpenNewWindow } from "common/components/openNewWindow";
import { SaveIcon } from "common/icons";
import { format, parseISO } from "date-fns";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setIncludeOnScreenTrades, setTradeDate, tradesSelector } from "../tradesSlice";

type Props = {
    onExportToCSV(): void;
};

export const TradesGridHeader: React.FC<Props> = ({ onExportToCSV }) => {
    const [dateValue, setDateValue] = React.useState<Common.Nullable<Date>>(null);

    const dispatch = useDispatch();
    const { meta, totalCount, tradeDate, includeOnScreenTrades } = useSelector(tradesSelector);

    // Handle when the trade date is changed
    const handleTradeDateSelected = React.useCallback((date: Date | null) => {
        dispatch(setTradeDate(date ? format(date, "yyyy-MM-dd") : null));
    }, []);

    React.useEffect(() => {
        if (tradeDate) {
            setDateValue(parseISO(tradeDate));
        }
    }, [tradeDate]);

    if (meta.status === "error") return <div className="py-4 px-2">An error occurred</div>;

    return (
        <div
            data-testid="trades-grid-header"
            className="flex items-center p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs"
        >
            <div data-testid="trades-count" className="uppercase">
                Trades ({totalCount ?? 0})
            </div>
            <div className="ml-2 cursor-pointer">
                <DateComponent
                    date={dateValue}
                    onSelectedDate={handleTradeDateSelected}
                    disabled={meta.status === "loading" || meta.status === "init"}
                    maxDate={new Date()}
                />
            </div>
            <div className="ml-2">
                <Checkbox
                    checked={includeOnScreenTrades}
                    onChange={() => dispatch(setIncludeOnScreenTrades(!includeOnScreenTrades))}
                    disabled={meta.status === "loading" || meta.status === "init"}
                >
                    Include on-screen trades
                </Checkbox>
            </div>
            <div className="ml-auto flex">
                <SaveIcon
                    data-testid="trades-csv"
                    className="cursor-pointer select-none mx-1"
                    onClick={onExportToCSV}
                />
                <OpenNewWindow name="trades" relativeUrl="/window/trades" />
            </div>
        </div>
    );
};
