import { FormInput, FormLabel } from "common/components/form";
import { blotterEntryPriceSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import debounce from "lodash/debounce";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const Price: React.FC = () => {
    const dispatch = useDispatch();
    const price = useSelector(blotterEntryPriceSelector);

    const [inputValue, setInputValue] = React.useState("");

    const debouncedInputChange = React.useCallback(
        debounce((price: string) => {
            dispatch(updateBlotterEntry({ price }));
        }, 400),
        [],
    );

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value);
        debouncedInputChange(e.currentTarget.value);
    };

    React.useEffect(() => {
        if (price === null || price === undefined) {
            setInputValue("");
        } else {
            setInputValue(price);
        }
    }, [price]);

    return (
        <div data-testid="price-panel" className="flex flex-col w-32 blotterEntryForm__spacing">
            <div className="flex justify-center">
                <FormLabel>Price</FormLabel>
            </div>
            <FormInput value={inputValue} onChange={handleInputChange} data-testid="price-input" />
        </div>
    );
};
