import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { RollOffRiskResponseDto } from "features/rollOffRisk/rollOffRiskTypes";
import { getWithRetries } from "./getWithRetries";

export const getRollOffRisk = async (
    positionType: string,
    deskId: Common.Nullable<number>,
    timespanDays: number,
    includeFutures: boolean,
) => {
    const token = await getToken();

    const params = new URLSearchParams();
    params.append("positionType", positionType);
    deskId && params.append("deskId", deskId.toString());
    params.append("pageNumber", "1"); // Always get all records for visible rows, to ensure have a consistent risk dataset
    params.append("timespanDays", timespanDays.toString());
    params.append("includeFutures", includeFutures.toString());

    const response = await getWithRetries<RollOffRiskResponseDto>(
        `${apiBaseUrl}/api/roll-off-risk?${params.toString()}`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );

    return response.data;
};
