import { AppDispatch } from "core/store";
import * as tradedUnitsApi from "api/tradedUnitsApi";
import { getTradedUnitsFailed, getTradedUnitsSuccess } from "./tradedUnitsSlice";

export const fetchTradedUnits = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await tradedUnitsApi.getTradedUnits();
            dispatch(getTradedUnitsSuccess(response));
        } catch (err: any) {
            dispatch(getTradedUnitsFailed(err.message));
        }
    };
};
