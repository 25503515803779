import { ICellRendererParams } from "@ag-grid-community/core";
import { Collapse, Expand } from "common/icons";
import includes from "lodash/includes";
import * as React from "react";

type Props = {
    groupedYears: string[];
    onToggleYear: (year: string) => void;
} & ICellRendererParams;

export const TenorYearToggle: React.FC<Props> = ({ data: { year, exchange }, node, groupedYears, onToggleYear }: Props) => {
    if (year === "") return node.isRowPinned() ?
        <span>
            Grand total
            {(exchange ? <><br/>{exchange}</> : '')}
        </span> : null;

    return (
        <div
            data-testid={`risk-year-group-${year}`}
            className="cursor-pointer flex items-center"
            onClick={() => onToggleYear(year)}
        >
            <span className="mr-2 mt-px"> {year}</span>
            {includes(groupedYears, year) ? <Expand /> : <Collapse />}
        </div>
    );
};
