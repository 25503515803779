import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { EODRiskResponseDto } from "features/eodRisk/eodRiskTypes";
import { getWithRetries } from "./getWithRetries";

export const getEodRisk = async (positionType: string, processingDate: string, deskId: Common.Nullable<number>) => {
    const token = await getToken();

    const response = await getWithRetries<EODRiskResponseDto>(
        `${apiBaseUrl}/api/eod-risk?positionType=${positionType}&processingDate=${processingDate}${
            deskId ? `&deskId=${deskId}` : ""
        }`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );

    return response.data;
};
