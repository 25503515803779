import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { TradedUnitsAPIResponse } from "features/tradedUnits/types";

export const getTradedUnits = async () => {
    const token = await getToken();

    const response = await axios.get<TradedUnitsAPIResponse>(`${apiBaseUrl}/api/tradedunits`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};
