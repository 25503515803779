import * as liveRiskApi from "api/liveRiskApi";
import { getRiskSummaryItems } from "api/liveRiskApi";
import { LiveRiskRealTimeMessage } from "common/types";
import { AppDispatch } from "core/store";
import {
    getRiskSummaryItemsSuccess,
    setStatus as setRiskSummaryStatus,
} from "features/riskSummaryItems/riskSummaryItemsSlice";
import { DeskDTO } from "features/desks/desksTypes";
import { resetFetchOnReconnect } from "features/realTime/realTimeSlice";
import { getLiveRiskItemsFailed, getLiveRiskItemsSuccess, setIsRiskUpdateRequired, setStatus } from "./liveRiskSlice";

export const fetchLiveRisk = (
    positionType: string,
    deskId: Common.Nullable<number>,
    includeTas: boolean,
    rollForwardDateTime?: string,
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("loading"));
            dispatch(setIsRiskUpdateRequired(false));
            const result = await liveRiskApi.getLiveRisk(positionType, deskId, includeTas, rollForwardDateTime);
            dispatch(getLiveRiskItemsSuccess(result));
        } catch (err: any) {
            dispatch(getLiveRiskItemsFailed(err.message));
        }
    };
};

export const refetchLiveRiskAndSummary = (
    message: LiveRiskRealTimeMessage,
    positionType: string,
    desk: Common.Nullable<DeskDTO>,
    includeTas: boolean,
    rollForwardDateTime?: string,
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setIsRiskUpdateRequired(false));

            dispatch(setStatus("refetching"));

            if (desk && desk.deskId !== message.deskId && desk.deskId !== message.topLevelDeskId) {
                dispatch(setStatus("loaded"));
                return;
            }

            const result = await liveRiskApi.getLiveRisk(
                positionType,
                desk ? desk.deskId : null,
                includeTas,
                rollForwardDateTime,
            );
            dispatch(getLiveRiskItemsSuccess(result));

            if (!desk) return;

            const riskSummaryItems = await getRiskSummaryItems(
                positionType,
                desk.deskId,
                includeTas,
                rollForwardDateTime,
            );

            dispatch(getRiskSummaryItemsSuccess(riskSummaryItems));
        } catch (err) {
            dispatch(setStatus("loaded"));
        }
    };
};

export const refetchOnReconnect = (
    positionType: string,
    desk: Common.Nullable<DeskDTO>,
    includeTas: boolean,
    rollForwardDateTime?: string,
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setIsRiskUpdateRequired(false));

            dispatch(setStatus("loading"));

            const result = await liveRiskApi.getLiveRisk(
                positionType,
                desk ? desk.deskId : null,
                includeTas,
                rollForwardDateTime,
            );
            dispatch(getLiveRiskItemsSuccess(result));

            if (!desk) return;

            dispatch(setRiskSummaryStatus("loading"));

            const riskSummaryItems = await getRiskSummaryItems(
                positionType,
                desk.deskId,
                includeTas,
                rollForwardDateTime,
            );
            dispatch(getRiskSummaryItemsSuccess(riskSummaryItems));
        } catch {
            dispatch(setStatus("loaded"));
        } finally {
            dispatch(resetFetchOnReconnect());
        }
    };
};
