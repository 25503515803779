import { AppDispatch } from "core/store";
import { getTransferDesksFailed, getTransferDesksSuccess, setStatus } from "./transferDesksSlice";
import * as desksApi from "api/desksApi"

export const fetchTransferDesks = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(setStatus({ status: "loading" }));

        try {
            const desksResponse = await desksApi.getTransferDesks();
            dispatch(getTransferDesksSuccess(desksResponse));
        } catch (err: any) {
            dispatch(getTransferDesksFailed(err.message));
        }
    };
};
