import { ColumnResizedEvent } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { desksSelector } from "features/desks/desksSlice";
import { isEqual } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { spreaderSelector, spreaderViewSpreadsOnlySelector } from "./spreaderSlice";
import { isSpreaderCustomColumn } from "../utils/customColumns";
import { columnsWithHighlightedTotals, spreaderColumnWidth } from "../utils/constants";

export const useGridState = () => {
    const [columnDefs, setColumnDefs] = React.useState<any>();
    const [gridWidth, setGridWidth] = React.useState<number>(0);
    const [columnState, setColumnState] = React.useState<{ [key: string]: any } | null>(null);
    const { selectedDeskId } = useSelector(desksSelector);
    const { risk } = useSelector(spreaderSelector);
    const [productGroupList, setProductGroupList] = React.useState<string[]>([]);
    const viewSpreadsOnly = useSelector(spreaderViewSpreadsOnlySelector);
    
    const resizeGrid = (newColState?: any, viewSpreadsOnlyParam?: boolean) => {
        const actualColState = newColState ?? columnState;
        const myViewSpreadsOnly = viewSpreadsOnlyParam ?? actualColState.viewSpreadsOnly;

        if (actualColState) {
            setGridWidth((_prevState) => {
                let width = 0;
                const productIds = Object.keys(actualColState);

                productIds.forEach((productId) => {
                    if (actualColState[productId]) {
                        if (actualColState[productId][productId] && !myViewSpreadsOnly) {
                            width += actualColState[productId][productId];
                        }
                        if (actualColState[productId].dummy && !myViewSpreadsOnly) {
                            width += actualColState[productId].dummy;
                        }
                        if (actualColState[productId].spread) {
                            width += actualColState[productId].spread;
                        }
                        if (actualColState[productId]["spread-kt"] && !myViewSpreadsOnly) {
                            width += actualColState[productId]["spread-kt"];
                        }
                        if (actualColState[productId].tenorCode && !myViewSpreadsOnly) {
                            width += actualColState[productId].tenorCode;
                        }
                    }
                });
                return width + spreaderColumnWidth.monthlyTenor + 2;
            });
        }
    };

    const gridRef = React.useRef<AgGridReact>(null);

    React.useEffect(() => {
        if (columnState) {
            resizeGrid();
        }
    }, [columnState, gridRef]);

    React.useEffect(() => {
        const productList = Object.keys(risk.productGroups);
        if (
            columnState === null || // Initial load
            columnState.deskId !== selectedDeskId || // Desk changed
            !isEqual(productList, productGroupList) || // Product list changed
            columnState.viewSpreadsOnly !== viewSpreadsOnly
        ) {
            // View spreads only changed
            const newColumnState: any = { deskId: selectedDeskId, viewSpreadsOnly: viewSpreadsOnly };
            Object.keys(risk.productGroups).forEach((productGroupCode) => {
                const productGroupId = risk.productGroups[productGroupCode].productGroupId.toString();

                newColumnState[productGroupId] = {
                    [productGroupId]: columnsWithHighlightedTotals.includes(productGroupId)? spreaderColumnWidth.highlightedColumn : spreaderColumnWidth.monthlyDefault,
                    spread: viewSpreadsOnly ? spreaderColumnWidth.defaultSpreadsOnly : spreaderColumnWidth.monthlyDefault,
                    dummy: isSpreaderCustomColumn(productGroupId) ? 0 : spreaderColumnWidth.monthlyDefault,
                    tenorCode: spreaderColumnWidth.monthlyDefault,
                    ["spread-kt"]: risk.productGroups[productGroupCode].conversionFactor ? spreaderColumnWidth.monthlyDefault : 0,
                };
            });
            setColumnState(newColumnState);
            setProductGroupList(productList);
        }
    }, [selectedDeskId, risk, viewSpreadsOnly]);

    const handleColumnResize = ({ finished, columns }: ColumnResizedEvent) => {
        if (finished && columns) {
            const newState = { ...columnState };

            for (const column of columns) {
                const width = column!.getActualWidth();
                const colId = column.getColId();

                if (colId && colId.indexOf("|") !== -1) {
                    const [productGroup, field] = colId.split("|");

                    if (newState[productGroup] && newState[productGroup][field]) {
                        newState[productGroup][field] = viewSpreadsOnly ? spreaderColumnWidth.defaultSpreadsOnly : width;
                    }
                }
            }

            setColumnState(newState);
            resizeGrid(newState);
        }
    };

    return {
        gridWidth,
        setGridWidth,
        handleColumnResize,
        defaultColumnWidth: spreaderColumnWidth.monthlyDefault,
        tenorColumnWidth: spreaderColumnWidth.monthlyTenor,
        columnState,
        setColumnState,
        columnDefs,
        setColumnDefs,
        gridRef,
    };
};
