import { IHeaderGroupParams } from "@ag-grid-community/core";
import { Collapse, Expand } from "common/icons";
import * as React from "react";

export const GroupHeader: React.FC<IHeaderGroupParams> = ({
    displayName,
    columnGroup: { isExpanded },
    setExpanded,
}) => {
    return (
        <div>
            <div data-testid="group-header-display-name" className="customHeaderLabel">
                {displayName}
            </div>
            {isExpanded() ? (
                <span className="ag-header-icon ag-header-expand-icon ag-header-expand-icon-expanded">
                    <Collapse data-testid="group-header-collapse" onClick={() => setExpanded(false)} />
                </span>
            ) : (
                <span className="ag-header-icon ag-header-expand-icon ag-header-expand-icon-collapsed">
                    <Expand data-testid="group-header-expand" onClick={() => setExpanded(true)} />
                </span>
            )}
        </div>
    );
};
