import { CancelIcon } from "common/icons";
import * as React from "react";
import { Button } from "../button";

type ModalAction = {
    id: string;
    text: string;
    mode: Common.UIMode;
    action: () => void;
};

type ModalProps = {
    title?: string;
    headerText?: string;
    message?: string | string[];
    onClose(): void;
    actions?: ModalAction[];
};

export const Modal: React.FC<ModalProps> = ({
    onClose,
    headerText = "Error",
    title = "Alert",
    message = "",
    actions = [],
}) => {
    if (message && typeof message === "string") {
        message = [message];
    }

    const messageArray: string[] = (message as string[]) || [];

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" data-testid="modal">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-white-900 p-2 flex justify-between">
                        <span className="text-sm font-bold">{title}</span>
                        <span>
                            <CancelIcon data-testid="modal-close-icon" className="cursor-pointer" onClick={onClose} />
                        </span>
                    </div>
                    <div className="px-4 pt-5 pb-4 bg-white-600">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                    className="text-lg leading-6 font-medium text-gray-900 "
                                    id="modal-headline"
                                    data-testid="modal-headline"
                                >
                                    {headerText}
                                </h3>
                                <div className="mt-2" data-testid="modal-content">
                                    {messageArray.length > 1 ? (
                                        <ul className="text-sm text-gray-500 list-disc">
                                            {messageArray.map((a) => (
                                                <li key={a}>{a}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className="text-sm text-gray-500">{messageArray?.[0]}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 flex justify-center bg-white-600">
                        {actions.map(({ id, text, action, mode }) => (
                            <Button
                                key={text}
                                className="m-1"
                                mode={mode}
                                onClick={action}
                                data-testid={`modal-${id}-button`}
                            >
                                {text}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
