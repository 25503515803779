import { RollOffRiskItemDto, TenorDateRiskItem } from "common/types";

export const getProductGroupTotalForRollOffRisk = (
    riskItems: RollOffRiskItemDto[] | undefined,
    productGroupName: string,
    data: TenorDateRiskItem,
) => {
    const { year, riskExpiryDateTime, isGrandTotal, isGroupedByYear } = data;
    if (!riskItems) return null;

    return riskItems.reduce((prev: number | null, current) => {
        const expiryYear = current.riskExpiryDateTime.substr(0, 4);
        if (
            current.productGroup !== productGroupName ||
            (!isGroupedByYear && current.riskExpiryDateTime !== riskExpiryDateTime && !isGrandTotal) ||
            (isGroupedByYear && year !== expiryYear && !isGrandTotal)
        ) {
            return prev;
        }

        return (prev ?? 0) + current.quantityBBL / 1000;
    }, null);
};
