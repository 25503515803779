import classNames from "classnames";
import * as React from "react";

type Props = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

export const FormLabel: React.FC<Props> = ({ children, className, ...props }) => {
    return (
        <label
            className={classNames(
                "inline-block tracking-widest py-1 px-4 m-1 uppercase font-bold text-xs rounded-lg shadow-lg bg-white-900 text-gray-900",
                className,
            )}
            {...props}
        >
            {children}
        </label>
    );
};
