import { FormLabel } from "common/components/form";
import {
    blotterEntrySideSelector,
    blotterEntryTradedUnitSelector,
    updateBlotterEntry,
} from "features/blotter/blotterSlice";
import { tradedUnitsSelector } from "features/tradedUnits/tradedUnitsSlice";
import { fetchTradedUnits } from "features/tradedUnits/tradedUnitsThunks";
import * as React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { BlotterEntryFormButton } from "./blotterEntryFormButton";

export const Unit: React.FC = () => {
    const dispatch = useDispatch();
    const { tradedUnits, meta } = useSelector(tradedUnitsSelector, shallowEqual);
    const tradedUnitId = useSelector(blotterEntryTradedUnitSelector, shallowEqual);
    const side = useSelector(blotterEntrySideSelector, shallowEqual);

    React.useEffect(() => {
        if (meta.status === "init") {
            dispatch(fetchTradedUnits());
        }
    }, [meta]);

    return (
        <div
            data-testid="unit-panel"
            className="blotterEntryForm__unit flex flex-col w-16 mt-4 blotterEntryForm__spacing"
        >
            <div className="flex justify-center">
                <FormLabel>Unit</FormLabel>
            </div>

            {tradedUnits &&
                tradedUnits.map((tradedUnit) => (
                    <BlotterEntryFormButton
                        key={tradedUnit.tradedUnitId}
                        side={side}
                        selected={tradedUnitId === tradedUnit.tradedUnitId}
                        onClick={() =>
                            dispatch(
                                updateBlotterEntry({
                                    tradedUnitId:
                                        tradedUnitId === tradedUnit.tradedUnitId ? null : tradedUnit.tradedUnitId,
                                }),
                            )
                        }
                    >
                        {tradedUnit.name}
                    </BlotterEntryFormButton>
                ))}

            {meta.status === "error" && <span className="text-xs mt-2 text-center">Error</span>}
        </div>
    );
};
