import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { State } from "./applicationTypes";

const initialState: State = {
    version: undefined,
    isNewVersionAvailable: false,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getApplicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {
        setApplicationVersion: (state, action: PayloadAction<string>) => {
            if (action.payload === state.version) return state;

            // Set new version available as long as we have a valid value for current and new versions
            state.isNewVersionAvailable = !!state.version && !!action.payload;

            state.version = action.payload;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();

            return state;
        },
    },
});

export const { setApplicationVersion } = getApplicationSlice.actions;

export const applicationSelector = (state: RootState) => state.global.application;
