import { BlotterTrade } from "features/blotter/blotterTypes";
import { findDeskByName } from "features/desks/desksSlice";
import { DeskDTO } from "features/desks/desksTypes";
import { transferDesksSelector } from "features/transferDesks/transferDesksSlice";
import * as React from "react";
import { useSelector } from "react-redux";

export const TransferDeskDropdownEditor = React.forwardRef((props: { value: string; data: BlotterTrade }, ref) => {
    const { desks } = useSelector(transferDesksSelector);

    const [value, setValue] = React.useState(props.value || "");
    const [option, setOption] = React.useState<DeskDTO | null>(null);

    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value || null;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                props.data.transferDeskId = option?.deskId || null;
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        const selectedOption = findDeskByName(value, desks);
        setOption(selectedOption || null);
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    const renderChildDesks = (desks: DeskDTO[], level: number): React.ReactNode => {
        const spaces = new Array(level).fill("&nbsp;&nbsp;&nbsp;").join("");

        if (desks.length === 0) return <></>;

        return desks.map((c) => (
            <React.Fragment key={c.deskId}>
                <option
                    key={c.deskId}
                    value={c.name}
                    disabled={c.deskId === props.data.deskId}
                    dangerouslySetInnerHTML={{ __html: `${spaces}${c.name}` }}
                ></option>
                {renderChildDesks(c.childDesks, level + 1)}
            </React.Fragment>
        ));
    };

    return (
        <select
            data-testid="transfer-desk-dropdown-editor"
            onChange={handleChange}
            className="w-full"
            ref={refInput}
            value={value}
        >
            <option value=""></option>
            {desks.map((d) => (
                <optgroup key={d.deskId} label={d.name}>
                    {renderChildDesks(d.childDesks, 0)}
                </optgroup>
            ))}
        </select>
    );
});
