import { ColumnParams } from "./liveRiskTypes";
import { RowSpanParams, ValueGetterParams, CellClassParams } from "@ag-grid-community/core";
import { format, parseISO } from "date-fns";
import { toggleGroupedYear, addExchangeToProductGroup } from "./liveRiskSlice";

export const getColumnDefs = (params: ColumnParams): any => {
    const {
        riskItemsByTenor,
        groupedYears,
        dispatch,
        productGroups,
        valueFormatter,
        riskItemsByGroup,
        getProductGroupColumnSettings,
        isCrossExchangeView,
        getProductGroupTotal,
        riskItems,
        getProductColumnSettings,
        productValueGetter,
        productsByProductGroup,
        diffProductValueGetter,
        productDiffsByProductGroup,
        columnState,
        grandTotalColumnWidth,
        getTenorGrandTotal,
        getDiffProductColumnSettings,
        selectedDesk,
        riskSummaryTenorItemColumns,
        getTenorRiskSummaryCellValue,
        riskSummaryTenorItems,
    } = params;

    return [
        {
            colId: "year",
            field: "year",
            headerName: "",
            filter: false,
            sortable: false,
            resizable: false,
            minWidth: 90,
            width: 90,
            maxWidth: 90,
            cellClassRules: {
                "cell-span": "value !== '' && value !== 'Grand total'",
            },
            rowSpan: ({ data: { year, isGrandTotal } }: RowSpanParams) =>
                isGrandTotal
                    ? 1
                    : riskItemsByTenor.filter((r) => {
                          const itemYear = r.tenorDate.substr(0, 4);
                          return year === itemYear;
                      }).length,

            valueGetter: ({ data: { year }, node }: ValueGetterParams) => (node?.isRowPinned() ? `Grand total` : year),

            cellRenderer: "TenorYearToggle",
            cellRendererParams: {
                groupedYears,
                onToggleYear: (year: string) => dispatch(toggleGroupedYear(year)),
            },
        },
        {
            field: "tenorDate",
            filter: false,
            sortable: false,
            resizable: true,
            type: "numericColumn",
            cellClass: "font-bold text-right",
            minWidth: 100,
            maxWidth: 100,
            headerName: "Tenor Date",
            valueGetter: ({ data: { year, tenorDate, isGrandTotal, isGroupedByYear } }: ValueGetterParams) => {
                if (isGroupedByYear) return year;
                return isGrandTotal ? "" : format(parseISO(tenorDate), "MMMyy");
            },
        },
        ...productGroups
            .filter((productGroupName) => riskItemsByGroup[productGroupName])
            .map((productGroupName) => {
                const productGroupColumnSettings = getProductGroupColumnSettings(productGroupName);
                return {
                    field: `${productGroupName}-parent`,
                    colId: `${productGroupName}-parent`,
                    headerName: productGroupName,
                    groupId: productGroupName,
                    refData: {
                        groupId: productGroupName,
                    },
                    openByDefault: productGroupColumnSettings.expanded,
                    children: [
                        {
                            field: productGroupName,
                            colId: productGroupName,
                            refData: {
                                level: "0",
                                productGroup: productGroupName,
                            },
                            columnGroupShow: "closed",
                            type: "numericColumn",
                            headerName: "",
                            cellClassRules: {
                                "text-red-600": (params: CellClassParams) => params.value < 0,
                            },
                            width: productGroupColumnSettings.width,
                            minWidth: productGroupColumnSettings.minWidth,
                            valueFormatter: valueFormatter,
                            valueGetter: ({ data }: ValueGetterParams) => {
                                let riskItemsToUse = riskItems;

                                if (isCrossExchangeView) {
                                    riskItemsToUse = addExchangeToProductGroup(riskItems);
                                }

                                return getProductGroupTotal(riskItemsToUse, productGroupName, data);
                            },
                        },
                        ...Object.keys(riskItemsByGroup[productGroupName]).map((productName) => {
                            const productColumnSettings = getProductColumnSettings(productGroupName, productName);
                            let productSymbol =
                                Object.values(riskItemsByGroup[productGroupName][productName])?.[0]?.[0]?.symbol ||
                                null;
                            productSymbol = productSymbol ? ` (${productSymbol})` : "";

                            return {
                                field: `${productName}-parent`,
                                colId: `${productName}-parent`,
                                headerName: `${productName}${productSymbol}`,
                                columnGroupShow: "open",
                                groupId: `${productGroupName}-${productName}`,
                                openByDefault: productColumnSettings.expanded,
                                refData: {
                                    groupId: `${productGroupName}-${productName}`,
                                },
                                children: [
                                    {
                                        field: productName,
                                        colId: productName,
                                        refData: {
                                            level: "1",
                                            productGroup: productGroupName,
                                            product: `${productName}${productSymbol}`,
                                        },
                                        width: productColumnSettings.width,
                                        minWidth: productColumnSettings.minWidth,
                                        headerName: "",
                                        type: "numericColumn",
                                        columnGroupShow: "closed",
                                        cellClassRules: {
                                            "text-red-600": (params: CellClassParams) => params.value < 0,
                                        },
                                        valueFormatter: valueFormatter,
                                        valueGetter: ({ data }: ValueGetterParams) =>
                                            productValueGetter(
                                                productsByProductGroup,
                                                productGroupName,
                                                productName,
                                                data,
                                            ),
                                    },
                                    ...Object.keys(riskItemsByGroup[productGroupName][productName]).map(
                                        (diffProduct) => {
                                            const diffProductColumnSettings = getDiffProductColumnSettings(
                                                productGroupName,
                                                productName,
                                                diffProduct,
                                            );
                                            let diffSymbol =
                                                riskItemsByGroup[productGroupName][productName][diffProduct]?.[0]
                                                    ?.diffSymbol || null;
                                            diffSymbol = diffSymbol ? ` (${diffSymbol})` : "";

                                            return {
                                                field: diffProduct,
                                                colId: diffProduct,
                                                refData: {
                                                    level: "2",
                                                    productGroup: productGroupName,
                                                    product: `${productName}${productSymbol}`,
                                                    diffProduct: `${diffProduct}${diffSymbol}`,
                                                },
                                                width: diffProductColumnSettings.width,
                                                minWidth: diffProductColumnSettings.minWidth,
                                                type: "numericColumn",
                                                columnGroupShow: "open",
                                                headerName: `${diffProduct}${diffSymbol}`,
                                                cellClassRules: {
                                                    "text-red-600": (params: CellClassParams) => params.value < 0,
                                                },
                                                valueFormatter: valueFormatter,
                                                valueGetter: ({ data }: ValueGetterParams) =>
                                                    diffProductValueGetter(
                                                        productDiffsByProductGroup,
                                                        productGroupName,
                                                        productName,
                                                        diffProduct,
                                                        data,
                                                    ),
                                            };
                                        },
                                    ),
                                ],
                            };
                        }),
                    ],
                };
            }),

        {
            columnGroupShow: "closed",
            colId: "grand-total",
            refData: {
                level: "3",
            },
            headerName: `Grand Total`,
            cellClass: "text-right font-bold bg-white-500",
            type: "numericColumn",
            resizable: true,
            pinned: "right",
            width: columnState && columnState["grand-total"] ? columnState["grand-total"].width : grandTotalColumnWidth,
            minWidth: 100,
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
                "text-3xl": (params: CellClassParams) => params.node.isRowPinned() && params.node.rowIndex === 0,
                "text-xl": (params: CellClassParams) => params.node.isRowPinned() && (params.node.rowIndex || 1) > 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => {
                const { exchange } = data;

                if (!exchange) {
                    return getTenorGrandTotal(riskItems, data);
                }

                return getTenorGrandTotal(
                    riskItems?.filter((item) => item.exchange === exchange),
                    data,
                );
            },
        },
        ...((selectedDesk?.topLevelDeskName &&
            riskSummaryTenorItemColumns[selectedDesk.topLevelDeskName]?.map((riskSummaryTenorItemColumn) => ({
                field: riskSummaryTenorItemColumn.key,
                filter: false,
                sortable: false,
                resizable: true,
                pinned: "right",
                type: "numericColumn",
                cellClass: "text-right",
                minWidth: riskSummaryTenorItemColumn.width,
                maxWidth: riskSummaryTenorItemColumn.width,
                cellClassRules: {
                    "text-red-600": (params: CellClassParams) => params.value < 0,
                },
                headerName: riskSummaryTenorItemColumn.name,
                valueFormatter: valueFormatter,
                valueGetter: ({ data }: ValueGetterParams) =>
                    getTenorRiskSummaryCellValue(riskSummaryTenorItemColumn.key, data, riskSummaryTenorItems),
            }))) ||
            []),
    ];
};
