import { Menu, MenuButton, MenuItem, MenuItems, MenuPopover } from "@reach/menu-button";
import classNames from "classnames";
import { Button } from "common/components/button";
import { ChevronIcon } from "common/icons";
import { getMonth, getYear } from "date-fns";
import * as React from "react";
import { default as DatePicker, default as ReactDatePicker, ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { months, years } from "./constants";
import "./dateComponent.styles.scss";
import { DateInputField } from "./dateInputField";

type Props = {
    label?: string;
    date?: Common.Nullable<Date>;
    maxDate?: Date;
    error?: boolean;
    type?: "standard" | "filter";
    onSelectedDate(date: Date | null): void;
} & Omit<ReactDatePickerProps, "onChange">;

export const DateComponent = React.forwardRef<HTMLInputElement, Props>(
    (
        { date, label, maxDate, error = false, type = "standard", disabled, onSelectedDate, showTimeInput, ...props },
        ref,
    ) => {
        const [selectedDate, setSelectedDate] = React.useState<Date | null>();

        const datePickerRef = React.useRef<ReactDatePicker>(null);

        React.useEffect(() => {
            setSelectedDate(date ?? null);
        }, [date]);

        const DateInput = React.forwardRef<HTMLInputElement, any>(({ onClick }: any, inputRef) => (
            <DateInputField
                ref={inputRef}
                label={label}
                date={selectedDate}
                onClick={onClick}
                onDateChanged={(date) => {
                    handleChange(date);
                }}
                error={error}
                disabled={disabled}
                showCalendarIcon={type === "standard"}
                showPlaceholder={type === "standard"}
                inputClassName={classNames({
                    "w-16": type === "filter",
                    "w-20": type === "standard" && !showTimeInput,
                    "w-24": type === "standard" && showTimeInput,
                })}
                closeDatePicker={() => datePickerRef && datePickerRef.current?.setOpen(false)}
                showTime={showTimeInput}
            />
        ));

        const handleChange = (date: Date | null, event?: any) => {
            setSelectedDate(date);

            if (!event || !showTimeInput || event.target.className.indexOf("react-datepicker__day") === -1) {
                onSelectedDate(date);
            }
        };

        return (
            <div data-testid="date-component">
                <DatePicker
                    ref={datePickerRef}
                    dateFormat={showTimeInput ? `dd/MM/yyyy HH:mm` : `dd/MM/yyyy`}
                    locale="en"
                    selected={selectedDate}
                    showPopperArrow={false}
                    maxDate={maxDate}
                    showTimeSelect={showTimeInput}
                    timeInputLabel="Time:"
                    customInput={<DateInput ref={ref} />}
                    dayClassName={(_date) => "font-sans text-xs font-normal"}
                    calendarClassName="bg-white-900 border-0 rounded-xl shadow-lg select-none"
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                        <div className="flex justify-center m-2.5" data-testid="date-component-header">
                            <Button
                                data-testid="date-component-today-button"
                                onClick={() => {
                                    handleChange(new Date());
                                    datePickerRef && datePickerRef.current?.setOpen(false);
                                }}
                            >
                                Today
                            </Button>

                            <Menu>
                                <MenuButton
                                    className="flex items-center ml-4 px-2 font-medium text-sm"
                                    data-testid="date-component-month"
                                >
                                    {months[getMonth(date)]} <ChevronIcon className="ml-2" />
                                </MenuButton>
                                <MenuPopover
                                    className="bg-white-900 z-10 absolute rounded-lg"
                                    portal={false}
                                    style={{
                                        left: "98px",
                                        top: "50px",
                                    }}
                                >
                                    <MenuItems
                                        className="year__dropdown rounded-lg shadow-lg"
                                        data-testid="date-component-month-selection"
                                    >
                                        {months.map((month) => (
                                            <MenuItem
                                                key={month}
                                                onSelect={() => {
                                                    changeMonth(months.indexOf(month));
                                                }}
                                                className="text-sm cursor-pointer py-1 px-4 text-left font-medium"
                                            >
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </MenuItems>
                                </MenuPopover>
                            </Menu>

                            <Menu>
                                <MenuButton
                                    className="flex items-center ml-4 px-2 font-medium text-sm"
                                    data-testid="date-component-year"
                                >
                                    {getYear(date)} <ChevronIcon className="ml-2" />
                                </MenuButton>
                                <MenuPopover
                                    className="bg-white-900 z-10 absolute rounded-lg"
                                    portal={false}
                                    style={{
                                        left: "198px",
                                        top: "50px",
                                    }}
                                >
                                    <MenuItems className="year__dropdown" data-testid="date-component-year-selection">
                                        {years.map((year) => (
                                            <MenuItem
                                                key={year}
                                                onSelect={() => {
                                                    changeYear(year);
                                                }}
                                                className="text-sm cursor-pointer py-1 px-4 font-medium"
                                            >
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </MenuItems>
                                </MenuPopover>
                            </Menu>
                        </div>
                    )}
                    disabled={disabled}
                    onChange={handleChange}
                    {...props}
                />
            </div>
        );
    },
);
