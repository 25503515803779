import { OpenNewWindow } from "common/components/openNewWindow";
import { ChevronDoubleLeft, ChevronDoubleRight, SaveIcon } from "common/icons";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseDecimalPosition, increaseDecimalPosition, setIncludeYearToDatePnl } from "./pnlSlice";
import { PassiveCheckbox } from "common/components/checkbox";
import { metaSelector, pnlIncludeYearToDateSelector } from "./pnlSlice";
import { Authorize } from "common/components/auth";

type Props = {
    onExportToCSV(): void;
};

export const PnLHeader: React.FC<Props> = ({ onExportToCSV }) => {
    const dispatch = useDispatch();
    const meta = useSelector(metaSelector);

    const includeYearToDatePnl = useSelector(pnlIncludeYearToDateSelector);

    return (
        <div
            className="flex items-center p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs"
            style={{ minHeight: "44px" }}
        >
            <div className="uppercase">PnL</div>
            <Authorize role="UI.YearToDatePnl.Read">
                <div className="ml-4 mr-2 cursor-pointer select-none" data-testid="includeYearToDate">
                    <PassiveCheckbox
                        checked={includeYearToDatePnl}
                        onChange={() => dispatch(setIncludeYearToDatePnl(!includeYearToDatePnl))}
                        disabled={meta.status === "loading" || meta.status === "init"}
                    >
                        Include YTD
                    </PassiveCheckbox>
                </div>
            </Authorize>
            <div className="ml-2 cursor-pointer"></div>
            <div className="flex ml-auto">
                <ChevronDoubleLeft
                    data-testid="pnl-decrease"
                    onClick={() => dispatch(decreaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <ChevronDoubleRight
                    data-testid="pnl-increase"
                    onClick={() => dispatch(increaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <SaveIcon data-testid="pnl-csv" className="cursor-pointer select-none mx-1" onClick={onExportToCSV} />
                <OpenNewWindow name="pnl" relativeUrl={`/window/pnl`} />
            </div>
        </div>
    );
};
