import { DateComponent } from "common/components/dateComponent";
import { HeaderNavigationTabs } from "common/components/headerNavigationTabs";
import { OpenNewWindow } from "common/components/openNewWindow";
import { useCustomLocationProperties } from "common/hooks";
import { ChevronDoubleLeft, ChevronDoubleRight, SaveIcon } from "common/icons";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseDecimalPosition, eodRiskSelector, increaseDecimalPosition, setProcessingDate } from "./eodRiskSlice";

type Props = {
    onExportToCSV(): void;
};

export const EODRiskHeader: React.FC<Props> = ({ onExportToCSV }) => {
    const [dateValue, setDateValue] = React.useState<Date>(new Date());
    const { processingDate } = useSelector(eodRiskSelector);
    const dispatch = useDispatch();
    const { shortPath, isPopupWindow } = useCustomLocationProperties();
    const handleDateSelected = React.useCallback((date: Date | null) => {
        date && dispatch(setProcessingDate(format(date, "yyyy-MM-dd")));
    }, []);

    React.useEffect(() => {
        setDateValue(parseISO(processingDate));
    }, []);

    const eodRiskNavigationTabs = [
        {
            name: "Onyx",
            to: `/${isPopupWindow ? "window" : "risk"}/eod/onyx`,
        },
        {
            name: "ABN",
            to: `/${isPopupWindow ? "window" : "risk"}/eod/abn`,
        },
    ];

    return (
        <div className="flex items-center p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs">
            <div className="uppercase">EOD Statement</div>
            <div className="ml-2">
                <HeaderNavigationTabs tabs={eodRiskNavigationTabs} />
            </div>
            <div className="ml-2 cursor-pointer" data-testid="blotter-date">
                <DateComponent
                    key={dateValue ? +dateValue : undefined}
                    date={dateValue}
                    onSelectedDate={handleDateSelected}
                    disabled={false}
                    maxDate={new Date()}
                />
            </div>
            <div className="flex ml-auto">
                <ChevronDoubleLeft
                    data-testid="eod-risk-decrease"
                    onClick={() => dispatch(decreaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <ChevronDoubleRight
                    data-testid="eod-risk-increase"
                    onClick={() => dispatch(increaseDecimalPosition())}
                    className="cursor-pointer select-none"
                />
                <SaveIcon
                    data-testid="eod-risk-csv"
                    className="cursor-pointer select-none mx-1"
                    onClick={onExportToCSV}
                />
                <OpenNewWindow name="eod" relativeUrl={`/window/eod/${shortPath}`} />
            </div>
        </div>
    );
};
