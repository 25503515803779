import { deskHasBeenSetSelector, desksSelector } from "features/desks/desksSlice";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setAutoUpdate, spreaderSelector } from "./spreaderSlice";
import { fetchSpreaderEntries, fetchSpreaderRisk } from "./spreaderThunks";

export const SpreaderUpdateNotification: React.FC = () => {
    const dispatch = useDispatch();
    const { isRiskUpdateRequired, isEntriesUpdateRequired } = useSelector(spreaderSelector);
    const { selectedDeskId } = useSelector(desksSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    if (!isRiskUpdateRequired && !isEntriesUpdateRequired) return null;

    return (
        <div
            data-testid="spreader-update-notification"
            className="absolute shadow-lg cursor-pointer left-1/2 rounded-lg py-1 px-4 text-xs font-bold bg-black-900 text-white-900"
            style={{ marginTop: "124px" }}
            onClick={() => {
                if (deskHasBeenSet) {
                    dispatch(setAutoUpdate(true));

                    dispatch(fetchSpreaderEntries(selectedDeskId));
                    dispatch(fetchSpreaderRisk(selectedDeskId));
                }
            }}
        >
            Spreader updated
        </div>
    );
};
