import { AppDispatch } from "core/store";
import { setLoading, getAccountSuccess, getAccountFailed } from "./authSlice";
import { getAccountAsync } from "core/auth";

export function fetchAccount() {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading());
        try {
            const account = await getAccountAsync();
            if (account) dispatch(getAccountSuccess(account));
        } catch (error: any) {
            dispatch(getAccountFailed({ message: error.message }));
        }
    };
}
