import { BlotterTrade } from "features/blotter/blotterTypes";
import { tradedUnitsSelector } from "features/tradedUnits/tradedUnitsSlice";
import { fetchTradedUnits } from "features/tradedUnits/tradedUnitsThunks";
import { TradedUnitDto } from "features/tradedUnits/types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const UnitsDropdownEditor = React.forwardRef((props: { value: string; data: BlotterTrade }, ref) => {
    const { tradedUnits, meta } = useSelector(tradedUnitsSelector);
    const [value, setValue] = React.useState(props.value || "");
    const [option, setOption] = React.useState<TradedUnitDto | null>(null);

    const dispatch = useDispatch();
    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useEffect(() => {
        if (meta.status === "init") {
            dispatch(fetchTradedUnits());
        }
    }, [meta]);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value || null;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                props.data.tradedUnitId = option?.tradedUnitId || null;
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        const selectedOption = tradedUnits?.find((t) => t.name === value);
        setOption(selectedOption || null);
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    return (
        <select
            data-testid="traded-unit-dropdown-editor"
            onChange={handleChange}
            className="w-full"
            ref={refInput}
            value={value}
        >
            <option value=""></option>
            {tradedUnits?.map((t) => (
                <option key={t.tradedUnitId} value={t.name}>
                    {t.name}
                </option>
            ))}
        </select>
    );
});
