import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useCustomLocationProperties } from "common/hooks";
import { LiveRiskRealTimeMessage } from "common/types";
import { createHubConnection } from "core/realTime/createHubConnection";
import { format } from "date-fns";
import { getAccountSelector } from "features/auth/authSlice";
import { deskHasBeenSetSelector, selectedDeskSelector } from "features/desks/desksSlice";
import { usePageVisibility } from "features/navigation/hooks";
import { fetchOnReconnectSelector, setConnectionState } from "features/realTime/realTimeSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
    liveRiskRealTimeDataSelector,
    setIsRiskUpdateRequired,
    setLastRealTimeMessage,
    setSignalRGroupName,
} from "../liveRiskSlice";
import { refetchLiveRiskAndSummary, refetchOnReconnect } from "../liveRiskThunks";
import {
    JOIN_GROUP,
    LEAVE_GROUP,
    SEND_RISK_DATA_UPDATE,
    SUBSCRIBED_TO_GROUP,
    UNSUBSCRIBED_FROM_GROUP,
} from "./constants";
import throttle from "lodash/throttle";

export const useLiveRiskHubConnection = (deskId?: number, currentGroupName?: string) => {
    const [hubConnection, setHubConnection] = React.useState<HubConnection>();
    const dispatch = useDispatch();
    const { pathname: route } = useLocation();
    const { shortPath } = useCustomLocationProperties();

    const { lastRealTimeMessage, includeTas, rollForwardDateTime, loadingStatus, isRiskUpdateRequired } =
        useSelector(liveRiskRealTimeDataSelector);

    const selectedDesk = useSelector(selectedDeskSelector);
    const fetchOnReconnect = useSelector(fetchOnReconnectSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const appInsights = useAppInsightsContext();
    const {
        meta: { status },
    } = useSelector(getAccountSelector);

    const pageIsVisible = usePageVisibility();

    const disconnect = React.useCallback(() => {
        try {
            hubConnection && hubConnection.stop();
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { customDescription: "SignalR - Live Risk - Error stopping SignalR connection" },
            );
        }
    }, [hubConnection]);

    React.useEffect(() => {
        if (!lastRealTimeMessage || !deskHasBeenSet) return;

        if (loadingStatus !== "loading" && loadingStatus !== "refetching") {
            dispatch(
                refetchLiveRiskAndSummary(
                    lastRealTimeMessage,
                    shortPath,
                    selectedDesk!,
                    includeTas,
                    rollForwardDateTime,
                ),
            );
        } else {
            dispatch(setIsRiskUpdateRequired(true));
        }
    }, [lastRealTimeMessage]);

    React.useEffect(() => {
        if (!lastRealTimeMessage || !deskHasBeenSet || !loadingStatus) return;

        if (loadingStatus !== "loading" && loadingStatus !== "refetching" && isRiskUpdateRequired) {
            dispatch(
                refetchLiveRiskAndSummary(
                    lastRealTimeMessage,
                    shortPath,
                    selectedDesk!,
                    includeTas,
                    rollForwardDateTime,
                ),
            );
        }
    }, [loadingStatus]);

    React.useEffect(() => {
        if (fetchOnReconnect && deskHasBeenSet) {
            dispatch(refetchOnReconnect(shortPath, selectedDesk!, includeTas, rollForwardDateTime));
        }
    }, [fetchOnReconnect, deskHasBeenSet]);

    React.useEffect(() => {
        if (status === "expired") {
            console.warn(`User account expired, disconnecting SignalR`);
            disconnect();
        }
    }, [status]);

    React.useEffect(() => {
        if (!pageIsVisible) {
            disconnect();
        }
    }, [pageIsVisible]);

    React.useEffect(() => {
        try {
            const newHubConnection = createHubConnection("live-risk", () => {
                dispatch(
                    setConnectionState({
                        state: HubConnectionState.Reconnecting,
                        route,
                        time: format(new Date(), "HH:mm:ss"),
                    }),
                );
            });

            setHubConnection(newHubConnection);
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { customDescription: "SignalR - Live Risk - Error creating hub connection" },
            );
        }
    }, []);

    const throttledDispatchRealTimeMessageUpdate = throttle(
        (connection: HubConnection, message: LiveRiskRealTimeMessage) => {
            dispatch(
                setConnectionState({
                    state: connection.state,
                    route,
                    time: format(new Date(), "HH:mm:ss"),
                }),
            );

            dispatch(setLastRealTimeMessage(message));
        },
        1000,
        { leading: true, trailing: true },
    );

    React.useEffect(() => {
        const connectToSignalR = async (connection: HubConnection) => {
            try {
                await connection.start();

                if (connection.state === HubConnectionState.Connected) {
                    dispatch(setConnectionState({ state: connection.state, route }));
                    await connection.send(JOIN_GROUP, deskId);
                }

                /* istanbul ignore next */
                connection.on(SEND_RISK_DATA_UPDATE, (message: LiveRiskRealTimeMessage) => {
                    throttledDispatchRealTimeMessageUpdate(connection, message);
                });

                connection.on(SUBSCRIBED_TO_GROUP, (groupName: string) => {
                    // Dispatch action to capture the subscribed groupName
                    dispatch(setSignalRGroupName(groupName));
                });

                connection.on(UNSUBSCRIBED_FROM_GROUP, (_groupName: string) => {
                    // Dispatch action to reset the subscribed groupName
                    dispatch(setSignalRGroupName(undefined));
                });

                /* istanbul ignore next */
                connection.onreconnected(async () => {
                    dispatch(setConnectionState({ state: connection.state, route }));
                    await connection.send(JOIN_GROUP, deskId);
                });

                connection.onclose((_error) => {
                    dispatch(setConnectionState({ state: connection.state, route }));
                });

                connection.onreconnecting((_error) => {
                    dispatch(setConnectionState({ state: connection.state, route }));
                });
            } catch (error: any) {
                appInsights.trackException(
                    { error, severityLevel: SeverityLevel.Warning },
                    { customDescription: "SignalR - Live Risk - Error connecting to SignalR" },
                );
            }
        };

        if (!hubConnection || !pageIsVisible || !deskHasBeenSet) {
            return;
        }

        connectToSignalR(hubConnection);

        return disconnect;
    }, [hubConnection, pageIsVisible, deskHasBeenSet]);

    React.useEffect(() => {
        const joinRiskGroup = async () => {
            try {
                if (!hubConnection || !deskHasBeenSet) return;

                dispatch(setConnectionState({ state: hubConnection.state, route }));

                if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
                    // First unsubscribe from existing group
                    if (currentGroupName) {
                        await hubConnection.send(LEAVE_GROUP, currentGroupName);
                    }

                    // Then subscribe to a new group
                    await hubConnection.send(JOIN_GROUP, deskId);
                }
            } catch (error: any) {
                appInsights.trackException(
                    { error, severityLevel: SeverityLevel.Error },
                    { customDescription: `SignalR - Live Risk - Error joining group for desk ${deskId}` },
                );
            }
        };
        joinRiskGroup();
    }, [deskId, deskHasBeenSet]);

    return;
};
