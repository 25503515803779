import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductGroupDto } from "common/types";
import { RootState } from "core/store";
import { DeskDTO, DesksAPIResponse, State } from "./desksTypes";

const initialState: State = {
    desks: [],
    productGroups: undefined,
    selectedDeskId: undefined,
    isOnyxDeskSelected: false,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getDesksSlice = createSlice({
    name: "desks",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<{ status: Common.Status }>) => {
            state.meta.status = action.payload.status;
            return state;
        },
        getDesksSuccess: (state, action: PayloadAction<{ apiResponse: DesksAPIResponse; hasAdminRole: boolean }>) => {
            state.desks = action.payload.apiResponse.desks || [];
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();

            // If a desk is already stored in session storage, check if it is still allowed for the user
            if (state.selectedDeskId !== undefined) {
                // If the Onyx desk is selected, check if the user still has admin role
                if (state.selectedDeskId === null) {
                    // If the user no longer has admin role, clear the desk selection
                    if (!action.payload.hasAdminRole) {
                        state.isOnyxDeskSelected = false;
                        sessionStorage.removeItem("deskId");
                        state.selectedDeskId = undefined;
                    }
                } else {
                    const selectedDesk = findDeskById(state.selectedDeskId, action.payload.apiResponse.desks);

                    // If the desk is no longer allowed for the user, clear the desk selection
                    if (!selectedDesk) {
                        sessionStorage.removeItem("deskId");
                        state.selectedDeskId = undefined;
                    }
                }
            }

            return state;
        },
        getDesksFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
        getProductGroupsSuccess: (state, action: PayloadAction<ProductGroupDto[]>) => {
            state.productGroups = action.payload;
            return state;
        },
        clearProductGroups: (state) => {
            state.productGroups = undefined;
            return state;
        },
        setSelectedDeskId: (state, action: PayloadAction<number | undefined | null>) => {
            state.selectedDeskId = action.payload;
            return state;
        },
        setIsOnyxDeskSelected: (state, action: PayloadAction<boolean>) => {
            state.isOnyxDeskSelected = action.payload;
            return state;
        },
    },
});

export const {
    setStatus,
    getDesksSuccess,
    getDesksFailed,
    setSelectedDeskId,
    getProductGroupsSuccess,
    clearProductGroups,
    setIsOnyxDeskSelected,
} = getDesksSlice.actions;

const selectSelectedDeskId = (state: RootState) => state.features.desks.selectedDeskId;
const selectDesks = (state: RootState) => state.features.desks;

export const desksSelector = createSelector(selectDesks, (desks) => desks);

export const flatDesksSelector = createSelector(selectDesks, ({ desks }) => {
    const flatDesks: DeskDTO[] = [];

    const flatten = (desk: DeskDTO) => {
        flatDesks.push(desk);
        desk.childDesks.forEach(flatten);
    };

    desks.forEach(flatten);

    return flatDesks;
});

export const selectedDeskSelector = createSelector([selectSelectedDeskId, selectDesks], (selectedDeskId, { desks }) => {
    if (selectedDeskId === undefined || !desks) return;
    if (selectedDeskId === null) return null;

    let selectedDesk = findDeskById(selectedDeskId, desks);
    if (!selectedDesk?.topLevelDeskId) return selectedDesk;

    const topLevelDesk = findDeskById(selectedDesk.topLevelDeskId, desks);
    selectedDesk = { ...selectedDesk, topLevelDeskName: topLevelDesk?.name };
    return selectedDesk;
});

export const deskHasBeenSetSelector = createSelector(selectSelectedDeskId, (selectedDeskId) => {
    return selectedDeskId !== undefined;
});

export const findDeskById = (deskId: number, desks: DeskDTO[]): DeskDTO | undefined => {
    let selectedDesk: DeskDTO | undefined;

    for (const desk of desks) {
        if (desk.deskId === deskId) {
            selectedDesk = desk;
        } else {
            selectedDesk = findDeskById(deskId, desk.childDesks);
        }
        if (selectedDesk) break;
    }

    return selectedDesk;
};

export const findDeskByName = (deskName: string, desks: DeskDTO[]): DeskDTO | undefined => {
    let selectedDesk: DeskDTO | undefined;

    for (const desk of desks) {
        if (desk.name === deskName) {
            selectedDesk = desk;
        } else {
            selectedDesk = findDeskByName(deskName, desk.childDesks);
        }
        if (selectedDesk) break;
    }

    return selectedDesk;
};
