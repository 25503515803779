import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { ExchangesAPIResponse, State } from "./types";

const initialState: State = {
    exchanges: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getExchangesSlice = createSlice({
    name: "exchanges",
    initialState,
    reducers: {
        getExchangesSuccess: (state, action: PayloadAction<ExchangesAPIResponse>) => {
            state.exchanges = action.payload.exchanges;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getExchangesFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
    },
});

export const { getExchangesSuccess, getExchangesFailed } = getExchangesSlice.actions;

const selectExchanges = (state: RootState) => state.features.exchanges.exchanges;
const selectStatus = (state: RootState) => state.features.exchanges.meta.status;

export const exchangesSelector = createSelector(selectExchanges, exchanges => exchanges)
export const statusSelector = createSelector(selectStatus, status => status)