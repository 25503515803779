import { tenorPeriods } from "../constants";

export const getYearFromPeriod = (period: Common.Period, referenceDate: Date = new Date()) => {
    const currentMonth = referenceDate.getMonth() + 1;
    const currentYear = referenceDate.getFullYear();

    if (!tenorPeriods.some((p) => p.name === period.name)) return null;

    if (period.name === "Cal") {
        return currentYear.toString();
    }

    if (period.name.startsWith("Q")) {
        if (period.months.some((m) => currentMonth <= m)) {
            return currentYear.toString();
        } else {
            return (currentYear + 1).toString();
        }
    }

    const [monthNumber] = period.months;

    if (currentMonth > monthNumber) {
        return (currentYear + 1).toString();
    }

    return currentYear.toString();
};
