import logoLightMode from "images/LogoLightMode.svg";
import * as React from "react";
import { NavLink } from "react-router-dom";

export const HeaderLogo: React.FC = () => {
    return (
        <div className="flex">
            <div>
                <NavLink to="/">
                    <img
                        data-testid={`navigation-logo`}
                        src={logoLightMode}
                        className="w-8 mr-4"
                        style={{ minWidth: "2rem" }}
                    />
                </NavLink>
            </div>
            <div className="text-4xl leading-9 whitespace-nowrap" data-testid="navigation-title">
                <span className="font-bold">ONYX</span> Eagle
            </div>
        </div>
    );
};
