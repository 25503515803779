import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { TradesAPIResponse } from "features/trades/tradesTypes";

export const getTrades = async (
    tradeDate: Common.Nullable<string>,
    includeOnScreenTrades: boolean = false,
    deskId: Common.Nullable<number>,
) => {
    const token = await getToken();
    const url = buildUrl(tradeDate, includeOnScreenTrades, deskId);

    const response = await axios.get<TradesAPIResponse>(url, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};

const buildUrl = (
    tradeDate: Common.Nullable<string>,
    includeOnScreenTrades: boolean,
    deskId: Common.Nullable<number>,
) => {
    const params = new URLSearchParams();
    tradeDate && params.append("tradeDate", tradeDate);
    params.append("includeOnScreenTrades", includeOnScreenTrades.toString());
    deskId && params.append("deskId", deskId.toString());

    return `${apiBaseUrl}/api/trades?${params.toString()}`;
};
