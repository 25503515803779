import { Case, SwitchStatement } from "common/components/switchStatement";
import { ConnectedIcon, DisconnectedIcon, ReconnectingIcon } from "common/icons";
import { realTimeSelector } from "features/realTime/realTimeSlice";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./realTime.styles.scss";

export const RealTime: React.FC = () => {
    const { connectionState, lastUpdatedTime, route } = useSelector(realTimeSelector);
    const history = useHistory();
    const pathname = history.location.pathname;
    const equivalentRoutes = [
        ["/spreader/spreads-only", "/spreader"],
        [
            "/risk/live/blotter",
            "/risk/live/exchange",
            "/risk/live/cross-exchange/blotter",
            "/risk/live/cross-exchange/exchange",
        ],
    ];

    const routeMatch = pathname === route || equivalentRoutes.some((a) => a.includes(pathname) && a.includes(route!));

    if (connectionState === undefined || !routeMatch) return null;

    return (
        <div
            key={lastUpdatedTime}
            data-testid="real-time"
            className="realTime flex items-center font-bold text-sm mr-2 bg-white-500 p-0.5 rounded-lg"
            title={lastUpdatedTime ? `Last updated ${lastUpdatedTime}` : ``}
        >
            <SwitchStatement test={connectionState}>
                <Case value={["Connected", "Connecting"]}>
                    <ConnectedIcon data-testid="real-time-connected" />
                </Case>
                <Case value={["Disconnected", "Disconnecting"]}>
                    <DisconnectedIcon data-testid="real-time-disconnected" /> <span className="mr-3">Disconnected</span>
                </Case>
                <Case value="Reconnecting">
                    <ReconnectingIcon data-testid="real-time-reconnecting" />
                    <span className="mr-3">Reconnecting...</span>
                </Case>
            </SwitchStatement>
        </div>
    );
};
