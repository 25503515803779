import { CellClassParams, RowSpanParams, ValueGetterParams } from "@ag-grid-community/core";
import { format, parseISO } from "date-fns";
import { ColumnParams } from "./eodRiskTypes";

export const getColumnDefs = (params: ColumnParams): any[] => {
    const {
        riskItemsByTenor,
        groupedYears,
        dispatch,
        toggleGroupedYear,
        productGroups,
        getProductGroupColumnSettings,
        valueFormatter,
        getProductGroupTotal,
        riskItems,
        riskItemsByGroup,
        getProductColumnSettings,
        productValueGetter,
        productsByProductGroup,
        getDiffProductColumnSettings,
        columnState,
        grandTotalColumnWidth,
        getTenorGrandTotal,
        diffProductValueGetter,
        productDiffsByProductGroup,
    } = params;

    return [
        {
            field: "year",
            headerName: "",
            filter: false,
            sortable: false,
            resizable: false,
            minWidth: 90,
            width: 90,
            maxWidth: 90,
            cellClassRules: {
                "cell-span": "value !== '' && value !== 'Grand total'",
            },
            rowSpan: ({ data: { year, isGrandTotal } }: RowSpanParams) =>
                isGrandTotal
                    ? 1
                    : riskItemsByTenor.filter((r) => {
                          const itemYear = r.tenorDate.substr(0, 4);
                          return year === itemYear;
                      }).length,

            valueGetter: ({ data: { year }, node }: ValueGetterParams) => (node?.isRowPinned() ? "Grand total" : year),

            cellRenderer: "TenorYearToggle",
            cellRendererParams: {
                groupedYears,
                onToggleYear: (year: string) => dispatch(toggleGroupedYear(year)),
            },
        },
        {
            field: "tenorDate",
            filter: false,
            sortable: false,
            resizable: true,
            type: "numericColumn",
            cellClass: "font-bold text-right",
            minWidth: 100,
            maxWidth: 100,
            headerName: "Tenor Date",
            valueGetter: ({ data: { year, tenorDate, isGrandTotal, isGroupedByYear } }: ValueGetterParams) => {
                if (isGroupedByYear) return year;
                return isGrandTotal ? "" : format(parseISO(tenorDate), "MMMyy");
            },
        },
        ...productGroups!.map((productGroup) => {
            const productGroupColumnSettings = getProductGroupColumnSettings(productGroup);
            return {
                headerName: productGroup,
                groupId: productGroup,
                openByDefault: productGroupColumnSettings.expanded,
                children: [
                    {
                        refData: {
                            level: "0",
                            productGroup,
                        },
                        columnGroupShow: "closed",
                        type: "numericColumn",
                        headerName: "",
                        cellClassRules: {
                            "text-red-600": (params: CellClassParams) => params.value < 0,
                        },
                        width: productGroupColumnSettings.width,
                        minWidth: productGroupColumnSettings.minWidth,
                        valueFormatter: valueFormatter,
                        valueGetter: ({ data }: ValueGetterParams) =>
                            getProductGroupTotal(riskItems, productGroup, data),
                    },
                    ...Object.keys(riskItemsByGroup[productGroup]).map((product) => {
                        const productColumnSettings = getProductColumnSettings(productGroup, product);
                        let productSymbol =
                            Object.values(riskItemsByGroup[productGroup][product])?.[0]?.[0]?.symbol || null;
                        productSymbol = productSymbol ? ` (${productSymbol})` : "";

                        return {
                            headerName: `${product}${productSymbol}`,
                            columnGroupShow: "open",
                            groupId: `${productGroup}-${product}`,
                            openByDefault: productColumnSettings.expanded,
                            children: [
                                {
                                    refData: {
                                        level: "1",
                                        productGroup,
                                        product: `${product}${productSymbol}`,
                                    },
                                    width: productColumnSettings.width,
                                    minWidth: productColumnSettings.minWidth,
                                    headerName: "",
                                    type: "numericColumn",
                                    columnGroupShow: "closed",
                                    cellClassRules: {
                                        "text-red-600": (params: CellClassParams) => params.value < 0,
                                    },
                                    valueFormatter: valueFormatter,
                                    valueGetter: ({ data }: ValueGetterParams) =>
                                        productValueGetter(productsByProductGroup, productGroup, product, data),
                                },
                                ...Object.keys(riskItemsByGroup[productGroup][product]).map((diffProduct) => {
                                    const diffProductColumnSettings = getDiffProductColumnSettings(
                                        productGroup,
                                        product,
                                        diffProduct,
                                    );
                                    const diffSymbol =
                                        riskItemsByGroup[productGroup][product][diffProduct][0].diffSymbol;
                                    return {
                                        refData: {
                                            level: "2",
                                            productGroup,
                                            product: `${product}${productSymbol}`,
                                            diffProduct: `${diffProduct} (${diffSymbol})`,
                                        },
                                        width: diffProductColumnSettings.width,
                                        minWidth: diffProductColumnSettings.minWidth,
                                        type: "numericColumn",
                                        columnGroupShow: "open",
                                        headerName: `${diffProduct} (${diffSymbol})`,
                                        cellClassRules: {
                                            "text-red-600": (params: CellClassParams) => params.value < 0,
                                        },
                                        valueFormatter: valueFormatter,
                                        valueGetter: ({ data }: ValueGetterParams) =>
                                            diffProductValueGetter(
                                                productDiffsByProductGroup,
                                                productGroup,
                                                product,
                                                diffProduct,
                                                data,
                                            ),
                                    };
                                }),
                            ],
                        };
                    }),
                ],
            };
        }),
        {
            columnGroupShow: "closed",
            refData: {
                level: "3",
            },
            headerName: `Grand Total`,
            cellClass: "text-right font-bold bg-white-500",
            type: "numericColumn",
            resizable: true,
            pinned: "right",
            width: columnState && columnState["grand-total"] ? columnState["grand-total"].width : grandTotalColumnWidth,

            minWidth: 100,
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
                "text-3xl": (params: CellClassParams) => params.node.isRowPinned(),
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => getTenorGrandTotal(riskItems, data),
        },
    ];
};
