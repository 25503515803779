import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { findDeskById } from "features/desks/desksSlice";
import { PnlItemRow, PnlRealTimeMessage, PnLResponseDto, State } from "./pnlTypes";
import { Onyx } from "common/constants";

const initialState: State = {
    decimalPosition: 0,
    pnlItemRows: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
    lastRealTimeMessage: undefined,
    isPnlUpdateRequired: false,
    includeYearToDatePnl: false,
};

export const getPnLSlice = createSlice({
    name: "pnl",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        getPnLSuccess: (state, action: PayloadAction<PnLResponseDto>) => {
            state.pnlItemRows = action.payload.pnlItemRows;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getPnLFailed: (state, action: PayloadAction<string>) => {
            state.meta.message = action.payload;
            state.meta.lastUpdated = Date.now();
            state.meta.status = "error";
            return state;
        },
        increaseDecimalPosition: (state) => {
            if (state.decimalPosition === 4) return state;
            state.decimalPosition += 1;
            return state;
        },
        decreaseDecimalPosition: (state) => {
            if (state.decimalPosition === 0) return state;
            state.decimalPosition -= 1;
            return state;
        },
        setLastRealTimeMessage: (state, action: PayloadAction<PnlRealTimeMessage>) => {
            state.lastRealTimeMessage = action.payload;
            return state;
        },
        setIsPnlUpdateRequired: (state, action: PayloadAction<boolean>) => {
            state.isPnlUpdateRequired = action.payload;
            return state;
        },
        setIncludeYearToDatePnl: (state, action: PayloadAction<boolean>) => {
            state.includeYearToDatePnl = action.payload;
            return state;
        },
    },
});

export const {
    setStatus,
    getPnLSuccess,
    getPnLFailed,
    increaseDecimalPosition,
    decreaseDecimalPosition,
    setLastRealTimeMessage,
    setIsPnlUpdateRequired,
    setIncludeYearToDatePnl,
} = getPnLSlice.actions;

const selectMetaState = (state: RootState) => state.features.pnl.meta;
const selectPnlItemRows = (state: RootState) => state.features.pnl.pnlItemRows;
const selectDecimalPosition = (state: RootState) => state.features.pnl.decimalPosition;
const selectDesks = (state: RootState) => state.features.desks.desks;
const selectLastRealTimeMessage = (state: RootState) => state.features.pnl.lastRealTimeMessage;
const selectIsPnlUpdateRequired = (state: RootState) => state.features.pnl.isPnlUpdateRequired;
const selectIncludeYearToDatePnl = (state: RootState) => state.features.pnl.includeYearToDatePnl;

const selectLastUpdated = (state: RootState) => state.features.pnl.meta.lastUpdated;
export const lastUpdatedSelector = createSelector(selectLastUpdated, (lastUpdated) => lastUpdated);

export const metaSelector = createSelector(selectMetaState, (meta) => meta);
export const decimalPositionSelector = createSelector(selectDecimalPosition, (decimalPosition) => decimalPosition);

export const lastRealTimeMessageSelector = createSelector(
    selectLastRealTimeMessage,
    (lastRealTimeMessage) => lastRealTimeMessage,
);

export const isPnlUpdateRequiredSelector = createSelector(
    selectIsPnlUpdateRequired,
    (isPnlUpdateRequired) => isPnlUpdateRequired,
);

export const pnlIncludeYearToDateSelector = createSelector(
    selectIncludeYearToDatePnl,
    (includeYearToDatePnl) => includeYearToDatePnl,
);

export const pnlItemRowsSelector = createSelector(
    [selectPnlItemRows, selectDesks, selectIncludeYearToDatePnl],
    (pnlItemRows, desks, includeYearToDatePnl) => {
        if (pnlItemRows && desks) {
            // remove rows without pnl data when YTD pnl not selected
            if (!includeYearToDatePnl) {
                pnlItemRows = pnlItemRows.filter(
                    (pnlItemRow) =>
                        pnlItemRow.dayPnl != 0 ||
                        pnlItemRow.overnightPnl != 0 ||
                        pnlItemRow.netPnl != 0 ||
                        pnlItemRow.brokerage != 0 ||
                        pnlItemRow.abnTradeFees != 0 ||
                        pnlItemRow.exchangeTradeFees != 0,
                );
            }

            const mappedPnlItemRows: PnlItemRow[] = pnlItemRows.map((pnlItemRow) => {
                const {
                    deskId,
                    deskName,
                    overnightPnl,
                    dayPnl,
                    brokerage,
                    abnTradeFees,
                    exchangeTradeFees,
                    netPnl,
                    yearToDatePnl,
                } = pnlItemRow;

                const desk = findDeskById(deskId, desks);
                const topLevelDesk = desks.find((d) => d.deskId === desk?.topLevelDeskId);

                return {
                    deskId,
                    deskName,
                    deskNumber: desk?.deskNumber,
                    dayPnl: dayPnl,
                    overnightPnl: overnightPnl,
                    brokerage: brokerage,
                    abnTradeFees: abnTradeFees,
                    exchangeTradeFees: exchangeTradeFees,
                    topLevelDeskId: topLevelDesk?.deskId,
                    topLevelDeskName: topLevelDesk?.name,
                    netPnl: netPnl,
                    yearToDatePnl: yearToDatePnl,
                    group: Onyx,
                };
            });
            return mappedPnlItemRows.length ? mappedPnlItemRows : undefined;
        }
        return undefined;
    },
);
