import { ColumnResizedEvent } from "@ag-grid-community/core";
import { desksSelector } from "features/desks/desksSlice";
import _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { eodRiskSelector, productGroupsSelector, riskItemsByGroupSelector } from "./eodRiskSlice";
import { AgGridReact } from "@ag-grid-community/react";

export const useGridState = () => {
    const gridRef = React.useRef<AgGridReact>(null);

    const [gridWidth, setGridWidth] = React.useState<number>(0);

    const { processingDate } = useSelector(eodRiskSelector);

    const riskItemsByGroup = useSelector(riskItemsByGroupSelector);
    const productGroups = useSelector(productGroupsSelector);

    const { selectedDeskId } = useSelector(desksSelector);

    const initialColumnWidth = 192;
    const productGroupColumnWidth = 100;
    const grandTotalColumnWidth = 120;
    const productColumnWidth = 200;
    const diffProductColumnWidth = 200;

    const [columnState, setColumnState] = React.useState<any>();
    const [columnDefs, setColumnDefs] = React.useState<any>();

    const handleColumnResize = (event: ColumnResizedEvent) => {
        if (event.column && event.finished) {
            const { level, productGroup, product, diffProduct } = event.column!.getColDef().refData as any;
            const width = event.column!.getActualWidth();

            // Set column state here
            setColumnState((prevState: any) => {
                switch (level) {
                    case "0":
                        prevState[productGroup] = { ...prevState[productGroup], width };
                        break;
                    case "1":
                        prevState[productGroup].children[product] = {
                            ...prevState[productGroup].children[product],
                            width,
                        };
                        break;
                    case "2":
                        prevState[productGroup].children[product].children[diffProduct] = {
                            ...prevState[productGroup].children[product].children[diffProduct],
                            width,
                        };
                        break;
                    case "3":
                        prevState["grand-total"] = prevState["grand-total"] || {};
                        prevState["grand-total"].width = width;
                        break;
                    default:
                        return prevState;
                }
                return prevState;
            });

            setGridWidth((prevState) => calculateWidth(prevState));
        }
    };

    const calculateWidth = (originalValue: number, colState?: any) => {
        const myState = colState ?? columnState;
        const productsWidth = !myState ? 0 : calculateWidthFromProducts(myState) + initialColumnWidth;
        return productsWidth || originalValue;
    };

    const calculateWidthFromProducts = (state: any) => {
        const products = Object.keys(state);
        let width = 0;
        products.forEach((product) => {
            if (
                state[product] &&
                state[product].expanded &&
                state[product].children &&
                Object.keys(state[product].children).length > 0
            ) {
                width -= state[product].width ?? 0;
                width += calculateWidthFromProducts(state[product].children);
            }

            width += state[product]?.width ?? 0;
        });
        return width;
    };

    React.useEffect(() => {
        const newColumnState = recalculateColumnState(columnState);
        if (columnState == undefined || !_.isEqual(columnState, newColumnState)) {
            setColumnState(newColumnState);
        }
    }, [processingDate, selectedDeskId, riskItemsByGroup, productGroups]);

    const recalculateColumnState = (colState?: any) => {
        const result = Object.keys(riskItemsByGroup).reduce((prev: any, productGroup: string) => {
            const current = { ...prev } || {};

            current[productGroup] = current[productGroup] || {
                width: colState?.[productGroup]?.width ?? productGroupColumnWidth,
                expanded: colState?.[productGroup]?.expanded ?? false,
                children: {},
            };

            Object.keys(riskItemsByGroup[productGroup]).forEach((product) => {
                let productSymbol = Object.values(riskItemsByGroup[productGroup][product])?.[0]?.[0]?.symbol || null;
                productSymbol = productSymbol ? ` (${productSymbol})` : "";

                current[productGroup].children[`${product}${productSymbol}`] = current[productGroup].children[
                    `${product}${productSymbol}`
                ] || {
                    width:
                        colState?.[productGroup]?.children?.[`${product}${productSymbol}`]?.width ?? productColumnWidth,
                    expanded: colState?.[productGroup]?.children?.[`${product}${productSymbol}`]?.expanded ?? false,
                    children: {},
                };

                Object.keys(riskItemsByGroup[productGroup][product]).forEach((diffProduct) => {
                    let diffSymbol = riskItemsByGroup[productGroup][product][diffProduct]?.[0]?.diffSymbol || null;
                    diffSymbol = diffSymbol ? ` (${diffSymbol})` : "";

                    current[productGroup].children[`${product}${productSymbol}`].children[
                        `${diffProduct}${diffSymbol}`
                    ] = current[productGroup].children[`${product}${productSymbol}`].children[
                        `${diffProduct}${diffSymbol}`
                    ] || {
                        width:
                            colState?.[productGroup]?.children?.[`${product}${productSymbol}`]?.children?.[
                                `${diffProduct}${diffSymbol}`
                            ]?.width ?? diffProductColumnWidth,
                    };
                });
            });

            return current;
        }, {});

        result.deskId = selectedDeskId;
        result.processingDate = processingDate;
        result["grand-total"] = result["grand-total"] || { width: grandTotalColumnWidth };

        return result;
    };

    const getProductGroupColumnSettings = (productGroup: string) => {
        const defaultSettings = { minWidth: productGroupColumnWidth, width: productGroupColumnWidth };
        return { ...defaultSettings, ...(columnState ?? {})[productGroup] };
    };

    const getProductColumnSettings = (productGroup: string, product: string) => {
        let productSymbol = Object.values(riskItemsByGroup[productGroup][product])?.[0]?.[0]?.symbol || null;
        productSymbol = productSymbol ? ` (${productSymbol})` : "";

        const defaultSettings = { minWidth: productColumnWidth, width: productColumnWidth };
        return { ...defaultSettings, ...(columnState ?? {})[productGroup]?.children?.[`${product}${productSymbol}`] };
    };

    const getDiffProductColumnSettings = (productGroup: string, product: string, diffProduct: string) => {
        let productSymbol = Object.values(riskItemsByGroup[productGroup][product])?.[0]?.[0]?.symbol || null;
        productSymbol = productSymbol ? ` (${productSymbol})` : "";

        let diffSymbol = riskItemsByGroup[productGroup][product][diffProduct]?.[0]?.diffSymbol || null;
        diffSymbol = diffSymbol ? ` (${diffSymbol})` : "";

        const defaultSettings = { minWidth: diffProductColumnWidth, width: diffProductColumnWidth };
        return {
            ...defaultSettings,
            ...(columnState ?? {})[productGroup]?.children?.[`${product}${productSymbol}`]?.children?.[
                `${diffProduct}${diffSymbol}`
            ],
        };
    };

    return {
        gridRef,
        gridWidth,
        setGridWidth,
        grandTotalColumnWidth,
        columnState,
        setColumnState,
        getProductGroupColumnSettings,
        getProductColumnSettings,
        getDiffProductColumnSettings,
        columnDefs,
        setColumnDefs,
        calculateWidth,
        handleColumnResize,
        recalculateColumnState,
    };
};
