import classNames from "classnames";
import {
    blotterMetaSelector,
    blotterPageSelector,
    canViewBlotterEntryFormSelector,
    showBlotterEntryFormSelector,
} from "features/blotter/blotterSlice";
import { BlotterGrid } from "features/blotter/components/blotterGrid";
import { useBlotterTradesHubConnection } from "features/blotter/hooks/useBlotterTradesHubConnection";
import { selectedDeskSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useSelector } from "react-redux";
import { BlotterTradesUpdateNotification } from "./components/blotterTradesUpdateNotification";

export const BlotterGridContainer: React.FC = () => {
    const showBlotterEntryForm = useSelector(showBlotterEntryFormSelector);
    const { status } = useSelector(blotterMetaSelector);
    const canViewBlotterEntryForm = useSelector(canViewBlotterEntryFormSelector);

    const selectedDesk = useSelector(selectedDeskSelector);
    const { signalRGroupName } = useSelector(blotterPageSelector);

    useBlotterTradesHubConnection(selectedDesk?.topLevelDeskId, signalRGroupName);

    return (
        <div
            className={classNames("flex flex-col flex-1 p-6 overflow-auto", {
                blotterGridContainer: canViewBlotterEntryForm && showBlotterEntryForm,
            })}
        >
            <div className="flex flex-1 flex-col">
                {status === "error" ? (
                    <div className="py-4 px-2">An error occurred</div>
                ) : (
                    <>
                        <BlotterGrid />
                        <BlotterTradesUpdateNotification />
                    </>
                )}
            </div>
        </div>
    );
};
