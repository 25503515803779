import {
    BodyScrollEvent,
    CellClickedEvent,
    EditableCallbackParams,
    FilterChangedEvent,
    RowNode,
    ValueFormatterParams,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import classNames from "classnames";
import { GridContainer, GridDateFilter, GridLoading, NumericEditor } from "common/components/grid";
import { Modal } from "common/components/modal";
import { tenorPeriods } from "common/constants";
import { isCurrentPeriod } from "common/utils/isCurrentPeriod";
import { format } from "date-fns";
import {
    blotterCanEditSelector,
    blotterGridHeaderStateSelector,
    blotterGridSelector,
    blotterIsEditingSelector,
    clearBlotterEntry,
    clearUpdatedTrades,
    isBlotterUpdateRequiredSelector,
    setAutoUpdate,
    setBlotterTradeToDelete,
    setBlotterTradeToDuplicate,
    setFilterEnabled,
    setProductGroupText,
    toggleBlotterEntryForm,
    updateBlotterEntry,
    setBlotterEditingRow,
    setBrokerId,
} from "features/blotter/blotterSlice";
import {
    fetchBlotterTrades,
    patchBlotterTrade,
    refetchBlotterTrades,
    refetchOnReconnect,
} from "features/blotter/blotterThunks";
import { deskHasBeenSetSelector, desksSelector, findDeskById, selectedDeskSelector } from "features/desks/desksSlice";
import debounce from "lodash/debounce";
import delay from "lodash/delay";
import includes from "lodash/includes";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./blotterGrid.styles.scss";
import { BlotterGridHeader } from "./blotterGridHeader";
import {
    ActionButtons,
    BooleanDropdownEditor,
    BrokersDropdownEditor,
    DateEditor,
    ExchangeDropdownEditor,
    CounterPartyTypeDropdownEditor,
    ProductGroupEditor,
    SideDropdownEditor,
    TenorPeriodDropdownEditor,
    TenorYearDropdownEditor,
    TransferDeskDropdownEditor,
    UnitsDropdownEditor,
} from "./components";
import { useBlotterTradeDeleteModal, useBlotterTradeDuplicateModal } from "./hooks";
import Big from "big.js";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import { fetchOnReconnectSelector } from "features/realTime/realTimeSlice";
import { getColumnDefs } from "./components/columnDefs";
import { counterPartyTypesSelector } from "features/counterPartyTypes/counterPartyTypesSlice";
import { CANCEL, DELETE, DUPLICATE, EDIT } from "features/blotter/hooks/constants";
import { BlotterTrade } from "features/blotter/blotterTypes";
import { fetchBlotterProductGroups } from "features/blotterProductGroups/blotterProductGroupsThunks";
import { getBlotterEntryFromRowData } from "./getBlotterEntryFromRowData";
import { blotterProductGroupsSelector } from "features/blotterProductGroups/blotterProductGroupsSlice";
import { brokersSelector } from "features/brokers/brokersSlice";
import { exchangesSelector } from "features/exchanges/exchangesSlice";
import { tradedUnitsSelector } from "features/tradedUnits/tradedUnitsSlice";

/* istanbul ignore next */
export const BlotterGrid: React.FC = () => {
    const dispatch = useDispatch();
    const gridRef = React.useRef<AgGridReact>(null);
    const [columnDefs, setColumnDefs] = React.useState<any>();
    const [scrollPosition, setScrollPosition] = React.useState<number>(0);
    const AUTO_UPDATE_CUTOFF = 32;

    const { deleteModalProps, handleDeleteModalClose, handleDeleteModalConfirm } = useBlotterTradeDeleteModal(
        gridRef?.current?.api,
    );

    const {
        duplicateModalProps,
        handleDuplicateModalClose,
        handleDuplicateModalCurrentDate,
        handleDuplicateModalTransactionDate,
    } = useBlotterTradeDuplicateModal(gridRef?.current?.api);

    const { blotterTrades, status, affectedBlotterTradeIds } = useSelector(blotterGridSelector);
    const { selectedDeskId, desks } = useSelector(desksSelector, shallowEqual);
    const { autoUpdate, blotterDate, lastRealTimeMessage } = useSelector(blotterGridHeaderStateSelector, shallowEqual);
    const isBlotterUpdateRequired = useSelector(isBlotterUpdateRequiredSelector);
    const canEdit = useSelector(blotterCanEditSelector);
    const isEditing = useSelector(blotterIsEditingSelector);
    const selectedDesk = useSelector(selectedDeskSelector);
    const fetchOnReconnect = useSelector(fetchOnReconnectSelector, shallowEqual);
    const counterPartyTypes = useSelector(counterPartyTypesSelector);
    const { allBlotterGroups } = useSelector(blotterProductGroupsSelector);
    const { allBrokers } = useSelector(brokersSelector);
    const exchanges = useSelector(exchangesSelector);
    const { tradedUnits } = useSelector(tradedUnitsSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    React.useEffect(() => {
        setColumnDefs(
            getColumnDefs({
                canEdit,
                dispatch,
                patchBlotterTrade,
                valueFormatter,
                isCurrentPeriod,
                tenorPeriods,
                counterPartyTypes,
                blotterProductGroups: allBlotterGroups,
                brokers: allBrokers,
                exchanges,
                desks,
                tradedUnits,
            }),
        );
    }, [
        canEdit,
        tenorPeriods,
        counterPartyTypes,
        autoUpdate,
        desks,
        exchanges,
        allBrokers,
        allBlotterGroups,
        tradedUnits,
    ]);

    React.useEffect(() => {
        const gridApi = gridRef?.current?.api;

        if (!isEditing && gridApi) {
            gridApi.deselectAll();
        }
    }, [isEditing]);

    const debouncedScrollHandler = React.useCallback(
        debounce((pos: number) => {
            // Automatically update the grid if the scroll position is below 32
            setScrollPosition(pos);
            dispatch(setAutoUpdate(pos < AUTO_UPDATE_CUTOFF));
        }, 300),
        [],
    );

    const handleFilterChange = (e: FilterChangedEvent) => {
        if (e.afterFloatingFilter) {
            dispatch(setFilterEnabled(e.api.isAnyFilterPresent()));
        }
    };

    const handleScroll = React.useCallback((e: BodyScrollEvent) => {
        debouncedScrollHandler(e.top);
    }, []);

    const onFlashRows = (affectedBlotterTradeIds: number[]) => {
        const gridApi = gridRef?.current?.api;

        if (gridApi) {
            const displayedRowCount = gridApi.getDisplayedRowCount();
            let rowIndex = gridApi.getFirstDisplayedRow();

            // Loop through all the displayed rows and if we have a matching trade id then flash it
            const rowNodes = [];
            for (let i = 0; i < displayedRowCount; i++) {
                const row = gridApi.getDisplayedRowAtIndex(rowIndex);
                if (row && affectedBlotterTradeIds.some((u) => u === row.data.id)) {
                    rowNodes.push(row);
                }
                rowIndex++;
            }
            if (rowNodes && rowNodes.length > 0)
                gridApi.flashCells({
                    rowNodes,
                });
        }
    };

    const handleEditClick = (data: any, node: RowNode) => {
        dispatch(setBlotterEditingRow(node.rowIndex));
        node.setSelected(true, true);
        const { blotterProductGroupName, brokerId, deskId } = data as BlotterTrade;

        const desk = findDeskById(deskId, desks);

        dispatch(toggleBlotterEntryForm(true));

        dispatch(setProductGroupText(blotterProductGroupName || ""));
        dispatch(setBrokerId(brokerId || null));

        dispatch(fetchBlotterProductGroups(desk?.topLevelDeskId));

        const blotterTrade = blotterTrades?.find((b) => b.id === data.id);
        if (blotterTrade) {
            dispatch(updateBlotterEntry(getBlotterEntryFromRowData(blotterTrade)));
        }
    };

    const handleDeleteClick = (data: any, node: RowNode) => {
        node.setSelected(true, true);
        dispatch(setBlotterTradeToDelete(data.id));
    };

    const handleDuplicateClick = (data: any, node: RowNode) => {
        node.setSelected(true, true);
        dispatch(setBlotterTradeToDuplicate(data.id));
    };

    const handleCancelClick = (node: RowNode) => {
        node.setSelected(false, true);
        dispatch(clearBlotterEntry());
    };

    const handleCellClicked = (params: CellClickedEvent) => {
        if (params.column.getColId() === "action" && (params.event?.target as HTMLButtonElement)?.dataset?.action) {
            const action = (params.event?.target as HTMLButtonElement)?.dataset?.action;
            const { data, node } = params;

            switch (action) {
                case EDIT:
                    handleEditClick(data, node);
                    break;
                case DELETE:
                    handleDeleteClick(data, node);
                    break;
                case CANCEL:
                    handleCancelClick(node);
                    break;
                case DUPLICATE:
                    handleDuplicateClick(data, node);
                    break;
            }
        }
    };

    // Animate the grid rows which have changed
    React.useLayoutEffect(() => {
        if (!isBlotterUpdateRequired && affectedBlotterTradeIds && affectedBlotterTradeIds.length > 0) {
            delay(onFlashRows, 0, affectedBlotterTradeIds);
            dispatch(clearUpdatedTrades());
        }
    }, [affectedBlotterTradeIds, isBlotterUpdateRequired]);

    const valueFormatter = React.useCallback(({ value }: ValueFormatterParams, decimalPlaces: number) => {
        return value !== null ? formatNumberWithCommas(new Big(value).toFixed(decimalPlaces, Big.roundHalfEven)) : "";
    }, []);

    React.useEffect(() => {
        if (deskHasBeenSet) {
            dispatch(fetchBlotterTrades(blotterDate, selectedDeskId!));
        }
    }, [blotterDate, selectedDeskId]);

    // Re-fetch when the signalR message comes in
    React.useEffect(() => {
        if (lastRealTimeMessage && deskHasBeenSet) {
            dispatch(refetchBlotterTrades(lastRealTimeMessage, autoUpdate, blotterDate, selectedDesk ?? null));
        }
    }, [lastRealTimeMessage]);

    // Handle when auto-update is toggled from false to true on scroll to top
    React.useEffect(() => {
        if (autoUpdate && isBlotterUpdateRequired && deskHasBeenSet) {
            dispatch(fetchBlotterTrades(blotterDate, selectedDeskId!));
        }
    }, [autoUpdate, isBlotterUpdateRequired, blotterDate, selectedDeskId]);

    React.useEffect(() => {
        if (fetchOnReconnect && deskHasBeenSet) {
            dispatch(refetchOnReconnect(autoUpdate, blotterDate, selectedDesk ?? null));
        }
    }, [fetchOnReconnect]);

    const noDataAvailable = !Array.isArray(blotterTrades) || blotterTrades.length === 0;
    return (
        <>
            <BlotterGridHeader
                onExportToCSV={() =>
                    gridRef?.current?.api &&
                    gridRef.current.api.exportDataAsCsv({
                        fileName: `Blotter_Trades_${selectedDesk?.name ?? "Onyx"}.csv`,
                    })
                }
            />

            {status === "error" && <div className="py-4 px-2 text-sm">An error has occurred</div>}

            {noDataAvailable && status === "loaded" && <div className="py-4 px-2">No data available</div>}

            {noDataAvailable && status === "loading" && <GridLoading />}

            {!noDataAvailable && status !== "error" && (
                <div
                    data-testid={`blotterTrades-grid${status === "loaded" ? "" : "-loading"}`}
                    className={classNames("blotterGrid flex-1 bg-white-300", {
                        "opacity-50": status === "loading" && !noDataAvailable,
                    })}
                >
                    {deleteModalProps.show && (
                        <Modal
                            title="Blotter"
                            headerText=""
                            onClose={handleDeleteModalClose}
                            message={deleteModalProps.message}
                            actions={[
                                {
                                    id: "confirm",
                                    text: "Confirm",
                                    action: handleDeleteModalConfirm,
                                    mode: "danger",
                                },
                                {
                                    id: "cancel",
                                    text: "Cancel",
                                    action: handleDeleteModalClose,
                                    mode: "secondary",
                                },
                            ]}
                        />
                    )}
                    {duplicateModalProps.show && (
                        <Modal
                            title="Set Trade Timestamp"
                            headerText=""
                            onClose={handleDuplicateModalClose}
                            message={duplicateModalProps.message}
                            actions={[
                                {
                                    id: "transaction",
                                    text: "Transaction time",
                                    action: handleDuplicateModalTransactionDate,
                                    mode: "primary",
                                },
                                {
                                    id: "current",
                                    text: "Current time",
                                    action: handleDuplicateModalCurrentDate,
                                    mode: "primary",
                                },
                                {
                                    id: "cancel",
                                    text: "Cancel",
                                    action: handleDuplicateModalClose,
                                    mode: "secondary",
                                },
                            ]}
                        />
                    )}
                    {!noDataAvailable && (
                        <GridContainer>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={columnDefs}
                                suppressScrollOnNewData
                                gridOptions={{ getRowId: (data: any) => data.data.id, suppressRowHoverHighlight: true }}
                                modules={[RangeSelectionModule, ClipboardModule]}
                                enableRangeSelection={true}
                                copyHeadersToClipboard={true}
                                enableCellTextSelection={false}
                                onBodyScroll={handleScroll}
                                onFilterChanged={handleFilterChange}
                                onCellEditingStarted={() => {
                                    dispatch(setAutoUpdate(false));
                                }}
                                onCellEditingStopped={() => {
                                    if (scrollPosition < AUTO_UPDATE_CUTOFF) {
                                        dispatch(setAutoUpdate(true));
                                    }
                                }}
                                onCellClicked={handleCellClicked}
                                defaultCsvExportParams={{
                                    processCellCallback: ({ value, column }: any) => {
                                        if (!value || !column) return value;

                                        const colId = column.getColId();

                                        if (
                                            includes(
                                                [
                                                    "tenor1StartDate",
                                                    "tenor1EndDate",
                                                    "tenor2StartDate",
                                                    "tenor2EndDate",
                                                ],
                                                colId,
                                            )
                                        ) {
                                            return format(value, "dd/MM/yyyy");
                                        } else if (colId === "createdDateTime") {
                                            return format(value, "dd/MM/yyyy HH:mm:ss");
                                        }

                                        return value;
                                    },
                                }}
                                components={{
                                    ActionButtons,
                                    BooleanDropdownEditor,
                                    DateEditor,
                                    ExchangeDropdownEditor,
                                    CounterPartyTypeDropdownEditor,
                                    NumericEditor,
                                    SideDropdownEditor,
                                    UnitsDropdownEditor,
                                    TenorPeriodDropdownEditor,
                                    TenorYearDropdownEditor,
                                    ProductGroupEditor,
                                    BrokersDropdownEditor,
                                    TransferDeskDropdownEditor,
                                    agDateInput: GridDateFilter,
                                }}
                                stopEditingWhenCellsLoseFocus
                                singleClickEdit
                                enableCellChangeFlash={false}
                                suppressExcelExport
                                popupParent={document.body}
                                defaultColDef={{
                                    flex: 1,
                                    minWidth: 65,
                                    resizable: true,
                                    sortable: true,
                                    filter: true,
                                    floatingFilter: true,
                                    editable: ({ column }: EditableCallbackParams) => {
                                        const columnId = column.getColId();

                                        return (
                                            !isEditing &&
                                            canEdit &&
                                            [
                                                "side",
                                                "quantity",
                                                "tradedUnitName",
                                                "pricingDay",
                                                "isStrip",
                                                "price",
                                                "tenor1Period",
                                                "tenor1Year",
                                                "tenor2Period",
                                                "tenor2Year",
                                                "brokerageAdjustment",
                                                "exchangeName",
                                                "tenor1StartDate",
                                                "tenor1EndDate",
                                                "tenor2StartDate",
                                                "tenor2EndDate",
                                                "blotterProductGroupName",
                                                "brokerName",
                                                "transferDeskName",
                                            ].some((c) => c === columnId)
                                        );
                                    },
                                }}
                                rowData={blotterTrades}
                                rowStyle={{ borderTopStyle: "none" }}
                                ensureDomOrder
                                icons={{
                                    sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                                    sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                                    menu: "",
                                    resize: "",
                                }}
                                rowClassRules={{
                                    "blotter-grid-row--transfer": function (params: any) {
                                        return !!params.data.transferDeskId;
                                    },
                                    "blotter-grid-row--matched": function (params: any) {
                                        return !!params.data.matchedTrades;
                                    },
                                    "blotter-grid-row--not-matchable": function (params: any) {
                                        return !params.data.isMatchableToExchangeTrade;
                                    },
                                }}
                            />
                        </GridContainer>
                    )}
                </div>
            )}
        </>
    );
};
