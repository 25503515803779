import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { RiskSummaryItemDto, State } from "./riskSummaryItemTypes";

const initialState: State = {
    decimalPosition: 0,
    riskSummaryItems: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getRiskSummaryItemSlice = createSlice({
    name: "riskSummaryItems",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        getRiskSummaryItemsSuccess: (state, action: PayloadAction<RiskSummaryItemDto[]>) => {
            state.riskSummaryItems = action.payload;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getRiskSummaryItemsFailed: (state, action: PayloadAction<string>) => {
            state.meta.message = action.payload;
            state.meta.lastUpdated = Date.now();
            state.meta.status = "error";
            return state;
        },
        increaseDecimalPosition: (state) => {
            if (state.decimalPosition === 4) return state;
            state.decimalPosition += 1;
            return state;
        },
        decreaseDecimalPosition: (state) => {
            if (state.decimalPosition === 0) return state;
            state.decimalPosition -= 1;
            return state;
        },
    },
});

export const {
    setStatus,
    getRiskSummaryItemsSuccess,
    getRiskSummaryItemsFailed,
    increaseDecimalPosition,
    decreaseDecimalPosition,
} = getRiskSummaryItemSlice.actions;

export const riskSummaryItemsSelector = (rootState: RootState) => rootState.features.riskSummaryItems;

const selectLastUpdated = (state: RootState) => state.features.riskSummaryItems.meta.lastUpdated;
export const lastUpdatedSelector = createSelector(selectLastUpdated, (lastUpdated) => lastUpdated);
