import { blotterDateSelector, isBlotterUpdateRequiredSelector } from "features/blotter/blotterSlice";
import { fetchBlotterTrades } from "features/blotter/blotterThunks";
import { deskHasBeenSetSelector, desksSelector } from "features/desks/desksSlice";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";

export const BlotterTradesUpdateNotification: React.FC = () => {
    const dispatch = useDispatch();

    const { selectedDeskId } = useSelector(desksSelector);
    const blotterDate = useSelector(blotterDateSelector);

    const isBlotterUpdateRequired = useSelector(isBlotterUpdateRequiredSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    if (!isBlotterUpdateRequired) return null;

    return (
        <div
            data-testid="blotter-trade-update-notification"
            className="absolute shadow-lg cursor-pointer left-1/2 rounded-lg py-1 px-4 text-xs font-bold bg-black-900 text-white-900 "
            style={{ marginTop: "124px" }}
            onClick={() => deskHasBeenSet && dispatch(fetchBlotterTrades(blotterDate, selectedDeskId!))}
        >{`Blotter Trades updated`}</div>
    );
};
