import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button";
import classNames from "classnames";
import { CustomNavLink } from "common/components/routing";
import { ChevronIcon } from "common/icons";
import { routes } from "core/routes";
import { getAccountSelector } from "features/auth/authSlice";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isChildRoute } from "..";

export const NavigationLinks: React.FC = () => {
    const location = useLocation();
    const { account } = useSelector(getAccountSelector);
    const history = useHistory();

    const getCustomNavLink = (
        to: string,
        exact: boolean | undefined,
        displayName: string,
        testId: string | undefined,
        className: string,
        chevron: boolean,
        key: string,
    ): React.ReactElement => (
        <CustomNavLink key={key} to={to} exact={exact} className={className} activeClassName="bg-white-500">
            {displayName}
            {chevron && (
                <span aria-hidden="true">
                    <ChevronIcon
                        className="inline ml-2 mb-0.5 align-middle"
                        data-testid={testId || `navigation-menu-chevron`}
                    />
                </span>
            )}
        </CustomNavLink>
    );

    const getNavLinks = (
        name: string,
        to: string | string[],
        exact: boolean | undefined,
        displayName: string,
        testId: string | undefined,
        className: string,
        chevron: boolean,
    ): React.ReactElement[] => {
        if (Array.isArray(to)) {
            return to.map((a) => getCustomNavLink(a, exact, displayName, testId, className, chevron, a));
        }

        return [getCustomNavLink(to, exact, displayName, testId, className, chevron, name)];
    };

    const getMenuItem = (childRoute: Common.Route, key: string): React.ReactElement => (
        <MenuItem
            data-testid={`menu-item-child-${childRoute.name}`}
            key={key}
            onSelect={() => {
                history.push(childRoute.to as string);
            }}
            className={classNames("px-4 py-2 text-gray-900 text-sm cursor-pointer", {
                "navigation__item--selected": location.pathname === childRoute.to,
            })}
        >
            {childRoute.displayName}
        </MenuItem>
    );

    const getMenuItems = (childRoute: Common.Route): React.ReactElement[] => {
        if (Array.isArray(childRoute.to)) {
            return childRoute.to.map((a) => getMenuItem(childRoute, a));
        }

        return [getMenuItem(childRoute, childRoute.name)];
    };

    return (
        <nav data-testid="navigation-links" className="ml-4 whitespace-nowrap">
            {routes
                .filter(({ hideFromNav }) => !hideFromNav)
                .filter(({ role }) => !role || (account && account.roles.some((r) => r === role)))
                .map(({ to, name, displayName, exact, childRoutes, testId }) => {
                    if (childRoutes && childRoutes.length > 0) {
                        return (
                            <Menu key={name}>
                                {(_props: any) => (
                                    <React.Fragment>
                                        <MenuButton className="focus:outline-none">
                                            {getNavLinks(
                                                name,
                                                to,
                                                exact,
                                                displayName,
                                                testId,
                                                "px-4 py-2 rounded-lg mr-2 font-bold text-sm pointer-events-none",
                                                true,
                                            )}
                                        </MenuButton>
                                        <MenuList className="navigation__dropdown flex flex-col bg-white-900 rounded-lg border-0 focus:outline-none mt-2 shadow-lg">
                                            {childRoutes
                                                .filter((childRoute) => !childRoute.hideFromNav)
                                                .map((childRoute) => {
                                                    if (!isChildRoute(childRoute))
                                                        return (
                                                            <div
                                                                key={childRoute.name}
                                                                data-testid={`menu-item-child-${childRoute.name}`}
                                                                className="border-b border-dashed my-2 mx-4 text-gray-900"
                                                            ></div>
                                                        );

                                                    return getMenuItems(childRoute);
                                                })}
                                        </MenuList>
                                    </React.Fragment>
                                )}
                            </Menu>
                        );
                    }

                    return getNavLinks(
                        name,
                        to,
                        exact,
                        displayName,
                        testId,
                        "px-4 py-2 rounded-lg mr-2 font-bold text-sm",
                        false,
                    )[0];
                })}
        </nav>
    );
};
