import classNames from "classnames";
import * as React from "react";

type Props = {
    type?: string;
    value?: string;
} & React.HTMLAttributes<HTMLInputElement>;

export const FormInput: React.FC<Props> = ({ value = "", onChange, type = "text", className, ...props }) => {
    return (
        <input
            data-testid="form-input"
            value={value}
            onChange={onChange}
            type={type}
            className={classNames("w-full border border-white-200 text-xs p-1 rounded-lg shadow-lg", className)}
            {...props}
        />
    );
};
