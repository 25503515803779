import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LiveRiskRealTimeMessage, SpreaderEntryRealTimeMessage } from "common/types";
import { RootState } from "core/store";
import {
    DeleteDummyEntryDto,
    SpreaderEntryResponseDto,
    SpreaderRiskResponseDto,
    State,
    UpdateDummyEntryDto,
} from "../spreaderTypes";

import { createSpreaderRiskTable } from "../utils";

const initialState: State = {
    decimalPosition: 0,
    entries: {},
    risk: {
        productGroups: {},
        additionalTenorPeriods: [],
    },
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
    lastRiskRealTimeMessage: undefined,
    lastSpreaderRealTimeMessage: undefined,
    riskSignalRGroupName: undefined,
    spreaderSignalRGroupName: undefined,
    autoUpdate: true,
    isRiskUpdateRequired: false,
    isEntriesUpdateRequired: false,
    spreaderSignalRConnectionId: undefined,
    viewSpreadsOnly: false,
};

export const getSpreaderSlice = createSlice({
    name: "spreader",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        reset: (state) => {
            state.risk = initialState.risk;
            state.entries = initialState.entries;
            return state;
        },
        getSpreaderRiskSuccess: (state, action: PayloadAction<SpreaderRiskResponseDto>) => {
            state.risk = action.payload;
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getSpreaderEntriesSuccess: (state, action: PayloadAction<SpreaderEntryResponseDto>) => {
            state.entries = action.payload;
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getSpreaderFailed: (state, action: PayloadAction<string>) => {
            state.meta.status = "error";
            state.meta.message = action.payload;
            state.meta.lastUpdated = Date.now();
            return state;
        },
        increaseDecimalPosition: (state) => {
            if (state.decimalPosition === 4) return state;
            state.decimalPosition += 1;
            return state;
        },
        decreaseDecimalPosition: (state) => {
            if (state.decimalPosition === 0) return state;
            state.decimalPosition -= 1;
            return state;
        },
        clearEntries: (state) => {
            state.entries = {};
            return state;
        },
        setAutoUpdate: (state, action: PayloadAction<boolean>) => {
            state.autoUpdate = action.payload;
            return state;
        },
        setIsRiskUpdateRequired: (state, action: PayloadAction<boolean>) => {
            state.isRiskUpdateRequired = action.payload;
            return state;
        },
        setIsEntriesUpdateRequired: (state, action: PayloadAction<boolean>) => {
            state.isEntriesUpdateRequired = action.payload;
            return state;
        },
        updateEntrySuccess: (
            state,
            action: PayloadAction<{ productGroup: string; entry: UpdateDummyEntryDto | DeleteDummyEntryDto }>,
        ) => {
            const newEntries = { ...state.entries };

            newEntries[action.payload.productGroup] = newEntries[action.payload.productGroup] || {
                productGroupId: action.payload.entry.productGroupId,
                entries: [],
            };

            newEntries[action.payload.productGroup].entries = newEntries[action.payload.productGroup].entries.filter(
                (e) => e.tenorPeriod !== action.payload.entry.tenorPeriod,
            );

            if ("value" in action.payload.entry) {
                newEntries[action.payload.productGroup].entries.push({
                    tenorPeriod: action.payload.entry.tenorPeriod,
                    value: action.payload.entry.value,
                    createdByUser: "",
                    createdDateTime: "",
                });
            }

            state.entries = newEntries;
            return state;
        },
        setLastRiskRealTimeMessage: (state, action: PayloadAction<LiveRiskRealTimeMessage>) => {
            state.lastRiskRealTimeMessage = action.payload;
            return state;
        },
        setLastSpreaderRealTimeMessage: (state, action: PayloadAction<SpreaderEntryRealTimeMessage>) => {
            state.lastSpreaderRealTimeMessage = action.payload;
            return state;
        },
        setRiskSignalRGroupName: (state, action: PayloadAction<string | undefined>) => {
            state.riskSignalRGroupName = action.payload;
            return state;
        },
        setSpreaderSignalRGroupName: (state, action: PayloadAction<string | undefined>) => {
            state.spreaderSignalRGroupName = action.payload;
            return state;
        },
        setSpreaderSignalRConnectionId: (state, action: PayloadAction<string | undefined>) => {
            state.spreaderSignalRConnectionId = action.payload;
            return state;
        },
        setViewSpreadsOnly: (state, action: PayloadAction<boolean>) => {
            state.viewSpreadsOnly = action.payload;
            return state;
        },
    },
});

export const {
    setStatus,
    getSpreaderRiskSuccess,
    getSpreaderEntriesSuccess,
    getSpreaderFailed,
    increaseDecimalPosition,
    decreaseDecimalPosition,
    updateEntrySuccess,
    clearEntries,
    reset,
    setLastRiskRealTimeMessage,
    setLastSpreaderRealTimeMessage,
    setRiskSignalRGroupName,
    setSpreaderSignalRGroupName,
    setAutoUpdate,
    setIsRiskUpdateRequired,
    setIsEntriesUpdateRequired,
    setSpreaderSignalRConnectionId,
    setViewSpreadsOnly,
} = getSpreaderSlice.actions;

export const spreaderSelector = (state: RootState) => state.features.spreader;

export const productGroupsSelector = (state: RootState) => {
    const { risk } = state.features.spreader;
    const deskProductGroupNames = state.features.desks.productGroups?.map((dpg) => dpg.productGroup);
    const riskItemProductGroupNames = Object.keys(risk.productGroups).sort();

    if (!deskProductGroupNames) {
        return null;
    }

    return [
        ...deskProductGroupNames.filter((deskProductGroup) =>
            riskItemProductGroupNames.some((riskProductGroup) => riskProductGroup === deskProductGroup),
        ),
        ...riskItemProductGroupNames.filter(
            (riskProductGroup) =>
                !deskProductGroupNames.some((deskProductGroup) => deskProductGroup === riskProductGroup),
        ),
    ];
};

export const spreaderRiskTableSelector = (state: RootState) => {
    const { risk, entries } = state.features.spreader;
    const riskTable = createSpreaderRiskTable(risk, entries);

    // Initialise the cumulative total row
    const totalRow: any = { tenorPeriod: "Cumulative" };
    const firstRowIndexWhoseQtyIsGreaterThanOne = new Map<string, number>();

    // Loop through the table backwards
    for (let rowIndex = riskTable.length - 1; rowIndex >= 0; rowIndex--) {
        const spreaderRow: any = riskTable[rowIndex];

        // Iterate through the spreader rows to calculate the totals
        Object.keys(spreaderRow).forEach((columnName) => {
            if (columnName.endsWith("-spread")) {
                const productGroupId = columnName.substring(0, columnName.indexOf("-spread"));
                if (totalRow[columnName] === undefined) totalRow[columnName] = 0;

                if (
                    spreaderRow[productGroupId] &&
                    Math.abs(spreaderRow[productGroupId]) > 1 &&
                    firstRowIndexWhoseQtyIsGreaterThanOne.get(columnName) === undefined
                ) {
                    firstRowIndexWhoseQtyIsGreaterThanOne.set(columnName, rowIndex);
                }
                if (
                    firstRowIndexWhoseQtyIsGreaterThanOne.has(columnName) &&
                    rowIndex <= (firstRowIndexWhoseQtyIsGreaterThanOne.get(columnName) ?? 0)
                ) {
                    totalRow[columnName] = totalRow[columnName] + spreaderRow[columnName] ?? 0;
                }
            }

            if (columnName.endsWith("-qty")) {
                // Blue columns
                const columnId = columnName.replace("-qty", "");
                if (!totalRow.hasOwnProperty(columnId)) totalRow[columnId] = 0;

                totalRow[columnId] += spreaderRow[columnId];
            }
        });
    }

    return { riskTable, riskTotalsTable: [totalRow] };
};

const selectViewSpreadsOnly = (state: RootState) => state.features.spreader.viewSpreadsOnly;
export const spreaderViewSpreadsOnlySelector = createSelector(
    selectViewSpreadsOnly,
    (viewSpreadsOnly) => viewSpreadsOnly,
);

const selectLastUpdated = (state: RootState) => state.features.spreader.meta.lastUpdated;
export const lastUpdatedSelector = createSelector(selectLastUpdated, (lastUpdated) => lastUpdated);

const selectMeta = (state: RootState) => state.features.spreader.meta;
export const metaSelector = createSelector(selectMeta, (meta) => meta);
