import { RiskItemDto, TenorDateRiskItem } from "common/types";
import { cellShouldBeBlank } from "./riskHelperFunctions";

export const getProductGroupTotal = (
    riskItems: RiskItemDto[] | undefined,
    productGroupName: string,
    data: TenorDateRiskItem,
): Common.Nullable<number> => {
    if (!riskItems || cellShouldBeBlank(data)) return null;

    const { year, tenorDate, isGrandTotal, isGroupedByYear } = data;

    return riskItems.reduce((prev: Common.Nullable<number>, current) => {
        const tenorYear = current.tenorDate.substring(0, 4);
        if (
            current.productGroup !== productGroupName ||
            (!isGroupedByYear && current.tenorDate !== tenorDate && !isGrandTotal) ||
            (isGroupedByYear && year !== tenorYear && !isGrandTotal)
        ) {
            return prev;
        }

        return (prev ?? 0) + current.quantityBBL / 1000;
    }, null);
};
