import { Account } from "features/auth/authTypes";

export const clientId = process.env.CLIENT_ID!;
export const authority = process.env.AUTHORITY!;
export const apiBaseUrl = process.env.API_BASE_URL!;
export const audience = process.env.AUDIENCE!;
export const redirectUri = process.env.REDIRECT_URI!;
export const appInsightsConnectionString = process.env.APP_INSIGHTS_CONNECTION_STRING;
const environment = process.env.ENVIRONMENT!;
const monitoringExcludeAccounts = process.env.MONITORING_EXCLUDE_ACCOUNTS!;

export const isProduction = () => environment === "production";

export const ignoreMonitoringForAccount = ({ email }: Account) => {
    if (!monitoringExcludeAccounts) {
        return false;
    }

    return monitoringExcludeAccounts
        .split(",")
        .some((excludedEmail) => excludedEmail.toLocaleLowerCase() === email.toLocaleLowerCase());
};
