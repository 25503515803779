import { BlotterTrade } from "features/blotter/blotterTypes";
import { blotterProductGroupsSelector } from "features/blotterProductGroups/blotterProductGroupsSlice";
import { fetchBlotterProductGroups } from "features/blotterProductGroups/blotterProductGroupsThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const ProductGroupEditor = React.forwardRef((props: { value: string; data: BlotterTrade }, ref) => {
    const [value, setValue] = React.useState<string>(props.value || "");
    const { allBlotterGroups } = useSelector(blotterProductGroupsSelector);
    const dispatch = useDispatch();

    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value || null;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                const selectedId = allBlotterGroups?.find((g) => g.name === value)?.blotterProductGroupId;
                props.data.blotterProductGroupId = selectedId || null;
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    React.useEffect(() => {
        if (!allBlotterGroups) {
            dispatch(fetchBlotterProductGroups());
        }
    }, []);

    return (
        <span>
            <select
                data-testid="product-group-dropdown-editor"
                value={value}
                onChange={handleChange}
                className="w-full"
                ref={refInput}
            >
                <option value=""></option>
                {allBlotterGroups?.map((p) => (
                    <option key={p.blotterProductGroupId} value={p.name}>
                        {p.name}
                    </option>
                ))}
            </select>
        </span>
    );
});
