import classNames from "classnames";
import { Check } from "common/icons";
import * as React from "react";

type Props = {
    checked?: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
};

export const Checkbox: React.FC<Props> = ({ children, checked = false, disabled = false, onChange }) => {
    const [isChecked, setIsChecked] = React.useState(checked);

    const handleOnClick = () => {
        !disabled &&
            setIsChecked((checked) => {
                onChange(!checked);
                return !checked;
            });
    };

    return (
        <div
            data-testid="checkbox"
            className={classNames("flex items-center cursor-pointer", { "opacity-50": disabled })}
            onClick={handleOnClick}
        >
            <span
                className={classNames(
                    "flex items-center justify-center border border-1 mr-2 rounded bg-white-900  border-white-100  w-4 h-4",
                    { "bg-green-900": isChecked },
                )}
            >
                {isChecked && <Check />}
            </span>
            <input
                type="checkbox"
                checked={isChecked}
                className="hidden"
                readOnly
                data-testid="checkbox-input"
                disabled={disabled}
            ></input>
            {children}
        </div>
    );
};
