export const productValueGetter = (productsByProductGroup: any, productGroup: string, product: string, data: any) => {
    if (data.isGrandTotal) {
        return Object.keys((productsByProductGroup as any)[productGroup][product]).reduce(
            (prev, riskExpiryDateTime) => {
                prev = (prev ?? 0) + (productsByProductGroup as any)[productGroup][product][riskExpiryDateTime];
                return prev;
            },
            null,
        );
    }

    if (data.isGroupedByYear) {
        return Object.keys((productsByProductGroup as any)[productGroup][product]).reduce(
            (prev, riskExpiryDateTime) => {
                if (data.year === riskExpiryDateTime.substr(0, 4)) {
                    prev = (prev ?? 0) + (productsByProductGroup as any)[productGroup][product][riskExpiryDateTime];
                }
                return prev;
            },
            null,
        );
    }

    return (productsByProductGroup as any)[productGroup][product][data.riskExpiryDateTime] || null;
};
