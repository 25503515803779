import { FormLabel } from "common/components/form";
import { Typeahead } from "common/components/typeahead";
import {
    blotterEntryProductGroupSelector,
    blotterEntrySideSelector,
    setProductGroupText,
    updateBlotterEntry,
} from "features/blotter/blotterSlice";
import { blotterProductGroupsSelector } from "features/blotterProductGroups/blotterProductGroupsSlice";
import { fetchBlotterProductGroups } from "features/blotterProductGroups/blotterProductGroupsThunks";
import { selectedDeskSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlotterEntryFormButton } from "./blotterEntryFormButton";

export const ProductGroup: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = React.useState<Common.Nullable<number>>(null);
    const selectedDesk = useSelector(selectedDeskSelector);

    const { allBlotterGroups, deskBlotterGroups, meta } = useSelector(blotterProductGroupsSelector);
    const { blotterProductGroupId, productGroupText } = useSelector(blotterEntryProductGroupSelector);
    const side = useSelector(blotterEntrySideSelector);

    React.useEffect(() => {
        if (selectedDesk) {
            dispatch(fetchBlotterProductGroups(selectedDesk.topLevelDeskId || selectedDesk.deskId));
        }
    }, [selectedDesk]);

    // Keep track of the currently selected blotter product group id
    React.useEffect(() => {
        setSelectedId(blotterProductGroupId);
    }, [blotterProductGroupId]);

    // Handle when a blotter group button is selected
    const handleButtonSelect = (id: number) => {
        dispatch(setProductGroupText(""));
        dispatch(
            updateBlotterEntry({
                blotterProductGroupId: blotterProductGroupId === id ? null : id,
            }),
        );
    };

    // Handle when an item is selected from the typeahead dropdown
    const handleTypeaheadSelect = (selectedItem: string) => {
        dispatch(setProductGroupText(selectedItem));
        const blotterGroup = allBlotterGroups?.find(({ name }) => name.toLowerCase() === selectedItem.toLowerCase());
        const blotterProductGroupId = blotterGroup ? blotterGroup.blotterProductGroupId : null;
        dispatch(
            updateBlotterEntry({
                blotterProductGroupId,
            }),
        );
        setSelectedId(blotterProductGroupId);
    };

    const data = !allBlotterGroups
        ? []
        : allBlotterGroups
              .map((b) => ({ id: b.blotterProductGroupId, name: b.name }))
              .filter(
                  ({ name }) =>
                      productGroupText === "" || name.toLowerCase().indexOf(productGroupText.toLowerCase()) >= 0,
              );

    const handleSetText = (selectedText: string) => {
        dispatch(setProductGroupText(selectedText));
    };

    return (
        <div data-testid="product-panel" className="flex flex-col blotterEntryForm__spacing mt-4">
            <div className="flex justify-center">
                <FormLabel>Product</FormLabel>
            </div>
            {meta.status === "loaded" && (
                <>
                    <div className="blotterEntryForm__productGroup flex flex-wrap">
                        {Array.isArray(deskBlotterGroups) &&
                            deskBlotterGroups.slice(0, 27).map(({ blotterProductGroupId: id, name }) => (
                                <BlotterEntryFormButton
                                    side={side}
                                    key={`button${id}`}
                                    className="w-32"
                                    selected={blotterProductGroupId === id}
                                    onClick={() => void handleButtonSelect(id)}
                                >
                                    {name}
                                </BlotterEntryFormButton>
                            ))}
                    </div>
                    <div className="mt-2">
                        <Typeahead
                            key={selectedId || undefined}
                            data={data}
                            onSelect={handleTypeaheadSelect}
                            error={
                                productGroupText !== "" &&
                                !allBlotterGroups?.some(
                                    ({ name }) => name.toLowerCase() === productGroupText.toLowerCase(),
                                )
                            }
                            text={productGroupText}
                            setText={handleSetText}
                            className="w-1/2"
                        />
                    </div>
                </>
            )}
            {meta.status === "error" && <span className="text-xs mt-2 text-center">Error</span>}
        </div>
    );
};
