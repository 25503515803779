import { CellClassParams, ValueGetterParams } from "@ag-grid-community/core";
import { ColumnParams } from "./riskSummaryItemTypes";

export const getColumnDefs = (params: ColumnParams): any[] => {
    const { hideQuantityKt, valueFormatter } = params;

    const columnnDefs = [
        {
            field: "riskSummaryItemName",
            width: 150,
            headerName: "Risk Summary",
        },
        {
            headerName: "Quantity KB",
            type: "numericColumn",
            width: 98,
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => data.quantityInKB,
        },
    ];

    if (!hideQuantityKt) {
        columnnDefs.push({
            headerName: "Quantity KT",
            type: "numericColumn",
            width: 100,
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => data.quantityInKT ?? 0,
        });
    }

    return columnnDefs;
};
