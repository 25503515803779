import { BlotterTrade } from "features/blotter/blotterTypes";
import { exchangesSelector, statusSelector } from "features/exchanges/exchangesSlice";
import { fetchExchanges } from "features/exchanges/exchangesThunks";
import { ExchangeDto } from "features/exchanges/types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const ExchangeDropdownEditor = React.forwardRef((props: { value: string; data: BlotterTrade }, ref) => {
    const exchanges = useSelector(exchangesSelector);
    const status = useSelector(statusSelector);
    const [value, setValue] = React.useState(props.value || "");
    const [option, setOption] = React.useState<ExchangeDto | null>(null);

    const dispatch = useDispatch();
    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useEffect(() => {
        if (status === "init") {
            dispatch(fetchExchanges());
        }
    }, [status]);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value || null;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                props.data.exchangeId = option?.exchangeId || null;
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        const selectedOption = exchanges?.find((t) => t.name === value);
        setOption(selectedOption || null);
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    return (
        <select
            data-testid="exchange-dropdown-editor"
            onChange={handleChange}
            className="w-full"
            ref={refInput}
            value={value}
        >
            <option value=""></option>
            {exchanges?.map((t) => (
                <option key={t.exchangeId} value={t.name}>
                    {t.name}
                </option>
            ))}
        </select>
    );
});
