import { ROLL_OFF_RISK_TYPE_BLOTTER, ROLL_OFF_RISK_TYPE_EXCHANGE } from "common/constants";
import { useCustomLocationProperties } from "common/hooks";
import { getPageTitle } from "common/utils/getPageTitle";
import { toTitleCase } from "common/utils/toTitleCase";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import { fetchOnReconnectSelector } from "features/realTime/realTimeSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRollOffRiskHubConnection } from "./hooks/useRollOffRiskHubConnection";
import { RollOffRiskGrid } from "./rollOffRiskGrid";
import { initialState, rollOffRiskSelector, setIsRiskUpdateRequired } from "./rollOffRiskSlice";
import { fetchRollOffRisk, refetchOnReconnect, refetchRollOffRisk } from "./rollOffRiskThunks";

export const RollOffRisk: React.FC = () => {
    const dispatch = useDispatch();
    const { selectedDeskId } = useSelector(desksSelector);
    const {
        meta,
        signalRGroupName,
        lastRealTimeMessage,
        includeFutures,
        isRiskUpdateRequired,
        timespanDays,
        defaultTimespanDays,
    } = useSelector(rollOffRiskSelector);
    const fetchOnReconnect = useSelector(fetchOnReconnectSelector);
    const { shortPath } = useCustomLocationProperties();
    const selectedDesk = useSelector(selectedDeskSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const fetchRisk = (timespanDays?: number, defaultTimespanDays?: number) => {
        if (deskHasBeenSet && [ROLL_OFF_RISK_TYPE_BLOTTER, ROLL_OFF_RISK_TYPE_EXCHANGE].some((p) => p === shortPath)) {
            dispatch(
                fetchRollOffRisk(
                    shortPath,
                    selectedDeskId!,
                    includeFutures,
                    timespanDays || initialState.timespanDays,
                    defaultTimespanDays || initialState.defaultTimespanDays,
                ),
            );
        }
    };

    React.useEffect(() => {
        fetchRisk();
    }, [selectedDeskId]);

    useRollOffRiskHubConnection(selectedDesk?.topLevelDeskId, signalRGroupName);

    React.useEffect(() => {
        document.title = getPageTitle(`${toTitleCase(shortPath)} Roll Off Risk`, selectedDesk);
    }, [selectedDesk, shortPath]);

    React.useEffect(() => {
        fetchRisk(timespanDays, defaultTimespanDays);
    }, [shortPath, includeFutures]);
    React.useEffect(() => {
        if (
            !lastRealTimeMessage ||
            selectedDesk === undefined ||
            ![ROLL_OFF_RISK_TYPE_BLOTTER, ROLL_OFF_RISK_TYPE_EXCHANGE].some((p) => p === shortPath)
        ) {
            return;
        }

        if (meta.status !== "loading" && meta.status !== "refetching") {
            dispatch(refetchRollOffRisk(lastRealTimeMessage, shortPath, selectedDesk, includeFutures, timespanDays));
        } else {
            dispatch(setIsRiskUpdateRequired(true));
        }
    }, [lastRealTimeMessage]);

    React.useEffect(() => {
        if (
            !lastRealTimeMessage ||
            selectedDesk === undefined ||
            ![ROLL_OFF_RISK_TYPE_BLOTTER, ROLL_OFF_RISK_TYPE_EXCHANGE].some((p) => p === shortPath) ||
            !meta.status
        ) {
            return;
        }

        if (meta.status !== "loading" && meta.status !== "refetching" && isRiskUpdateRequired) {
            dispatch(refetchRollOffRisk(lastRealTimeMessage, shortPath, selectedDesk, includeFutures, timespanDays));
        }
    }, [meta.status]);

    React.useEffect(() => {
        if (
            fetchOnReconnect &&
            deskHasBeenSet &&
            [ROLL_OFF_RISK_TYPE_BLOTTER, ROLL_OFF_RISK_TYPE_EXCHANGE].some((p) => p === shortPath)
        ) {
            dispatch(refetchOnReconnect(shortPath, selectedDesk!, includeFutures, timespanDays));
        }
    }, [fetchOnReconnect]);

    return (
        <div className="flex flex-col flex-1 p-6 overflow-auto" data-testid="roll-off-risk-container">
            <div className="flex flex-1 flex-col">
                <RollOffRiskGrid />
            </div>
        </div>
    );
};
