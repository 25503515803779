import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { BlotterProductGroupsApiResponse, State } from "./types";

const initialState: State = {
    deskBlotterGroups: undefined,
    allBlotterGroups: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getBlotterProductGroupsSlice = createSlice({
    name: "blotterProductGroups",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<{ status: Common.Status }>) => {
            state.meta.status = action.payload.status;
            return state;
        },
        getBlotterProductGroupsSuccess: (
            state,
            action: PayloadAction<{ deskId?: number; data: BlotterProductGroupsApiResponse }>,
        ) => {
            if (action.payload.deskId === undefined) {
                state.allBlotterGroups = action.payload.data.blotterProductGroups;
            } else {
                state.deskBlotterGroups = action.payload.data.blotterProductGroups;
            }

            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getBlotterProductGroupsFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
    },
});

export const { setStatus, getBlotterProductGroupsSuccess, getBlotterProductGroupsFailed } =
    getBlotterProductGroupsSlice.actions;

const selectBlotterProductGroups = (state: RootState) => state.features.blotterProductGroups;

export const blotterProductGroupsSelector = createSelector(
    selectBlotterProductGroups,
    (blotterProductGroups) => blotterProductGroups,
);
