import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LiveRiskRealTimeMessage, TenorDateRiskItem } from "common/types";
import { RootState } from "core/store";
import { RollOffRiskResponseDto, State } from "./rollOffRiskTypes";
import { format, parseISO } from "date-fns";
import _ from "lodash";

export const initialState: State = {
    decimalPosition: 0,
    groupedYears: [],
    riskItems: undefined,
    defaultTimespanDays: 31,
    timespanDays: 31,
    includeFutures: false,
    lastRealTimeMessage: undefined,
    signalRGroupName: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
    isRiskUpdateRequired: false,
};

export const getRollOffRisksSlice = createSlice({
    name: "rollOffRisk",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        setIncludeFutures: (state, action: PayloadAction<boolean>) => {
            state.includeFutures = action.payload;
            return state;
        },
        getRollOffRiskSuccess: (
            state,
            action: PayloadAction<{
                response: RollOffRiskResponseDto;
                timespanDays?: number;
                defaultTimespanDays?: number;
            }>,
        ) => {
            state.riskItems = action.payload.response.riskItems;
            if (action.payload.timespanDays) state.timespanDays = action.payload.timespanDays;
            if (action.payload.defaultTimespanDays) state.defaultTimespanDays = action.payload.defaultTimespanDays;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            state.groupedYears = [];
            return state;
        },
        getRollOffRiskFailed: (state, action: PayloadAction<string>) => {
            state.meta.message = action.payload;
            state.meta.lastUpdated = Date.now();
            state.meta.status = "error";
            state.groupedYears = [];
            return state;
        },
        increaseDecimalPosition: (state) => {
            if (state.decimalPosition === 4) return state;
            state.decimalPosition += 1;
            return state;
        },
        decreaseDecimalPosition: (state) => {
            if (state.decimalPosition === 0) return state;
            state.decimalPosition -= 1;
            return state;
        },
        toggleGroupedYear: (state, action: PayloadAction<string>) => {
            if (state.groupedYears.some((y) => y === action.payload)) {
                state.groupedYears = [...state.groupedYears.filter((y) => y !== action.payload)];
            } else {
                state.groupedYears = [...state.groupedYears, action.payload];
            }
            return state;
        },
        setLastRealTimeMessage: (state, action: PayloadAction<LiveRiskRealTimeMessage>) => {
            state.lastRealTimeMessage = action.payload;
            return state;
        },
        setSignalRGroupName: (state, action: PayloadAction<string | undefined>) => {
            state.signalRGroupName = action.payload;
            return state;
        },
        resetTimespanDays: (state) => {
            state.timespanDays = initialState.timespanDays;
            state.defaultTimespanDays = initialState.defaultTimespanDays;
            return state;
        },
        setIsRiskUpdateRequired: (state, action: PayloadAction<boolean>) => {
            state.isRiskUpdateRequired = action.payload;
            return state;
        },
    },
});

export const {
    setStatus,
    setIncludeFutures,
    getRollOffRiskSuccess,
    getRollOffRiskFailed,
    toggleGroupedYear,
    increaseDecimalPosition,
    decreaseDecimalPosition,
    setLastRealTimeMessage,
    setSignalRGroupName,
    resetTimespanDays,
    setIsRiskUpdateRequired,
} = getRollOffRisksSlice.actions;

const selectLastUpdated = (state: RootState) => state.features.rollOffRisk.meta.lastUpdated;
export const lastUpdatedSelector = createSelector(selectLastUpdated, (lastUpdated) => lastUpdated);

export const rollOffRiskSelector = (rootState: RootState) => rootState.features.rollOffRisk;

export const productGroupsSelector = (state: RootState) => {
    const deskProductGroupNames = state.features.desks.productGroups?.map((dpg) => dpg.productGroup);
    const riskItemProductGroups = riskItemsByGroupSelector(state);
    const riskItemProductGroupNames = Object.keys(riskItemProductGroups).sort();

    if (!deskProductGroupNames) {
        return null;
    }

    return [
        ...deskProductGroupNames.filter((deskProductGroup) =>
            riskItemProductGroupNames.some((riskProductGroup) => riskProductGroup === deskProductGroup),
        ),
        ...riskItemProductGroupNames.filter(
            (riskProductGroup) =>
                !deskProductGroupNames.some((deskProductGroup) => deskProductGroup === riskProductGroup),
        ),
    ];
};

export const riskItemsByGroupSelector = (state: RootState) => {
    const riskItems = state.features.rollOffRisk.riskItems;
    return _.mapValues(
        _.groupBy(riskItems, (i) => i.productGroup),

        (app) =>
            _.mapValues(
                _.groupBy(app, (i) => i.productName),

                (app) =>
                    _.mapValues(
                        _.groupBy(app, (i) => i.diffProductName),

                        (app) => _.groupBy(app, (i) => i.tenorPeriod),
                    ),
            ),
    );
};

export const riskItemsByExpirySelector = (state: RootState) => {
    const riskItems = state.features.rollOffRisk.riskItems;
    const groupedYears = state.features.rollOffRisk.groupedYears;

    if (!riskItems) return [];

    // Get a unique list of diff product names
    const uniqueDiffProductNames = _.uniq(riskItems.map((r) => r.diffProductName));

    // Create an object with all the product names and amounts as key/value pairs for rendering on the grid
    const diffProductObject = uniqueDiffProductNames.reduce((prev, current) => {
        return { ...prev, [current]: 0 };
    }, {});

    // Create an object per tenor date / or year for groupings
    const groupedByYears: string[] = [];
    const newRiskItemsByExpiry = _.uniq(riskItems.map((r) => r.riskExpiryDateTime))
        .map((riskExpiryDateTime) => {
            const year = format(parseISO(riskExpiryDateTime), "yyyy");
            const isGroupedByYear = _.includes(groupedYears, year);

            if (isGroupedByYear) {
                if (_.includes(groupedByYears, year)) {
                    return null;
                }
                groupedByYears.push(year);
                return {
                    riskExpiryDateTime: year,
                    tenorDate: year,
                    year,
                    isGroupedByYear,
                    isGrandTotal: false,
                    exchange: (riskItems && riskItems[0] && riskItems[0].exchange) || null,
                    isOverallGrandTotal: false,
                    ...diffProductObject,
                };
            }

            return {
                riskExpiryDateTime,
                year: "",
                isGroupedByYear: false,
                isGrandTotal: false,
                ...diffProductObject,
            };
        })
        .filter(Boolean) as TenorDateRiskItem[];

    // Populate only the first row with the year for row spanning
    const rowSpanYears: string[] = [];
    return newRiskItemsByExpiry.map((item) => {
        const year = format(parseISO(item.riskExpiryDateTime), "yyyy");
        if (rowSpanYears.some((y) => y === year)) {
            return { ...item };
        }
        rowSpanYears.push(year);
        return { ...item, year };
    });
};

export const productsByProductGroupSelector = (state: RootState) => {
    const riskItems = state.features.rollOffRisk.riskItems;
    if (!riskItems) return {};

    return riskItems.reduce((prev, current) => {
        const newValue: any = { ...prev };
        newValue[current.productGroup] = newValue[current.productGroup] || {};

        newValue[current.productGroup][current.productName] = newValue[current.productGroup][current.productName] || {};

        newValue[current.productGroup][current.productName][current.riskExpiryDateTime] =
            newValue[current.productGroup][current.productName][current.riskExpiryDateTime] || 0;

        newValue[current.productGroup][current.productName][current.riskExpiryDateTime] += current.quantityBBL / 1000;

        return newValue;
    }, {});
};

export const productDiffsByProductGroupSelector = (state: RootState) => {
    const riskItems = state.features.rollOffRisk.riskItems;
    if (!riskItems) return {};

    return riskItems.reduce((prev, current) => {
        const newValue: any = { ...prev };
        newValue[current.productGroup] = newValue[current.productGroup] || {};

        newValue[current.productGroup][current.productName] = newValue[current.productGroup][current.productName] || {};

        newValue[current.productGroup][current.productName][current.diffProductName] =
            newValue[current.productGroup][current.productName][current.diffProductName] || {};

        newValue[current.productGroup][current.productName][current.diffProductName][current.riskExpiryDateTime] =
            newValue[current.productGroup][current.productName][current.diffProductName][current.riskExpiryDateTime] ||
            0;

        newValue[current.productGroup][current.productName][current.diffProductName][current.riskExpiryDateTime] +=
            current.quantityBBL / 1000;

        return newValue;
    }, {});
};
