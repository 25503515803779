import * as React from "react";
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";
import "@reach/combobox/styles.css";
import "./typeahead.styles.scss";
import classNames from "classnames";

type Props = {
    text: string;
    setText(text: string): void;
    data: { id: number; name: string }[];
    error?: boolean;
    onSelect(selectedItem: string): void;
    className?: string;
    portal?: boolean;
    hidePopover?: boolean;
    id?: string;
};

export const Typeahead: React.FC<Props> = ({
    text,
    setText,
    data,
    onSelect,
    error,
    className,
    portal = true,
    hidePopover = false,
    id = undefined,
}) => {
    const useFocus = () => {
        const refInput = React.useRef<HTMLInputElement>(null);
        const setFocus = () => {
            if (id) {
                setTimeout(() => document.getElementById(id)?.focus());
            }
        };

        return [refInput, setFocus] as const;
    };

    const [refInput, setFocus] = useFocus();

    const handleSearchTermChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText && setText(e.currentTarget.value);
        setFocus();
    };

    return (
        <Combobox
            aria-label="Product Groups"
            className="flex justify-center pb-4"
            onSelect={(val) => {
                onSelect(val);
                setFocus();
            }}
        >
            <ComboboxInput
                id={id}
                ref={refInput}
                data-testid="typeahead-input"
                selectOnClick
                className={classNames(
                    "border border-white-200 text-xs p-1 rounded-lg shadow-lg outline-none",
                    { "border-red-500": error },
                    className,
                )}
                onChange={handleSearchTermChange}
                value={text}
                autoComplete="off"
            />
            <ComboboxPopover
                className={classNames(
                    "typeahead__dropdown border-0 shadow-lg rounded-lg bg-white-900 text-gray-900",
                    hidePopover ? "hide" : "",
                )}
                portal={portal}
            >
                {data.length > 0 ? (
                    <ComboboxList data-testid="typeahead-options">
                        {data.map(({ id, name }) => (
                            <ComboboxOption
                                className="p-2 hover:bg-green-900 hover:text-white-500"
                                key={id}
                                value={name}
                            />
                        ))}
                    </ComboboxList>
                ) : (
                    <div className="m-2">No results found</div>
                )}
            </ComboboxPopover>
        </Combobox>
    );
};
