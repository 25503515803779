import { HeaderLogo } from "features/navigation/components";
import * as React from "react";
import "./pages.styles.css";

export const AccessDeniedPage = () => {
    return (
        <div className="flex w-full flex-1 justify-center items-center flex-col">
            <HeaderLogo />
            <div
                data-testid="accessdenied-content"
                className="page__contentBox p-6 rounded-lg mt-8 text-center text-2xl bg-white-500"
            >
                <span data-testid="accessdenied-text" className="uppercase font-bold ">
                    Access denied
                </span>
                <p>You don't have permission, please contact tech support</p>
            </div>
        </div>
    );
};
