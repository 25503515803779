import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { BlotterProductGroupsApiResponse } from "features/blotterProductGroups/types";

export const getBlotterProductGroups = async (deskId?: number) => {
    const token = await getToken();

    const response = await axios.get<BlotterProductGroupsApiResponse>(
        `${apiBaseUrl}/api/blotterproductgroups/${deskId || ""}`,
        {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        },
    );

    return response.data;
};
