import { CellClassParams, ValueGetterParams } from "@ag-grid-community/core";
import { format, parseISO } from "date-fns";
import { ColumnParams } from "./rollOffRiskTypes";

export const getColumnDefs = (params: ColumnParams): any[] => {
    const {
        productGroups,
        getProductGroupColumnSettings,
        riskItemsByGroup,
        getProductColumnSettings,
        valueFormatter,
        getProductGroupTotalForRollOffRisk,
        riskItems,
        productValueGetter,
        productsByProductGroup,
        getDiffProductColumnSettings,
        tenorProductValueGetter,
        productDiffsByProductGroup,
        columnState,
        grandTotalColumnWidth,
        getTenorGrandTotal,
    } = params;

    return [
        {
            field: "riskExpiryDateTime",
            filter: false,
            sortable: false,
            resizable: true,
            type: "numericColumn",
            cellClass: "font-bold text-right",
            minWidth: 140,
            maxWidth: 140,
            headerName: "Expiry",
            valueGetter: ({ data: { year, riskExpiryDateTime, isGrandTotal, isGroupedByYear } }: ValueGetterParams) => {
                if (isGroupedByYear) return year;
                return isGrandTotal ? "" : format(parseISO(riskExpiryDateTime), "dd/MM/yyyy HH:mm");
            },
        },
        ...productGroups.map((productGroup) => {
            const productGroupColumnSettings = getProductGroupColumnSettings(productGroup);
            return {
                headerName: productGroup,
                groupId: productGroup,
                openByDefault: productGroupColumnSettings.expanded,
                children: [
                    {
                        refData: {
                            level: "0",
                            productGroup,
                        },
                        columnGroupShow: "closed",
                        type: "numericColumn",
                        headerName: "",
                        cellClassRules: {
                            "text-red-600": (params: CellClassParams) => params.value < 0,
                        },
                        width: productGroupColumnSettings.width,
                        minWidth: productGroupColumnSettings.minWidth,
                        valueFormatter: valueFormatter,
                        valueGetter: ({ data }: ValueGetterParams) =>
                            getProductGroupTotalForRollOffRisk(riskItems, productGroup, data),
                    },
                    ...Object.keys(riskItemsByGroup[productGroup]).map((product) => {
                        const productColumnSettings = getProductColumnSettings(productGroup, product);
                        let productSymbol =
                            Object.values(Object.values(riskItemsByGroup[productGroup][product])?.[0])?.[0]?.[0]
                                ?.symbol || null;

                        productSymbol = productSymbol ? ` (${productSymbol})` : "";
                        return {
                            headerName: `${product}${productSymbol}`,
                            columnGroupShow: "open",
                            groupId: `${productGroup}-${product}`,
                            openByDefault: productColumnSettings.expanded,
                            children: [
                                {
                                    refData: {
                                        level: "1",
                                        productGroup,
                                        product: `${product}${productSymbol}`,
                                    },
                                    width: productColumnSettings.width,
                                    minWidth: productColumnSettings.minWidth,
                                    headerName: "",
                                    type: "numericColumn",
                                    columnGroupShow: "closed",
                                    cellClassRules: {
                                        "text-red-600": (params: CellClassParams) => params.value < 0,
                                    },
                                    valueFormatter: valueFormatter,
                                    valueGetter: ({ data }: ValueGetterParams) =>
                                        productValueGetter(productsByProductGroup, productGroup, product, data),
                                },
                                ...Object.keys(riskItemsByGroup[productGroup][product]).map((diffProduct) => {
                                    const diffProductColumnSettings = getDiffProductColumnSettings(
                                        productGroup,
                                        product,
                                        diffProduct,
                                    );
                                    const diffProductObject = riskItemsByGroup[productGroup][product][diffProduct];
                                    let diffSymbol =
                                        diffProductObject[Object.keys(diffProductObject)?.[0]]?.[0]?.diffSymbol || null;

                                    diffSymbol = diffSymbol ? ` (${diffSymbol})` : "";
                                    return {
                                        headerName: `${diffProduct}${diffSymbol}`,
                                        columnGroupShow: "open",
                                        groupId: `${productGroup}-${product}-${diffProduct}`,
                                        openByDefault: diffProductColumnSettings.expanded,
                                        children: Object.keys(riskItemsByGroup[productGroup][product][diffProduct]).map(
                                            (tenorDate) => ({
                                                refData: {
                                                    level: "3",
                                                    productGroup,
                                                    product: `${product}${productSymbol}`,
                                                    diffProduct: `${diffProduct}${diffSymbol}`,
                                                    tenorDate,
                                                },
                                                width: diffProductColumnSettings.width,
                                                minWidth: diffProductColumnSettings.minWidth,
                                                type: "numericColumn",
                                                columnGroupShow: "open",
                                                headerName: tenorDate,
                                                cellClassRules: {
                                                    "text-red-600": (params: CellClassParams) => params.value < 0,
                                                },
                                                valueFormatter: valueFormatter,
                                                valueGetter: ({ data }: ValueGetterParams) =>
                                                    tenorProductValueGetter(
                                                        riskItemsByGroup[productGroup][product][diffProduct],
                                                        productDiffsByProductGroup,
                                                        productGroup,
                                                        product,
                                                        diffProduct,
                                                        tenorDate,
                                                        data,
                                                    ),
                                            }),
                                        ),
                                    };
                                }),
                            ],
                        };
                    }),
                ],
            };
        }),

        {
            columnGroupShow: "closed",
            refData: {
                level: "3",
            },
            headerName: `Grand Total`,
            cellClass: "text-right font-bold bg-white-500",
            type: "numericColumn",
            resizable: true,
            pinned: "right",
            width: columnState && columnState["grand-total"] ? columnState["grand-total"].width : grandTotalColumnWidth,
            minWidth: 100,
            cellClassRules: {
                "text-red-600": (params: CellClassParams) => params.value < 0,
                "text-3xl": (params: CellClassParams) => params.node.isRowPinned(),
            },
            valueFormatter: valueFormatter,
            valueGetter: ({ data }: ValueGetterParams) => getTenorGrandTotal(riskItems, data) ?? 0,
        },
    ];
};
