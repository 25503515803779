import axios from "axios";
import { UI_VERSION_RESPONSE_HEADER } from "common/constants";
import { store } from "core/store";
import { setApplicationVersion } from "features/application/applicationSlice";

export const registerAxiosInterceptors = () => {
    axios.interceptors.response.use((response) => {
        const uiVersion = response.headers[UI_VERSION_RESPONSE_HEADER];
        store.dispatch(setApplicationVersion(uiVersion));
        return response;
    });
};
