import * as React from "react";
import { tenorYears } from "common/constants";

export const TenorYearDropdownEditor = React.forwardRef((props: { value: string }, ref) => {
    const [value, setValue] = React.useState(props.value || "");
    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value || null;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    return (
        <select
            data-testid="tenor-year-dropdown-editor"
            onChange={handleChange}
            className="w-full"
            ref={refInput}
            value={value}
        >
            <option value=""></option>
            {tenorYears.map((year) => (
                <option key={year} value={year}>
                    {year}
                </option>
            ))}
        </select>
    );
});
