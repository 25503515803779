import { useCustomLocationProperties } from "common/hooks";
import { getPageTitle } from "common/utils/getPageTitle";
import { selectedDeskSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { WeeklySpreaderGrid } from "./weeklySpreaderGrid";

import { WeeklySpreaderUpdateNotification } from "./weeklySpreaderUpdateNotification";

type Props = {
    match?: any;
};

export const WeeklySpreader: React.FC<Props> = () => {
    const selectedDesk = useSelector(selectedDeskSelector);
    const history = useHistory();

    const { isPopupWindow } = useCustomLocationProperties();

    React.useEffect(() => {
        document.title = getPageTitle("Weekly Spreader", selectedDesk);
    }, [selectedDesk]);

    React.useEffect(() => {
        history.push(`${isPopupWindow ? "/window" : ""}${"/spreader/weekly"}`);
    }, [isPopupWindow]);

    return (
        <div className="flex flex-1 flex-col p-6 overflow-auto">
            <WeeklySpreaderGrid />
            {<WeeklySpreaderUpdateNotification />}
        </div>
    );
};
