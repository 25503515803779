import { ColumnResizedEvent } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { desksSelector } from "features/desks/desksSlice";
import includes from "lodash/includes";
import * as React from "react";
import { useSelector } from "react-redux";
import { pnlItemRowsSelector, pnlIncludeYearToDateSelector } from "./pnlSlice";
import "./pnlStyles.scss";

export const useGridState = () => {
    const [gridWidth, setGridWidth] = React.useState<number>(0);
    const [columnState, setColumnState] = React.useState<any>(null);
    const [columnDefs, setColumnDefs] = React.useState<any>(null);
    const [pnlHierachy, setPnlHierachy] = React.useState<any>(null);
    const { selectedDeskId } = useSelector(desksSelector);
    const pnlItemRows = useSelector(pnlItemRowsSelector);
    const includeYearToDatePnl = useSelector(pnlIncludeYearToDateSelector);
    const minWidth = 910;
    const gridRef = React.useRef<AgGridReact>(null);

    const defaultColumnWidth = 100;
    const defaultMinColumnWidth = 80;
    const resizableColumnIds = [
        "ag-Grid-AutoColumn",
        "deskNumber",
        "dayPnl",
        "overnightPnl",
        "brokerage",
        "abnTradeFees",
        "exchangeTradeFees",
        "netPnl",
        "yearToDatePnl",
    ];

    const resizeGrid = (colState?: any) => {
        const myState = colState ?? columnState;
        if (myState) {
            setGridWidth((_prevState) => {
                let width = 0;

                resizableColumnIds.forEach((columnId) => {
                    const hiddenYearToDatePnlColumn = columnId === "yearToDatePnl" && !includeYearToDatePnl;

                    if (!hiddenYearToDatePnlColumn && myState[columnId]) {
                        width += myState[columnId];
                    }
                });
                return width + 2;
            });
        }
    };

    React.useEffect(() => {
        const { api, columnApi } = gridRef.current || {};

        if (columnState && api && columnApi) {
            resizeGrid();
        }
    }, [columnState, gridRef, includeYearToDatePnl]);

    React.useEffect(() => {
        if (columnState === null || columnState.deskId !== selectedDeskId) {
            const newColumnState: any = {
                deskId: selectedDeskId,
                "ag-Grid-AutoColumn": 250,
                deskNumber: 100,
                dayPnl: 90,
                overnightPnl: 110,
                brokerage: 80,
                abnTradeFees: 80,
                exchangeTradeFees: 110,
                netPnl: 90,
                yearToDatePnl: 120,
            };

            setColumnState(newColumnState);
        }
    }, [selectedDeskId, pnlItemRows]);

    const handleColumnResize = (event: ColumnResizedEvent) => {
        const { finished, columns } = event;
        if (finished && columns) {
            const newState = { ...columnState };

            for (const column of columns) {
                const width = column!.getActualWidth();
                const colId = column.getColId();

                if (colId && includes(resizableColumnIds, colId)) {
                    newState[colId] = width;
                }
            }

            setColumnState(newState);
            resizeGrid(newState);
        }
    };

    return {
        gridWidth,
        setGridWidth,
        handleColumnResize,
        defaultColumnWidth,
        defaultMinColumnWidth,
        columnState,
        setColumnState,
        minWidth,
        columnDefs,
        setColumnDefs,
        gridRef,
        pnlHierachy,
        setPnlHierachy,
        includeYearToDatePnl,
    };
};
