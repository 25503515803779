import {
    NewValueParams,
    ValueFormatterParams,
    RangeSelectionChangedEvent,
    CellRange,
    GridApi,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import Big from "big.js";
import classNames from "classnames";
import { GridLoading } from "common/components/grid";
import { preserveSelectionOnRender } from "common/components/grid/gridHelpers";
import { populateAverageAndSumValues } from "common/utils/cellCalculations";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import { isStringNumeric } from "common/utils/isStringNumeric";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import { fetchOnReconnectSelector } from "features/realTime/realTimeSlice";
import isFinite from "lodash/isFinite";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnWeeklySpreaderDefs } from "./columnWeeklySpreaderDefs";
import { useLiveRiskHubConnection } from "../hooks/useLiveRiskHubConnection";
import { useSpreaderEntryHubConnection } from "../hooks/useSpreaderEntryHubConnection";
import "../spreader.styles.scss";
import { WeeklySpreaderGridHeader } from "./weeklySpreaderGridHeader";
import {
    productGroupsSelector,
    setAutoUpdate,
    setIsEntriesUpdateRequired,
    setIsRiskUpdateRequired,
    weeklySpreaderRiskTableSelector,
    weeklySpreaderSelector,
} from "./weeklySpreaderSlice";
import {
    autoFlattenEntries,
    clearAll,
    deleteSpreaderDummyEntry,
    fetchWeeklySpreaderEntriesAndRisk,
    fetchWeeklySpreaderRisk,
    refetchOnReconnect,
    refetchWeeklySpreaderEntries,
    refetchWeeklySpreaderRisk,
    updateSpreaderDummyEntry,
} from "./weeklySpreaderThunks";
import { useWeeklyGridState } from "./useWeeklyGridState";
import { weeklyAutoFlatten } from "../utils";
import { WEEKLY_SPREADER_VIEW } from "../utils/constants";

/* istanbul ignore next */
export const WeeklySpreaderGrid: React.FC = () => {
    const dispatch = useDispatch();

    const {
        gridRef,
        gridWidth,
        handleColumnResize,
        columnState,
        defaultColumnWidth,
        tenorColumnWidth,
        setColumnDefs,
        columnDefs,
        setColumnState,
    } = useWeeklyGridState();

    const {
        meta,
        autoUpdate,
        risk,
        decimalPosition,
        spreaderSignalRGroupName,
        riskSignalRGroupName,
        lastRiskRealTimeMessage,
        lastSpreaderRealTimeMessage,
        isEntriesUpdateRequired,
        isRiskUpdateRequired,
        viewSpreadsOnly,
    } = useSelector(weeklySpreaderSelector);

    const { riskTable, riskTotalsTable } = useSelector(weeklySpreaderRiskTableSelector);

    const selectedDesk = useSelector(selectedDeskSelector);
    const { selectedDeskId } = useSelector(desksSelector);

    const productGroups = useSelector(productGroupsSelector);

    const fetchOnReconnect = useSelector(fetchOnReconnectSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const minWidth = 300;

    useLiveRiskHubConnection(selectedDesk?.topLevelDeskId, riskSignalRGroupName);
    useSpreaderEntryHubConnection(selectedDeskId, spreaderSignalRGroupName);

    // Re-fetch spreader risk when real-time message comes in
    React.useEffect(() => {
        if (!lastRiskRealTimeMessage || selectedDesk === undefined) return;

        if (autoUpdate && meta.status !== "loading" && meta.status !== "refetching") {
            dispatch(refetchWeeklySpreaderRisk(lastRiskRealTimeMessage, selectedDesk));
        } else {
            dispatch(setIsRiskUpdateRequired(true));
        }
    }, [lastRiskRealTimeMessage]);

    // Re-fetch spreader entries when real-time message comes in, if not editing or loading
    React.useEffect(() => {
        if (!lastSpreaderRealTimeMessage || selectedDesk === undefined) return;

        if (autoUpdate && meta.status !== "loading" && meta.status !== "refetching") {
            dispatch(refetchWeeklySpreaderEntries(lastSpreaderRealTimeMessage, selectedDesk));
            // Also fetch risk so calculated columns like Net Brent are appropriately updated to include Spreader Entries
            dispatch(fetchWeeklySpreaderRisk(selectedDeskId));
        
        } else {
            dispatch(setIsEntriesUpdateRequired(true));
        }
    }, [lastSpreaderRealTimeMessage]);

    React.useEffect(() => {
        if (selectedDesk === undefined || !meta.status) return;

        if (meta.status === "loading" || meta.status === "refetching") return;

        if (isEntriesUpdateRequired && lastSpreaderRealTimeMessage) {
            dispatch(refetchWeeklySpreaderEntries(lastSpreaderRealTimeMessage, selectedDesk));
            // Also fetch risk so calculated columns like Net Brent are appropriately updated to include Spreader Entries
            dispatch(setIsRiskUpdateRequired(true));
        }

        if (isRiskUpdateRequired && lastRiskRealTimeMessage) {
            dispatch(refetchWeeklySpreaderRisk(lastRiskRealTimeMessage, selectedDesk));
        }
    }, [meta.status]);

    // Re-fetch when SignalR resumes a broken connection
    React.useEffect(() => {
        if (fetchOnReconnect && deskHasBeenSet) {
            dispatch(refetchOnReconnect(selectedDeskId!));
        }
    }, [fetchOnReconnect]);

    // Fetch spreader risk and entries on initial load or desk selection
    React.useEffect(() => {
        dispatch(fetchWeeklySpreaderEntriesAndRisk(selectedDeskId));
        return setColumnState(null);
    }, [selectedDeskId]);

    const valueFormatter = React.useCallback(
        ({ value }: ValueFormatterParams) =>
            isFinite(value) ? formatNumberWithCommas(new Big(value).toFixed(decimalPosition, Big.roundHalfEven)) : "",
        [decimalPosition],
    );

    const handleAutoFlatten = (spreaderView: string) => {
        if (selectedDeskId) {
            const entries = weeklyAutoFlatten(spreaderView, selectedDeskId, riskTable, risk);
            dispatch(autoFlattenEntries(selectedDeskId, entries));
        }
    };

    const handleClearAll = () => {
        selectedDeskId && dispatch(clearAll(selectedDeskId));
    };

    const handleDummyValueInput = (
        productGroup: string,
        { newValue, oldValue, data: { tenorPeriod } }: NewValueParams,
    ) => {
        if (!selectedDeskId) return;

        if (!newValue) {
            if (!oldValue) return;

            updateDummyValue(null, productGroup, tenorPeriod);
        } else {
            if (!isStringNumeric(newValue)) return;

            const numericNewValue = Number(newValue);

            if (newValue == oldValue) return;

            updateDummyValue(numericNewValue, productGroup, tenorPeriod);
        }
    };

    const updateDummyValue = (newValue: Common.Nullable<number>, productGroup: string, tenorPeriod: string) => {
        const dummyEntry = {
            deskId: selectedDeskId,
            productGroupId: risk.productGroups[productGroup].productGroupId,
            value: newValue,
            tenorPeriod,
            spreaderView: WEEKLY_SPREADER_VIEW,
        };
        newValue
            ? dispatch(updateSpreaderDummyEntry(productGroup, dummyEntry))
            : dispatch(deleteSpreaderDummyEntry(productGroup, dummyEntry));
  };

    const selectedCellRanges = React.useRef<CellRange[]>([]);
    const sumLabel = React.useRef<HTMLLabelElement>(null);
    const avgLabel = React.useRef<HTMLLabelElement>(null);

    const onRangeSelectionChanged = (event: RangeSelectionChangedEvent) => {
        const { api } = event;

        if (!api) {
            return [];
        }

        updateSumAndAverage(api);
    };

    const updateSumAndAverage = (gridApi: GridApi) => {
        const updatedRanges = gridApi?.getCellRanges() || [];

        populateAverageAndSumValues(gridApi, updatedRanges, sumLabel, avgLabel, decimalPosition);

        selectedCellRanges.current = updatedRanges.map((range) => ({ ...range }));
    };

    React.useEffect(() => {
        const { api, columnApi } = gridRef.current || {};

        if (!api || !columnApi) {
            return;
        }

        preserveSelectionOnRender(selectedCellRanges, api, columnApi);
        updateSumAndAverage(api);
    }, [selectedDeskId, viewSpreadsOnly, risk, riskTable, productGroups, columnState]);

    React.useEffect(() => {
        setColumnDefs(
            columnWeeklySpreaderDefs({
                tenorColumnWidth,
                productGroups,
                risk,
                columnState,
                defaultColumnWidth,
                viewSpreadsOnly,
                valueFormatter,
                handleDummyValueInput,
            }),
        );
    }, [columnState, decimalPosition]);

    const noDataAvailable = !productGroups || productGroups.length === 0;
    return (
        <div data-testid={`spreader-grid${meta.status === "loaded" ? "" : "-loading"}`} className="flex-1 bg-white-300">
            <div
                className={classNames("spreader-grid ag-theme-alpine h-full", {
                    "opacity-50": meta.status === "loading" && !noDataAvailable,
                })}
                style={
                    noDataAvailable
                        ? {}
                        : { minWidth: `${minWidth}px`, maxWidth: "100%", width: gridWidth ? `${gridWidth}px` : "100%" }
                }
            >
                <WeeklySpreaderGridHeader
                    sumRef={sumLabel}
                    avgRef={avgLabel}
                    onAutoFlatten={() => handleAutoFlatten(WEEKLY_SPREADER_VIEW)}
                    onClearAll={handleClearAll}
                    onExportToCSV={() =>
                        gridRef.current &&
                        gridRef.current.api?.exportDataAsCsv({
                            fileName: `Spreader_${selectedDesk?.name ?? "Onyx"}.csv`,
                        })
                    }
                />

                {noDataAvailable && meta.status === "loaded" && (
                    <div data-testid="no-data" className="py-4 px-2 text-base">
                        No data available
                    </div>
                )}

                {noDataAvailable && meta.status === "loading" && <GridLoading />}

                {meta.status === "error" && <div className="py-4 px-2 text-sm">An error has occurred</div>}

                {!noDataAvailable && meta.status !== "error" && (
                    <AgGridReact
                        ref={gridRef}
                        getRowId={(data: any) => data.data.tenorPeriod}
                        suppressRowHoverHighlight={true}
                        modules={[RangeSelectionModule, ClipboardModule]}
                        enableRangeSelection={true}
                        copyHeadersToClipboard={true}
                        onRangeSelectionChanged={onRangeSelectionChanged}
                        suppressExcelExport
                        popupParent={document.body}
                        onCellEditingStarted={() => {
                            dispatch(setAutoUpdate(false));
                        }}
                        onCellEditingStopped={() => {
                            dispatch(setAutoUpdate(true));
                        }}
                        defaultColDef={{
                            minWidth: defaultColumnWidth,
                            resizable: true,
                            sortable: false,
                            filter: false,
                            floatingFilter: false,
                            suppressMovable: true,
                            flex: gridWidth < minWidth ? 1 : 0,
                        }}
                        rowData={riskTable}
                        rowStyle={{ borderTopStyle: "none" }}
                        domLayout="autoHeight"
                        ensureDomOrder
                        enableCellTextSelection={false}
                        onColumnResized={handleColumnResize}
                        icons={{
                            sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                            sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                            menu: "",
                            resize: "",
                        }}
                        stopEditingWhenCellsLoseFocus
                        singleClickEdit
                        pinnedBottomRowData={riskTotalsTable}
                        columnDefs={columnDefs}
                    />
                )}
            </div>
        </div>
    );
};