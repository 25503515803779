import { IAggFuncParams } from "@ag-grid-community/core";

export const formatNumberWithCommas = (n: string) => {
    const parts = n.split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
};

export const isNumeric = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

export const sumWithNull = ({ values }: IAggFuncParams) =>
    values.reduce((sum, value): number | null => {
        const amountToAdd = isNumeric(value) ? parseFloat(value) : 0;

        if (sum === null && amountToAdd === 0) {
            return null;
        }

        return sum + amountToAdd;
    }, null);
