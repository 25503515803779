import { CellRange, GridApi, IRowModel } from "@ag-grid-community/core";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import Big from "big.js";
import { SumAndCount } from "common/types";

const PINNED_POSITION_BOTTOM = "bottom";

export const populateAverageAndSumValues = (
    gridApi: GridApi | undefined,
    cellRanges: CellRange[],
    sumLabelRef: React.RefObject<HTMLLabelElement>,
    avgLabelRef: React.RefObject<HTMLLabelElement>,
    decimalPosition: number,
) => {
    if (!gridApi) return;

    const rowModel = gridApi.getModel();
    const sumLabel = sumLabelRef.current;
    const avgLabel = avgLabelRef.current;

    if (!sumLabel || !avgLabel) return;

    const singleCellSelected =
        Array.isArray(cellRanges) &&
        cellRanges.length === 1 &&
        cellRanges[0].startRow?.rowIndex === cellRanges[0].endRow?.rowIndex &&
        cellRanges[0].columns.length === 1;

    if (!cellRanges || cellRanges.length === 0 || singleCellSelected) {
        sumLabel.innerHTML = "";
        avgLabel.innerHTML = "";
        return;
    }

    let totalSum = 0;
    let totalCount = 0;

    cellRanges.forEach(function (range: CellRange) {
        if (!range || !range.startRow || !range.endRow) return;

        const rowCount = gridApi.getDisplayedRowCount();
        const rowIndexes = getRangeRowIndexes(rowCount, range);

        const { sum, count } = calculateSumAndCount(rowIndexes, range, gridApi, rowModel);
        totalSum += sum;
        totalCount += count;
    });

    if (totalCount === 0) {
        sumLabel.innerHTML = "";
        avgLabel.innerHTML = "";
        return;
    }

    sumLabel.innerHTML =
        "SUM: " + formatNumberWithCommas(new Big(totalSum).toFixed(decimalPosition, Big.roundHalfEven));
    avgLabel.innerHTML =
        "AVG: " + formatNumberWithCommas(new Big(totalSum / totalCount).toFixed(decimalPosition, Big.roundHalfEven));
};

const getRangeRowIndexes = (rowCount: number, cellRange: CellRange): number[][] => {
    const startRow = cellRange.startRow?.rowIndex || 0;
    const endRow = cellRange.endRow?.rowIndex || 0;
    const onlyPinned =
        cellRange.endRow?.rowPinned === PINNED_POSITION_BOTTOM &&
        cellRange.startRow?.rowPinned === PINNED_POSITION_BOTTOM;
    const endPinned = cellRange.endRow?.rowPinned === PINNED_POSITION_BOTTOM;
    const startPinned = cellRange.startRow?.rowPinned === PINNED_POSITION_BOTTOM;

    if (onlyPinned) return getRowIndexesWhenAllPinned(startRow, endRow);
    if (endPinned) return getRowIndexesWhenEndPinned(startRow, endRow, rowCount);
    if (startPinned) return getRowIndexesWhenStartPinned(startRow, endRow, rowCount);

    return getRowIndexesWhenNoPinnedRows(startRow, endRow);
};

const getRowIndexesWhenAllPinned = (startRow: number, endRow: number): number[][] => {
    const pinnedRowIterator = Array(Math.max(endRow, startRow) + 1).keys();
    const pinnedRowIndexes = Array.from(pinnedRowIterator).slice(Math.min(endRow, startRow));
    return [[], pinnedRowIndexes];
};

const getRowIndexesWhenEndPinned = (startRow: number, endRow: number, rowCount: number): number[][] => {
    const unpinnedRowIterator = Array(rowCount - startRow).keys();
    const unpinnedRowArray = Array.from(unpinnedRowIterator);
    const unpinnedRowIndexes = unpinnedRowArray.map((i) => i + startRow);
    const pinnedRowIterator = Array(endRow + 1).keys();
    const pinnedRowIndexes = Array.from(pinnedRowIterator);

    return [unpinnedRowIndexes, pinnedRowIndexes];
};

const getRowIndexesWhenStartPinned = (startRow: number, endRow: number, rowCount: number): number[][] => {
    const unpinnedRowIterator = Array(rowCount - endRow).keys();
    const unpinnedRowArray = Array.from(unpinnedRowIterator);
    const unpinnedRowIndexes = unpinnedRowArray.map((i) => i + endRow);
    const pinnedRowIterator = Array(startRow + 1).keys();
    const pinnedRowIndexes = Array.from(pinnedRowIterator);

    return [unpinnedRowIndexes, pinnedRowIndexes];
};

const getRowIndexesWhenNoPinnedRows = (startRow: number, endRow: number): number[][] => {
    const numberOfRows = Math.abs(endRow - startRow) + 1;
    const rowStart = Math.min(startRow, endRow);
    const rowIterator = Array(numberOfRows).keys();
    const rowArray = Array.from(rowIterator);
    const rowIndexes = rowArray.map((i) => i + rowStart);

    return [rowIndexes, []];
};

const calculateSumAndCount = (
    rows: number[][],
    range: CellRange,
    gridApi: GridApi,
    rowModel: IRowModel,
): SumAndCount => {
    const unpinnedRows = rows[0];
    const pinnedRows = rows[1];
    let sum = 0;
    let count = 0;

    unpinnedRows.forEach((rowIndex) => {
        range.columns.forEach(function (column) {
            const rowNode = rowModel.getRow(rowIndex);
            if (!rowNode) return;

            const value = gridApi.getValue(column, rowNode);
            if (typeof value === "number") {
                sum += value;
                count++;
            }
        });
    });

    pinnedRows.forEach((rowIndex) => {
        range.columns.forEach(function (column) {
            const rowNode = gridApi.getPinnedBottomRow(rowIndex);

            if (!rowNode) return;

            const value = gridApi.getValue(column, rowNode);
            if (typeof value === "number") {
                sum += value;
                count++;
            }
        });
    });

    return {
        sum,
        count,
    };
};
