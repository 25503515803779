import * as eodRiskApi from "api/eodRiskApi";
import { AppDispatch } from "core/store";
import { setStatus, getEodRiskItemsSuccess, getEodRiskItemsFailed } from "./eodRiskSlice";

export const fetchEodRisk = (positionType: string, processingDate: string, deskId: Common.Nullable<number>) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("loading"));
            const result = await eodRiskApi.getEodRisk(positionType, processingDate, deskId);
            dispatch(getEodRiskItemsSuccess(result));
        } catch (err: any) {
            dispatch(getEodRiskItemsFailed(err.message));
        }
    };
};
