import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";

const inTestMode = !!process.env.JEST_WORKER_ID || process.env.NODE_ENV === "test";

export const generateStore = () =>
    configureStore({
        reducer: rootReducer,
        devTools: process.env.ENVIRONMENT != "production",
        middleware: (getDefaultMiddleware) =>
            inTestMode ? getDefaultMiddleware({ serializableCheck: false }) : getDefaultMiddleware(),
    });

export const store = generateStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
