import { ValueFormatterParams } from "@ag-grid-community/core";
import { AppDispatch, RootState } from "core/store";
import { BlotterProductGroupDto } from "features/blotterProductGroups/types";
import { BrokerDto } from "features/brokers/types";
import { CounterPartyTypeDto } from "features/counterPartyTypes/types";
import { DeskDTO } from "features/desks/desksTypes";
import { ExchangeDto } from "features/exchanges/types";
import { TradedUnitDto } from "features/tradedUnits/types";

export interface State {
    blotterDate: string;
    blotterTrades?: BlotterTradeDto[];
    validationMessage?: string[];
    showBlotterEntryForm: boolean;
    blotterEntry: CreateBlotterTrade;
    productGroupText: string;
    brokerText: string;
    blotterTradeToDelete?: BlotterTradeDto;
    blotterTradeToDuplicate?: BlotterTradeDto;
    meta: Common.MetaState;
    lastRealTimeMessage?: BlotterTradeRealTimeMessage | BlotterTradePnlRealTimeMessage;
    signalRGroupName?: string;
    signalRConnectionId?: string;
    autoUpdate: boolean;
    affectedBlotterTradeIds: number[];
    isFilterEnabled: boolean;
    isBlotterUpdateRequired: boolean;
    editingRow: number | null;
}

export type BlotterTradeRealTimeMessageType = "Created" | "Deleted" | "Updated";

export interface BlotterTradesApiResponse {
    blotterTrades: BlotterTradeDto[];
}

export interface BlotterTradeDto {
    id: number;
    deskId: number;
    date: string;
    price: number;
    side: Common.Side;
    quantity: number;
    tradedUnitId: number;
    blotterProductGroupId: number;
    tenor1Period: string;
    tenor1Year: string;
    tenor1StartDate: string;
    tenor1EndDate: string;
    tenor2Period: string;
    tenor2Year: string;
    tenor2StartDate: string;
    tenor2EndDate: string;
    exchangeId: number;
    brokerageAdjustment: number;
    pricingDay: number;
    brokerId: number;
    counterPartyTypeId?: number;
    notes: string;
    createdByUserId: number;
    updatedByUserId?: number;
    deskName: string;
    tradedUnitName?: string;
    exchangeName?: string;
    brokerName?: string;
    counterPartyTypeName?: string;
    blotterProductGroupName?: string;
    createdBy: string;
    updatedBy?: string;
    createdDateTime: string;
    isStrip: boolean;
    pnl: number;
    livePrice: number;
    brokerage: number;
}

export interface BlotterTrade {
    id: number;
    deskId: number;
    date?: Date;
    price?: number;
    side: Common.Side;
    quantity: number;
    tradedUnitId?: Common.Nullable<number>;
    blotterProductGroupId?: Common.Nullable<number>;
    tenor1Period: string;
    tenor1Year: string;
    tenor1StartDate?: Date;
    tenor1EndDate?: Date;
    tenor2Period: string;
    tenor2Year: string;
    tenor2StartDate?: Date;
    tenor2EndDate?: Date;
    exchangeId?: Common.Nullable<number>;
    brokerageAdjustment?: number;
    pricingDay?: number;
    brokerId?: Common.Nullable<number>;
    counterPartyTypeId?: Common.Nullable<number>;
    notes?: string;
    createdByUserId: number;
    updatedByUserId?: number;
    deskName: string;
    tradedUnitName?: string;
    exchangeName?: string;
    counterPartyTypeName?: string;
    brokerName?: string;
    blotterProductGroupName?: string;
    createdBy: string;
    updatedBy?: string;
    createdDateTime: Date;
    isStrip: boolean;
    transferDeskId?: Common.Nullable<number>;
    pnl: number;
    livePrice: number;
    brokerage: number;
}

export interface CreateBlotterTrade {
    id?: number;
    deskId: Common.Nullable<number>;
    date: Common.Nullable<string>;
    price: Common.Nullable<string>;
    side: Common.Nullable<Common.Side>;
    quantity: Common.Nullable<number>;
    tradedUnitId: Common.Nullable<number>;
    blotterProductGroupId: Common.Nullable<number>;
    tenor1Period: Common.Nullable<string>;
    tenor1Year: Common.Nullable<string>;
    tenor1StartDate: Common.Nullable<string>;
    tenor1EndDate: Common.Nullable<string>;
    tenor2Period: Common.Nullable<string>;
    tenor2Year: Common.Nullable<string>;
    tenor2StartDate: Common.Nullable<string>;
    tenor2EndDate: Common.Nullable<string>;
    exchangeId: Common.Nullable<number>;
    brokerageAdjustment: Common.Nullable<string>;
    pricingDay: Common.Nullable<number>;
    brokerId: Common.Nullable<number>;
    counterPartyTypeId: Common.Nullable<number>;
    notes: Common.Nullable<string>;
    isStrip: Common.Nullable<boolean>;
    transferDeskId: Common.Nullable<number>;
}

export interface BlotterTradeRealTimeMessage {
    deskId?: number;
    tradeDate: string;
    topLevelDeskId?: number;
    messageType: BlotterTradeRealTimeMessageType;
    affectedBlotterTradeIds: number[];
}

export interface BlotterTradePnlRealTimeMessage {
    messageType: "PnlUpdated";
}

export const isBlotterTradePnlMessage = (
    message: BlotterTradeRealTimeMessage | BlotterTradePnlRealTimeMessage,
): message is BlotterTradePnlRealTimeMessage => {
    return message.messageType === "PnlUpdated";
};

export interface ColumnParams {
    canEdit: boolean;
    dispatch: any;
    patchBlotterTrade: (
        id: number,
        blotterTrade: Partial<CreateBlotterTrade>,
    ) => (dispatch: AppDispatch, getState: () => RootState) => Promise<void>;
    valueFormatter: (value: ValueFormatterParams, decimalPlaces: number) => string;
    isCurrentPeriod: (
        period: Common.Period | undefined,
        year: Common.Nullable<string>,
        startDate: Common.Nullable<string>,
        endDate: Common.Nullable<string>,
        referenceDate?: Date,
    ) => boolean;
    tenorPeriods: Common.Period[];
    counterPartyTypes: CounterPartyTypeDto[] | undefined;
    blotterProductGroups: BlotterProductGroupDto[] | undefined;
    brokers: BrokerDto[] | undefined;
    exchanges: ExchangeDto[] | undefined;
    desks: DeskDTO[] | undefined;
    tradedUnits: TradedUnitDto[] | undefined;
}

export interface BlotterFieldLoading {
    id: number;
    field: string;
}
