import axios from "axios";
import { getToken } from "core/auth";
import { apiBaseUrl } from "core/env";
import { ExchangesAPIResponse } from "features/exchanges/types";

export const getExchanges = async () => {
    const token = await getToken();

    const response = await axios.get<ExchangesAPIResponse>(`${apiBaseUrl}/api/exchanges`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        },
    });

    return response.data;
};
