import { ColumnParams } from "../spreaderTypes";
import { CellClassParams, EditableCallbackParams } from "@ag-grid-community/core";
import { isSpreaderCustomColumn } from "../utils/customColumns";
import { columnsWithHighlightedTotals } from "../utils/constants";

export const getColumnDefs = (params: ColumnParams): any[] => {
    const {
        tenorColumnWidth,
        productGroups,
        risk,
        columnState,
        defaultColumnWidth,
        viewSpreadsOnly,
        valueFormatter,
        handleDummyValueInput,
    } = params;

    return [
        {
            field: "tenorPeriod",
            headerName: "Tenor",
            minWidth: tenorColumnWidth,
            width: tenorColumnWidth,
            maxWidth: tenorColumnWidth,
        },
        ...productGroups.map((productGroup) => {
            const productGroupId = risk.productGroups[productGroup].productGroupId.toString();
            const conversionFactor = risk.productGroups[productGroup].conversionFactor;

            return {
                headerName: productGroup,
                groupId: productGroupId,
                children: [
                    ...(!viewSpreadsOnly
                        ? [
                              {
                                  colId: `${productGroupId}|${productGroupId}`,
                                  field: productGroupId,
                                  headerName: "Qty",
                                  cellClassRules: {
                                      "bg-blue-200 ag-right-aligned-cell": (params: CellClassParams) =>
                                          !(
                                              columnsWithHighlightedTotals.includes(productGroupId) &&
                                              params.node.isRowPinned()
                                          ),
                                      "bg-green-200 text-lg text-nowrap ag-right-aligned-cell": (
                                          params: CellClassParams,
                                      ) =>
                                          columnsWithHighlightedTotals.includes(productGroupId) &&
                                          params.node.isRowPinned(),
                                      "text-red-600": (params: CellClassParams) => params.value < 0,
                                  },
                                  valueFormatter: valueFormatter,
                                  valueGetter: ({ data }: any) => data[productGroupId],
                                  width:
                                      columnState &&
                                      columnState[productGroupId] &&
                                      columnState[productGroupId][productGroupId]
                                          ? columnState[productGroupId][productGroupId]
                                          : defaultColumnWidth,
                                  hide: viewSpreadsOnly,
                              },
                              {
                                  colId: `${productGroupId}|tenorCode`,
                                  headerName: "",
                                  field: `tenorCode`,
                                  width:
                                      columnState &&
                                      columnState[productGroupId] &&
                                      columnState[productGroupId]["tenorCode"]
                                          ? columnState[productGroupId]["tenorCode"]
                                          : defaultColumnWidth,
                                  hide: viewSpreadsOnly,
                              },
                          ]
                        : []),
                    {
                        colId: `${productGroupId}|spread`,
                        field: `${productGroupId}-spread`,
                        headerName: "Spr",
                        cellClass: "ag-right-aligned-cell",
                        cellClassRules: {
                            "text-red-600": (params: CellClassParams) => params.value < 0,
                        },
                        valueFormatter: valueFormatter,
                        valueGetter: ({ data }: any) => data[`${productGroupId}-spread`],
                        width:
                            columnState && columnState[productGroupId] && columnState[productGroupId]["spread"]
                                ? columnState[productGroupId]["spread"]
                                : defaultColumnWidth,
                    },
                    ...(conversionFactor && !viewSpreadsOnly
                        ? [
                              {
                                  colId: `${productGroupId}|spread-kt`,
                                  field: `${productGroupId}-spread-kt`,
                                  headerName: "KT",
                                  cellClass: "ag-right-aligned-cell",
                                  cellClassRules: {
                                      "text-red-600": (params: CellClassParams) => params.value < 0,
                                  },
                                  valueFormatter: valueFormatter,
                                  valueGetter: ({ data }: any) => data[`${productGroupId}-spread-kt`],
                                  width:
                                      columnState &&
                                      columnState[productGroupId] &&
                                      columnState[productGroupId]["spread-kt"]
                                          ? columnState[productGroupId]["spread-kt"]
                                          : defaultColumnWidth,
                              },
                          ]
                        : []),
                    ...(!viewSpreadsOnly && !isSpreaderCustomColumn(productGroupId)
                        ? [
                              {
                                  colId: `${productGroupId}|dummy`,
                                  field: `${productGroupId}-dummy`,
                                  headerName: "DV",
                                  cellClass: "bg-yellow-100 ag-right-aligned-cell",
                                  editable: (params: EditableCallbackParams) =>
                                      params.data.tenorPeriod !== "Cumulative",
                                  onCellValueChanged: (params: any) => handleDummyValueInput(productGroup, params),
                                  cellClassRules: {
                                      "text-red-600": (params: CellClassParams) => params.value < 0,
                                  },
                                  valueGetter: ({ data }: any) => data[`${productGroupId}-dummy`],
                                  valueFormatter: valueFormatter,
                                  width:
                                      columnState && columnState[productGroupId] && columnState[productGroupId]["dummy"]
                                          ? columnState[productGroupId]["dummy"]
                                          : defaultColumnWidth,
                              },
                          ]
                        : []),
                ],
            };
        }),
    ];
};
