import { FormLabel } from "common/components/form";
import { Typeahead } from "common/components/typeahead";
import {
    brokerBlotterEntrySelector,
    setBrokerId,
    setBrokerText,
    updateBlotterEntry,
} from "features/blotter/blotterSlice";
import { brokersSelector } from "features/brokers/brokersSlice";
import { fetchBrokers } from "features/brokers/brokersThunks";
import debounce from "lodash/debounce";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const Broker: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = React.useState<Common.Nullable<number>>(null);
    const [hidePopover, setHidePopover] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);
    const { brokers, allBrokers } = useSelector(brokersSelector);
    const { brokerId, brokerText } = useSelector(brokerBlotterEntrySelector);

    React.useEffect(() => {
        if (!allBrokers) {
            dispatch(fetchBrokers());
        }
    }, []);

    React.useEffect(() => {
        if (allBrokers) {
            dispatch(setBrokerText(allBrokers?.filter((a) => a.brokerId === brokerId)?.[0]?.name || ""));
            setData([]);
            setHidePopover(true);
        }
    }, [allBrokers, brokerId]);

    const handleSelect = (selectedText: string) => {
        const brokerId = brokers?.find((b) => b.name === selectedText)?.brokerId || null;
        dispatch(setBrokerText(selectedText || ""));
        dispatch(setBrokerId(brokerId));
        setSelectedId(brokerId);
        dispatch(updateBlotterEntry({ brokerId }));
    };

    const updateBrokersList = React.useCallback(
        debounce((selectedText: string) => {
            if (selectedText) {
                dispatch(fetchBrokers(selectedText));
            }
        }, 400),
        [],
    );

    const handleChange = (selectedText: string) => {
        setHidePopover(false);
        dispatch(setBrokerId(null));
        setSelectedId(null);

        if (/^[a-zA-Z0-9_.-\s()]*$/.test(selectedText)) {
            dispatch(setBrokerText(selectedText));
            updateBrokersList(selectedText);
        }
    };

    React.useEffect(() => {
        setData(!brokers ? [] : brokers.map((b) => ({ id: b.brokerId, name: b.name })));
    }, [brokers]);

    return (
        <div data-testid="broker-panel" className="flex flex-col w-32">
            <div className="flex justify-center">
                <FormLabel>Broker</FormLabel>
            </div>
            <Typeahead
                id="blotter-broker-typeahead"
                key={selectedId || undefined}
                data={data}
                text={brokerText}
                setText={handleChange}
                onSelect={handleSelect}
                className="w-full"
                error={
                    brokerText !== "" &&
                    !brokerId &&
                    !brokers?.some(({ name }) => name.toLowerCase() === brokerText.toLowerCase())
                }
                hidePopover={hidePopover}
            />
        </div>
    );
};
