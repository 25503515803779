import { ColumnGroupOpenedEvent, ProcessHeaderForExportParams, ValueFormatterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import Big from "big.js";
import classNames from "classnames";
import { GridLoading, GroupHeader } from "common/components/grid";
import { defaultGridHeaderHeight } from "common/constants";
import { useCustomLocationProperties } from "common/hooks";
import { Collapse, Expand } from "common/icons";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import { desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import _ from "lodash";
import capitalize from "lodash/capitalize";
import * as React from "react";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import { getColumnDefs } from "./columnDefs";
import "./rollOffRisk.styles.scss";
import { RollOffRiskHeader } from "./rollOffRiskHeader";
import { RollOffRiskLoadMore } from "./rollOffRiskLoadMore";
import {
    productDiffsByProductGroupSelector,
    productGroupsSelector,
    productsByProductGroupSelector,
    riskItemsByExpirySelector,
    riskItemsByGroupSelector,
    rollOffRiskSelector,
} from "./rollOffRiskSlice";
import { useGridState } from "./useGridState";
import {
    getProductGroupTotalForRollOffRisk,
    getTenorGrandTotal,
    productValueGetter,
    tenorProductValueGetter,
} from "./utils";

/* istanbul ignore next */
export const RollOffRiskGrid: React.FC = () => {
    const {
        gridWidth,
        setGridWidth,
        grandTotalColumnWidth,
        columnState,
        setColumnState,
        getProductGroupColumnSettings,
        getProductColumnSettings,
        getDiffProductColumnSettings,
        columnDefs,
        setColumnDefs,
        gridRef,
        calculateWidth,
        handleColumnResize,
    } = useGridState();

    const { shortPath } = useCustomLocationProperties();

    const minWidth = 500;
    const selectedDesk = useSelector(selectedDeskSelector);
    const { selectedDeskId } = useSelector(desksSelector);

    const { meta, riskItems, decimalPosition } = useSelector(rollOffRiskSelector);
    const productGroups = useSelector(productGroupsSelector);

    const riskItemsByGroup = useSelector(riskItemsByGroupSelector);
    const riskItemsByExpiry = useSelector(riskItemsByExpirySelector);
    const productsByProductGroup = useSelector(productsByProductGroupSelector);
    const productDiffsByProductGroup = useSelector(productDiffsByProductGroupSelector);

    const valueFormatter = React.useCallback(
        ({ value }: ValueFormatterParams) => {
            if (!_.isFinite(value)) return "";
            return formatNumberWithCommas(new Big(value).toFixed(decimalPosition, Big.roundHalfEven));
        },
        [decimalPosition],
    );

    const handleProcessHeaderForClipboard = (params: ProcessHeaderForExportParams) => {
        const colDef = params.column.getColDef();
        const { headerName, refData } = colDef;

        if (refData === undefined) return headerName;

        let { productGroup, product, diffProduct, tenorDate } = refData;
        productGroup = productGroup || "";
        product = product ? `-${product}` : "";
        diffProduct = diffProduct ? `-${diffProduct}` : "";
        tenorDate = tenorDate ? `-${tenorDate}` : "";

        return `${productGroup}${product}${diffProduct}${tenorDate}` || headerName;
    };

    const productGroupsLoaded = productGroups !== null;
    const noDataAvailable =
        _.isEmpty(riskItems) || _.isEmpty(riskItemsByGroup) || _.isEmpty(riskItemsByExpiry) || !productGroupsLoaded;

    React.useEffect(() => {
        if (productGroups) {
            setColumnDefs(
                getColumnDefs({
                    productGroups,
                    getProductGroupColumnSettings,
                    riskItemsByGroup,
                    getProductColumnSettings,
                    valueFormatter,
                    getProductGroupTotalForRollOffRisk,
                    riskItems,
                    productValueGetter,
                    productsByProductGroup,
                    getDiffProductColumnSettings,
                    tenorProductValueGetter,
                    productDiffsByProductGroup,
                    columnState,
                    grandTotalColumnWidth,
                    getTenorGrandTotal,
                }),
            );
            setGridWidth((prevState) => calculateWidth(prevState));
        }
    }, [columnState, decimalPosition, productGroups, riskItems, selectedDeskId]);

    return (
        <div data-testid={`rollOffRisk-grid`} className={classNames("rollOffRisk-grid flex-1 bg-white-300")}>
            <div
                className={classNames("ag-theme-alpine h-full roll-off-risk", {
                    "opacity-50": !noDataAvailable && meta.status === "loading",
                })}
                style={{
                    minWidth: `${minWidth}px`,
                    maxWidth: "100%",
                    width: gridWidth ? `${gridWidth}px` : "100%",
                }}
            >
                <RollOffRiskHeader
                    onExportToCSV={() =>
                        gridRef.current &&
                        gridRef.current.api.exportDataAsCsv({
                            fileName: `RollOff_Risk_${capitalize(shortPath)}_Based_${selectedDesk?.name ?? "Onyx"}.csv`,
                        })
                    }
                />
                {meta.status === "error" && <div className="py-4 px-2 text-sm">An error has occurred</div>}

                {(meta.status === "loading" || !productGroupsLoaded) && noDataAvailable && <GridLoading />}

                {meta.status === "loaded" && productGroupsLoaded && noDataAvailable && (
                    <div data-testid={`no-data`} className="py-4 px-2 text-sm">
                        No data available
                    </div>
                )}

                {!noDataAvailable && meta.status !== "error" && (
                    <AgGridReact
                        ref={gridRef}
                        modules={[RangeSelectionModule, ClipboardModule]}
                        columnDefs={columnDefs}
                        onColumnResized={handleColumnResize}
                        suppressRowHoverHighlight={true}
                        enableRangeSelection={true}
                        copyHeadersToClipboard={true}
                        defaultCsvExportParams={{
                            processCellCallback: ({ value, column }) => {
                                if (!value || !isFinite(value)) return value;

                                const colId = column ? column.getColId() : "";
                                if (colId === "year") return value;

                                return formatNumberWithCommas(
                                    new Big(value).toFixed(decimalPosition, Big.roundHalfEven),
                                );
                            },
                        }}
                        suppressExcelExport
                        suppressRowTransform={true}
                        popupParent={document.body}
                        enableCellTextSelection={false}
                        processHeaderForClipboard={handleProcessHeaderForClipboard}
                        components={{
                            Expand,
                            Collapse,
                            GroupHeader,
                        }}
                        defaultColDef={{
                            resizable: true,
                            sortable: false,
                            filter: false,
                            floatingFilter: false,
                            suppressMovable: true,
                            flex: gridWidth < minWidth ? 1 : 0,
                        }}
                        headerHeight={defaultGridHeaderHeight}
                        rowStyle={{ borderTopStyle: "none" }}
                        domLayout="autoHeight"
                        ensureDomOrder
                        rowData={riskItemsByExpiry}
                        icons={{
                            sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                            sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                            columnGroupOpened: renderToString(<Collapse />),
                            columnGroupClosed: renderToString(<Expand />),
                            menu: "",
                            resize: "",
                        }}
                        getRowHeight={(params: any) => (params.node.isRowPinned() ? 50 : undefined)}
                        onColumnGroupOpened={(event: ColumnGroupOpenedEvent) => {
                            const { api, columnApi } = gridRef?.current || {};

                            if (!api) return;

                            // Handle when a column is expanded or collapsed
                            const level = event.columnGroup.getLevel();
                            const isExpanded = event.columnGroup.isExpanded();
                            const groupId = event.columnGroup.getColGroupDef()?.groupId;
                            const parent = event.columnGroup.getOriginalParent();
                            const parentGroupId = parent?.getColGroupDef()?.groupId;
                            const headerName = event.columnGroup.getColGroupDef()?.headerName ?? "";

                            const noOfGroupsExpanded = columnState
                                ? Object.keys(columnState)
                                      .map((groupId) => columnState[groupId] && columnState[groupId].expanded)
                                      .filter(Boolean).length
                                : 0;

                            // Handle when a top level group is expanded or collapsed
                            if (level === 0 && noOfGroupsExpanded === 0 && isExpanded) {
                                // If expand and we don't already have any expanded groups then expand the header height and column width
                                api && api.setHeaderHeight(75);
                            } else if (level === 0 && noOfGroupsExpanded === 1 && !isExpanded) {
                                // If collapse and this is the last group expanded, then collapse the header height
                                api && api.setHeaderHeight(defaultGridHeaderHeight);
                            }

                            // Update the local grouping state
                            setColumnState((prevState: any) => {
                                const newState = { ...prevState };

                                // If there is no group id set then just return prevState
                                if (!groupId) return prevState;

                                switch (level) {
                                    case 0:
                                        // Initialise the state for the given groupId key
                                        newState[groupId] = newState[groupId] || {
                                            children: {},
                                        };
                                        // Set the expanded state
                                        newState[groupId].expanded = isExpanded;

                                        // If collapse, iterate through the children and collapse all children
                                        if (!isExpanded) {
                                            Object.keys(newState[groupId].children).forEach((c) => {
                                                // Initialise the child key if not already created and set the expanded state
                                                newState[groupId].children[c] = newState[groupId].children[c] || {};
                                                newState[groupId].children[c].expanded = false;

                                                // Iterate through all products and set the corresponding column expanded state
                                                Object.keys(riskItemsByGroup[groupId]).map((product) => {
                                                    columnApi?.setColumnGroupOpened(`${groupId}-${product}`, false);
                                                });
                                            });
                                        }
                                        break;
                                    case 1:
                                        // If there is not parent group id then return
                                        if (!parentGroupId) return prevState;

                                        // Initialise the parent group and children
                                        newState[parentGroupId].children[headerName] =
                                            newState[parentGroupId].children[headerName] || {};

                                        newState[parentGroupId].children[headerName].expanded = isExpanded;
                                        break;
                                    default:
                                        return prevState;
                                }

                                return newState;
                            });
                        }}
                    />
                )}
                {meta.status !== "loading" && productGroups !== null && meta.status !== "error" && (
                    <RollOffRiskLoadMore gridWidth={gridWidth} />
                )}
            </div>
        </div>
    );
};
