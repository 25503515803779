import { getPnL } from "api/pnlApi";
import { AppDispatch } from "core/store";
import { getPnLFailed, getPnLSuccess, setIsPnlUpdateRequired, setStatus } from "./pnlSlice";

export const fetchPnL = (topLevelDeskId?: number) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setStatus("loading"));
        dispatch(setIsPnlUpdateRequired(false));

        await attemptPnlFetch(dispatch, topLevelDeskId);
    };
};

export const refetchPnL = (topLevelDeskId?: number) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setStatus("refetching"));
        dispatch(setIsPnlUpdateRequired(false));

        await attemptPnlFetch(dispatch, topLevelDeskId);
    };
};

export const attemptPnlFetch = async (dispatch: AppDispatch, topLevelDeskId?: number) => {
    try {
        const response = await getPnL(topLevelDeskId);
        dispatch(getPnLSuccess(response));
    } catch (err: any) {
        dispatch(getPnLFailed(err.message));
    }
};
