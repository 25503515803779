import * as React from "react";

export const SaveIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        style={{ marginTop: "3px" }}
    >
        <g clipPath="url(#clip0)">
            <path
                d="M3.66667 11.6667V7.66667H10.3333V11.6667H11.6667V4.21867L9.78133 2.33333H2.33333V11.6667H3.66667ZM1.66667 1H10.3333L13 3.66667V12.3333C13 12.5101 12.9298 12.6797 12.8047 12.8047C12.6797 12.9298 12.5101 13 12.3333 13H1.66667C1.48986 13 1.32029 12.9298 1.19526 12.8047C1.07024 12.6797 1 12.5101 1 12.3333V1.66667C1 1.48986 1.07024 1.32029 1.19526 1.19526C1.32029 1.07024 1.48986 1 1.66667 1ZM5 9V11.6667H9V9H5Z"
                fill="#A3A3A3"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
