import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { createHubConnection } from "core/realTime/createHubConnection";
import { format } from "date-fns";
import { getAccountSelector } from "features/auth/authSlice";
import { usePageVisibility } from "features/navigation/hooks";
import { setConnectionState } from "features/realTime/realTimeSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { setLastRealTimeMessage } from "../pnlSlice";
import { PnlRealTimeMessage } from "../pnlTypes";
import { SEND_PNL_UPDATE } from "./constants";
import { deskHasBeenSetSelector } from "features/desks/desksSlice";

export const usePnlHubConnection = () => {
    const [hubConnection, setHubConnection] = React.useState<HubConnection>();
    const dispatch = useDispatch();
    const { pathname: route } = useLocation();

    const appInsights = useAppInsightsContext();
    const {
        meta: { status },
    } = useSelector(getAccountSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const pageIsVisible = usePageVisibility();

    const disconnect = React.useCallback(() => {
        try {
            hubConnection && hubConnection.stop();
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { customDescription: "SignalR - PnL- Error stopping SignalR connection" },
            );
        }
    }, [hubConnection]);

    React.useEffect(() => {
        if (status === "expired") {
            console.warn(`User account expired, disconnecting SignalR`);
            disconnect();
        }
    }, [status]);

    React.useEffect(() => {
        if (!pageIsVisible) {
            disconnect();
        }
    }, [pageIsVisible]);

    React.useEffect(() => {
        try {
            const newHubConnection = createHubConnection("pnl", () => {
                dispatch(
                    setConnectionState({
                        state: HubConnectionState.Reconnecting,
                        route,
                        time: format(new Date(), "HH:mm:ss"),
                    }),
                );
            });

            setHubConnection(newHubConnection);
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { customDescription: "SignalR - PnL - Error creating hub connection" },
            );
        }
    }, []);

    React.useEffect(() => {
        const connectToSignalR = async (connection: HubConnection) => {
            try {
                await connection.start();

                if (connection.state === HubConnectionState.Connected) {
                    dispatch(setConnectionState({ state: connection.state, route }));
                }

                /* istanbul ignore next */
                connection.on(SEND_PNL_UPDATE, (message: PnlRealTimeMessage) => {
                    dispatch(
                        setConnectionState({
                            state: connection.state,
                            route,
                            time: format(new Date(), "HH:mm:ss"),
                        }),
                    );
                    dispatch(setLastRealTimeMessage(message));
                });

                /* istanbul ignore next */
                connection.onreconnected(async () => {
                    dispatch(setConnectionState({ state: connection.state, route }));
                });

                connection.onclose((_error) => {
                    dispatch(setConnectionState({ state: connection.state, route }));
                });

                connection.onreconnecting((_error) => {
                    dispatch(setConnectionState({ state: connection.state, route }));
                });
            } catch (error: any) {
                appInsights.trackException(
                    { error, severityLevel: SeverityLevel.Warning },
                    { customDescription: "SignalR - PnL- Error connecting to SignalR" },
                );
            }
        };

        if (!hubConnection || !pageIsVisible || !deskHasBeenSet) {
            return;
        }

        connectToSignalR(hubConnection);

        return disconnect;
    }, [hubConnection, pageIsVisible, deskHasBeenSet]);

    return;
};
