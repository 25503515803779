import * as React from "react";

export const BooleanDropdownEditor = React.forwardRef((props: { value: string }, ref) => {
    const [value, setValue] = React.useState(props.value.toString() || "false");
    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        setTimeout(() => refInput.current && refInput.current.focus());
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value === "true";
            },

            isCancelBeforeStart() {
                return false;
            },

            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        setValue(value);
        setTimeout(() => refInput.current && refInput.current.blur());
    };

    return (
        <select
            onChange={handleChange}
            className="w-full"
            ref={refInput}
            value={value}
            data-testid="boolean-dropdown-editor"
        >
            <option value="true">true</option>
            <option value="false">false</option>
        </select>
    );
});
