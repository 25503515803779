import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { apiBaseUrl } from "core/env";
import { getToken } from "core/auth";

export const createHubConnection = (hubName: string, onReconnecting: () => void) => {
    return new HubConnectionBuilder()
        .withUrl(`${apiBaseUrl}/hubs/${hubName}`, { accessTokenFactory: async () => (await getToken()).accessToken })
        .configureLogging(LogLevel.None)
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
                if (retryContext.elapsedMilliseconds >= 1_000 * 60 * 60 * 24 * 7) {
                    return null; // Stop reconnecting
                }

                onReconnecting();
                return 3_000; // Wait 3s before reconnecting
            },
        })
        .build();
};
