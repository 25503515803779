import { getPageTitle } from "common/utils/getPageTitle";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import { fetchOnReconnectSelector } from "features/realTime/realTimeSlice";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { TradesGrid, TradeUpdateNotification } from "./components";
import { useTradesHubConnection } from "./hooks/useTradesHubConnection";
import "./trades.styles.scss";
import { setIsTradesUpdateRequired, tradesSelector } from "./tradesSlice";
import { fetchTrades, refetchOnReconnect, refetchTrades } from "./tradesThunks";

export const Trades = () => {
    const dispatch = useDispatch();
    const {
        meta,
        autoUpdate,
        newTradeIds,
        lastRealTimeMessage,
        signalRGroupName,
        tradeDate,
        includeOnScreenTrades,
        isTradesUpdateRequired,
    } = useSelector(tradesSelector);

    const { selectedDeskId } = useSelector(desksSelector);
    const selectedDesk = useSelector(selectedDeskSelector);
    const fetchOnReconnect = useSelector(fetchOnReconnectSelector);
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    useTradesHubConnection(includeOnScreenTrades, selectedDesk?.topLevelDeskId, signalRGroupName);

    React.useEffect(() => {
        document.title = getPageTitle("Trades", selectedDesk);
    }, [selectedDesk]);

    // Fetch data if the trade date or the include on-screen trades checkbox changes
    React.useEffect(() => {
        if (deskHasBeenSet) {
            dispatch(fetchTrades(tradeDate, includeOnScreenTrades, selectedDeskId!));
        }
    }, [tradeDate, includeOnScreenTrades, selectedDeskId]);

    // Re-fetch when the signalR message comes in
    React.useEffect(() => {
        if (!lastRealTimeMessage || !deskHasBeenSet) return;

        if (meta.status !== "loading" && meta.status !== "refetching") {
            dispatch(
                refetchTrades(lastRealTimeMessage, autoUpdate, tradeDate, includeOnScreenTrades, selectedDesk ?? null),
            );
        } else {
            dispatch(setIsTradesUpdateRequired(true));
        }
    }, [lastRealTimeMessage]);

    React.useEffect(() => {
        if (!lastRealTimeMessage || !meta.status) return;

        if (meta.status !== "loading" && meta.status !== "refetching" && isTradesUpdateRequired) {
            dispatch(
                refetchTrades(lastRealTimeMessage, autoUpdate, tradeDate, includeOnScreenTrades, selectedDesk ?? null),
            );
        }
    }, [meta.status]);

    // Re-fetch when SignalR connection is re-established
    React.useEffect(() => {
        if ((fetchOnReconnect && deskHasBeenSet) || (autoUpdate && newTradeIds.length > 0 && deskHasBeenSet)) {
            dispatch(refetchOnReconnect(autoUpdate, tradeDate, includeOnScreenTrades, selectedDesk ?? null));
        }
    }, [fetchOnReconnect, autoUpdate]);

    if (meta.status === "error") return <div className="py-4 px-2">An error occurred</div>;
    return (
        <div className="flex flex-1 flex-col p-6 overflow-auto">
            <TradesGrid />
            <TradeUpdateNotification />
        </div>
    );
};
