import classNames from "classnames";
import { Check } from "common/icons";
import * as React from "react";

type Props = {
    checked: boolean;
    disabled?: boolean;
    onChange: () => void;
};

export const PassiveCheckbox: React.FC<Props> = ({ children, checked, disabled = false, onChange }) =>
    <div
        data-testid="checkbox"
        className={classNames("flex items-center cursor-pointer", { "opacity-50": disabled })}
        onClick={onChange}
    >
        <span
            className={classNames(
                "flex items-center justify-center border border-1 mr-2 rounded bg-white-900  border-white-100  w-4 h-4",
                { "bg-green-900": checked },
            )}
        >
            {checked && <Check />}
        </span>
        <input
            type="checkbox"
            checked={checked}
            className="hidden"
            readOnly
            data-testid="checkbox-input"
            disabled={disabled}
        ></input>
        {children}
    </div>