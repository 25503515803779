import { AppDispatch } from "core/store";
import * as counterPartyTypesApi from "api/counterPartyTypesApi";
import { getCounterPartyTypesSuccess, getCounterPartyTypesFailed } from "./counterPartyTypesSlice";

export const fetchCounterPartyTypes = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await counterPartyTypesApi.getCounterPartyTypes();
            dispatch(getCounterPartyTypesSuccess(response));
        } catch (err: any) {
            dispatch(getCounterPartyTypesFailed(err.message));
        }
    };
};
