import { ColumnResizedEvent } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { desksSelector } from "features/desks/desksSlice";
import { isEqual } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { weeklySpreaderSelector } from "./weeklySpreaderSlice";
import { isSpreaderCustomColumn } from "../utils/customColumns";
import { spreaderColumnWidth } from "../utils/constants";


export const useWeeklyGridState = () => {
    const [columnDefs, setColumnDefs] = React.useState<any>();
    const [gridWidth, setGridWidth] = React.useState<number>(0);
    const [columnState, setColumnState] = React.useState<{ [key: string]: any } | null>(null);
    const { selectedDeskId } = useSelector(desksSelector);
    const { risk } = useSelector(weeklySpreaderSelector);
    const [productGroupList, setProductGroupList] = React.useState<string[]>([]);
    

    const resizeGrid = (newColState?: any) => {
        const actualColState = newColState ?? columnState;

        if (actualColState) {
            setGridWidth((_prevState) => {
                let width = 0;
                const productIds = Object.keys(actualColState);

                productIds.forEach((productId) => {
                    if (actualColState[productId]) {
                        if (actualColState[productId][productId]) {
                            width += actualColState[productId][productId];
                        }
                        if (actualColState[productId].dummy) {
                            width += actualColState[productId].dummy;
                        }
                        if (actualColState[productId].spread) {
                            width += actualColState[productId].spread;
                        }
                        if (actualColState[productId]["spread-kt"]) {
                            width += actualColState[productId]["spread-kt"];
                        }
                    }
                });
                return width + spreaderColumnWidth.weeklyTenor + 2;
            });
        }
    };

    const gridRef = React.useRef<AgGridReact>(null);

    React.useEffect(() => {
        if (columnState) {
            resizeGrid();
        }
    }, [columnState, gridRef]);

    React.useEffect(() => {
        const productList = Object.keys(risk.productGroups);
        if (
            columnState === null || // Initial load
            columnState.deskId !== selectedDeskId || // Desk changed
            !isEqual(productList, productGroupList) // Product list changed
        ) {
            // View spreads only changed
            const newColumnState: any = { deskId: selectedDeskId };
            Object.keys(risk.productGroups).forEach((productGroupCode) => {
                const productGroupId = risk.productGroups[productGroupCode].productGroupId.toString();

                newColumnState[productGroupId] = {
                    [productGroupId]: spreaderColumnWidth.weeklyDefault,
                    spread: spreaderColumnWidth.weeklyDefault,
                    dummy: isSpreaderCustomColumn(productGroupId) ? 0 : spreaderColumnWidth.weeklyDefault,
                    ["spread-kt"]: risk.productGroups[productGroupCode].conversionFactor ? spreaderColumnWidth.weeklyDefault : 0,
                };
            });
            setColumnState(newColumnState);
            setProductGroupList(productList);
        }
    }, [selectedDeskId, risk]);

    const handleColumnResize = ({ finished, columns }: ColumnResizedEvent) => {
        if (finished && columns) {
            const newState = { ...columnState };

            for (const column of columns) {
                const width = column!.getActualWidth();
                const colId = column.getColId();

                if (colId && colId.indexOf("|") !== -1) {
                    const [productGroup, field] = colId.split("|");

                    if (newState[productGroup] && newState[productGroup][field]) {
                        newState[productGroup][field] = width;
                    }
                }
            }

            setColumnState(newState);
            resizeGrid(newState);
        }
    };

    return {
        gridWidth,
        setGridWidth,
        handleColumnResize,
        defaultColumnWidth: spreaderColumnWidth.weeklyDefault,
        tenorColumnWidth: spreaderColumnWidth.weeklyTenor,
        columnState,
        setColumnState,
        columnDefs,
        setColumnDefs,
        gridRef,
    };
};
