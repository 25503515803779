import { Menu, MenuButton, MenuItem, MenuItems, MenuPopover } from "@reach/menu-button";
import classNames from "classnames";
import { ChevronIcon } from "common/icons";
import * as React from "react";
import "./dropdown.styles.scss";

const findItem = (id: Common.Nullable<number>, items: Common.DropdownItem[]): Common.DropdownItem | undefined => {
    let item = items.find((i) => i.id === id);
    if (!item) {
        for (const i of items) {
            item = findItem(id, i.children || []);
            if (item) break;
        }
    }
    return item;
};

type Props = {
    selectedId: Common.Nullable<number>;
    data: Common.DropdownItem[];
    onChange(selectedItem?: Common.DropdownItem): boolean;
    className?: string;
    style?: React.CSSProperties;
    allowEmptySelection?: boolean;
    defaultSelectedId?: Common.Nullable<number>;
};

export const Dropdown: React.FC<Props> = ({
    selectedId,
    data,
    className,
    style,
    onChange,
    allowEmptySelection = true,
    defaultSelectedId = null,
}) => {
    const [selected, setSelected] = React.useState<Common.DropdownItem | undefined>();

    React.useEffect(() => {
        if (defaultSelectedId) {
            const selectedItem = findItem(defaultSelectedId, data);
            onChange(selectedItem) && setSelected(selectedItem);
        }
    }, []);

    React.useEffect(() => {
        setSelected(findItem(selectedId, data));
    }, [selectedId]);

    const handleSelected = (selectedItem?: Common.DropdownItem) => {
        onChange(selectedItem) && setSelected(selectedItem);
    };

    const renderChildren = (children: Common.DropdownItem[], level: number) => {
        // Create padding based on level, if greater than 1
        // i.e. if level = 2 then the left padding is 8
        const indentStyle: { [key: string]: boolean } = {};
        indentStyle[`pl-${level * 4}`] = level > 1;

        return children.map((child) => (
            <React.Fragment key={child.id}>
                <MenuItem
                    data-testid={`dropdown-selection-item-${child.id}`}
                    className={classNames(
                        "h-8 pl-6 pr-4 py-2 text-xs cursor-pointer",
                        {
                            ...indentStyle,
                            "desk__item--selected": selectedId && child.id === selectedId,
                        },
                        child.disabled ? "text-white-100" : "text-gray-900",
                    )}
                    onSelect={() => handleSelected(child)}
                >
                    {child.value}
                </MenuItem>
                {child.children && renderChildren(child.children, level + 1)}
            </React.Fragment>
        ));
    };

    return (
        <Menu data-testid="dropdown">
            <MenuButton
                className="flex justify-between focus:outline-none px-2 py-1 rounded-lg font-bold text-xs bg-white-900"
                data-testid="dropdown-button"
            >
                <span data-testid="dropdown-selected-text">{selected?.value}</span>
                <span aria-hidden="true">
                    <ChevronIcon className="inline ml-2 mb-0.5 align-middle" />
                </span>
            </MenuButton>
            <MenuPopover
                className={classNames(
                    "overflow-x-hidden overflow-y-auto bg-white-900 z-10 absolute rounded-lg shadow-lg w-32",
                    className,
                )}
                style={style}
                portal
            >
                <MenuItems className="dropdown flex flex-col bg-white-900 rounded-lg border-0 focus:outline-none shadow-lg">
                    {allowEmptySelection && (
                        <MenuItem
                            data-testid={`dropdown-selection-item-blank`}
                            className={classNames("h-8 px-4 py-2 text-gray-900 text-xs cursor-pointer", {
                                "dropdown__item--selected": false,
                            })}
                            onSelect={handleSelected}
                        >
                            {""}
                        </MenuItem>
                    )}
                    {data.map((item) => (
                        <React.Fragment key={item.id}>
                            <MenuItem
                                data-testid={`dropdown-selection-item`}
                                className={classNames(
                                    "px-4 py-2 text-xs cursor-pointer",
                                    {
                                        "dropdown__item--selected": item.value === selected?.value,
                                    },
                                    item.disabled ? "text-white-100" : "text-gray-900",
                                )}
                                onSelect={() => handleSelected(item)}
                            >
                                {item.value}
                            </MenuItem>
                            {item.children && renderChildren(item.children, 1)}
                        </React.Fragment>
                    ))}
                </MenuItems>
            </MenuPopover>
        </Menu>
    );
};
