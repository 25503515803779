import * as React from "react";

export const ReconnectingIcon: React.FC = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="8" fill="#F3F3F3" />
        <g clipPath="url(#clip0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 16C24 20.4183 20.4183 24 16 24C11.5817 24 8 20.4183 8 16C8 11.5817 11.5817 8 16 8C20.4183 8 24 11.5817 24 16ZM17 20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20C15 19.4477 15.4477 19 16 19C16.5523 19 17 19.4477 17 20ZM16 11C15.4477 11 15 11.4477 15 12V16C15 16.5523 15.4477 17 16 17C16.5523 17 17 16.5523 17 16V12C17 11.4477 16.5523 11 16 11Z"
                fill="#D97706"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
);
