import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Modal } from "common/components/modal";
import { CustomRoute } from "common/components/routing";
import { Case, SwitchStatement } from "common/components/switchStatement";
import { reactPlugin } from "core/appInsights";
import { ignoreMonitoringForAccount, isProduction } from "core/env";
import { fullPageRoutes, routes, windowRoutes } from "core/routes";
import { getAccountSelector } from "features/auth/authSlice";
import { fetchAccount } from "features/auth/authThunks";
import { setShowBlotterEntryForm } from "features/blotter/blotterThunks";
import { fetchBlotterProductGroups } from "features/blotterProductGroups/blotterProductGroupsThunks";
import { fetchBrokers } from "features/brokers/brokersThunks";
import {
    deskHasBeenSetSelector,
    desksSelector,
    findDeskById,
    getProductGroupsSuccess,
    setSelectedDeskId,
    setStatus,
} from "features/desks/desksSlice";
import { fetchDeskProductGroups, fetchDesks, setDesk } from "features/desks/desksThunks";
import { Navigation } from "features/navigation";
import { ErrorPage, LoadingPage } from "features/pages";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { isChildRoute } from "./features/navigation";
import { datadogRum } from "@datadog/browser-rum";

const loadRoutes = ({ name, to, exact, component, role, childRoutes, redirectTo, render }: Common.Route) => {
    return (
        <CustomRoute key={name} path={to} exact={exact} component={component} render={render} role={role}>
            {childRoutes && childRoutes.length > 0 && (
                <Switch>
                    {childRoutes.map((childRoute) => isChildRoute(childRoute) && loadRoutes(childRoute))}
                    {redirectTo && <Redirect to={redirectTo} />}
                </Switch>
            )}
        </CustomRoute>
    );
};

export const App = (): JSX.Element => {
    const dispatch = useDispatch();
    const {
        isOnyxDeskSelected,
        desks,
        selectedDeskId,
        meta: { status: desksStatus },
    } = useSelector(desksSelector);

    const {
        meta: { status: accountStatus },
        account,
        hasAdminRole,
    } = useSelector(getAccountSelector);

    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const [showLoadingPage, setShowLoadingPage] = React.useState(true);

    React.useEffect(() => {
        if (account && isProduction() && !ignoreMonitoringForAccount(account)) {
            // Datadog initialisation
            datadogRum.init({
                applicationId: "785d033e-2936-47d3-9b6c-5441200dc516",
                clientToken: "pub76b2fd648c2f60ca2e37734ff9acabf0",
                site: "datadoghq.eu",
                service: "eagle",
                env: process.env.ENVIRONMENT,
                // Specify a version number to identify the deployed version of your application in Datadog
                // version: '1.0.0',
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: "allow",
                allowedTracingUrls: [
                    process.env.API_BASE_URL ?? "",
                    "https://stonyxeagleuiprod.z33.web.core.windows.net",
                ],
            });

            datadogRum.setUser({
                id: account.id,
                name: account.name,
                email: account.email,
            });

            datadogRum.startSessionReplayRecording();
        }
    }, [account]);

    React.useEffect(() => {
        dispatch(fetchAccount());
        /* istanbul ignore next */
        dispatch(setSelectedDeskId(sessionStorage.deskId ? +sessionStorage.deskId : undefined));
        dispatch(
            setShowBlotterEntryForm(
                localStorage.showBlotterEntryForm === undefined || localStorage.showBlotterEntryForm === "true",
            ),
        );
    }, [dispatch]);

    React.useEffect(() => {
        if (accountStatus === "loaded") {
            dispatch(fetchDesks(hasAdminRole));
            dispatch(fetchBlotterProductGroups());
            dispatch(fetchBrokers());
        }
    }, [accountStatus, hasAdminRole]);

    React.useEffect(() => {
        if (accountStatus === "loaded") {
            if (selectedDeskId) {
                dispatch(fetchDeskProductGroups(selectedDeskId));
            } else {
                dispatch(getProductGroupsSuccess([]));
            }
        }
    }, [accountStatus, selectedDeskId]);

    React.useEffect(() => {
        if (accountStatus === "loaded" && account && desksStatus === "loaded") {
            if (desks.length > 0) {
                // Default to the primary desk if the selected desk is blank or not in the list of available desks, if no primary desk select the first desk
                const hasSelectedDesk = selectedDeskId && findDeskById(selectedDeskId, desks);

                if (!isOnyxDeskSelected && !hasSelectedDesk) {
                    const mainPrimaryDesk = desks
                        .find((a) => a.isPrimaryDesk === true)
                        ?.childDesks?.find((a) => a.isMainSubDesk === true);
                    const firstTopLevelDesk = desks[0];
                    const mainFirstDesk = firstTopLevelDesk?.childDesks.find((a) => a.isMainSubDesk === true);

                    // All users default to their main primary desk if they have one
                    if (mainPrimaryDesk) {
                        dispatch(setDesk(mainPrimaryDesk));
                    }
                    // Non-admin users without a primary desk will default to the first main desk - or top level desk if no main desk exists
                    else if (!hasAdminRole) {
                        if (mainFirstDesk) {
                            dispatch(setDesk(mainFirstDesk));
                        } else {
                            dispatch(setDesk(firstTopLevelDesk));
                        }
                    }
                    // Admin users without a primary desk will default to the Onyx desk (no desk)
                    else {
                        dispatch(setDesk());
                    }
                }
            }
            // Admin users without any desks will default to the Onyx desk (no desk)
            else if (hasAdminRole) {
                dispatch(setDesk());
            }
            // Set the page as loaded for users without any desks
            else {
                dispatch(setStatus({ status: "loaded" }));
            }
        }
    }, [account, accountStatus, selectedDeskId, desks, isOnyxDeskSelected, desksStatus]);

    React.useEffect(() => {
        const desksHaveLoaded = desksStatus === "loaded";

        if (desks.length > 0) {
            setShowLoadingPage(!desksHaveLoaded || !deskHasBeenSet);
        } else {
            setShowLoadingPage(!desksHaveLoaded);
        }
    }, [desks, desksStatus, deskHasBeenSet]);

    const reload = () => {
        location.reload();
    };

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Router>
                <main className="flex flex-col h-screen bg-white-300">
                    <SwitchStatement test={accountStatus}>
                        <Case value="loading">
                            <LoadingPage />
                        </Case>
                        <Case value={["loaded", "expired"]}>
                            {showLoadingPage ? (
                                <LoadingPage />
                            ) : (
                                <Switch>
                                    {fullPageRoutes.map(({ name, to, component }) => (
                                        <CustomRoute key={name} path={to} component={component} />
                                    ))}
                                    {windowRoutes.map(({ name, to, component, render }) => (
                                        <CustomRoute key={name} path={to} component={component} render={render} />
                                    ))}
                                    <CustomRoute path="/" role="UI.Access">
                                        {accountStatus === "expired" && (
                                            <Modal
                                                title="Session expired"
                                                headerText=""
                                                onClose={reload}
                                                actions={[
                                                    { id: "reload", text: "OK", action: reload, mode: "primary" },
                                                ]}
                                                message="Your session has expired. Click OK to refresh your browser."
                                            />
                                        )}
                                        <Navigation />
                                        <Switch>{routes.map((route) => loadRoutes(route))}</Switch>
                                    </CustomRoute>
                                </Switch>
                            )}
                        </Case>
                        <Case value="error">
                            <ErrorPage />
                        </Case>
                        <Case value="init">
                            <LoadingPage />
                        </Case>
                    </SwitchStatement>
                </main>
            </Router>
        </AppInsightsContext.Provider>
    );
};
