import classNames from "classnames";
import {
    blotterEntryFormContainerSelector,
    canViewBlotterEntryFormSelector,
    initialiseBlotterEntry,
} from "features/blotter/blotterSlice";
import { BlotterEntryForm, BlotterEntryFormHeader } from "features/blotter/components/blotterEntryForm";
import { selectedDeskSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const BlotterEntryFormContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { showBlotterEntryForm, blotterDate } = useSelector(blotterEntryFormContainerSelector);
    const canViewBlotterEntryForm = useSelector(canViewBlotterEntryFormSelector);

    const selectedDesk = useSelector(selectedDeskSelector);

    React.useEffect(() => {
        if (selectedDesk) {
            dispatch(initialiseBlotterEntry({ date: blotterDate, deskId: selectedDesk.deskId }));
        }
    }, [selectedDesk, blotterDate]);

    if (!canViewBlotterEntryForm) return null;

    return (
        <div
            className={classNames(" flex flex-col mt-2", {
                "blotterEntryForm__container flex-1": showBlotterEntryForm,
            })}
        >
            <BlotterEntryFormHeader />
            <BlotterEntryForm show={showBlotterEntryForm} />
        </div>
    );
};
