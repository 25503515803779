import * as tradesApi from "api/tradesApi";
import { AppDispatch } from "core/store";
import {
    setStatus,
    getTradesFailed,
    getTradesSuccess,
    getTradesUpdateSuccess,
    setIsTradesUpdateRequired,
} from "./tradesSlice";
import { TradeRealTimeMessage } from "./tradesTypes";
import { parseISO, format } from "date-fns";
import { DeskDTO } from "features/desks/desksTypes";
import { resetFetchOnReconnect } from "features/realTime/realTimeSlice";

export const fetchTrades = (
    tradeDate: Common.Nullable<string>,
    includeOnScreenTrades: boolean = false,
    selectedDeskId: Common.Nullable<number>,
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setStatus("loading"));
        dispatch(setIsTradesUpdateRequired(false));

        try {
            const tradesResponse = await tradesApi.getTrades(tradeDate, includeOnScreenTrades, selectedDeskId);
            dispatch(getTradesSuccess(tradesResponse));
        } catch (err: any) {
            dispatch(getTradesFailed(err.message));
        }
    };
};

export const refetchTrades = (
    message: TradeRealTimeMessage,
    autoUpdate: boolean,
    tradeDate: Common.Nullable<string>,
    includeOnScreenTrades: boolean = false,
    desk: Common.Nullable<DeskDTO>,
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("refetching"));
            dispatch(setIsTradesUpdateRequired(false));

            const incomingTradeDate = format(parseISO(message.tradeDate), "dd/MM/yyyy");
            const currentTradeDate = tradeDate
                ? format(parseISO(tradeDate), "dd/MM/yyyy")
                : format(new Date(), "dd/MM/yyyy");

            if (
                incomingTradeDate !== currentTradeDate ||
                (message.isOnScreenTrade && !includeOnScreenTrades) ||
                (desk && desk.deskId !== message.deskId && desk.deskId !== message.topLevelDeskId)
            ) {
                dispatch(setStatus("loaded"));
                return;
            }

            const tradesResponse = await tradesApi.getTrades(
                tradeDate,
                includeOnScreenTrades,
                desk ? desk.deskId : null,
            );
            dispatch(getTradesUpdateSuccess({ autoUpdate, response: tradesResponse }));
        } catch (err) {
            dispatch(setStatus("loaded"));
        }
    };
};

export const refetchOnReconnect = (
    autoUpdate: boolean,
    tradeDate: Common.Nullable<string>,
    includeOnScreenTrades: boolean,
    desk: Common.Nullable<DeskDTO>,
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setStatus("loading"));
            dispatch(setIsTradesUpdateRequired(false));

            const tradesResponse = await tradesApi.getTrades(
                tradeDate,
                includeOnScreenTrades,
                desk ? desk.deskId : null,
            );
            dispatch(getTradesUpdateSuccess({ autoUpdate, response: tradesResponse }));
        } catch {
            dispatch(setStatus("loaded"));
        } finally {
            dispatch(resetFetchOnReconnect());
        }
    };
};
