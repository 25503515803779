import { CROSS_EXCHANGE_RISK, LIVE_RISK, LIVE_RISK_TYPE_BLOTTER, LIVE_RISK_TYPE_EXCHANGE } from "common/constants";
import { getPageTitle } from "common/utils/getPageTitle";
import { toTitleCase } from "common/utils/toTitleCase";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import { RiskSummaryItemsGrid } from "features/riskSummaryItems/riskSummaryItemsGrid";
import { riskSummaryItemsSelector } from "features/riskSummaryItems/riskSummaryItemsSlice";
import { fetchRiskSummaryItems } from "features/riskSummaryItems/riskSummaryItemsThunks";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLiveRiskHubConnection } from "./hooks/useLiveRiskHubConnection";
import "./liveRisk.styles.scss";
import { LiveRiskGrid } from "./liveRiskGrid";
import { liveRiskRealTimeDataSelector, setIsCrossExchangeView } from "./liveRiskSlice";

type Props = {
    match?: any;
};

export const LiveRisk: React.FC<Props> = ({ match }) => {
    const { riskSummaryItems } = useSelector(riskSummaryItemsSelector, shallowEqual);
    const hasRiskSummaryItemItems = riskSummaryItems && riskSummaryItems.length > 0;
    const { params } = match || {};
    const { blotter, exchange, cross } = params || {};

    const { signalRGroupName, includeTas, rollForwardDateTime, isCrossExchangeView } = useSelector(
        liveRiskRealTimeDataSelector,
        shallowEqual,
    );
    const deskHasBeenSet = useSelector(deskHasBeenSetSelector);

    const history = useHistory();

    const dispatch = useDispatch();
    const { selectedDeskId } = useSelector(desksSelector);
    const selectedDesk = useSelector(selectedDeskSelector);

    useLiveRiskHubConnection(selectedDesk?.topLevelDeskId, signalRGroupName);

    React.useEffect(() => {
        if (cross === "cross") {
            dispatch(setIsCrossExchangeView(true));
        }
    }, []);

    React.useEffect(() => {
        const viewTypeUrlSegment = isCrossExchangeView ? `${LIVE_RISK}/${CROSS_EXCHANGE_RISK}` : LIVE_RISK;
        history.push(
            !isCrossExchangeView
                ? `/risk/${viewTypeUrlSegment}/${blotter || exchange}`
                : `/risk/${viewTypeUrlSegment}/${blotter || exchange}`,
        );
    }, [isCrossExchangeView]);

    React.useEffect(() => {
        document.title = getPageTitle(`${toTitleCase(blotter || exchange)} Live Risk`, selectedDesk);
    }, [selectedDesk, blotter, exchange, isCrossExchangeView]);

    React.useEffect(() => {
        if (
            deskHasBeenSet &&
            [LIVE_RISK_TYPE_BLOTTER, LIVE_RISK_TYPE_EXCHANGE].some((p) => p === (blotter || exchange))
        ) {
            dispatch(fetchRiskSummaryItems(blotter || exchange, selectedDeskId!, includeTas, rollForwardDateTime));
        }
    }, [selectedDeskId, blotter, exchange, includeTas, rollForwardDateTime, isCrossExchangeView]);

    return (
        <div className="flex flex-1 p-6 overflow-auto" data-testid="live-risk-container">
            <div className="flex flex-col flex-1 mr-2" style={{ width: hasRiskSummaryItemItems ? "85%" : "100%" }}>
                <LiveRiskGrid />
            </div>
            {hasRiskSummaryItemItems && (
                <div className="flex flex-col flex-1 ml-2" style={{ width: "15%" }}>
                    <RiskSummaryItemsGrid />
                </div>
            )}
        </div>
    );
};
