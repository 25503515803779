import { ICellRendererParams } from "@ag-grid-community/core";
import * as React from "react";
import classNames from "classnames";
import { CANCEL, DELETE, DUPLICATE, EDIT } from "features/blotter/hooks/constants";
import { useSelector } from "react-redux";
import { blotterEditingRowSelector } from "features/blotter/blotterSlice";

export const ActionButtons: React.FC<ICellRendererParams> = (props: ICellRendererParams) => {
    const { node, data } = props;
    const editingRow = useSelector(blotterEditingRowSelector);

    const matchedTrades = !!data.matchedTrades;
    const isCurrentRowEditing = node.rowIndex === editingRow;

    const actionButton = (text: string, dataAction: string, matchedTrades: boolean, dataTestId: String) => (
        <button
            data-testid={dataTestId}
            className={classNames("border leading-5 px-2 rounded-md outline-none focus:outline-none", {
                "border-gray-900": !matchedTrades,
                "border-white-900": matchedTrades,
            })}
            data-action={dataAction}
        >
            {text}
        </button>
    );

    return (
        <>
            <span className="mr-0.5">
                {isCurrentRowEditing
                    ? actionButton("Cancel", CANCEL, matchedTrades, "blotter-grid-edit-button")
                    : actionButton("Edit", EDIT, matchedTrades, "blotter-grid-edit-button")}
            </span>
            <span className="mx-0.5">
                {actionButton("Delete", DELETE, matchedTrades, "blotter-grid-delete-button")}
            </span>
            <span className="ml-0.5">
                {actionButton("Duplicate", DUPLICATE, matchedTrades, "blotter-grid-duplicate-button")}
            </span>
        </>
    );
};
