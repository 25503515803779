import { Button } from "common/components/button";
import { ChevronDoubleLeft, ChevronDoubleRight, SaveIcon } from "common/icons";
import { desksSelector } from "features/desks/desksSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseDecimalPosition, increaseDecimalPosition } from "././weeklySpreaderSlice";
import { OpenNewWindow } from "common/components/openNewWindow";

type Props = {
    sumRef: any;
    avgRef: any;
    onAutoFlatten(): void;
    onExportToCSV(): void;
    onClearAll(): void;
};

export const WeeklySpreaderGridHeader: React.FC<Props> = ({
    onExportToCSV,
    onAutoFlatten,
    onClearAll,
    sumRef,
    avgRef,
}) => {
    const dispatch = useDispatch();
    const { selectedDeskId } = useSelector(desksSelector);
    const headerStyle = `flex flex-wrap items-center p-2 border border-white-700 font-bold text-left text-xs`;

    return (
        <div className="border-white-700 text-black-800 bg-white-700">
            <div data-testid="spreader-grid-header" className={headerStyle}>
                <div className="uppercase">Weekly Spreader</div>
                {selectedDeskId && (
                    <>
                        <div className="ml-4">
                            <Button
                                data-testid="spreader-auto-flatten"
                                size="sm"
                                mode="none"
                                className="bg-white-500"
                                onClick={onAutoFlatten}
                            >
                                Auto Flatten
                            </Button>
                        </div>
                        <div className="ml-2">
                            <Button
                                data-testid="spreader-clear-all"
                                size="sm"
                                mode="none"
                                className="bg-white-500"
                                onClick={onClearAll}
                            >
                                Clear All
                            </Button>
                        </div>
                    </>
                )}
                <div className="ml-auto flex">
                    <div className="self-center mr-4">
                        <label ref={sumRef}></label>
                    </div>
                    <div className="self-center mr-4">
                        <label ref={avgRef}></label>
                    </div>
                    <ChevronDoubleLeft
                        data-testid="spreader-decrease"
                        onClick={() => dispatch(decreaseDecimalPosition())}
                        className="cursor-pointer select-none"
                    />
                    <ChevronDoubleRight
                        data-testid="spreader-increase"
                        onClick={() => dispatch(increaseDecimalPosition())}
                        className="cursor-pointer select-none"
                    />
                    <SaveIcon
                        data-testid="spreader-csv"
                        className="cursor-pointer select-none mx-1"
                        onClick={onExportToCSV}
                    />
                    <OpenNewWindow name="spreader" relativeUrl={`/window/spreader/weekly`} />
                </div>
            </div>
        </div>
    );
};
