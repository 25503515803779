import * as React from "react";
import delay from "lodash/delay";

export const NumericEditor = React.forwardRef((props: { value: string }, ref) => {
    const [value, setValue] = React.useState(props.value || "");
    const refInput = React.useRef<any>(null);

    React.useEffect(() => {
        delay(() => refInput.current && refInput.current.select(), 0);
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value === "" ? null : +value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <input
            data-testid="numeric-editor-input"
            type="number"
            ref={refInput}
            value={value}
            onChange={handleChange}
            className="w-full"
        />
    );
});
