import { ColumnGroupOpenedEvent, ProcessHeaderForExportParams, ValueFormatterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import Big from "big.js";
import classNames from "classnames";
import { GridLoading, GroupHeader, TenorYearToggle } from "common/components/grid";
import { defaultGridHeaderHeight } from "common/constants";
import { useCustomLocationProperties } from "common/hooks";
import { Collapse, Expand } from "common/icons";
import { formatNumberWithCommas } from "common/utils/numberFormatHelpers";
import {
    diffProductValueGetter,
    getProductGroupTotal,
    getTenorGrandTotal,
    productValueGetter,
} from "common/utils/risk";
import { desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import _ from "lodash";
import capitalize from "lodash/capitalize";
import * as React from "react";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { EODRiskHeader } from "./eodRiskHeader";
import {
    eodRiskSelector,
    productDiffsByProductGroupSelector,
    productGroupsSelector,
    productsByProductGroupSelector,
    riskItemsByGroupSelector,
    riskItemsByTenorGrandTotalSelector,
    riskItemsByTenorSelector,
    toggleGroupedYear,
} from "./eodRiskSlice";
import { useGridState } from "./useGridState";
import { getColumnDefs } from "./columnDefs";

/* istanbul ignore next */
export const EODRiskGrid: React.FC = () => {
    const {
        gridRef,
        gridWidth,
        setGridWidth,
        grandTotalColumnWidth,
        columnState,
        setColumnState,
        getProductGroupColumnSettings,
        getProductColumnSettings,
        getDiffProductColumnSettings,
        setColumnDefs,
        columnDefs,
        calculateWidth,
        handleColumnResize,
    } = useGridState();

    const minWidth = 500;
    const dispatch = useDispatch();

    const selectedDesk = useSelector(selectedDeskSelector);
    const { selectedDeskId } = useSelector(desksSelector);

    const { shortPath } = useCustomLocationProperties();

    const { meta, riskItems, decimalPosition, groupedYears } = useSelector(eodRiskSelector);
    const productGroups = useSelector(productGroupsSelector);

    const riskItemsByGroup = useSelector(riskItemsByGroupSelector);
    const riskItemsByTenor = useSelector(riskItemsByTenorSelector);
    const riskItemsByTenorGrandTotal = useSelector(riskItemsByTenorGrandTotalSelector);
    const productsByProductGroup = useSelector(productsByProductGroupSelector);
    const productDiffsByProductGroup = useSelector(productDiffsByProductGroupSelector);

    const valueFormatter = React.useCallback(
        ({ value }: ValueFormatterParams) =>
            value !== null ? formatNumberWithCommas(new Big(value).toFixed(decimalPosition, Big.roundHalfEven)) : "",
        [decimalPosition],
    );

    const productGroupsLoaded = productGroups !== null;
    const noDataAvailable =
        _.isEmpty(riskItems) || _.isEmpty(riskItemsByGroup) || _.isEmpty(riskItemsByTenor) || !productGroupsLoaded;

    const handleProcessHeaderForClipboard = (params: ProcessHeaderForExportParams) => {
        const colDef = params.column.getColDef();
        const { headerName, refData } = colDef;

        if (refData === undefined) return headerName;

        let { productGroup, product, diffProduct } = refData;
        productGroup = productGroup || "";
        product = product ? `-${product}` : "";
        diffProduct = diffProduct ? `-${diffProduct}` : "";

        return `${productGroup}${product}${diffProduct}` || headerName;
    };

    React.useEffect(() => {
        if (productGroups) {
            setColumnDefs(
                getColumnDefs({
                    riskItemsByTenor,
                    groupedYears,
                    dispatch,
                    toggleGroupedYear,
                    productGroups,
                    getProductGroupColumnSettings,
                    valueFormatter,
                    getProductGroupTotal,
                    riskItems,
                    riskItemsByGroup,
                    getProductColumnSettings,
                    productValueGetter,
                    productsByProductGroup,
                    getDiffProductColumnSettings,
                    columnState,
                    grandTotalColumnWidth,
                    getTenorGrandTotal,
                    diffProductValueGetter,
                    productDiffsByProductGroup,
                }),
            );

            setGridWidth((prevState) => calculateWidth(prevState));
        }
    }, [columnState, decimalPosition, riskItems, groupedYears, productGroups, selectedDeskId]);

    return (
        <div data-testid={`eodRisk-grid`} className={classNames("eodRisk-grid flex-1 bg-white-300")}>
            <div
                className={classNames("ag-theme-alpine h-full", {
                    "opacity-50": meta.status === "loading" && !noDataAvailable,
                })}
                style={{ minWidth: `${minWidth}px`, maxWidth: "100%", width: gridWidth ? `${gridWidth}px` : "100%" }}
            >
                <EODRiskHeader
                    onExportToCSV={() =>
                        gridRef.current &&
                        gridRef.current.api?.exportDataAsCsv({
                            fileName: `EOD_Risk_${capitalize(shortPath)}_Based_${selectedDesk?.name ?? "Onyx"}.csv`,
                        })
                    }
                />
                {(() => {
                    if (noDataAvailable && productGroupsLoaded && meta.status === "loaded")
                        return (
                            <div data-testid={`no-data`} className="py-4 px-2 text-sm">
                                No data available
                            </div>
                        );

                    if (noDataAvailable && (!productGroupsLoaded || meta.status === "loading")) return <GridLoading />;

                    if (meta.status === "error") return <div className="py-4 px-2 text-sm">An error occurred</div>;

                    if (riskItemsByTenor.length === 0) {
                        return <></>;
                    }

                    return (
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            onColumnResized={handleColumnResize}
                            modules={[RangeSelectionModule, ClipboardModule]}
                            enableRangeSelection={true}
                            suppressRowHoverHighlight={true}
                            enableCellTextSelection={false}
                            copyHeadersToClipboard={true}
                            processHeaderForClipboard={handleProcessHeaderForClipboard}
                            defaultCsvExportParams={{
                                processCellCallback: ({ value, column }) => {
                                    if (!value || !isFinite(value)) return value;

                                    const colId = column ? column.getColId() : "";
                                    if (colId === "year") return value;

                                    return formatNumberWithCommas(
                                        new Big(value).toFixed(decimalPosition, Big.roundHalfEven),
                                    );
                                },
                            }}
                            suppressExcelExport
                            suppressRowTransform={true}
                            popupParent={document.body}
                            components={{
                                TenorYearToggle,
                                Expand,
                                Collapse,
                                GroupHeader,
                            }}
                            defaultColDef={{
                                resizable: true,
                                sortable: false,
                                filter: false,
                                floatingFilter: false,
                                suppressMovable: true,
                                flex: gridWidth < minWidth ? 1 : 0,
                            }}
                            headerHeight={defaultGridHeaderHeight}
                            rowStyle={{ borderTopStyle: "none" }}
                            domLayout="autoHeight"
                            ensureDomOrder
                            rowData={riskItemsByTenor}
                            pinnedBottomRowData={[riskItemsByTenorGrandTotal]}
                            icons={{
                                sortAscending: `<span style="font-size: 10px;">&#9650;</span>`,
                                sortDescending: `<span style="font-size: 10px;">&#9660;</span>`,
                                columnGroupOpened: renderToString(<Collapse />),
                                columnGroupClosed: renderToString(<Expand />),
                                menu: "",
                                resize: "",
                            }}
                            getRowHeight={(params: any) => (params.node.isRowPinned() ? 50 : undefined)}
                            onColumnGroupOpened={(event: ColumnGroupOpenedEvent) => {
                                const { api, columnApi } = gridRef?.current || {};

                                if (!api) return;

                                // Handle when a column is expanded or collapsed
                                const level = event.columnGroup.getLevel();
                                const isExpanded = event.columnGroup.isExpanded();
                                const groupId = event.columnGroup.getColGroupDef()?.groupId;
                                const parent = event.columnGroup.getOriginalParent();
                                const parentGroupId = parent?.getColGroupDef()?.groupId;
                                const headerName = event.columnGroup.getColGroupDef()?.headerName ?? "";

                                const noOfGroupsExpanded = columnState
                                    ? Object.keys(columnState)
                                          .map((groupId) => columnState[groupId] && columnState[groupId].expanded)
                                          .filter(Boolean).length
                                    : 0;

                                // Handle when a top level group is expanded or collapsed
                                if (level === 0 && noOfGroupsExpanded === 0 && isExpanded) {
                                    // If expand and we don't already have any expanded groups then expand the header height and column width
                                    api && api.setHeaderHeight(75);
                                } else if (level === 0 && noOfGroupsExpanded === 1 && !isExpanded) {
                                    // If collapse and this is the last group expanded, then collapse the header height
                                    api && api.setHeaderHeight(defaultGridHeaderHeight);
                                }

                                // Update the local grouping state
                                setColumnState((prevState: any) => {
                                    const newState = { ...prevState };

                                    // If there is no group id set then just return prevState
                                    if (!groupId) return prevState;

                                    switch (level) {
                                        case 0:
                                            // Initialise the state for the given groupId key
                                            newState[groupId] = newState[groupId] || {
                                                children: {},
                                            };
                                            // Set the expanded state
                                            newState[groupId].expanded = isExpanded;

                                            // If collapse, iterate through the children and collapse all children
                                            if (!isExpanded) {
                                                Object.keys(newState[groupId].children).forEach((c) => {
                                                    // Initialise the child key if not already created and set the expanded state
                                                    newState[groupId].children[c] = newState[groupId].children[c] || {};
                                                    newState[groupId].children[c].expanded = false;

                                                    // Iterate through all products and set the corresponding column expanded state
                                                    Object.keys(riskItemsByGroup[groupId]).map((product) => {
                                                        columnApi?.setColumnGroupOpened(`${groupId}-${product}`, false);
                                                    });
                                                });
                                            }
                                            break;
                                        case 1:
                                            // If there is not parent group id then return
                                            if (!parentGroupId) return prevState;

                                            // Initialise the parent group and children
                                            newState[parentGroupId].children[headerName] =
                                                newState[parentGroupId].children[headerName] || {};

                                            newState[parentGroupId].children[headerName].expanded = isExpanded;
                                            break;
                                        default:
                                            return prevState;
                                    }

                                    return newState;
                                });
                            }}
                        />
                    );
                })()}
            </div>
        </div>
    );
};
