import { ChevronDoubleDown, ChevronDoubleUp } from "common/icons";
import { blotterSelector } from "features/blotter/blotterSlice";
import { setShowBlotterEntryForm } from "features/blotter/blotterThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const BlotterEntryFormHeader: React.FC = () => {
    const dispatch = useDispatch();
    const { showBlotterEntryForm } = useSelector(blotterSelector);
    return (
        <div
            data-testid="blotterEntryFormHeader"
            className="flex items-center justify-center p-2 border border-white-700 font-bold text-left text-black-800 bg-white-700 text-xs"
        >
            <div
                data-testid="toggle"
                className="flex flex-1 justify-center uppercase cursor-pointer "
                onClick={() => dispatch(setShowBlotterEntryForm(!showBlotterEntryForm))}
            >
                {showBlotterEntryForm ? <ChevronDoubleDown data-testid="down" /> : <ChevronDoubleUp data-testid="up" />}
            </div>
        </div>
    );
};
