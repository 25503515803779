import * as blotterApi from "api/blotterApi";
import { resolveDate } from "common/utils/resolveDate";
import { AppDispatch, RootState } from "core/store";
import { deskHasBeenSetSelector, desksSelector, selectedDeskSelector } from "features/desks/desksSlice";
import {
    clearBlotterEntry,
    getBlotterTradesFailed,
    getBlotterTradesSuccess,
    setBlotterTradeToDelete,
    setBlotterTradeToDuplicate,
    setIsBlotterUpdateRequired,
    setStatus,
    setValidationMessage,
    showBlotterEntryFormSelector,
    toggleBlotterEntryForm,
    updateBlotterTrade,
} from "./blotterSlice";
import { differenceInDays } from "date-fns";
import {
    BlotterTradePnlRealTimeMessage,
    BlotterTradeRealTimeMessage,
    CreateBlotterTrade,
    isBlotterTradePnlMessage,
} from "./blotterTypes";
import { DeskDTO } from "features/desks/desksTypes";
import throttle from "lodash/throttle";
import { format, parseISO } from "date-fns";
import { resetFetchOnReconnect } from "features/realTime/realTimeSlice";
import { appInsights } from "core/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { NOT_SELECTED_COUNTERPARTY_ID } from "./hooks/constants";

export const fetchBlotterTrades = (blotterDate: string, deskId: Common.Nullable<number>) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setIsBlotterUpdateRequired(false));
        dispatch(setStatus("loading"));

        try {
            const blotterTradesResponse = await blotterApi.getBlotterTrades(blotterDate, deskId);

            dispatch(getBlotterTradesSuccess(blotterTradesResponse));
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { customDescription: "Error in blotterThunks.ts fetchBlotterTrades" },
            );
            dispatch(getBlotterTradesFailed(error.message));
        }
    };
};

const throttledRefetch = throttle(
    async (
        dispatch: AppDispatch,
        message: BlotterTradeRealTimeMessage | BlotterTradePnlRealTimeMessage,
        autoUpdate: boolean,
        tradeDate: string,
        desk: Common.Nullable<DeskDTO>,
    ) => {
        try {
            if (!autoUpdate) {
                dispatch(setIsBlotterUpdateRequired(true));
                return;
            }

            dispatch(setIsBlotterUpdateRequired(false));
            dispatch(setStatus("refetching"));

            if (!isBlotterTradePnlMessage(message)) {
                const incomingTradeDate = format(parseISO(message.tradeDate), "dd/MM/yyyy");
                const currentTradeDate = format(parseISO(tradeDate), "dd/MM/yyyy");

                if (
                    incomingTradeDate !== currentTradeDate ||
                    (desk && desk.deskId !== message.deskId && desk.deskId !== message.topLevelDeskId)
                ) {
                    dispatch(setStatus("loaded"));
                    return;
                }
            }

            const blotterTradesResponse = await blotterApi.getBlotterTrades(tradeDate, desk ? desk.deskId : null);
            dispatch(getBlotterTradesSuccess(blotterTradesResponse));
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { customDescription: "Error occurred in blotterThunks.ts throttledRefetch" },
            );
            dispatch(setStatus("loaded"));
        }
    },
    1000,
    {
        leading: true,
        trailing: true,
    },
);

export const refetchBlotterTrades = (
    message: BlotterTradeRealTimeMessage | BlotterTradePnlRealTimeMessage,
    autoUpdate: boolean,
    tradeDate: string,
    desk: Common.Nullable<DeskDTO>,
) => {
    return async (dispatch: AppDispatch) => {
        throttledRefetch(dispatch, message, autoUpdate, tradeDate, desk);
    };
};

export const refetchOnReconnect = (autoUpdate: boolean, tradeDate: string, desk: Common.Nullable<DeskDTO>) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (!autoUpdate) {
                dispatch(setIsBlotterUpdateRequired(true));
                return;
            }

            dispatch(setIsBlotterUpdateRequired(false));
            dispatch(setStatus("loading"));

            const blotterTradesResponse = await blotterApi.getBlotterTrades(tradeDate, desk ? desk.deskId : null);
            dispatch(getBlotterTradesSuccess(blotterTradesResponse));
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { customDescription: "Error occurred in blotterThunks.ts refetchOnReconnect" },
            );
            dispatch(setStatus("loaded"));
        } finally {
            dispatch(resetFetchOnReconnect());
        }
    };
};

export const setShowBlotterEntryForm = (showBlotterEntryForm: boolean) => {
    return (dispatch: AppDispatch) => {
        localStorage.showBlotterEntryForm = showBlotterEntryForm;
        dispatch(toggleBlotterEntryForm(showBlotterEntryForm));
    };
};

export const patchBlotterTrade = (id: number, blotterTrade: Partial<CreateBlotterTrade>) => {
    return async (dispatch: AppDispatch) => {
        try {
            const updatedBlotterTrade = await blotterApi.patchBlotterTrade(id, blotterTrade);
            dispatch(updateBlotterTrade(updatedBlotterTrade));
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { id, blotterTrade, customDescription: "Error occurred in blotterThunks.ts patchBlotterTrade" },
            );
            dispatch(setValidationMessage([`An error occurred updating blotter trade. ${error.message}`]));
        }
    };
};

export const deleteBlotterTrade = (id: number) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            const deskHasBeenSet = deskHasBeenSetSelector(state);
            const { selectedDeskId } = desksSelector(state);
            const { blotterDate } = state.features.blotter;

            await blotterApi.deleteBlotterTrade(id);

            if (deskHasBeenSet) {
                dispatch(fetchBlotterTrades(blotterDate, selectedDeskId!) as any);
            }
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { id, customDescription: "Error occurred in blotterThunks.ts deleteBlotterTrade" },
            );
            dispatch(setValidationMessage([`Failed to delete blotter trade ${id}`]));
        } finally {
            dispatch(setBlotterTradeToDelete());
        }
    };
};

export const duplicateBlotterTrade = (id: number, useTransactionDate: boolean) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            const deskHasBeenSet = deskHasBeenSetSelector(state);
            const { selectedDeskId } = desksSelector(state);
            const { blotterDate } = state.features.blotter;
            await blotterApi.duplicateBlotterTrade(id, useTransactionDate);

            if (deskHasBeenSet) {
                dispatch(fetchBlotterTrades(blotterDate, selectedDeskId!) as any);
            }
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                { id, customDescription: "Error occurred in blotterThunks.ts duplicateBlotterTrade" },
            );
            dispatch(setValidationMessage([`Failed to duplicate blotter trade ${id}`]));
        } finally {
            dispatch(setBlotterTradeToDuplicate());
        }
    };
};

export const submitBlotterTrade = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        const selectedDesk = selectedDeskSelector(state);
        const deskHasBeenSet = deskHasBeenSetSelector(state);
        const { selectedDeskId } = desksSelector(state);
        const showForm = showBlotterEntryFormSelector(state);
        const { blotterEntry, productGroupText, brokerText, blotterDate } = state.features.blotter;

        if (!showForm || !blotterEntry.deskId) return;

        if (
            selectedDesk &&
            !blotterEntry.id &&
            selectedDesk.childDesks.length > 0 &&
            selectedDesk.topLevelDeskId === selectedDesk.deskId
        ) {
            return;
        }

        const validationMessages: string[] = [];

        if (!blotterEntry.side) {
            validationMessages.push("Please select either BUY or SELL side.");
        }

        if (!blotterEntry.counterPartyTypeId || blotterEntry.counterPartyTypeId === NOT_SELECTED_COUNTERPARTY_ID) {
            validationMessages.push("Please select a counterparty.");
        }

        if (productGroupText.length > 0 && !blotterEntry.blotterProductGroupId) {
            validationMessages.push(`Incorrect product ${productGroupText}.`);
        }

        if (brokerText.length > 0 && !blotterEntry.brokerId) {
            validationMessages.push(`Incorrect broker ${brokerText}.`);
        }

        if (!!blotterEntry.tenor1StartDate && !!blotterEntry.tenor1EndDate) {
            const start = resolveDate(blotterEntry.tenor1StartDate);
            const end = resolveDate(blotterEntry.tenor1EndDate);

            if (!!start && !!end && differenceInDays(start, end) > 0) {
                validationMessages.push("Tenor 1 start date cannot be after tenor 1 end date");
            }
        }

        if (!!blotterEntry.tenor2StartDate && !!blotterEntry.tenor2EndDate) {
            const start = resolveDate(blotterEntry.tenor2StartDate);
            const end = resolveDate(blotterEntry.tenor2EndDate);

            if (!!start && !!end && differenceInDays(start, end) > 0) {
                validationMessages.push("Tenor 2 start date cannot be after tenor 2 end date");
            }
        }

        if (validationMessages.length > 0) {
            dispatch(setValidationMessage(validationMessages));
            return;
        }

        try {
            dispatch(setStatus("loading"));
            if (blotterEntry.id) {
                await blotterApi.updateBlotterTrade(blotterEntry);
            } else {
                await blotterApi.createBlotterTrade(blotterEntry);
            }

            dispatch(clearBlotterEntry());

            if (deskHasBeenSet) {
                dispatch(fetchBlotterTrades(blotterDate, selectedDeskId!) as any);
            }
        } catch (error: any) {
            appInsights.trackException(
                { error, severityLevel: SeverityLevel.Error },
                {
                    blotterEntry,
                    customDescription: `Error occurred in blotterThunks.ts submitBlotterTrade for ${
                        blotterEntry.id ? "updateBlotterTrade" : "createBlotterTrade"
                    }`,
                },
            );
            dispatch(setValidationMessage([`An error occurred submitting the blotter trade.`]));
        }
    };
};
