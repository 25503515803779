import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { BrokersAPIResponse, State } from "./types";

const initialState: State = {
    allBrokers: undefined,
    brokers: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
};

export const getBrokersSlice = createSlice({
    name: "brokers",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<{ status: Common.Status }>) => {
            state.meta.status = action.payload.status;
            return state;
        },
        getBrokersSuccess: (state, action: PayloadAction<{ search?: string; response: BrokersAPIResponse }>) => {
            if (action.payload.search) {
                state.brokers = action.payload.response.brokers;
            } else {
                state.allBrokers = action.payload.response.brokers;
            }
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            return state;
        },
        getBrokersFailed: (state, action: PayloadAction<{ message: string }>) => {
            state.meta.status = "error";
            state.meta.lastUpdated = Date.now();
            state.meta.message = action.payload.message;
            return state;
        },
    },
});

export const { setStatus, getBrokersSuccess, getBrokersFailed } = getBrokersSlice.actions;

const selectBrokers = (state: RootState) => state.features.brokers;
export const brokersSelector = createSelector(selectBrokers, (brokers) => brokers);
