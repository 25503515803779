import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import { format, parseISO, compareAsc, addMonths } from "date-fns";
import _ from "lodash";
import { LiveRiskResponseDto, State } from "./liveRiskTypes";
import { LiveRiskRealTimeMessage, RiskItemDto, TenorDateRiskItem } from "common/types";
import { calculateRiskSummaryTenorItems } from "common/utils/risk/getTenorRiskSummaryItems";

const processingDate = format(new Date(), "yyyy-MM-dd");

const initialState: State = {
    includeTas: false,
    decimalPosition: 0,
    groupedYears: [],
    processingDate,
    riskItems: undefined,
    riskSummaryTenorItems: undefined,
    meta: {
        status: "init",
        message: "",
        lastUpdated: undefined,
    },
    lastRealTimeMessage: undefined,
    signalRGroupName: undefined,
    rollForwardDateTime: undefined,
    isRiskUpdateRequired: false,
    isCrossExchangeView: false,
};

export const getLiveRiskSlice = createSlice({
    name: "liveRisk",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<Common.Status>) => {
            state.meta.status = action.payload;
            return state;
        },
        setProcessingDate: (state, action: PayloadAction<string>) => {
            state.processingDate = action.payload;
            return state;
        },
        getLiveRiskItemsSuccess: (state, action: PayloadAction<LiveRiskResponseDto>) => {
            state.riskItems = action.payload.riskItems;
            state.meta.status = "loaded";
            state.meta.lastUpdated = Date.now();
            state.riskSummaryTenorItems = calculateRiskSummaryTenorItems(action.payload.riskItems);
            return state;
        },
        getLiveRiskItemsFailed: (state, action: PayloadAction<string>) => {
            state.meta.status = "error";
            state.meta.message = action.payload;
            state.meta.lastUpdated = Date.now();
            return state;
        },
        increaseDecimalPosition: (state) => {
            if (state.decimalPosition === 4) return state;
            state.decimalPosition = state.decimalPosition + 1;
            return state;
        },
        decreaseDecimalPosition: (state) => {
            if (state.decimalPosition === 0) return state;
            state.decimalPosition = state.decimalPosition - 1;
            return state;
        },
        toggleGroupedYear: (state, action: PayloadAction<string>) => {
            if (state.groupedYears.some((y) => y === action.payload)) {
                state.groupedYears = [...state.groupedYears.filter((y) => y !== action.payload)];
            } else {
                state.groupedYears = [...state.groupedYears, action.payload];
            }
            return state;
        },
        setIncludeTas: (state, action: PayloadAction<boolean>) => {
            state.includeTas = action.payload;
            return state;
        },
        setIsCrossExchangeView: (state, action: PayloadAction<boolean>) => {
            state.isCrossExchangeView = action.payload;
            return state;
        },
        setLastRealTimeMessage: (state, action: PayloadAction<LiveRiskRealTimeMessage>) => {
            state.lastRealTimeMessage = action.payload;
            return state;
        },
        setSignalRGroupName: (state, action: PayloadAction<string | undefined>) => {
            state.signalRGroupName = action.payload;
            return state;
        },
        setRollForwardDateTime: (state, action: PayloadAction<string>) => {
            state.rollForwardDateTime = action.payload;
            return state;
        },
        setIsRiskUpdateRequired: (state, action: PayloadAction<boolean>) => {
            state.isRiskUpdateRequired = action.payload;
            return state;
        },
    },
});

export const {
    setProcessingDate,
    getLiveRiskItemsSuccess,
    getLiveRiskItemsFailed,
    setStatus,
    increaseDecimalPosition,
    decreaseDecimalPosition,
    toggleGroupedYear,
    setIncludeTas,
    setIsCrossExchangeView,
    setLastRealTimeMessage,
    setSignalRGroupName,
    setRollForwardDateTime,
    setIsRiskUpdateRequired,
} = getLiveRiskSlice.actions;

export const liveRiskSelector = (state: RootState) => state.features.liveRisk;

const selectLiveRiskRealTimeData = (state: RootState) => ({
    lastRealTimeMessage: state.features.liveRisk.lastRealTimeMessage,
    includeTas: state.features.liveRisk.includeTas,
    isCrossExchangeView: state.features.liveRisk.isCrossExchangeView,
    rollForwardDateTime: state.features.liveRisk.rollForwardDateTime,
    signalRGroupName: state.features.liveRisk.signalRGroupName,
    loadingStatus: state.features.liveRisk.meta.status,
    isRiskUpdateRequired: state.features.liveRisk.isRiskUpdateRequired,
});

export const liveRiskRealTimeDataSelector = createSelector(selectLiveRiskRealTimeData, (data) => data);

const selectLiveRiskGridData = (state: RootState) => ({
    riskItems: state.features.liveRisk.riskItems,
    decimalPosition: state.features.liveRisk.decimalPosition,
    groupedYears: state.features.liveRisk.groupedYears,
});

export const liveRiskGridDataSelector = createSelector(selectLiveRiskGridData, (data) => data);

const selectMeta = (state: RootState) => state.features.liveRisk.meta;

export const metaSelector = createSelector(selectMeta, (meta) => meta);

const selectRiskItemsAndCrossExchange = (state: RootState) => ({
    riskItems: state.features.liveRisk.riskItems,
    isCrossExchangeView: state.features.liveRisk.isCrossExchangeView,
});

export const riskItemsByGroupSelector = createSelector(selectRiskItemsAndCrossExchange, (state) => {
    return _.mapValues(
        _.groupBy(state.riskItems, (i) =>
            state.isCrossExchangeView && i.exchange ? `${i.productGroup} (${i.exchange})` : i.productGroup,
        ),
        (app) =>
            _.mapValues(
                _.groupBy(app, (i) => i.productName),
                (app) => _.groupBy(app, (i) => i.diffProductName),
            ),
    );
});

export const productGroupsSelector = createSelector(
    [
        (state: RootState) => ({
            desks: state.features.desks,
            isCrossExchangeView: state.features.liveRisk.isCrossExchangeView,
        }),
        riskItemsByGroupSelector,
    ],
    (state, riskItemProductGroups) => {
        const { desks, isCrossExchangeView } = state;
        const deskProductGroupNames = desks.productGroups?.map((dpg) => dpg.productGroup);
        const riskItemProductGroupNames = Object.keys(riskItemProductGroups);

        if (!deskProductGroupNames) {
            return null;
        }

        if (deskProductGroupNames.length === 0) {
            return riskItemProductGroupNames.sort();
        }

        if (isCrossExchangeView) {
            const groupWithExchangeToGroup = new Map<string, string>();
            const groupToExchangeGroups = new Map<string, string[]>();

            for (let i = 0; i < riskItemProductGroupNames.length; i++) {
                const productGroupWithExchangeName = riskItemProductGroupNames[i];
                const exchangeProductGroup = riskItemProductGroups[productGroupWithExchangeName];
                const product = exchangeProductGroup[Object.keys(exchangeProductGroup)[0]];
                const items = product[Object.keys(product)[0]];
                const productGroupName = items[0].productGroup;
                groupWithExchangeToGroup.set(productGroupWithExchangeName, productGroupName);
                const entry = groupToExchangeGroups.get(productGroupName);

                if (!entry) {
                    groupToExchangeGroups.set(productGroupName, []);
                }

                groupToExchangeGroups.get(productGroupName)!.push(productGroupWithExchangeName);
            }

            const productGroupNamesFromDesks: string[] = [];

            for (let i = 0; i < deskProductGroupNames.length; i++) {
                const groupName = deskProductGroupNames[i];
                const entry = groupToExchangeGroups.get(groupName);

                if (entry) {
                    productGroupNamesFromDesks.push(...entry);
                } else {
                    productGroupNamesFromDesks.push(groupName);
                }
            }

            return [
                ...productGroupNamesFromDesks,
                ...riskItemProductGroupNames
                    .filter(
                        (riskProductGroup) =>
                            !deskProductGroupNames.some(
                                (deskProductGroup) =>
                                    deskProductGroup === groupWithExchangeToGroup.get(riskProductGroup),
                            ),
                    )
                    .sort(),
            ];
        }

        return [
            ...deskProductGroupNames.filter((deskProductGroup) =>
                riskItemProductGroupNames.some((riskProductGroup) => riskProductGroup === deskProductGroup),
            ),
            ...riskItemProductGroupNames
                .filter(
                    (riskProductGroup) =>
                        !deskProductGroupNames.some((deskProductGroup) => deskProductGroup === riskProductGroup),
                )
                .sort(),
        ];
    },
);

const selectRiskItemsByTenor = (state: RootState) => ({
    riskItems: state.features.liveRisk.riskItems,
    groupedYears: state.features.liveRisk.groupedYears,
});

export const riskItemsByTenorSelector = createSelector(selectRiskItemsByTenor, ({ riskItems, groupedYears }) => {
    if (!riskItems) return [];

    // Get a unique list of diff product names
    const uniqueDiffProductNames = _.uniq(riskItems.map((r) => r.diffProductName));

    // Create an object with all the product names and amounts as key/value pairs for rendering on the grid
    const diffProductObject = uniqueDiffProductNames.reduce((prev, current) => {
        return { ...prev, [current]: 0 };
    }, {});

    // Create an object per tenor date / or year for groupings
    const groupedByYears: string[] = [];
    let newRiskItemsByTenor = _.uniq(riskItems.map((r) => r.tenorDate))
        .map((tenorDate) => {
            const year = format(parseISO(tenorDate), "yyyy");
            const isGroupedByYear = _.includes(groupedYears, year);

            if (isGroupedByYear) {
                if (_.includes(groupedByYears, year)) {
                    return null;
                }
                groupedByYears.push(year);
                return {
                    tenorDate: year,
                    year,
                    isGroupedByYear,
                    isGrandTotal: false,
                    ...diffProductObject,
                };
            }

            return {
                tenorDate,
                year: "",
                isGroupedByYear: false,
                isGrandTotal: false,
                ...diffProductObject,
            };
        })
        .filter(Boolean) as TenorDateRiskItem[];

    // Add in missing tenors where there are no risk items
    const allUniqueYears = new Set(newRiskItemsByTenor.map((a) => a.year));
    const allUniqueTenorDates = new Set(newRiskItemsByTenor.map((a) => a.tenorDate));

    const sortedTenorDates = newRiskItemsByTenor.map((a) => parseISO(a.tenorDate)).sort(compareAsc);
    const minTenorDate = sortedTenorDates[0];
    const maxTenorDate = sortedTenorDates.slice(-1)[0];

    const missingGroupedYears = groupedYears.filter((a) => !allUniqueYears.has(a));

    missingGroupedYears.forEach((year) => {
        newRiskItemsByTenor.push({
            tenorDate: year,
            year,
            isGroupedByYear: true,
            isGrandTotal: false,
            ...diffProductObject,
            exchange: null,
            isOverallGrandTotal: false,
        });
    });

    for (let tenorDate = addMonths(minTenorDate, 1); tenorDate < maxTenorDate; tenorDate = addMonths(tenorDate, 1)) {
        const year = format(tenorDate, "yyyy");
        if (!allUniqueYears.has(year) && missingGroupedYears.indexOf(year) === -1) {
            const formattedTenor = format(tenorDate, "yyyy-MM-dd'T'HH:mm:ss");
            if (!allUniqueTenorDates.has(formattedTenor)) {
                newRiskItemsByTenor.push({
                    tenorDate: formattedTenor,
                    year: "",
                    isGroupedByYear: false,
                    isGrandTotal: false,
                    ...diffProductObject,
                    exchange: null,
                    isOverallGrandTotal: false,
                });
            }
        }
    }

    // Sort with missing months added
    newRiskItemsByTenor = newRiskItemsByTenor.sort((a, b) => compareAsc(parseISO(a.tenorDate), parseISO(b.tenorDate)));

    // Populate only the first row with the year for row spanning
    const rowSpanYears: string[] = [];
    return newRiskItemsByTenor.map((item) => {
        const year = format(parseISO(item.tenorDate), "yyyy");
        if (rowSpanYears.some((y) => y === year)) {
            return { ...item };
        }
        rowSpanYears.push(year);
        return { ...item, year };
    });
});

export const riskItemsByTenorGrandTotalSelector = createSelector(selectRiskItemsAndCrossExchange, (state) => {
    const riskItems = state.riskItems;

    if (!riskItems) return [];

    const overallTotal = riskItems.reduce((prev, current, _index) => grandTotalReduce(prev, current, _index), {
        year: "",
        tenorDate: format(new Date(), "yyyy-MM-dd"),
        isGrandTotal: true,
        isOverallGrandTotal: true,
        isGroupedByYear: false,
        exchange: null,
    });

    if (!state.isCrossExchangeView) return [overallTotal];

    const exchanges = Array.from(new Set(riskItems?.map((a) => a.exchange))).sort();
    const exchangeTotals = exchanges.map((exchange) =>
        riskItems
            .filter((item) => item.exchange === exchange)
            .reduce((prev, current, _index) => grandTotalReduce(prev, current, _index), {
                year: "",
                tenorDate: format(new Date(), "yyyy-MM-dd"),
                isGrandTotal: true,
                isOverallGrandTotal: false,
                isGroupedByYear: false,
                exchange: exchange,
            }),
    );

    return [overallTotal, ...exchangeTotals];
});

const grandTotalReduce = (prev: any, current: RiskItemDto, _inde: number) => {
    const riskItem: TenorDateRiskItem = prev;
    riskItem[current.diffProductName] = (riskItem[current.diffProductName] || 0) + current.quantityBBL;
    return { ...prev, ...riskItem };
};

export const productsByProductGroupSelector = createSelector(selectRiskItemsAndCrossExchange, (state) => {
    if (!state.riskItems) return {};

    let riskItems = state.riskItems;

    if (state.isCrossExchangeView) {
        riskItems = addExchangeToProductGroup(riskItems);
    }

    return productsByProductGroup(riskItems);
});

const productsByProductGroup = (riskItems: RiskItemDto[]) =>
    riskItems.reduce((prev, current) => {
        const newValue: any = { ...prev };
        newValue[current.productGroup] = newValue[current.productGroup] || {};

        newValue[current.productGroup][current.productName] = newValue[current.productGroup][current.productName] || {};

        newValue[current.productGroup][current.productName][current.tenorDate] =
            newValue[current.productGroup][current.productName][current.tenorDate] || 0;

        newValue[current.productGroup][current.productName][current.tenorDate] += current.quantityBBL / 1000;

        return newValue;
    }, {});

export const productDiffsByProductGroupSelector = createSelector(selectRiskItemsAndCrossExchange, (state) => {
    if (!state.riskItems) return {};

    let riskItems = state.riskItems;

    if (state.isCrossExchangeView) {
        riskItems = addExchangeToProductGroup(riskItems);
    }

    return productDiffsByProductGroup(riskItems);
});

const productDiffsByProductGroup = (riskItems: RiskItemDto[]) =>
    riskItems.reduce((prev, current) => {
        const newValue: any = { ...prev };
        newValue[current.productGroup] = newValue[current.productGroup] || {};

        newValue[current.productGroup][current.productName] = newValue[current.productGroup][current.productName] || {};

        newValue[current.productGroup][current.productName][current.diffProductName] =
            newValue[current.productGroup][current.productName][current.diffProductName] || {};

        newValue[current.productGroup][current.productName][current.diffProductName][current.tenorDate] =
            newValue[current.productGroup][current.productName][current.diffProductName][current.tenorDate] || 0;

        newValue[current.productGroup][current.productName][current.diffProductName][current.tenorDate] +=
            current.quantityBBL / 1000;

        return newValue;
    }, {});

export const addExchangeToProductGroup = (riskItems: RiskItemDto[] | undefined): RiskItemDto[] => {
    if (!riskItems) {
        return [];
    }
    const riskItemsCopy: RiskItemDto[] = [];

    for (let i = 0; i < riskItems.length; i++) {
        const item = riskItems[i];
        const newItem: RiskItemDto = {
            onyxProductId: item.onyxProductId,
            diffOnyxProductId: item.diffOnyxProductId,
            tenorDate: item.tenorDate,
            quantityBBL: item.quantityBBL,
            productGroupId: item.productGroupId,
            productGroup: `${item.productGroup} (${item.exchange})`,
            productName: item.productName,
            diffProductName: item.diffProductName,
            exchangeId: item.exchangeId,
            exchange: item.exchange,
            diffSymbol: item.diffSymbol,
            symbol: item.symbol,
        };
        riskItemsCopy.push(newItem);
    }

    return riskItemsCopy;
};

const selectRiskSummaryTenorItems = (state: RootState) => state.features.liveRisk.riskSummaryTenorItems;
export const riskSummaryTenorItemsSelector = createSelector(selectRiskSummaryTenorItems, (riskSummaryTenorItems) => {
    return riskSummaryTenorItems;
});

const selectLastUpdated = (state: RootState) => state.features.liveRisk.meta.lastUpdated;
export const lastUpdatedSelector = createSelector(selectLastUpdated, (lastUpdated) => lastUpdated);
