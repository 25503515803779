import { EditableCallbackParams, NewValueParams, ValueFormatterParams } from "@ag-grid-community/core";
import { dateFormatter, timeFormatter } from "common/formatters";
import { areDatesEqual } from "common/utils/areDatesEqual";
import { format } from "date-fns";
import { updateBlotterTrade } from "features/blotter/blotterSlice";
import { ColumnParams } from "features/blotter/blotterTypes";

export const getColumnDefs = (params: ColumnParams): any[] => {
    const {
        canEdit,
        dispatch,
        patchBlotterTrade,
        valueFormatter,
        isCurrentPeriod,
        tenorPeriods,
        counterPartyTypes,
        blotterProductGroups,
        brokers,
        exchanges,
        desks,
        tradedUnits,
    } = params;

    return [
        {
            colId: "action",
            field: "id",
            headerName: "Actions",
            cellRenderer: "ActionButtons",
            minWidth: 230,
            hide: !canEdit,
            filter: false,
        },
        {
            field: "deskName",
            headerName: "Desk",
            minWidth: 130,
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "side",
            minWidth: 65,
            cellEditor: "SideDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), side: newValue }));
                    dispatch(patchBlotterTrade(data.id, { side: newValue }));
                }
            },
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "quantity",
            type: "numericColumn",
            cellEditor: "NumericEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), quantity: newValue }));
                    dispatch(patchBlotterTrade(data.id, { quantity: +newValue }));
                }
            },
            minWidth: 90,
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "tradedUnitName",
            floatingFilterComponentParams: { suppressFilterButton: true },
            headerName: "Unit",
            cellEditor: "UnitsDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    const tradedUnitId = tradedUnits?.find((a) => a.name === newValue)?.tradedUnitId || null;

                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), tradedUnitId: tradedUnitId }));
                    dispatch(patchBlotterTrade(data.id, { tradedUnitId: tradedUnitId }));
                }
            },

            minWidth: 80,
        },
        {
            field: "blotterProductGroupName",
            floatingFilterComponentParams: { suppressFilterButton: true },
            headerName: "Product",
            cellEditor: "ProductGroupEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    const blotterProductGroupId =
                        blotterProductGroups?.find((a) => a.name === newValue)?.blotterProductGroupId || null;

                    dispatch(
                        updateBlotterTrade({
                            ...convertDatesToStrings(data),
                            blotterProductGroupId: blotterProductGroupId,
                        }),
                    );
                    dispatch(
                        patchBlotterTrade(data.id, {
                            blotterProductGroupId: blotterProductGroupId,
                        }),
                    );
                }
            },
            minWidth: 120,
        },
        {
            field: "symbol",
            minWidth: 90,
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "tenor1Period",
            headerName: "T1 Period",
            minWidth: 90,
            cellEditor: "TenorPeriodDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), tenor1Period: newValue }));
                    dispatch(patchBlotterTrade(data.id, { tenor1Period: newValue }));
                }
            },

            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "tenor1Year",
            headerName: "T1 Year",
            minWidth: 80,
            cellEditor: "TenorYearDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), tenor1Year: newValue }));
                    dispatch(patchBlotterTrade(data.id, { tenor1Year: newValue }));
                }
            },
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "tenor1StartDate",
            headerName: "T1 Start",
            valueFormatter: dateFormatter,
            filter: "agDateColumnFilter",
            floatingFilterComponentParams: { suppressFilterButton: true },
            minWidth: 135,
            cellEditor: "DateEditor",
            cellEditorPopup: true,
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (!areDatesEqual(newValue, oldValue)) {
                    const patch = {
                        tenor1Period: newValue ? null : data.tenor1Period,
                        tenor1Year: newValue ? null : data.tenor1Year,
                        tenor1StartDate: newValue ? format(newValue, "yyyy-MM-dd") : null,
                    };
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), ...patch }));
                    dispatch(patchBlotterTrade(data.id, patch));
                }
            },
        },
        {
            field: "tenor1EndDate",
            headerName: "T1 End",
            valueFormatter: dateFormatter,
            minWidth: 135,
            filter: "agDateColumnFilter",
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellEditor: "DateEditor",
            cellEditorPopup: true,
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (!areDatesEqual(newValue, oldValue)) {
                    const patch = {
                        tenor1Period: newValue ? null : data.tenor1Period,
                        tenor1Year: newValue ? null : data.tenor1Year,
                        tenor1EndDate: newValue ? format(newValue, "yyyy-MM-dd") : null,
                    };
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), ...patch }));
                    dispatch(patchBlotterTrade(data.id, patch));
                }
            },
        },
        {
            field: "tenor2Period",
            headerName: "T2 Period",
            minWidth: 95,
            cellEditor: "TenorPeriodDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), tenor2Period: newValue }));
                    dispatch(patchBlotterTrade(data.id, { tenor2Period: newValue }));
                }
            },
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "tenor2Year",
            headerName: "T2 Year",
            minWidth: 80,
            cellEditor: "TenorYearDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), tenor2Year: newValue }));
                    dispatch(patchBlotterTrade(data.id, { tenor2Year: newValue }));
                }
            },
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "tenor2StartDate",
            headerName: "T2 Start",
            valueFormatter: dateFormatter,
            minWidth: 135,
            filter: "agDateColumnFilter",
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellEditor: "DateEditor",
            cellEditorPopup: true,
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (!areDatesEqual(newValue, oldValue)) {
                    const patch = {
                        tenor2Period: newValue ? null : data.tenor2Period,
                        tenor2Year: newValue ? null : data.tenor2Year,
                        tenor2StartDate: newValue ? format(newValue, "yyyy-MM-dd") : null,
                    };
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), ...patch }));
                    dispatch(patchBlotterTrade(data.id, patch));
                }
            },
        },
        {
            field: "tenor2EndDate",
            headerName: "T2 End",
            valueFormatter: dateFormatter,
            minWidth: 135,
            filter: "agDateColumnFilter",
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellEditor: "DateEditor",
            cellEditorPopup: true,
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (!areDatesEqual(newValue, oldValue)) {
                    const patch = {
                        tenor2Period: newValue ? null : data.tenor2Period,
                        tenor2Year: newValue ? null : data.tenor2Year,
                        tenor2EndDate: newValue ? format(newValue, "yyyy-MM-dd") : null,
                    };
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), ...patch }));
                    dispatch(patchBlotterTrade(data.id, patch));
                }
            },
        },
        {
            field: "price",
            type: "numericColumn",
            cellEditor: "NumericEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), price: newValue }));
                    dispatch(patchBlotterTrade(data.id, { price: newValue }));
                }
            },
            minWidth: 80,
            floatingFilterComponentParams: { suppressFilterButton: true },
            valueFormatter: (params: ValueFormatterParams) => valueFormatter(params, 4),
        },
        {
            field: "livePrice",
            type: "numericColumn",
            valueFormatter: (params: ValueFormatterParams) => valueFormatter(params, 6),
            minWidth: 95,
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "brokerName",
            floatingFilterComponentParams: { suppressFilterButton: true },
            headerName: "Broker Alias",
            minWidth: 105,
            cellEditor: "BrokersDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    const brokerId = brokers?.find((a) => a.name === newValue)?.brokerId || null;

                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), brokerId: brokerId }));
                    dispatch(
                        patchBlotterTrade(data.id, {
                            brokerId: brokerId,
                        }),
                    );
                }
            },
            editable: ({ data }: EditableCallbackParams) => canEdit && !data.matchedTrades,
        },
        {
            field: "counterPartyTypeName",
            minWidth: 95,
            floatingFilterComponentParams: { suppressFilterButton: true },
            headerName: "CP Type",
            cellEditor: "CounterPartyTypeDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    const counterPartyTypeId =
                        counterPartyTypes?.find((a) => a.name === newValue)?.counterPartyTypeId || null;

                    dispatch(
                        updateBlotterTrade({ ...convertDatesToStrings(data), counterPartyTypeId: counterPartyTypeId }),
                    );
                    dispatch(
                        patchBlotterTrade(data.id, {
                            counterPartyTypeId: counterPartyTypeId,
                        }),
                    );
                }
            },
            editable: true,
        },
        {
            field: "exchangeName",
            minWidth: 95,
            floatingFilterComponentParams: { suppressFilterButton: true },
            headerName: "Exchange",
            cellEditor: "ExchangeDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    const exchangeId = exchanges?.find((a) => a.name === newValue)?.exchangeId || null;
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), exchangeId: exchangeId }));
                    dispatch(patchBlotterTrade(data.id, { exchangeId: exchangeId }));
                }
            },
            editable: ({ data }: EditableCallbackParams) => canEdit && !data.matchedTrades,
        },
        {
            field: "isStrip",
            minWidth: 80,
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellEditor: "BooleanDropdownEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), isStrip: newValue }));
                    dispatch(patchBlotterTrade(data.id, { isStrip: newValue }));
                }
            },
        },
        {
            field: "pricingDay",
            minWidth: 105,
            type: "numericColumn",
            cellEditor: "NumericEditor",
            editable: ({ data }: EditableCallbackParams) =>
                isCurrentPeriod(
                    tenorPeriods.find((p) => p.name === data.tenor1Period),
                    data.tenor1Year,
                    data.tenor1StartDate,
                    data.tenor1EndDate,
                ),
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), pricingDay: +newValue }));
                    dispatch(patchBlotterTrade(data.id, { pricingDay: +newValue }));
                }
            },
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "brokerageAdjustment",
            minWidth: 170,
            type: "numericColumn",
            cellEditor: "NumericEditor",
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), brokerageAdjustment: newValue }));
                    dispatch(patchBlotterTrade(data.id, { brokerageAdjustment: newValue }));
                }
            },
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "brokerage",
            type: "numericColumn",
            valueFormatter: (params: ValueFormatterParams) => valueFormatter(params, 2),
            minWidth: 95,
            floatingFilterComponentParams: { suppressFilterButton: true },
        },
        {
            field: "pnl",
            type: "numericColumn",
            valueFormatter: (params: ValueFormatterParams) => valueFormatter(params, 2),
            floatingFilterComponentParams: { suppressFilterButton: true },
            headerName: "PnL",
            minWidth: 95,
        },
        {
            field: "createdDateTime",
            type: "numericColumn",
            floatingFilterComponentParams: { suppressFilterButton: true },
            valueFormatter: timeFormatter,
            headerName: "Created",
            minWidth: 85,
        },
        {
            field: "createdBy",
            floatingFilterComponentParams: { suppressFilterButton: true },
            minWidth: 150,
        },
        {
            field: "matchedTrades",
            floatingFilterComponentParams: { suppressFilterButton: true },
            minWidth: 140,
        },
        {
            field: "transferDeskName",
            headerName: "Transfer Desk",
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellEditor: "TransferDeskDropdownEditor",
            minWidth: 120,
            onCellValueChanged: ({ newValue, oldValue, data }: NewValueParams) => {
                if (newValue !== oldValue) {
                    const transferDeskId =
                        desks
                            ?.map((a) => a.childDesks)
                            .flatMap((a) => a)
                            .find((a) => a.name === newValue)?.deskId || null;

                    dispatch(updateBlotterTrade({ ...convertDatesToStrings(data), transferDeskId: transferDeskId }));
                    dispatch(
                        patchBlotterTrade(data.id, {
                            transferDeskId: transferDeskId,
                        }),
                    );
                }
            },
        },
        {
            field: "id",
            type: "numericColumn",
            floatingFilterComponentParams: { suppressFilterButton: true },
            minWidth: 80,
        },
    ];
};

const convertDatesToStrings = (blotterTrade: any) => {
    const copy = {
        ...blotterTrade,
        date: blotterTrade.date ? format(blotterTrade.date, "yyyy-MM-dd") : undefined,
        tenor1StartDate: blotterTrade.tenor1StartDate ? format(blotterTrade.tenor1StartDate, "yyyy-MM-dd") : undefined,
        tenor1EndDate: blotterTrade.tenor1EndDate ? format(blotterTrade.tenor1EndDate, "yyyy-MM-dd") : undefined,
        tenor2StartDate: blotterTrade.tenor2StartDate ? format(blotterTrade.tenor2StartDate, "yyyy-MM-dd") : undefined,
        tenor2EndDate: blotterTrade.tenor2EndDate ? format(blotterTrade.tenor2EndDate, "yyyy-MM-dd") : undefined,
        createdDateTime: blotterTrade.createdDateTime ? format(blotterTrade.createdDateTime, "yyyy-MM-dd") : undefined,
    };

    return copy;
};
