import { Dropdown } from "common/components/dropdown";
import { FormLabel } from "common/components/form";
import { blotterEntryExchangeIdSelector, updateBlotterEntry } from "features/blotter/blotterSlice";
import { exchangesSelector } from "features/exchanges/exchangesSlice";
import { fetchExchanges } from "features/exchanges/exchangesThunks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export const Exchange: React.FC = () => {
    const dispatch = useDispatch();
    const exchanges = useSelector(exchangesSelector);
    const exchangeId = useSelector(blotterEntryExchangeIdSelector);

    React.useEffect(() => {
        dispatch(fetchExchanges());
    }, []);

    const handleSelection = (selectedExchange?: Common.DropdownItem) => {
        dispatch(updateBlotterEntry({ exchangeId: selectedExchange ? selectedExchange.id : null }));
        return true;
    };

    return (
        <div className="flex flex-col w-32 blotterEntryForm__spacing" data-testid="exchange">
            <div className="flex justify-center">
                <FormLabel>Exchange</FormLabel>
            </div>
            {exchanges && (
                <Dropdown
                    selectedId={exchangeId}
                    data={exchanges.map((e) => ({ id: e.exchangeId, value: e.name }))}
                    onChange={handleSelection}
                />
            )}
        </div>
    );
};
