import { HeaderLogo } from "features/navigation/components";
import * as React from "react";
import "./pages.styles.css";

export const ErrorPage = () => {
    return (
        <div className="flex w-full flex-1 justify-center items-center flex-col">
            <HeaderLogo />
            <div className="page__contentBox p-6 rounded-lg mt-8 text-center text-2xl bg-white-500">
                <span className="uppercase font-bold ">An error has occurred</span>
                <p>Please try to login again or contact tech support</p>
            </div>
        </div>
    );
};
