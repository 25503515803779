import { DateComponent } from "common/components/dateComponent";
import * as React from "react";

export const GridDateFilter = React.forwardRef((props, ref) => {
    const [date, setDate] = React.useState<Date | null>(null);

    React.useImperativeHandle(ref, () => ({
        getDate() {
            return date;
        },

        setDate(date: Date) {
            setDate(date);
        },
    }));

    const handleSelectedDate = (date: Common.Nullable<Date>) => {
        setDate(date);
        (props as any).onDateChanged();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "Enter") {
            event.stopPropagation();
        }
    };

    return (
        <div className="ag-input-wrapper custom-date-filter" role="presentation" onKeyDown={handleKeyDown}>
            <DateComponent date={date} onSelectedDate={handleSelectedDate} type="filter" portalId="root-portal" />
        </div>
    );
});
