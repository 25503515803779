export const defaultQuantities = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 18, 20, 25, 30, 37, 45, 50, 62, 75, 100, 125, 150, 200, 250, 300,
];

export const tenorPeriods: Common.Period[] = [
    {
        name: "Q1",
        months: [1, 2, 3],
    },
    {
        name: "Q2",
        months: [4, 5, 6],
    },
    {
        name: "Q3",
        months: [7, 8, 9],
    },
    {
        name: "Q4",
        months: [10, 11, 12],
    },
    {
        name: "Jan",
        months: [1],
    },
    {
        name: "Feb",
        months: [2],
    },
    {
        name: "Mar",
        months: [3],
    },
    {
        name: "Apr",
        months: [4],
    },
    {
        name: "May",
        months: [5],
    },
    {
        name: "Jun",
        months: [6],
    },
    {
        name: "Jul",
        months: [7],
    },
    {
        name: "Aug",
        months: [8],
    },
    {
        name: "Sep",
        months: [9],
    },
    {
        name: "Oct",
        months: [10],
    },
    {
        name: "Nov",
        months: [11],
    },
    {
        name: "Dec",
        months: [12],
    },
    {
        name: "Cal",
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
];

const tenorYearsCount = 4;
export const tenorYears = new Array(tenorYearsCount)
    .fill(0)
    .map((_, index) => (new Date().getFullYear() + index).toString());

export const LIVE_RISK_TYPE_EXCHANGE = "exchange";
export const LIVE_RISK_TYPE_BLOTTER = "blotter";
export const ROLL_OFF_RISK_TYPE_EXCHANGE = "exchange";
export const ROLL_OFF_RISK_TYPE_BLOTTER = "blotter";
export const EOD_RISK_TYPE_ABN = "abn";
export const EOD_RISK_TYPE_ONYX = "onyx";
export const SPREADS_ONLY = "spreads-only";
export const CROSS_EXCHANGE_RISK = "cross-exchange";
export const LIVE_RISK = "live";

export const UI_VERSION_RESPONSE_HEADER = "x-ui-version";

export const appName = "Eagle";

export const Onyx = "Onyx";

export const defaultGridHeaderHeight = 35;

export const spreaderTenorCodes = ["F/G", "G/H", "H/J", "J/K", "K/M", "M/N", "N/Q", "Q/U", "U/V", "V/X", "X/Z", "Z/F"];

export const AxiosTimeoutError = "ECONNABORTED";

export const AxiosCancelledError = "ERR_CANCELED";

export const AjaxTimeouts = [5000, 10000, 15000, 15000, 15000, 60000];
