import * as React from "react";

export const ConnectedIcon: React.FC = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="8" fill="#F3F3F3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24ZM18.3374 13.6L19.2 14.4287L15.9288 17.5713L15.0662 18.4L12.8 16.2229L13.6626 15.3942L15.0662 16.7425L18.3374 13.6Z"
            fill="#059669"
        />
    </svg>
);
