import isFinite from "lodash/isFinite";
import { cellShouldBeBlank } from "./riskHelperFunctions";

export const diffProductValueGetter = (
    productDiffsByProductGroup: any,
    productGroup: string,
    product: string,
    diffProduct: string,
    data: any,
) => {
    if (cellShouldBeBlank(data)) return null;

    const { isGrandTotal, isGroupedByYear } = data;

    // Return the total across all tenors
    if (isGrandTotal) {
        return Object.keys((productDiffsByProductGroup as any)[productGroup][product][diffProduct]).reduce(
            (prev, tenorDate) => {
                prev += (productDiffsByProductGroup as any)[productGroup][product][diffProduct][tenorDate];
                return prev;
            },
            0,
        );
    }

    // Return the total grouped by year
    if (isGroupedByYear) {
        return Object.keys((productDiffsByProductGroup as any)[productGroup][product][diffProduct]).reduce(
            (prev, tenorDate) => {
                if (data.year === tenorDate.substr(0, 4)) {
                    prev += (productDiffsByProductGroup as any)[productGroup][product][diffProduct][tenorDate];
                }
                return prev;
            },
            0,
        );
    }

    // Return the amount for the diff product by tenor date
    const value = (productDiffsByProductGroup as any)[productGroup][product][diffProduct][data.tenorDate];
    return isFinite(value) ? value : null;
};
